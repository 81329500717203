import { Injectable, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, merge, ReplaySubject, Subject } from 'rxjs';
import { filter, map, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

import { OrderDetails } from 'projects/shared/src/lib/models/order';
import { OrderService } from 'projects/shared/src/lib/services/order.service';
import { OrderPaymentDetails, PromoCodeDetails, UserDetails } from 'projects/shared/src/public-api';
import { OrderPaymentService } from 'projects/shared/src/lib/services/order-payment.service';
import { SortDirections } from 'projects/shared/src/lib/enumerations/sort-directions';
import { OrderPaymentTypes } from 'projects/shared/src/lib/enumerations/order-payment-types';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import * as moment from 'moment';
import { SocketService } from 'projects/shared/src/lib/services/socket.service';
import { EpsonPrintService } from 'projects/shared/src/lib/services/epson-print.service';
import { PrinterService } from 'projects/shared/src/lib/services/printer.service';

export enum CheckoutState {
  ViewCategory = 1,
  PayCash = 2,
  PayDigital = 3,
  RefundCash = 4,
  PayReader = 5,
}

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {
  public order$: Observable<OrderDetails>;
  public onOrderChanged$: Subject<void> = new Subject<void>();
  private orderChanged$!: Observable<void>;

  public paymentsTotal$!: Observable<number>;
  public refundTotal$!: Observable<number>;
  public difference$!: Observable<number>;
  public orderPayments$!: Observable<Array<OrderPaymentDetails>>;

  public state$: BehaviorSubject<CheckoutState> = new BehaviorSubject<CheckoutState>(CheckoutState.ViewCategory);
  public success$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public destroy$: Subject<void> = new Subject();

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private orderService: OrderService,
    private router: Router,
    private orderpaymentService: OrderPaymentService,
    private toastr: ToastrService,
    private socketService: SocketService,
    private epsonPrintService: EpsonPrintService,
    private printerService: PrinterService,
  ) {
    this.orderChanged$ = this.onOrderChanged$.asObservable();


    const orderId$ = this.route.params
      .pipe(
        map(params => params.id),
        shareReplay(1),
        takeUntil(this.destroy$)
      );

    const webhookReceived$ = combineLatest([orderId$, this.socketService.webhookReceived$]).pipe(
      filter(([orderId, orderInfo]) => orderId == orderInfo.orderId),
      switchMap(([orderId, orderInfo]) => this.orderService.recalculate(orderInfo.orderId)),
    );

    const orderChanged$ = combineLatest([orderId$, this.orderChanged$])
      .pipe(
        switchMap(([orderId]) => this.orderService.recalculate(orderId)),
        shareReplay(1),
        takeUntil(this.destroy$)
      );
    const getOrder$ = orderId$
      .pipe(
        switchMap(id => this.orderService.get(id)),
        takeUntil(this.destroy$)
      );

    this.order$ = merge(orderChanged$, getOrder$, webhookReceived$)
      .pipe(
        shareReplay(1),
        takeUntil(this.destroy$)
      );

    this.orderPayments$ = this.order$.pipe(
      switchMap((order) => {
        const pagedResult$ = this.orderpaymentService.query({ order: order.id, sortColumns: [{ column: "created_at", direction: SortDirections.Descending }] });
        return pagedResult$.records$;
      }),
      takeUntil(this.destroy$)
    );
    this.paymentsTotal$ = this.orderPayments$.pipe(
      map((ops) => {
        const digitalTotal = ops.filter(op => op.type == OrderPaymentTypes.Digital).reduce((total, op) => total + op.amount, 0);
        const cashTotal = ops.filter(op => op.type == OrderPaymentTypes.Cash).reduce((total, op) => total + op.amount, 0);
        return digitalTotal + cashTotal;
      }),
      takeUntil(this.destroy$)
    );
    this.refundTotal$ = this.orderPayments$.pipe(
      map((ops) => {
        const digitalRefundTotal = ops.filter(op => op.type == OrderPaymentTypes.DigitalRefund).reduce((total, op) => total + op.amount, 0);
        const cashRefundTotal = ops.filter(op => op.type == OrderPaymentTypes.CashRefund).reduce((total, op) => total + op.amount, 0);
        const creditBalanceRefundTotal = ops.filter(op => op.type == OrderPaymentTypes.CreditBalanceRefund).reduce((total, op) => total + op.amount, 0);
        return digitalRefundTotal + cashRefundTotal + creditBalanceRefundTotal;
      }),
      takeUntil(this.destroy$)
    );
    this.difference$ = combineLatest([this.order$, this.refundTotal$]).pipe(
      map(([order, refunds]) => {
        return order.finaltotal;
      }),
      takeUntil(this.destroy$)
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public addProduct(orderId: number, productId: number): Observable<any> {
    return this.orderService.addProduct(orderId, productId);
  }

  public addPhysicalGiftCertificateProduct(orderId: number, productId: number, amount: number, code: string): Observable<any> {
    return this.orderService.addPhysicalGiftCertificateProduct(orderId, productId, amount, code);
  }

  public addDigitalGiftCertificateProduct(orderId: number, productId: number, amount: number, email: string): Observable<any> {
    return this.orderService.addDigitalGiftCertificateProduct(orderId, productId, amount, email);
  }

  public setProductQuantity(orderId: number, productId: number, quantity: number): Observable<any> {
    return this.orderService.setProductQuantity(orderId, productId, quantity);
  }

  public removeProduct(orderId: number, productId: number, productOrderId: number): Observable<any> {
    return this.orderService.removeProduct(orderId, productId, productOrderId);
  }

  public addGolfPass(orderId: number, golfpassId: number): Observable<any> {
    return this.orderService.addGolfPass(orderId, golfpassId);
  }

  public removeGolfPass(orderId: number, golfpassId: number): Observable<any> {
    return this.orderService.removeGolfPass(orderId, golfpassId);
  }

  public async checkoutSucces(message: string,
                              orderId: number,
                              printerIds: number[],
                              email = '',
                              skipNavigation = false): Promise<void> {
    this.success$.next(true);
    this.toastr.success(message);

    let adminPath: any[] = ['/admin', 'tee-sheet'];
    let managerPath: any[] = ['/manager', 'tee-sheet'];

    let order = await this.order$.pipe(take(1)).toPromise();

    if (order?.tee_times?.[0]?.datestr) {
      let extra = {queryParam: JSON.stringify({selectedDate: moment(order?.tee_times?.[0]?.datestr).toISOString()})};
      adminPath.push(extra);
      managerPath.push(extra);
    }

    if (orderId) {
      await this.orderService.completeOrder(orderId).pipe(
        takeUntil(this.destroy$)
      ).toPromise();
    }

    if (orderId) {
      order = await this.orderService.get(orderId).toPromise();
      for (const printerId of printerIds) {
        this.printerService.get(printerId).subscribe((printerDetails) => {
          this.epsonPrintService.printReceipt(printerDetails, order);
        });
      }
    }

    if (orderId && email) {
      this.orderService.sendReceipt(orderId, email).pipe(
        switchMap(() => this.authService.viewManager$),
        takeUntil(this.destroy$)
      )
        .subscribe((viewManager) => {
          this.toastr.success("Emailed receipt successfully.");
          if(!skipNavigation) {
            if (!viewManager) {
              this.router.navigate(adminPath);
            } else {
              this.router.navigate(managerPath);
            }
          }
        }
        );
    } else {
      if(!skipNavigation) {
        this.authService.viewManager$
        .pipe(
          take(1),
          takeUntil(this.destroy$)
        )
        .subscribe((viewManager) => {
          if (!viewManager) {
            this.router.navigate(adminPath);
          } else {
            this.router.navigate(managerPath);
          }
        }
        );
      }
    }
  }

  public updateOrderUser(order: OrderDetails, user: UserDetails) {
    return this.orderService.update(order.id, {
      ...order,
      users_permissions_user: user.id
    });
  }

  public updateOrderPromo(order: OrderDetails, promo: PromoCodeDetails | null) {
    return this.orderService.update(order.id, {
      promo_code: <any>(promo?.id ?? null)
    });
  }

  public isSucces(): boolean {
    return this.success$.value;
  }
}
