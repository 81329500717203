<div class="modal-header">
    <h4 class="modal-title pull-left">Select Golf Pass</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="cancel()">
        <span class="modal-close" aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <h6  class="page-header">Golf Pass List</h6>
    <div class="table-responsive">
        <gcl-admin-golf-pass-table 
            [enableCreate]="false" 
            [enableManage]="false"
            [id_nin]="id_nin"
            (onRowClicked)="onPassClicked($event)"
        ></gcl-admin-golf-pass-table>
    </div>
    <div class="d-flex justify-content-end align-items-center">
        <button class="btn btn-outline-light-green" (click)="cancel()">Cancel</button>
        <button class="btn btn-light-green ml-2" [disabled]="selectedPass === null ? true : false" (click)="selectPass()">Select Golf Pass</button>
    </div>
</div>
