import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { IDirty } from 'projects/shared/src/lib/models/IDirty';
import { OrderService } from 'projects/shared/src/lib/services/order.service';
import { take } from 'rxjs/operators';
import { SharedCheckoutComponent } from '../../../shared/modules/checkout/shared-checkout/shared-checkout.component';

@Component({
  selector: 'gcl-admin-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit, AfterViewInit, IDirty {
  @ViewChild('checkout') checkout!: SharedCheckoutComponent;

  isDirty(): boolean {
    return !this.success;
  };

  confirmedCallback(orderId: number) {
    this.orderService.cancel(orderId).pipe(take(1)).subscribe();
  }

  private success: boolean = false;

  constructor(private router: Router, private orderService: OrderService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    let confirmModalMessage = 'Are you sure you want to cancel this order?';
    let confirmModalTitle = 'Cancel Order';
    
    this.checkout.cancel.subscribe(async orderId => {
      let path: any[] = ['/manager', 'tee-sheet'];

      let order = await this.orderService.get(orderId).pipe(take(1)).toPromise();
  
      if(order?.tee_times?.[0]?.datestr) {
        let extra = {queryParam: JSON.stringify({selectedDate: moment(order?.tee_times?.[0]?.datestr).toISOString()})};
        path.push(extra);
      }

      this.router.navigate(path, {state: {confirmParams: orderId, confirmModalMessage, confirmModalTitle}});
    })
  }

  public onSuccess(success: boolean): void {
    this.success = success;
  }

  public cancel(): void {

  }
}
