import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ModalModule } from 'ngx-bootstrap/modal';

import { SharedModule } from 'projects/shared/src/public-api';
import { PromoCodesRoutingModule } from './promo-codes-routing.module';
import { PromoCodeCreateComponent } from './promo-code-create/promo-code-create.component';
import { PromoCodeEditComponent } from './promo-code-edit/promo-code-edit.component';
import { PromoCodeListComponent } from './promo-code-list/promo-code-list.component';
import { PromoCodeViewComponent } from './promo-code-view/promo-code-view.component';


@NgModule({
  declarations: [PromoCodeCreateComponent, PromoCodeEditComponent, PromoCodeListComponent, PromoCodeViewComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,

    FontAwesomeModule,
    ModalModule.forRoot(),
    SharedModule,

    PromoCodesRoutingModule
  ]
})
export class PromoCodesModule { }
