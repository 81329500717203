import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ProductType } from 'projects/shared/src/lib/enumerations/product-type';
import { OrderDetails } from 'projects/shared/src/public-api';
import { PhysicalGiftCardAssignment } from '../../models/physical-gift-card-assignment';

@Component({
  selector: 'gcl-admin-add-physical-gift-certificate-modal',
  templateUrl: './add-physical-gift-certificate-modal.component.html',
  styleUrls: ['./add-physical-gift-certificate-modal.component.scss']
})
export class AddPhysicalGiftCertificateModalComponent implements OnInit {
  giftCertificateType!: ProductType;
  order!: OrderDetails;

  @Output() onGiftCertificateAssign = new EventEmitter<PhysicalGiftCardAssignment>();

  get code() { return this.form.get('code'); }
  get amount() { return this.form.get('amount'); }

  public form = this.fb.group({
    code: ['', [Validators.required]],
    amount: [null, [Validators.required, Validators.min(0)]]
  });

  constructor(private fb: FormBuilder, private bsModalRef: BsModalRef) {
  }


  ngOnInit(): void {
  }

  assignCertificate() {
    if(this.form.valid) {
      this.onGiftCertificateAssign.emit(this.form.value);
    }
  }

  cancel() {
    this.bsModalRef.hide();
  }
}
