import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';

import { SharedModule } from 'projects/shared/src/public-api';

import { StaffRoutingModule } from './staff-routing.module';
import { StaffCreateComponent } from './staff-create/staff-create.component';
import { StaffEditComponent } from './staff-edit/staff-edit.component';
import { StaffListComponent } from './staff-list/staff-list.component';
import { StaffViewComponent } from './staff-view/staff-view.component';


@NgModule({
  declarations: [StaffCreateComponent, StaffEditComponent, StaffListComponent, StaffViewComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    FontAwesomeModule,

    StaffRoutingModule
  ]
})
export class StaffModule { }
