<div class="container-fluid py-2 h-100 overflow-y-auto">
    <div class="row">
        <div class="col-12">
            <h6  class="page-header">Golf Passes List</h6>
            <gcl-lib-table 
                [totalItems]="total$ | async" 
                [itemsPerPage]="initialPagination.itemsPerPage" 
                [sortBy]="initialPagination.sortBy" 
                [sortDescending]="initialPagination.sortDescending"
                [page]="initialPagination.page"
                [searchTxt]="initialPagination.search"
                (onPage)="pagination$.next($event)" 
                [tableClass]="'table table-striped table-hover'" 
                (onCreate)="goToCreate()">
                <thead>
                    <tr>
                        <th columnSort="name">Name</th>
                        <th columnSort="price" width="200" class="text-center">Price</th>
                        <th columnSort="available" width="200" class="text-center">Available</th>
                        <th columnSort="taxable" width="200" class="text-center">Taxable</th>
                        <th width="200" class="text-center">Manage</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let record of records$ | async">
                        <td>{{record.name}}</td>
                        <td class="text-center">{{record.price | currency}}</td>
                        <td class="text-center">{{(record.available ? "Yes" : "No")}}</td>
                        <td class="text-center">{{(record.taxable ? "Yes" : "No")}}</td>
                        <td class="text-center">
                            <div class="btn-group btn-group-sm">
                                <a [routerLink]="['/admin','golf-passes', record.id, 'view']" [queryParams]="getQueryParams()" class="btn btn-link btn-primary-light">View</a>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="(total$ | async) == 0">
                        <td colspan="4">No golf passes.</td>
                    </tr>
                </tbody>
            </gcl-lib-table>
        </div>
    </div>
</div>