<gcl-lib-table 
[totalItems]="total$ | async" 
[sortBy]="'id'" 
(onPage)="pagination$.next($event)" 
[tableClass]="'table table-striped table-hover'" 
[enableCreate]="false"
>
<thead>
    <tr>
        <th columnSort="order.id">ORDER NUMBER</th>
        <th columnSort="created_at">TIMESTAMP</th>
        <th columnSort="amount">TOTAL</th>
        <th columnSort="type">TRANSACTION TYPE</th>
        <!-- <th width="100" class="text-center">Manage</th> -->
    </tr>
</thead>
<tbody>
    <tr *ngFor="let transaction of records$ | async">
        <td>{{transaction?.order?.id ? transaction.order.id : 'N/A'}}</td>
        <td>{{transaction?.created_at | date}}</td>
        <td>{{transaction.amount | currency}}</td>
        <td>{{GetCreditBalanceTransactionTypesDisplayName(transaction.type)}}</td>
        <!-- <td class="text-center">
            <div class="btn-group btn-group-sm">
                <a [routerLink]="['/admin','orders', order.id, 'view']" class="btn btn-link btn-primary-light">View</a>
            </div>
        </td> -->
    </tr>
    <tr *ngIf="(total$ | async) == 0">
        <td colspan="9">No credit balance transactions.</td>
    </tr>
</tbody>
</gcl-lib-table>
