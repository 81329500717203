import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';

import { environment } from 'projects/admin/src/environments/environment';

import { GolfPassDetails } from 'projects/shared/src/lib/models/golfpass';
import { ConfirmationModalService } from 'projects/shared/src/lib/services/confirmation-modal.service';
import { GolfPassService } from 'projects/shared/src/lib/services/golf-pass.service';
import { ScheduleDetails, RoundDetails, OnPageEvent } from 'projects/shared/src/public-api';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { ScheduleService } from 'projects/shared/src/lib/services/schedule.service';
import { SortDirections } from 'projects/shared/src/lib/enumerations/sort-directions';

@Component({
  selector: 'gcl-admin-golf-pass-view',
  templateUrl: './golf-pass-view.component.html',
  styleUrls: ['./golf-pass-view.component.scss']
})
export class GolfPassViewComponent implements OnInit {
  public apiUrl: string = environment.apiUrl;
  public faChevronLeft = faChevronLeft;
  public SortDirections = SortDirections;

  public golfpass$!: Observable<GolfPassDetails>;
  total$: BehaviorSubject<number> = new BehaviorSubject(1);
  schedules$: ReplaySubject<ScheduleDetails[]> = new ReplaySubject(1);

  pagination$ = new BehaviorSubject<OnPageEvent>({
    itemsPerPage: 10,
    page: 1,
    sortBy: 'id',
    sortDescending: true
  });

  private destroyed$ = new Subject();

  constructor(
    private golfpassService: GolfPassService,
    private route: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private confirmationModalService: ConfirmationModalService,
    private authService: AuthService,
    private scheduleService: ScheduleService
  ) { }

  ngOnInit(): void {
    const golfpassId$ = this.route.params.pipe(
      map(params => params.golfpassid)
    );

    this.golfpass$ = golfpassId$.pipe(
      switchMap(golfpassId => this.golfpassService.get(golfpassId)),
      shareReplay(1)
    );

    this.pagination$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        this.getPageResult();
      });
  }

  private getPageResult() {
    combineLatest([this.golfpass$, this.authService.course$])
      .pipe(
        takeUntil(this.destroyed$)
      ).subscribe(([golfpass, course]) => {
        const pagedResult = this.scheduleService.query({
          skip: (this.pagination$.value.page - 1) * this.pagination$.value.itemsPerPage,
          take: this.pagination$.value.itemsPerPage,
          sortColumns: [{
            column: this.pagination$.value.sortBy,
            direction: this.pagination$.value.sortDescending ? SortDirections.Descending : SortDirections.Ascending,
          }],
          search: this.pagination$.value.search,

          golfPass: golfpass.id,
          course: course?.id
        });

        pagedResult.filteredCount$.subscribe((total) => this.total$.next(total));
        pagedResult.records$.subscribe((records) => this.schedules$.next(records));
      });
  }


  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public openDeleteModal() {
    this.confirmationModalService.showConfirmationModal({
      title: 'Confirm Deletion',
      message: 'You are about to delete this golf pass? This action cannot be undone.'
    }).subscribe(accept => {
      if (accept) {
        this.delete();
      }
    })
  }

  private delete(): void {
    this.golfpass$.pipe(
      switchMap((golfpass: GolfPassDetails) => this.golfpassService.delete(golfpass.id)),
      shareReplay(1),
      takeUntil(this.destroyed$)
    ).subscribe((golfpass: GolfPassDetails) => {
      this.toastrService.success("Golf Pass deleted successfully!");
      this.router.navigate(["/admin", "golf-passes"]);
    })
  }

  public getQueryParams() {
    return this.route.snapshot.queryParams;
  }

}
