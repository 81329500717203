import { Component, OnDestroy, OnInit, Output, EventEmitter, Input, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { combineLatest, forkJoin, Observable, of, Subject } from 'rxjs';
import { map, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { OrderDetails } from 'projects/shared/src/lib/models/order';
import { CategoryDetails, ProductOrderDetails, UserDetails } from 'projects/shared/src/public-api';
import { OrderService } from 'projects/shared/src/lib/services/order.service';
import { GolfOrderService } from 'projects/shared/src/lib/services/golf-order.service';
import { ProductOrderService } from 'projects/shared/src/lib/services/product-order.service';
import { UserService } from 'projects/shared/src/lib/services/user.service';
import { OrderPaymentService } from 'projects/shared/src/lib/services/order-payment.service';
import { ConfirmationModalService } from 'projects/shared/src/lib/services/confirmation-modal.service';
import { OrderPaymentTypes } from 'projects/shared/src/lib/enumerations/order-payment-types';

import { ViewProductComponent } from '../components/view-product/view-product.component';
import { ViewGolfPassComponent } from '../components/view-golf-pass/view-golf-pass.component';
import { PlayerSelectModalComponent } from '../../../components/player-select-modal/player-select-modal.component';
import { CheckoutService, CheckoutState } from '../checkout.service';

import { Course } from 'projects/shared/src/lib/models/course';
import { getDateTime } from 'projects/shared/src/lib/utili/get-datetime';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { StripeService } from 'projects/shared/src/lib/services/stripe.service';
import { CategoryService } from 'projects/shared/src/lib/services/category.service';
import { GolfPassOrderService } from 'projects/shared/src/lib/services/golf-pass-order.service';
import { PlayerEditModalComponent } from '../../../components/player-edit-modal/player-edit-modal.component';
import { ToastrService } from 'ngx-toastr';
import { PromoCode, PromoCodeDetails } from 'projects/shared/src/lib/models/promo-code';
import { PromoCodeService } from 'projects/shared/src/lib/services/promo-code.service';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { PlayerCreateModalComponent } from '../../../components/player-create-modal/player-create-modal.component';
import { PromoSelectModalComponent } from '../../../components/promo-select-modal/promo-select-modal.component';
import { getOrderDiscountAmount } from '../../../utils/get-order-discount-amount';
import { CreditBalanceTransactionService } from 'projects/shared/src/lib/services/credit-balance-transaction.service';
import { GratuityModalComponent } from '../../../components/gratuity-modal/gratuity-modal.component';

export interface DisplayOrderItem {
  orderId: number;
  category: string;
  name: string;
  quantity: number;
  unitprice: number;
  price: number;
  taxrate: number;
}

export interface DisplayGolfProducItem extends DisplayOrderItem {
  golfOrderId: number;
  golfproductId: number;
  refunded: boolean;
}

export interface DisplayGolfPassItem extends DisplayOrderItem {
  golfpassOrderId: number;
  golfpassId: number;
}

export interface DisplayProductAddOnItem extends DisplayOrderItem {
  productOrderId: number;
  productId: number;
  taxable: boolean;
  available: boolean;
  refunded: boolean;
}

@Component({
  selector: 'gcl-admin-shared-checkout',
  templateUrl: './shared-checkout.component.html',
  styleUrls: ['./shared-checkout.component.scss'],
  providers: [CheckoutService]
})
export class SharedCheckoutComponent implements OnInit, OnDestroy {
  public faChevronLeft = faChevronLeft;
  public getDateTime = getDateTime;

  course$!: Observable<Course>;
  order$!: Observable<OrderDetails>;
  user$!: Observable<UserDetails | null>;
  creditBalance$!: Observable<number | null>;

  golfOrders$!: Observable<DisplayGolfProducItem[]>;
  golfpassOrders$!: Observable<DisplayGolfPassItem[]>;
  productOrders$!: Observable<DisplayProductAddOnItem[]>;
  tip$!: Observable<ProductOrderDetails | undefined>;
  categories$!: Observable<CategoryDetails[]>;

  difference$!: Observable<number>;
  public isCollapsed: boolean = false;

  public bsModalRef!: BsModalRef;
  public userBsModalRef!: BsModalRef;

  public CheckoutState = CheckoutState;
  public state$!: Observable<CheckoutState>;

  public today: Date = new Date();
  hasOrders$!: Observable<boolean>;

  public form: FormGroup = this.fb.group({
    promoCode: [null]
  });
  get promoCode() { return this.form.get("promoCode") as FormControl }

  promoCodes$!: Observable<PromoCodeDetails[]>;

  applyCreditBalance: boolean = false;
  togglingCreditBalance: boolean = false;

  @Input()
  registerPage: boolean = false;

  @Output()
  cancel: EventEmitter<number> = new EventEmitter();

  @Output()
  isDirty: EventEmitter<boolean> = new EventEmitter();

  private destroy$: Subject<any> = new Subject();

  @ViewChildren(PopoverDirective) popovers!: QueryList<PopoverDirective>;

  constructor(
    private authService: AuthService,
    private orderService: OrderService,
    private orderPaymentService: OrderPaymentService,
    private checkoutService: CheckoutService,
    private confirmationModalService: ConfirmationModalService,
    private golfOrderService: GolfOrderService,
    private golfPassOrderService: GolfPassOrderService,
    private productOrderService: ProductOrderService,
    private categoryService: CategoryService,
    private userService: UserService,
    private modalService: BsModalService,
    private stripeService: StripeService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private promoCodeService: PromoCodeService,
    private creditBalanceTransactionService: CreditBalanceTransactionService,
  ) { }

  ngOnInit(): void {
    this.course$ = this.authService.course$
      .pipe(
        take(1),
        takeUntil(this.destroy$)
      );

    this.order$ = this.checkoutService.order$.pipe(tap(order => this.applyCreditBalance = order.useCreditBalance));
    this.difference$ = this.checkoutService.difference$;
    this.state$ = this.checkoutService.state$;

    this.user$ = this.order$.pipe(
      switchMap(order => this.userService.get(order.users_permissions_user?.id ?? 0)),
      shareReplay(1),
      takeUntil(this.destroy$)
    );

    this.creditBalance$ = this.order$.pipe(
      switchMap(order => this.creditBalanceTransactionService.getBalance(order.users_permissions_user?.id ?? 0)),
      shareReplay(1),
      takeUntil(this.destroy$)
    );

    this.hasOrders$ = this.order$.pipe(
      map(o => o.golforders.length > 0 || o.golfpassorders.length > 0 || o.productorders.length > 0)
    );

    this.promoCodes$ = this.promoCodeService.getAll().pipe(take(1));

    this.initGolfOrders();
    this.initGolfPassOrders();
    this.initProductOrders();
    this.initTip();

    this.checkoutService.success$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((success) => this.isDirty.emit(success));
  }

  private initGolfOrders(): void {

    this.golfOrders$ = combineLatest([this.course$, this.order$])
      .pipe(
        switchMap(([course, order]) => combineLatest([of(course), of(order), combineLatest(order.golforders.map(go => this.golfOrderService.get(go.id)))])),
        switchMap(([course, order, golforders]) => of(golforders.map(go => <DisplayGolfProducItem>{
          orderId: order.id,
          golfOrderId: go.id,
          golfproductId: go.golfproduct.id,
          category: "GOLF PRODUCT",
          name: go.golfproduct.name,
          quantity: go.quantity,
          price: go.extprice,
          unitprice: go.unitprice,
          taxrate: (course?.taxrate / 100),
        }))),
        takeUntil(this.destroy$)
      );

  }
  private initGolfPassOrders(): void {

    this.golfpassOrders$ = combineLatest([this.course$, this.order$])
      .pipe(
        switchMap(([course, order]) => {
          const pagedResult = this.golfPassOrderService.query({ order: order.id });
          return combineLatest([of(course), of(order), pagedResult.records$]);
        }),
        switchMap(([course, order, golfpassorders]) => of(golfpassorders.map(gpo => <DisplayGolfPassItem>{
          orderId: order.id,
          golfpassOrderId: gpo.id,
          golfpassId: gpo.golfpass.id,
          category: "GOLF PASSES",
          name: gpo.golfpass.name,
          quantity: gpo.quantity,
          unitprice: gpo.unitprice,
          price: gpo.extprice,
          taxrate: ((course?.taxrate || 0) / 100),
        }))),
        takeUntil(this.destroy$)
      );
  }

  private initProductOrders(): void {

    this.categories$ = this.course$
      .pipe(
        switchMap(course => {
          const pagedResult = this.categoryService.query({ course: course.id });
          return pagedResult.records$;
        }),
        takeUntil(this.destroy$)
      );

    this.productOrders$ = combineLatest([this.course$, this.order$, this.categories$]).pipe(
      switchMap(([course, order, categories]) => {
        const pagedResult = this.productOrderService.query({ order: order.id });
        return combineLatest([of(course), of(order), pagedResult.records$, of(categories)]);
      }),
      switchMap(([course, order, productOrders, categories]) => of(productOrders.map(po => <DisplayProductAddOnItem>{
        orderId: order.id,
        productOrderId: po.id,
        productId: po.product.id,
        category: categories.find(cat => cat.id == po?.product?.category)?.name || "PRODUCT",
        name: po.product.name,
        quantity: po.quantity,
        price: po.extprice,
        unitprice: po.unitprice,
        taxrate: po.product.taxable ? ((course?.taxrate || 0) / 100) : 0,
        taxable: po.product.taxable,
        available: po.product.available
      }).filter(po => po.name !== "Tip"))),
      takeUntil(this.destroy$)
    );

    this.productOrders$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((addons) => {
      const unavalaible = addons.filter(addon => !addon.available);
      if (unavalaible.length > 0) {
        this.confirmAddOnRemoval(unavalaible);
      }
    });
  }

  private initTip() {
    this.tip$ = this.order$.pipe(
      switchMap((order) => {
        const pagedResult = this.productOrderService.query({ order: order.id });
        
        return pagedResult.records$.pipe(map(productOrders => productOrders.find(po => po.product.name === "Tip")));        
      })
    );
  }

  public confirmAddOnRemoval(unavailable: Array<DisplayProductAddOnItem>): void {
    let message = "<p>There are products that are no longer available on the order.</p>";
    message += "<ul>"
    message += unavailable.map((addon) => `<li>${addon.name} (${addon.quantity}x)</li>`)
    message += "</ul>"
    this.confirmationModalService.showConfirmationModal({
      title: "Confirm removing unavailable add-ons",
      message: message,
      isHTML: true
    }).subscribe((accept) => {
      if (accept) {
        let obs$: Array<Observable<any>> = [];
        unavailable.forEach(addon => obs$.push(this.checkoutService.removeProduct(addon.orderId, addon.productId, addon.productOrderId)));
        forkJoin(obs$).pipe(takeUntil(this.destroy$)).subscribe(() => this.checkoutService.onOrderChanged$.next());
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  
  public getFirstTeeTime(order: OrderDetails): Date {
    const firstTime = order.tee_times[0];
    return getDateTime(firstTime?.datestr as string, firstTime?.timestr as string)
  }

  public getLastTeeTime(order: OrderDetails): Date {
    const lastTime = order.tee_times[order.tee_times.length - 1];
    return getDateTime(lastTime?.datestr as string, lastTime?.timestr as string)
  }

  public onState(state: CheckoutState): void {
    this.checkoutService.state$.next(state);
  }

  public payClick(): void {
    let modalRef = this.modalService.show(GratuityModalComponent, {
      initialState: {
        order$: this.order$.pipe(take(1))
      },
      class: 'modal-dialog-centered modal-md',
      ignoreBackdropClick: true
    });
    
    if (!modalRef.content) {
      return;
    }

    modalRef.content.confirm$
    .pipe(take(1))
    .subscribe(() => {
      this.checkoutService.onOrderChanged$.next();
      this.onState(CheckoutState.PayDigital);
    });

    modalRef.content.cancel$
    .pipe(take(1))
    .subscribe(() => {
      this.checkoutService.onOrderChanged$.next();
    });
  }

  public onCancel(): void {
    this.order$.pipe(take(1)).subscribe(order => this.cancel.emit(order.id))
  }

  public async onPayLater(orderId: number): Promise<void> {
    await this.checkoutService.checkoutSucces("Order submitted successfully", orderId, []);
  }

  public openViewGolfPassModal(golfpassOrderId: number) {
    combineLatest([this.course$, this.golfPassOrderService.get(golfpassOrderId)])
      .pipe(
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe(([course, golfpassOrder]) => {

        let initialState = {
          order$: this.checkoutService.order$,
          golfpassOrder: golfpassOrder,
          taxrate: (course?.taxrate || 0) / 100
        };

        const bsModalRef = this.modalService.show(ViewGolfPassComponent, {
          ignoreBackdropClick: true,
          class: 'modal-dialog-centered',
          initialState
        });

        bsModalRef.content?.remove
          .subscribe(() => {
            this.checkoutService.removeGolfPass(golfpassOrder.order.id, golfpassOrder.golfpass.id)
              .pipe(
                switchMap(() => this.orderService.recalculate(golfpassOrder.order.id)),
                takeUntil(this.destroy$)
              )
              .subscribe(() => {
                this.checkoutService.onOrderChanged$.next();
                bsModalRef.hide();
              })
          });
      });
  }

  public openViewProductModal(productOrderId: number): void {
    combineLatest([this.course$, this.productOrderService.get(productOrderId)])
      .pipe(
        shareReplay(1),
        takeUntil(this.destroy$)
      )
      .subscribe(([course, productOrder]) => {
        let initialState = {
          order$: this.checkoutService.order$,
          productOrder: productOrder,
          taxrate: ((course?.taxrate || 0) / 100),
        };

        const bsModalRef = this.modalService.show(ViewProductComponent, {
          ignoreBackdropClick: true,
          class: 'modal-dialog-centered',
          initialState
        });

        bsModalRef.content?.setQuantity.subscribe((qty: { orderId: number, productId: number, quantity: number }) => {
          (this.checkoutService.setProductQuantity(qty.orderId, qty.productId, qty.quantity))
            .pipe(
              switchMap(() => this.orderService.recalculate(qty.orderId)),
              takeUntil(this.destroy$)
            )
            .subscribe(() => {
              this.checkoutService.onOrderChanged$.next();
              bsModalRef.hide();
            })
        });

        bsModalRef.content?.remove.subscribe((qty: { orderId: number, productId: number }) => {
          this.checkoutService.removeProduct(qty.orderId, qty.productId, productOrderId)
            .pipe(
              switchMap(() => this.orderService.recalculate(qty.orderId)),
              takeUntil(this.destroy$)
            )
            .subscribe(() => {
              this.checkoutService.onOrderChanged$.next();
              bsModalRef.hide();
            })
        });
      });
  }

  public openEditUserModal(user: UserDetails | null): void {
    if (user != null) {
      const bsModalRef = this.modalService.show(PlayerEditModalComponent, {
        ignoreBackdropClick: true,
        class: 'modal-dialog-centered modal-lg',
        initialState: {
          userId: user?.id
        }
      });
      bsModalRef.content?.submit$.subscribe((user: UserDetails) => this.checkoutService.onOrderChanged$.next());
    }
  }

  public confirmCashPaymentRefund(): void {
    combineLatest([this.checkoutService.difference$, this.order$]).subscribe(([refundAmount, order]) => {
      this.confirmationModalService.showConfirmationModal({
        title: "Confirm Refund",
        message: `You are about to refund ${this.convertToCurrencyFormat(refundAmount)}.`
      }).subscribe((accept) => {
        if (accept) {
          this.refundCashPayment(order, refundAmount);
        }
      });
    });
  }

  private refundCashPayment(order: OrderDetails, refundAmount: number): void {
    this.orderPaymentService.create({
      amount: -1 * refundAmount,
      order: order.id,
      success: true,
      type: OrderPaymentTypes.CashRefund
    }).subscribe(async (op) => await this.checkoutService.checkoutSucces(`${refundAmount} has been refunded.`, order.id, []));
  }

  public confirmDigitalPaymentRefund(): void {
    combineLatest([this.checkoutService.difference$, this.order$]).subscribe(([refundAmount, order]) => {
      this.confirmationModalService.showConfirmationModal({
        title: "Confirm Refund",
        message: `You are about to refund ${this.convertToCurrencyFormat(refundAmount)}.`
      }).subscribe((accept) => {
        if (accept) {
          this.refundDigitalPayment(order, refundAmount);
        }
      });
    });
  }

  private convertToCurrencyFormat(amount: number): string {
    const options = { style: 'currency', currency: 'USD' };
    return new Intl.NumberFormat('en-US', options).format(amount);
  }

  private refundDigitalPayment(order: OrderDetails, refundAmount: number): void {
    this.orderService.refund(order, Math.abs(refundAmount))
      .subscribe(async (op) => await this.checkoutService.checkoutSucces(`${refundAmount} has been refunded.`, order.id, []));
  }

  async openSelectUserModal(order: OrderDetails) {
    const selectModalRef = this.modalService.show(PlayerSelectModalComponent, {
      ignoreBackdropClick: true,
      class: "modal-lg modal-dialog-centered",
    });

    if (selectModalRef.content) {
      selectModalRef.content.select$.subscribe(async (user: UserDetails | null) => {
        if (user) {
          this.checkoutService.updateOrderUser(order, user)
            .pipe(takeUntil(this.destroy$))
            .subscribe((order) => {
              this.checkoutService.onOrderChanged$.next();
              this.stripeService.reloadPaymentList$.next(order.users_permissions_user?.id as number);
            });
        }
        selectModalRef.hide();
      });

      selectModalRef.content.openCreateModal$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.openCreateModal(order);
        selectModalRef.hide();
      });

      selectModalRef.content.openEditModal$
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe((_user: UserDetails) => {
          this.openEditModal(order, _user);
          selectModalRef.hide();
        });
      }
  }

  public openEditModal(order: OrderDetails, user: UserDetails): void {
    const editModalRef = this.modalService.show(PlayerEditModalComponent, {
      ignoreBackdropClick: true,
      class: "modal-lg modal-dialog-centered",
      initialState: {
        userId: user.id
      }
    });

    if (editModalRef.content) {
      editModalRef.content.submit$
        .pipe(
          tap((user: UserDetails) => {
            if (user) {
              this.checkoutService.updateOrderUser(order, user)
                .pipe(takeUntil(this.destroy$))
                .subscribe((order) => {
                  this.checkoutService.onOrderChanged$.next();
                  this.stripeService.reloadPaymentList$.next(order.users_permissions_user?.id as number);
                });
            }
            editModalRef.hide();
          }),
          takeUntil(this.destroy$)
        ).subscribe();

      editModalRef.content.cancel$
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe(() => editModalRef.hide());
    }
  }

  public openCreateModal(order: OrderDetails): void {
    const createModalRef = this.modalService.show(PlayerCreateModalComponent, {
      ignoreBackdropClick: true,
      class: "modal-lg modal-dialog-centered"
    });

    if (createModalRef.content) {
      createModalRef.content.submit$
        .pipe(
          tap((user: UserDetails) => {
            if (user) {
              this.checkoutService.updateOrderUser(order, user)
                .pipe(takeUntil(this.destroy$))
                .subscribe((order) => {
                  this.checkoutService.onOrderChanged$.next();
                  this.stripeService.reloadPaymentList$.next(order.users_permissions_user?.id as number);
                });
            }            
            createModalRef.hide();
          }),
          takeUntil(this.destroy$)
        ).subscribe();

      createModalRef.content.cancel$
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe(() => createModalRef.hide());
    }
  }

  public discountAmount(order: OrderDetails) {
    return getOrderDiscountAmount(order);
  }

  public showDiscount(order: OrderDetails) {
    return this.discountAmount(order) > 0;
  }

  public openPromoModal(order: OrderDetails) {
    const promoModalRef = this.modalService.show(PromoSelectModalComponent, {
      ignoreBackdropClick: true,
      class: "modal-lg modal-dialog-centered"
    });

    if (promoModalRef.content) {
      promoModalRef.content.select$.subscribe(async (promo: PromoCodeDetails | null) => {
        if (promo) {
          this.checkoutService.updateOrderPromo(order, promo)
          .pipe(
            switchMap(() => this.orderService.recalculate(order.id)),
            takeUntil(this.destroy$)
          )
          .subscribe((order) => {
            this.checkoutService.onOrderChanged$.next();
          });
        }
        promoModalRef.hide();
      });
    }
  }

  public removePromo(order: OrderDetails) {
    this.checkoutService.updateOrderPromo(order, null)
    .pipe(
      switchMap(() => this.orderService.recalculate(order.id)),
      takeUntil(this.destroy$)
    )
    .subscribe((order) => {
      this.checkoutService.onOrderChanged$.next();
    });
  }

  public hidePopover() {
    this.popovers.forEach((popover: PopoverDirective) => {
      popover.hide();
    });  
  }

  public async toggledCreditBalance(useCreditBalance: boolean) {
    if(this.togglingCreditBalance) {
      this.applyCreditBalance = !this.applyCreditBalance;
      return;
    }

    this.togglingCreditBalance = true;

    this.order$.pipe(
      switchMap(order => this.orderService.adminToggleCreditBalance(order.id, useCreditBalance).pipe(take(1))),
      take(1)
    ).subscribe(
      order => {
        this.applyCreditBalance = order.useCreditBalance;
        this.togglingCreditBalance = false;
        this.checkoutService.onOrderChanged$.next();
      },
      error => {
        console.error('Error setting useCreditBalance');
        this.togglingCreditBalance = false;
        this.applyCreditBalance = !useCreditBalance;
      }
    )
  }
}
