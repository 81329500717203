import { Component, Input, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { setTime } from 'ngx-bootstrap/chronos/utils/date-setters';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { GoogleMapsCoordinate } from '../../models/google-maps-coordinate';
import { NormalizedAddress } from '../../models/normalized-address';
import { AddressService } from '../../services/address.service';

@Component({
  selector: 'gcl-lib-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.css']
})
export class MapsComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  centerAddress?: string;
  @Input()
  centerCoordinate?: GoogleMapsCoordinate;

  @Input()
  address?: string;
  @Input()
  coordinates?: Array<GoogleMapsCoordinate>;

  @Input()
  maptype: "roadmap" | "satellite" | "hybrid" | "terrain" = "satellite";

  @ViewChildren(MapInfoWindow) infoWindowsView!: QueryList<MapInfoWindow>;

  public apiLoaded$!: Observable<boolean>;
  public optionLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public mapOptions!: google.maps.MapOptions;

  public centerMarker?: GoogleMapsCoordinate;
  public markerPositions: Array<GoogleMapsCoordinate> = [];

  private destroy$: Subject<boolean> = new Subject();

  constructor(private addressService: AddressService) {
    this.apiLoaded$ = of(true);
  }

  ngOnInit(): void {
    if (!this.centerAddress && !this.centerCoordinate) {
      throw new Error("Maps Component - center address and/or coordinate are required.");
    }

    this.apiLoaded$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((loaded: boolean) => {
        if (loaded) {
          this.optionLoaded$.next(false);
          this.initMap();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Only reload if map is already loaded.
    if (this.optionLoaded$.value) {
      if (changes["centerAddress"] || changes["centerCoordinate"] || changes["maptype"] || changes["address"]) {
        this.optionLoaded$.next(false);
        this.initMap();
      }
      if (changes["coordinates"]) {
        this.optionLoaded$.next(false);
        this.clearAllMarkers();
        this.initCoordinateMarkers();
        this.optionLoaded$.next(true);
      }
    }
  }

  private initMap() {
    this.initMapOptions();

    if (this.centerAddress || this.centerCoordinate) {
      this.initCenter(this.centerAddress, this.centerCoordinate, () => {
        if (this.address) {
          this.initPlaceMarker(this.address, undefined, () => this.initCoordinateMarkerAndLoadOptions());
        } else {
          this.initCoordinateMarkerAndLoadOptions();
        }
      });
    } else if (this.address) {
      this.initCenter(this.address, undefined, () => this.initPlaceMarker(this.address, undefined, () => this.initCoordinateMarkerAndLoadOptions()));
    }
  }

  private initMapOptions(): void {
    this.mapOptions = {
      mapTypeId: this.maptype,
      mapTypeControl: false,
      zoom: 17,  // Building view,
      center: undefined
    };
    this.centerMarker = undefined;
    this.markerPositions = [];
  }

  private initCoordinateMarkerAndLoadOptions(): void {
    this.initCoordinateMarkers();
    this.optionLoaded$.next(true);
  }

  private initCenter(address?: string, coordinate?: GoogleMapsCoordinate, callback?: Function): void {
    if (address) {
      this.initNormalizedAddress(address, (normalizeAddress: NormalizedAddress) => {
        this.setCenterMarker(normalizeAddress.latitude, normalizeAddress.longitude);
        if (callback) { callback(); }
      });
    }
    else if (coordinate) {
      this.setCenterMarker(coordinate.coordinate.lat, coordinate.coordinate.lng);
      if (callback) { callback(); }
    }
  }

  private setCenterMarker(lat: number, lng: number): void {
    this.mapOptions.center = {
      lat: lat,
      lng: lng
    };

    const homeIcon = {
      url: 'assets/golf.svg',
      scaledSize: new google.maps.Size(40, 40),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(0, 32)
    };

    this.centerCoordinate = {
      coordinate: {
        lat: lat,
        lng: lng
      },
      options: {
        icon: homeIcon,
        draggable: false
      }
    };
  }

  private initPlaceMarker(address?: string, coordinate?: GoogleMapsCoordinate, callback?: Function): void {
    if (address) {
      this.initNormalizedAddress(address, (normalizeAddress: NormalizedAddress) => {
        this.addMarker({
          coordinate: {
            lat: normalizeAddress.latitude,
            lng: normalizeAddress.longitude
          }
        });
        if (callback) { callback(); }
      });
    } else if (coordinate) {
      this.addMarker(coordinate);
      if (callback) { callback(); }
    }
  }

  private initNormalizedAddress(address: string, callback: Function): void {
    this.addressService.normalizeAddress(address)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((normalizeAddress: NormalizedAddress) => callback(normalizeAddress));
  }

  private initCoordinateMarkers(): void {
    if (this.coordinates != undefined && this.coordinates.length > 0) {
      this.coordinates.forEach(coord => this.addMarker(coord));
    }
  }

  private addMarker(coordinate: GoogleMapsCoordinate): void {
    this.markerPositions.push(coordinate);
  }

  private clearAllMarkers(): void {
    this.markerPositions = [];
  }

  public openInfoWindow(marker: MapMarker, windowIndex: number): void {
    this.infoWindowsView.get(windowIndex)?.open(marker);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
