import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, forkJoin, Observable, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import { ConfirmationModalService } from 'projects/shared/src/lib/services/confirmation-modal.service';
import { OrderService } from 'projects/shared/src/lib/services/order.service';
import { NewAdminOrder, Order, OrderDetails, UpdateAdminOrder } from 'projects/shared/src/public-api';
import { OrderStatuses } from 'projects/shared/src/lib/enumerations/order-status';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { getDateTime } from 'projects/shared/src/lib/utili/get-datetime';
import { GetNineTypesDisplayName, NineTypes } from 'projects/shared/src/lib/enumerations/nine-type';
import { Reservation } from '../../../shared/components/tee-sheet-table/tee-sheet-table.component';

@Component({
  selector: 'gcl-admin-add-squeeze-time',
  templateUrl: './add-squeeze-time.component.html',
  styleUrls: ['./add-squeeze-time.component.scss']
})
export class AddSqueezeTimeComponent implements OnInit, OnDestroy {
  public faChevronLeft = faChevronLeft;

  public selectedDate!: Date;
  public selectedTime!: Date;
  public nineType!: string;

  private orderId$!: Observable<number>;
  public order$!: Observable<OrderDetails>;
  public reservation!: Reservation;
  public canEdit: boolean = true;
  private queryParam$!: Observable<string>;

  private destroyed$ = new Subject();

  constructor(public route: ActivatedRoute, private authService: AuthService, private router: Router, private confirmationModalService: ConfirmationModalService, private orderService: OrderService) { }

  ngOnInit(): void {
    this.orderId$ = this.route.params.pipe(
      map(params => params.orderId)
    );

    this.order$ = this.orderId$.pipe(
      switchMap((orderId) => this.orderService.get(orderId)),
      takeUntil(this.destroyed$)
    );

    this.queryParam$ = this.route.params.pipe(
      map(params => params.queryParam),
      takeUntil(this.destroyed$)
    );

    combineLatest([this.queryParam$, this.order$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([params, order]) => {
        if (params) {
          this.reservation = JSON.parse(params) as Reservation;

          this.selectedDate = new Date(this.reservation.selectedDate);
          let teeTime = order.tee_times[0];
          this.selectedTime = getDateTime(teeTime.datestr, teeTime.timestr);
          this.nineType = GetNineTypesDisplayName(teeTime.nine);

          this.canEdit = order.status !== OrderStatuses.Paid || this.selectedTime >= new Date();
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public cancel(): void {
    this.confirmationModalService.showConfirmationModal({
      title: 'Confirm Cancellation',
      message: 'You may have unsaved changes? This action cannot be undone.'
    }).subscribe(accept => {
      if (accept) {
        this.router.navigate(['/manager', 'tee-sheet', {
          queryParam: JSON.stringify({ selectedDate: this.selectedDate })
        }]);
      }
    });
  }

  public submit(newOrder: Partial<NewAdminOrder>): void {
    this.authService.course$
      .pipe(
        take(1),
        switchMap(course => this.submitOrders(course.id, newOrder)),
        takeUntil(this.destroyed$)
      )
      .subscribe((order: Order) => {
        this.router.navigate(["/manager", "tee-sheet", "reservation", order.id, "checkout"]);
      });
  }

  private submitOrders(courseId: number, newOrder: Partial<NewAdminOrder>): Observable<Order> {
    const start = new Date(this.selectedTime);

    let order = Object.assign({
      status: OrderStatuses.Submitted,
      course: courseId,
      datestr: start.toISODate(),
      timestr: start.getTimeString(false),
      nine: this.nineType
    }, newOrder);

    return this.orderService.adminSubmit(order);
  }

}
