import { Component, EventEmitter, OnInit, Output, Input, forwardRef, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { switchMap, shareReplay, takeUntil } from 'rxjs/operators';

import { AuthService } from '../../services/auth.service';
import { ReservationTypes } from '../../enumerations/reservation-type';
import { GolfPassDetails } from '../../models/golfpass';
import { GolfPassService } from '../../services/golf-pass.service';


@Component({
  selector: 'gcl-lib-search-golf-passes',
  templateUrl: './search-golf-passes.component.html',
  styleUrls: ['./search-golf-passes.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SearchGolfPassesComponent),
    multi: true
  }],
})
export class SearchGolfPassesComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {
  public records$!: Observable<Array<GolfPassDetails>>;

  public selectedItem?: GolfPassDetails;
  public id?: number;
  public disabled!: boolean;

  private propagateChange = (_: any) => { };
  private propagateTouched = () => { };

  private destroy$: Subject<boolean> = new Subject();
  
  @Input()
  multiselect: boolean = false;

  @Output()
  addItem: EventEmitter<GolfPassDetails> = new EventEmitter<GolfPassDetails>();

  constructor(private authService: AuthService, private golfpassService: GolfPassService) { }

  ngOnInit(): void {
    this.getPagedResults();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getPagedResults();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  getPagedResults() {
    const course$ = this.authService.course$
      .pipe(
        shareReplay(1),
        takeUntil(this.destroy$)
      );

    this.records$ = course$.pipe(
      switchMap(course => {
        const pagedResult = this.golfpassService.query({
          course: course?.id,
        });
        return pagedResult.records$;
      }),
      shareReplay(1),
      takeUntil(this.destroy$)
    );
  }

  public setRecord(id: string) {
    // TODO: clean up model binding.
    this.records$.subscribe((records) => {
      const record = records.find(r => r.id == parseInt(id));
      if (record) {
        this.selectedItem = record;

        this.propagateChange(this.selectedItem);
        this.propagateTouched();
      } else {
        this.propagateChange(undefined);
        this.propagateTouched();
      }
    });
  }

  public addRecord() {
    if (this.multiselect) {
      this.records$.subscribe((records) => {
        if (this.id) {
          const record = records.find(r => r.id == this.id);
          if (record) {
            this.selectedItem = record;
            this.addItem.emit(this.selectedItem);

            this.propagateChange(this.selectedItem);
            this.propagateTouched();
          } else {
            this.propagateChange(undefined);
            this.propagateTouched();
          }
        } else {
          this.propagateChange(undefined);
          this.propagateTouched();
        }
      });
    }
  }

  writeValue(val?: GolfPassDetails): void {
    this.selectedItem = val;
    this.id = val?.id;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
