import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { switchMap, shareReplay, map, tap } from 'rxjs/operators';

import { CONFIG_TOKEN, SharedModuleConfig } from '../shared.config';
import { LocalAuthResponse } from '../models/local-auth-response';
import { UserDetails } from '../models/user';
import { Registeration } from '../models/registeration';
import { Course, CourseDetails } from '../models/course';
import { CourseService } from './course.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private accessTokenSubject$: BehaviorSubject<string>;
  public accessToken$: Observable<string>;

  public user$: Observable<UserDetails>;
  public userName$: Observable<string>;
  public userCourses$: Observable<Array<Course>>;
  public course$: ReplaySubject<Course> = new ReplaySubject<Course>(1);

  public isAuthenticated$: Observable<boolean>;

  public isAdministratorRole$!: Observable<boolean>;
  public viewManager$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public role$: Observable<string>;

  private _onExternalLogin$ = new Subject<void>();
  public get onExternalLogin$() { return this._onExternalLogin$.asObservable(); }

  constructor(private http: HttpClient, private router: Router, @Inject(CONFIG_TOKEN) protected config: SharedModuleConfig) {

    this.accessTokenSubject$ = new BehaviorSubject<string>(this.getAccessToken() || '');
    this.accessToken$ = this.accessTokenSubject$.asObservable();

    this.user$ = this.accessToken$.pipe(
      switchMap(accessToken => this.getMe()),
      shareReplay(1)
    );

    this.userName$ = this.user$.pipe(
      map((user: UserDetails) => (user.firstName && user.lastName) ? `${user.firstName} ${user.lastName}` : user.username),
      shareReplay(1)
    );

    this.userCourses$ = this.user$.pipe(
      switchMap((user: UserDetails) => this.getUserCourses(user.id)),
      tap((courses: Array<Course>) => {
        const cachedCourseJson = this.getFromLocalStorage("course");
        if (cachedCourseJson) {
          const cachedCourse = JSON.parse(cachedCourseJson) as Course;
          const course = courses.find(c => c.id == cachedCourse.id);

          if(course) {
            this.setCourse(course);
            return;
          }          
        }
          
        this.setCourse(courses[0]);        
      }),
      shareReplay(1)
    );

    this.role$ = this.user$.pipe(
      map(user => user.role?.name)
    );

    this.isAdministratorRole$ = this.user$.pipe(
      map(user => user.role?.name == "Administrator"),
      tap((isAdminRole: boolean) => {
        const viewManager = JSON.parse(this.getFromLocalStorage("viewManager") || "true") as boolean;
        if (!viewManager && isAdminRole) {
          this.viewAdmin();
        } else {
          this.viewManager();
        }
      }),
    );

    this.isAuthenticated$ = this.accessToken$.pipe(
      map(t => !!t)
    );
  }
  
  private getUserCourses(userId: number): Observable<Array<Course>> {
    return this.http.get<UserDetails>(`${this.config.apiUrl}/users/${userId}`).pipe(
      map(response => response.courses),
    );
  }

  viewManager() {
    this.viewManager$.next(true);
    this.saveToLocalStorage("viewManager", JSON.stringify(true));
  }

  viewAdmin() {
    this.viewManager$.next(false);
    this.saveToLocalStorage("viewManager", JSON.stringify(false));
  }

  setCourse(course: Course): void {
    this.course$.next(course);
    this.saveToLocalStorage("course", JSON.stringify(course));
  }

  logout() {
    this.removeAccessTokenFromLocalStorage();
    this.accessTokenSubject$.next('');
    this.router.navigate(['/login']);
  }

  register(user: Partial<Registeration>) {
    return this.http.post<LocalAuthResponse>(`${this.config.apiUrl}/auth/local/register`, user).pipe(
      tap(response => this.accessTokenSubject$.next(response.jwt)),
      tap(response => this.saveAccessTokenToLocalStorage(response.jwt))
    );
  }

  login(identifier: string, password: string, rememberMe = false) {
    return this.http.post<LocalAuthResponse>(`${this.config.apiUrl}/auth/local`, {
      identifier: identifier,
      password: password
    }).pipe(
      tap(response => this.accessTokenSubject$.next(response.jwt)),
      tap(response => this.saveAccessTokenToLocalStorage(response.jwt)),
      tap(response => {
        response.user?.role?.name == "Administrator" ? this.viewAdmin() : this.viewManager();
      })
    );
  }

  public sendForgotPassword(email: string): Observable<LocalAuthResponse> {
    return this.http.post<LocalAuthResponse>(`${this.config.apiUrl}/auth/forgot-password`, {
      email: email
    }).pipe(
    );
  }

  public resetPassword(code: string, password: string, confirmPassword: string): Observable<LocalAuthResponse> {
    return this.http.post<LocalAuthResponse>(`${this.config.apiUrl}/auth/reset-password`, {
      code: code,
      password: password,
      passwordConfirmation: confirmPassword
    }).pipe(
    );
  }

  public getMe() {
    return this.http.get<UserDetails>(`${this.config.apiUrl}/users/me`);
  }

  public updateMe(user: Partial<UserDetails>): Observable<UserDetails> {
    return this.http.put<UserDetails>(`${this.config.apiUrl}/users/me`, user);
  }

  public changePassword(password: string) {
    return this.http.put(`${this.config.apiUrl}/users/me`, {
      password: password
    });
  }

  private removeAccessTokenFromLocalStorage(): void {
    this.removeFromLocatStorage(this.config.jwtLocalStorageKey);
  }

  private saveAccessTokenToLocalStorage(accessToken: string): void {
    this.saveToLocalStorage(this.config.jwtLocalStorageKey, accessToken);
  }

  private removeFromLocatStorage(key: string): void {
    localStorage.removeItem(key);
  }

  private getFromLocalStorage(key: string): string | null {
    return localStorage.getItem(key);
  }

  private saveToLocalStorage(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  private tokenExpired(token: string): boolean {
    if (token) {
      const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
      return (Math.floor((new Date).getTime() / 1000)) >= expiry;
    }
    return true;
  }

  public getAccessToken(): string {
    const jwt = localStorage.getItem(this.config.jwtLocalStorageKey) as string;
    const expired = this.tokenExpired(jwt);
    if (jwt && expired) {
      this.removeAccessTokenFromLocalStorage();
      return '';
    } else {
      return jwt;
    }
  }

  public triggerExternalLogin() {
    this._onExternalLogin$.next();
  }
}
