import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';

import { BsDropdownModule } from'ngx-bootstrap/dropdown';

import { AccountRoutingModule } from './account-routing.module';
import { AccountChangePasswordComponent } from './account-change-password/account-change-password.component';
import { AccountEditComponent } from './account-edit/account-edit.component';
import { AccountViewComponent } from './account-view/account-view.component';
import { SharedModule } from 'projects/shared/src/public-api';

@NgModule({
  declarations: [AccountChangePasswordComponent, AccountEditComponent, AccountViewComponent],
  imports: [
    CommonModule,
    
    ReactiveFormsModule,
    BsDropdownModule.forRoot(),
    SharedModule,

    AccountRoutingModule
  ]
})
export class AccountModule { }
