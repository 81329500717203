<div class="container-fluid py-2 h-100 overflow-y-auto">
    <div class="row">
        <div class="col-12">
                <div class="d-flex justify-content-end py-2">
                    <button type="button" (click)="cancel()" class="btn btn-outline-light-green px-5">Cancel</button>
                    <button type="button" (click)="submit()" class="btn btn-light-green px-5">Save</button>
                </div>
                <div class="card">
                    <div class="card-body">
                        <gcl-admin-product-form></gcl-admin-product-form>
                    </div>
                </div>
        </div>
    </div>
</div>
