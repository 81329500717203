<div class="p-4" *ngIf="order$ | async as order">
    <div class="d-flex flex-row justify-content-between">
        <h5 class="font-weight-bold m-0">{{golfpassOrder.golfpass.name | uppercase}}</h5>
        <div class="d-flex flex-row justify-content-between">
            <h5 class="mb-0 font-weight-bold">{{golfpassOrder.extprice | currency}}</h5>
        </div>
    </div>
    <div class="d-flex flex-row justify-content-between">
        <span class="subtitle mt-1">GOLF PASSES</span>
        <span class="subtitle mt-1">
            {{ (taxrate * golfpassOrder.golfpass.price) | currency }}                                                     
        </span>
    </div>
    
    <div class="mt-2">
        <button class="btn btn-sm btn-outline-light-green mr-2" (click)="onRemoveItem()">Remove Item</button>
        <button class="btn btn-sm btn-light-green flex-fill" (click)="bsModalRef.hide()">Ok</button>    
    </div>
</div>
