import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl } from '@angular/forms';
import { BehaviorSubject, combineLatest, forkJoin, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, shareReplay, skip, takeUntil, takeWhile } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';

import { CartStatuses, GetCartStatusesDisplayName } from 'projects/shared/src/lib/enumerations/cart-status';
import { SortDirections } from 'projects/shared/src/lib/enumerations/sort-directions';
import { OnPageEvent } from 'projects/shared/src/lib/models/on-page-event';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { CartService } from 'projects/shared/src/lib/services/cart.service';
import { CartDetails } from 'projects/shared/src/public-api';

@Component({
  selector: 'gcl-admin-cart-list',
  templateUrl: './cart-list.component.html',
  styleUrls: ['./cart-list.component.scss']
})
export class CartListComponent implements OnInit, OnDestroy {
  public getCartStatusDisplayName = GetCartStatusesDisplayName;
  public getCartStatuses() { return Object.values(CartStatuses) }

  get status() { return this.filters.get("status") as FormControl; }

  filters = this.fb.group({
    status: [null],
  });

  initialPagination = {
    itemsPerPage: 10,
    page: 1,
    sortBy: 'id',
    sortDescending: true,
    search: ''
  };

  pagination$ = new BehaviorSubject<OnPageEvent>(this.initialPagination);

  total$: BehaviorSubject<number> = new BehaviorSubject(0);
  records$: ReplaySubject<CartDetails[]> = new ReplaySubject(0);

  private destroyed$: Subject<boolean> = new Subject();

  constructor(private fb: FormBuilder, private cartService: CartService, private authService: AuthService, private router: Router, private toastrService: ToastrService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.pagination$
      .pipe(
        takeUntil(this.destroyed$),
        skip(1)
      )
      .subscribe(() => {
        this.getPageResult();
      });

    this.initFunc();

    this.filters.valueChanges
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        this.getPageResult();
      });
  }

  private getPageResult() {
    this.authService.course$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(course => {
      const pagedResult = this.cartService.query({
        skip: (this.pagination$.value.page - 1) * this.pagination$.value.itemsPerPage,
        take: this.pagination$.value.itemsPerPage,
        sortColumns: [{
          column: this.pagination$.value.sortBy,
          direction: this.pagination$.value.sortDescending ? SortDirections.Descending : SortDirections.Ascending,
        }],
        search: this.pagination$.value.search,

        course: course?.id,
        status: this.status?.value == "null" ? null : this.status?.value
      });

      pagedResult.filteredCount$.subscribe((total) => this.total$.next(total));
      pagedResult.records$.subscribe((records) => this.records$.next(records));
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { ...this.pagination$.value, ...this.filters.value },
        queryParamsHandling: 'merge',
      });
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  public clearAllCarts(): void {
    this.records$
      .pipe(
        map(records => records.filter(record => (record.status == CartStatuses.assigned || record.status == CartStatuses.reserved))),
        takeUntil(this.destroyed$)
      )
      .subscribe(records => {
        let clearCarts: Array<Observable<CartDetails>> = [];
        records.forEach(record => {
          clearCarts.push(this.getClearCartObs(record));
        });

        forkJoin(clearCarts).pipe(
          shareReplay(1),
          takeUntil(this.destroyed$)
        ).subscribe(() => {
          this.toastrService.success("All carts successfully cleared!");
          this.getPageResult();
        });
      });
  }

  private getClearCartObs(cart: CartDetails): Observable<CartDetails> {
    return this.cartService.update(cart.id, {
      name: cart.name,
      status: CartStatuses.available,
      uuid: cart.uuid,
      course: cart.course?.id,
      user: cart.user?.id,
      device: cart.device?.id,
    }).pipe(
      takeUntil(this.destroyed$)
    )
  }

  public clearCart(cart: CartDetails): void {
    this.getClearCartObs(cart)
      .subscribe(cc => {
        this.toastrService.success("Cart successfully cleared!");
        this.getPageResult();
      });
  }

  public goToCreate() {
    this.router.navigate(['/admin', 'carts', 'create']);
  }

  public getQueryParams() {
    return this.route.snapshot.queryParams;
  }

  private initFunc() {
    const params = this.route.snapshot.queryParams;
    if (params['status']) {
      this.filters.patchValue({
        status: params['status']
      });
    }
    this.initialPagination = {
      itemsPerPage: !isNaN(parseInt(params['itemsPerPage'])) ? parseInt(params['itemsPerPage']) : this.initialPagination.itemsPerPage,
      page: !isNaN(parseInt(params['page'])) ? parseInt(params['page']) : this.initialPagination.page,
      sortBy: params['sortBy'] ? params['sortBy'] : this.initialPagination.sortBy,
      sortDescending: params['sortDescending'] ? params['sortDescending'] : this.initialPagination.sortDescending,
      search: params['search'] ? params['search'] : this.initialPagination.search
    };
    this.pagination$.next(this.initialPagination);
  }
}
