import { Pipe, PipeTransform } from '@angular/core';
import { GetOrderStatusesDisplayName, OrderStatuses } from 'projects/shared/src/lib/enumerations/order-status';

@Pipe({
  name: 'orderStatus'
})
export class OrderStatusPipe implements PipeTransform {

  transform(value: OrderStatuses, ...args: unknown[]): string {
    return GetOrderStatusesDisplayName(value);
  }

}
