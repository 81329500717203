import { Component, Input, OnInit } from '@angular/core';
import { GetOrderPaymentTypesDisplayName } from 'projects/shared/src/lib/enumerations/order-payment-types';
import { OrderDetails } from 'projects/shared/src/public-api';

@Component({
  selector: 'gcl-admin-order-payment-table',
  templateUrl: './order-payment-table.component.html',
  styleUrls: ['./order-payment-table.component.scss']
})
export class OrderPaymentTableComponent implements OnInit {
  GetOrderPaymentTypesDisplayName = GetOrderPaymentTypesDisplayName;

  @Input('order') order!: OrderDetails;

  constructor() { }

  ngOnInit(): void {
  }

}
