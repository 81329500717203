import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { OrderDetails } from 'projects/shared/src/lib/models/order';
import { OrderService } from 'projects/shared/src/lib/services/order.service';
import { Observable } from 'rxjs';
import { take } from 'rxjs/internal/operators/take';

@Component({
  selector: 'gcl-admin-gratuity-modal',
  templateUrl: './gratuity-modal.component.html',
  styleUrls: ['./gratuity-modal.component.scss']
})

export class GratuityModalComponent implements OnInit {
  @Output()
  confirm$: EventEmitter<void> = new EventEmitter<void>();
  cancel$: EventEmitter<void> = new EventEmitter<void>();
  
  order$!: Observable<OrderDetails>;
  tip: string = '0.00';
  tipPercentage: number = -1;

  constructor(private modalRef: BsModalRef, private orderService: OrderService, private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  setCustomTip(amount: string): void {
    this.tipPercentage = -1;
    this.tip = parseFloat(amount).toFixed(2);
  }

  setTipPercentage(amount: number): void {
    this.tipPercentage = amount;
    this.order$.subscribe(order => this.tip = (order.subtotal * (amount / 100)).toFixed(2));
  }

  async confirm(): Promise<void> {
    let tipAmount = parseFloat(this.tip);
    let pcBasedTip = false;

    if (this.tipPercentage > 0) {
      tipAmount = this.tipPercentage;
      pcBasedTip = true;
    }

    let order = await this.order$.pipe(take(1)).toPromise(); 

    try {
      await this.orderService.setTipAmount(order.id, tipAmount, pcBasedTip).toPromise();

      this.confirm$.emit();

      this.modalRef.hide();
    } catch(e) {
      this.toastr.error("An error has occurred. Please try again.");
    }
  }

  cancel(): void {
    this.tip = '0.00';
    this.tipPercentage = -1;
    
    this.cancel$.emit();

    this.modalRef.hide();
  }
}
