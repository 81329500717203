export enum GolfProductReservationTypes {
    SingleParty = "SingleParty",
    Group = "Group",
    League = "League",
    Event = "Event",
}

export function GetGolfProductReservationTypesDisplayName(reservation: GolfProductReservationTypes): string {
    switch (reservation) {
        case GolfProductReservationTypes.SingleParty:
            return "Single Party";
        case GolfProductReservationTypes.Group:
            return "Group";
        case GolfProductReservationTypes.League:
            return "League";
        case GolfProductReservationTypes.Event:
            return "Event";
        default:
            return "N/A";
    }
}