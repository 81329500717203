import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { shareReplay, takeUntil, tap } from 'rxjs/operators';

import { v4 as uuidv4 } from 'uuid';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';

import { UserService } from 'projects/shared/src/lib/services/user.service';
import { UserDetails } from 'projects/shared/src/public-api';
import { Roles } from 'projects/shared/src/lib/enumerations/roles';

import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { Course } from 'projects/shared/src/lib/models/course';
import { CourseService } from 'projects/shared/src/lib/services/course.service';

@Component({
  selector: 'gcl-admin-staff-create',
  templateUrl: './staff-create.component.html',
  styleUrls: ['./staff-create.component.scss']
})
export class StaffCreateComponent implements OnInit, OnDestroy {

  public Roles = Roles;
  public faChevronLeft = faChevronLeft;

  public courses$!: Observable<Array<Course>>;

  get role() { return this.form.get("role") as FormControl; }
  get username() { return this.form.get("username") as FormControl; }
  get email() { return this.form.get("email") as FormControl; }
  get confirmed() { return this.form.get("confirmed") as FormControl; }
  get blocked() { return this.form.get("blocked") as FormControl; }
  get cellphone() { return this.form.get("cellphone") as FormControl; }
  get licenseNumber() { return this.form.get("licenseNumber") as FormControl; }
  get licenseProvider() { return this.form.get("licenseProvider") as FormControl; }
  get licenseExpires() { return this.form.get("licenseExpires") as FormControl; }
  get firstName() { return this.form.get("firstName") as FormControl; }
  get lastName() { return this.form.get("lastName") as FormControl; }
  get dateOfBirth() { return this.form.get("dateOfBirth") as FormControl; }
  get courses() { return this.form.get("courses") as FormControl }

  public form = this.fb.group({
    role: [null, [Validators.required]],
    username: [null, []],
    email: [null, [Validators.required]],
    confirmed: [false, []],
    blocked: [false, []],
    cellphone: [null, [Validators.required]],
    licenseNumber: [null, []],
    licenseProvider: [null, []],
    licenseExpires: [null, []],
    firstName: [null, [Validators.required]],
    lastName: [null, [Validators.required]],
    dateOfBirth: [null, []],
    courses: [null, [Validators.required]],
  });


  private destroyed$ = new Subject();

  constructor(private fb: FormBuilder, private userService: UserService, private courseService: CourseService, public route: ActivatedRoute, private router: Router, private toastr: ToastrService, public authService: AuthService) { }

  ngOnInit(): void {
    this.courses$ = this.courseService.getAll()
      .pipe(
        shareReplay(1)
      );

    this.authService.course$
      .pipe(
        shareReplay(1),
        takeUntil(this.destroyed$)
      ).subscribe((course) => this.form.patchValue({
        courses: [course.id]
      }));
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public submit(): void {
    if (this.form.valid) {
      this.form.patchValue({
        username: this.username.value || this.email.value,
      });

      this.userService.create({ ...this.form.value, ...{ password: uuidv4() } })
        .pipe(
          takeUntil(this.destroyed$)
        )
        .subscribe((user: UserDetails) => {
          this.userService.generateAndSendTempPasswordEmail(user.id).subscribe(() => {
            this.toastr.success("Staff created successfully!");
            this.router.navigate(["/admin", "staff", user.id, "view"]);
          });
        }, (err: HttpErrorResponse) => {
          err.error.message.forEach((message: any) => {
            if (message.messages.length > 0) {
              message.messages.forEach((mes: any) => {
                const field = mes.field[0];
                const formField = this.form.get(field);
                if (formField) {
                  formField.setErrors({
                    error: mes.message
                  });
                }
              })
            }
          })
        });
    } else {
      Object.values(this.form.controls).forEach(control => {
        control.markAsTouched();
        control.markAsDirty();
      });
    }
  }
}
