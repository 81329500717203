import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, combineLatest, Subject, ReplaySubject } from 'rxjs';
import { shareReplay, map, takeUntil, skip } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';

import { OnPageEvent } from 'projects/shared/src/lib/models/on-page-event';
import { CourseDetails } from 'projects/shared/src/lib/models/course';
import { CourseService } from 'projects/shared/src/lib/services/course.service';
import { MediaService } from 'projects/shared/src/lib/services/media.service';
import { Media } from 'projects/shared/src/public-api';
import { SortDirections } from 'projects/shared/src/lib/enumerations/sort-directions';

@Component({
  selector: 'gcl-admin-course-list',
  templateUrl: './course-list.component.html',
  styleUrls: ['./course-list.component.scss']
})
export class CourseListComponent implements OnInit, OnDestroy {
  public apiUrl: string = environment.apiUrl;

  initialPagination = {
    itemsPerPage: 10,
    page: 1,
    sortBy: 'id',
    sortDescending: true,
    search: ''
  };
  pagination$ = new BehaviorSubject<OnPageEvent>(this.initialPagination);

  total$: BehaviorSubject<number> = new BehaviorSubject(0);
  records$: ReplaySubject<CourseDetails[]> = new ReplaySubject(0);

  private destory$: Subject<boolean> = new Subject();

  constructor(private courseService: CourseService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.pagination$
      .pipe(
        takeUntil(this.destory$),
        skip(1)
      )
      .subscribe(() => {
        this.getPageResult();
      });

    this.initFunc();
  }

  ngOnDestroy(): void {
    this.destory$.next(true);
    this.destory$.complete();
  }

  private getPageResult() {
    const pagedResult = this.courseService.query({
      skip: (this.pagination$.value.page - 1) * this.pagination$.value.itemsPerPage,
      take: this.pagination$.value.itemsPerPage,
      sortColumns: [{
        column: this.pagination$.value.sortBy,
        direction: this.pagination$.value.sortDescending ? SortDirections.Descending : SortDirections.Ascending,
      }],
      search: this.pagination$.value.search
    });

    pagedResult.filteredCount$.subscribe((total) => this.total$.next(total));
    pagedResult.records$.subscribe((records) => this.records$.next(records));
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.pagination$.value,
      queryParamsHandling: 'merge',
    });
  }

  public goToCreate() {
    this.router.navigate(['/admin', 'courses', 'create']);
  }

  public getQueryParams() {
    return this.route.snapshot.queryParams;
  }

  private initFunc() {
    const params = this.route.snapshot.queryParams;
    this.initialPagination = {
      itemsPerPage: !isNaN(parseInt(params['itemsPerPage'])) ? parseInt(params['itemsPerPage']) : this.initialPagination.itemsPerPage,
      page: !isNaN(parseInt(params['page'])) ? parseInt(params['page']) : this.initialPagination.page,
      sortBy: params['sortBy'] ? params['sortBy'] : this.initialPagination.sortBy,
      sortDescending: params['sortDescending'] ? params['sortDescending'] : this.initialPagination.sortDescending,
      search: params['search'] ? params['search'] : this.initialPagination.search
    };
    this.pagination$.next(this.initialPagination);
  }
}
