<gcl-lib-table 
[totalItems]="total$ | async" 
[sortBy]="'id'" 
(onPage)="pagination$.next($event)" 
[tableClass]="'table table-striped table-hover'" 
[enableCreate]="enableCreate"
>
<thead>
    <tr>
        <th>PASS NAME</th>
        <th>COURSE NAME</th>
        <th>PRICE</th>
        <th>STATUS</th>
        <th width="100" class="text-center" *ngIf="enableManage">MANAGE</th>
    </tr>
</thead>
<tbody>
    <tr *ngFor="let pass of records$ | async" [class.selected-row]="pass.id === selectedPass?.id" (click)="onSelect(pass)">
        <td>{{pass?.name}}</td>
        <td>{{pass?.course?.name}}</td>
        <td>{{pass?.price | currency}}</td>
        <td>{{pass?.available ? 'Active' : 'Inactive'}}</td>
        <td class="text-center" *ngIf="enableManage">
            <div class="btn-group btn-group-sm">
                <a [routerLink]="['/admin','golf-passes', pass.id, 'view']" class="btn btn-link btn-primary-light">View</a>
            </div>
        </td>
    </tr>
    <tr *ngIf="(total$ | async) == 0">
        <td colspan="9">No golf passes.</td>
    </tr>
</tbody>
</gcl-lib-table>
