<div class="d-flex flex-column h-100">
    <div class="d-flex justify-content-start py-2 bg-light no-flex">
        <a class="text-light-green" [routerLink]="['/admin','golf-passes']" [queryParams]="getQueryParams()">
            <fa-icon [icon]="faChevronLeft" class="px-3"></fa-icon> <span>Back to Golf Pass List</span>
        </a>
    </div>
    
    <div class="container-fluid py-2 fill-flex overflow-y-auto">
        <div class="row" *ngIf="golfpass$ | async as golfpass">
            <div class="col-12">
                <h6 class="page-header">Golf Pass View</h6>
                <div class="d-flex justify-content-end py-2">
                    <a [routerLink]="['/admin','golf-passes', golfpass.id, 'edit']" class="btn btn-light-green mx-1">Edit</a>
                    <a [routerLink]="['/admin','golf-passes', golfpass.id, 'clone']" class="btn btn-outline-light-green mx-1">Clone</a>
                    <button (click)="openDeleteModal()" type="button" class="btn btn-outline-secondary mx-1">Delete</button>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label >Name</label>
                                            <p>{{golfpass.name}}</p>    
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label >Price</label>
                                            <p>{{golfpass.price | currency}}</p>    
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label >Taxable</label>
                                            <p>{{golfpass.taxable  ? 'Yes' : 'No'}}</p>    
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label >Available</label>
                                            <p>{{golfpass.available ? 'Yes' : 'No'}}</p>    
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <gcl-user-golf-pass-guidlines [golfpassId]="golfpass.id"></gcl-user-golf-pass-guidlines>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <!-- Schedules -->
    <div class="container-fluid py-2 overflow-y-auto">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between pb-4">
                            <h6 class="page-header">Schedules</h6>
                        </div>
                        <gcl-lib-table 
                            [totalItems]="total$ | async" 
                            [sortBy]="'id'" 
                            [enableCreate]="false"
                            (onPage)="pagination$.next($event)"
                            [tableClass]="'table table-striped table-hover'">
                            <thead>
                                <tr>
                                    <th columnSort="name">Name</th>
                                    <th columnSort="starttod">Start Time of Day</th>
                                    <th columnSort="endtod">End Time of Day</th>
                                    <th columnSort="turn">Turn</th>
                                    <th columnSort="active">Active</th>
                                    <th width="100" class="text-center">Manage</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let record of schedules$ | async">
                                    <td>{{record.name}}</td>
                                    <td>{{record.starttod}}</td>
                                    <td>{{record.endtod}}</td>
                                    <td>{{record.turn}}</td>
                                    <td>{{record.active ? 'Yes' : 'No'}}</td>
                                    <td class="text-center">
                                        <div class="btn-group btn-group-sm">
                                            <a [routerLink]="['/admin','schedules', record.id, 'view']" class="btn btn-link btn-primary-light">View</a>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngIf="(total$ | async) == 0">
                                    <td colspan="6">No schedules.</td>
                                </tr>
                            </tbody>
                        </gcl-lib-table>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
