import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EventsRoutingModule } from './events-routing.module';
import { EventListComponent } from './event-list/event-list.component';
import { AdminSharedModule } from '../../shared/admin-shared.module';
import { EventCreateComponent } from './event-create/event-create.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'projects/shared/src/lib/shared.module';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { EventViewComponent } from './event-view/event-view.component';
import { EventEditComponent } from './event-edit/event-edit.component';


@NgModule({
  declarations: [
    EventListComponent, 
    EventCreateComponent, 
    EventViewComponent, 
    EventEditComponent, 
  ],
  imports: [
    CommonModule,
    EventsRoutingModule,
    AdminSharedModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    SharedModule,
    TimepickerModule,
  ]
})
export class EventsModule { }
