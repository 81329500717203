import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { OnPageEvent, UserDetails } from 'projects/shared/src/public-api';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { map, shareReplay, skip, switchMap, take, takeUntil } from 'rxjs/operators';
import { SortDirections } from 'projects/shared/src/lib/enumerations/sort-directions';
import { EventService } from 'projects/shared/src/lib/services/event.service';
import { EventQuery } from 'projects/shared/src/lib/queries/event-query';
import { EventDetails } from 'projects/shared/src/lib/models/event';
import { UserService } from 'projects/shared/src/lib/services/user.service';

@Component({
  selector: 'gcl-admin-event-table',
  templateUrl: './event-table.component.html',
  styleUrls: ['./event-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventTableComponent implements OnInit, OnDestroy {
  @Input() enableCreate: boolean = true;
  _course: number = 0;
  @Input() set course(course: number) {
    this._course = course
    this.getPageResult();

  }
  @Output() onCreate: EventEmitter<void> = new EventEmitter();

  initialPagination = {
    itemsPerPage: 10,
    page: 1,
    sortBy: 'id',
    sortDescending: true,
    search: ''
  };
  pagination$ = new BehaviorSubject<OnPageEvent>(this.initialPagination);

  total$: BehaviorSubject<number> = new BehaviorSubject(0);
  records$: ReplaySubject<EventDetails[]> = new ReplaySubject(0);

  public eventUsers$!: Observable<UserDetails[]>;

  private destory$: Subject<boolean> = new Subject();

  constructor(
    private router: Router,
    private eventService: EventService,
    private userService: UserService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.pagination$
      .pipe(
        takeUntil(this.destory$),
        skip(1)
      )
      .subscribe(() => {
        this.getPageResult();
      });

    this.eventUsers$ = this.records$.pipe(
      switchMap(events => {
        let eventUsers = events.filter(e => !!(e?.order?.users_permissions_user)).map(e => e.order.users_permissions_user) as number[];
        return this.userService.query({ id_in: eventUsers }).records$;
      }),
      takeUntil(this.destory$),
      shareReplay(1)
    );

    this.initFunc();
  }

  ngOnDestroy() {
    this.destory$.next(true);
    this.destory$.complete();
  }

  private getPageResult() {
    let query: EventQuery = {
      skip: (this.pagination$.value.page - 1) * this.pagination$.value.itemsPerPage,
      take: this.pagination$.value.itemsPerPage,
      sortColumns: [{
        column: this.pagination$.value.sortBy,
        direction: this.pagination$.value.sortDescending ? SortDirections.Descending : SortDirections.Ascending,
      }],
      search: this.pagination$.value.search,
    };

    if (this._course) {
      query.course = this._course;
    }

    const pagedResult = this.eventService.query(query);

    pagedResult.filteredCount$.subscribe((total) => this.total$.next(total));
    pagedResult.records$.subscribe((records) => this.records$.next(records));
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.pagination$.value,
      queryParamsHandling: 'merge',
    });
  }

  public goToCreate() {
    this.router.navigate(['/admin', 'orders', 'create']);
  }

  onCreateClick() {
    this.onCreate.emit();
  }

  getEventUser(userId: number) {
    return this.eventUsers$.pipe(
      take(1),
      map(users => users.find(u => u.id === userId)),
      map(user => {
        let fullName = '';

        if (user?.firstName) {
          fullName += user.firstName;
        }
        if (user?.firstName && user?.lastName) {
          fullName += ' ';
        }
        if (user?.lastName) {
          fullName += user?.lastName;
        }
        if (!fullName) {
          fullName = 'N/A'
        }

        return fullName
      })
    )
  }

  public getQueryParams() {
    return this.route.snapshot.queryParams;
  }

  private initFunc() {
    const params = this.route.snapshot.queryParams;
    this.initialPagination = {
      itemsPerPage: !isNaN(parseInt(params['itemsPerPage'])) ? parseInt(params['itemsPerPage']) : this.initialPagination.itemsPerPage,
      page: !isNaN(parseInt(params['page'])) ? parseInt(params['page']) : this.initialPagination.page,
      sortBy: params['sortBy'] ? params['sortBy'] : this.initialPagination.sortBy,
      sortDescending: params['sortDescending'] ? params['sortDescending'] : this.initialPagination.sortDescending,
      search: params['search'] ? params['search'] : this.initialPagination.search
    };
    this.pagination$.next(this.initialPagination);
  }
}
