import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'gcl-lib-counter-button',
  templateUrl: './counter-button.component.html',
  styleUrls: ['./counter-button.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CounterButtonComponent),
    multi: true
  }],
})
export class CounterButtonComponent implements OnInit, ControlValueAccessor {
  public faPlus = faPlus;
  public faMinus = faMinus;

  public _value: number = 0;

  private propagateChange = (_: any) => { };
  private propagateTouched = (_: any) => { };

  @Input() min?: number | null = null;
  @Input() max?: number | null = null;
  @Input() bordered: boolean = true;
  @Input() ltext: string = "";
  @Input() vtext: string = "";
  @Input() rtext: string = "";
  @Input() value?: number | null = null;
  @Input() decrementCallback?: Function | null = null;
  @Input() inputCallback?: Function | null = null;
  @Input() incrementCallback?: Function | null = null;

  @Output()
  clicked: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
    if (this.value) {
      this._value = this.value;
    }
    this.updateText(0, this._value);
  }

  public decrement(): void {
    if (this.decrementCallback != null) {
      this.decrementCallback();
    } else {
      if (this.min == null || (this._value > this.min)) {
        const prev = this._value;
        this._value -= 1;
        this.propagateChange(this._value);
        this.updateText(prev, this._value);
      }
    }
  }

  public onClick(): void {
    if (this.inputCallback != null) {
      this.inputCallback();
    } else {
      this.propagateChange(this._value);
      this.clicked.emit(this._value);
    }
  }

  public increment(): void {
    if (this.incrementCallback != null) {
      this.incrementCallback();
    } else {
      if (!this.max || (this._value < this.max)) {
        const prev = this._value;
        this._value += 1;
        this.propagateChange(this._value);
        this.updateText(prev, this._value);
      }
    }
  }

  private updateText(prev: number, current: number): void {
    this.vtext = this.vtext.replace("$value", "(" + current + ")");
    this.vtext = this.vtext.replace("(" + prev + ")", "(" + current + ")");
  }

  writeValue(val: number): void {
    this._value = val;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }
}
