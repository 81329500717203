import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ModalModule } from 'ngx-bootstrap/modal';

import { SchedulesRoutingModule } from './schedules-routing.module';
import { ScheduleListComponent } from './schedule-list/schedule-list.component';
import { ScheduleCreateComponent } from './schedule-create/schedule-create.component';
import { ScheduleEditComponent } from './schedule-edit/schedule-edit.component';
import { ScheduleViewComponent } from './schedule-view/schedule-view.component';
import { SharedModule } from 'projects/shared/src/public-api';


@NgModule({
  declarations: [ScheduleListComponent, ScheduleCreateComponent, ScheduleEditComponent, ScheduleViewComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,

    FontAwesomeModule,
    ModalModule.forRoot(),
    SharedModule,
    
    SchedulesRoutingModule
  ]
})
export class SchedulesModule { }
