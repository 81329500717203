<gcl-lib-table 
[totalItems]="total$ | async" 
[sortBy]="'id'" 
(onPage)="pagination$.next($event)" 
[tableClass]="'table table-striped table-hover'" 
[enableCreate]="false"
>
<thead>
    <tr>
        <th columnSort="id">ORDER NUMBER</th>
        <th columnSort="course.full_name">COURSE</th>
        <th columnSort="users_permissions_user.firstName">CUSTOMER</th>
        <th columnSort="status">STATUS</th>
        <th columnSort="subtotal">SUBTOTAL</th>
        <th columnSort="tip">TIP</th>
        <th columnSort="tax">TAX</th>
        <th columnSort="total">TOTAL</th>
        <th columnSort="finaltotal">BALANCE</th>
        <th width="100" class="text-center">MANAGE</th>
    </tr>
</thead>
<tbody>
    <tr *ngFor="let order of records$ | async">
        <td>{{order.id}}</td>
        <td>{{order.course?.full_name}}</td>
        <td>{{order.users_permissions_user?.firstName}} {{order.users_permissions_user?.lastName}}</td>
        <td>{{order.status}}</td>
        <td>{{order.subtotal | currency}}</td>
        <td>{{order.tip | currency}}</td>
        <td>{{order.tax | currency}}</td>
        <td>{{order.total | currency}}</td>
        <td>{{order.finaltotal | currency}}</td>
        <td class="text-center">
            <div class="btn-group btn-group-sm">
                <a [routerLink]="['/admin','orders', order.id, 'view']" class="btn btn-link btn-primary-light">View</a>
            </div>
        </td>
    </tr>
    <tr *ngIf="(total$ | async) == 0">
        <td colspan="10">No orders.</td>
    </tr>
</tbody>
</gcl-lib-table>
