import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { OrderService } from 'projects/shared/src/lib/services/order.service';
import { combineLatest, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'gcl-admin-sub-navbar',
  templateUrl: './sub-navbar.component.html',
  styleUrls: ['./sub-navbar.component.scss']
})
export class SubNavbarComponent implements OnInit, OnDestroy {
  public isCollapsed: boolean = true;
  public faUser = faUser;

  private destroy$: Subject<void> = new Subject();

  public onRegister = false;

  constructor(public authService: AuthService, private orderService: OrderService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.onRegister = this.router.url.includes('register-checkout');

    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((e) => {
      let evt = e as NavigationEnd;
      let url = evt.urlAfterRedirects;
      
      this.onRegister = url.includes('register-checkout');
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async gotoRegister() {
    this.authService.course$
      .pipe(
        take(1),
        switchMap(course => this.orderService.create({
          course: course.id,
          golforders: [],
          productorders: [],
          subtotal: 0,
          tax: 0,
          total: 0,
          finaltotal: 0,
        })),
        switchMap(order => this.orderService.recalculate(order.id)),
        takeUntil(this.destroy$))
      .subscribe((order) => {
        let urlFragment = this.router.url.toLowerCase().includes('manager') ? 'manager' : 'admin';
        this.router.navigate([`/${urlFragment}`, 'tee-sheet', order.id, 'register-checkout'], { relativeTo: this.route });
      })
  }
  
  onNavClick() {
    this.isCollapsed = true;
  }
}
