import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ProductType } from 'projects/shared/src/lib/enumerations/product-type';
import { OrderDetails } from 'projects/shared/src/public-api';
import { DigitalGiftCardAssignment } from '../../models/digital-gift-card-assignment';


@Component({
  selector: 'gcl-admin-add-digital-gift-certificate-modal',
  templateUrl: './add-digital-gift-certificate-modal.component.html',
  styleUrls: ['./add-digital-gift-certificate-modal.component.scss']
})
export class AddDigitalGiftCertificateModalComponent implements OnInit {
  order!: OrderDetails;

  @Output() onGiftCertificateAssign = new EventEmitter<DigitalGiftCardAssignment>();

  get email() { return this.form.get('email'); }
  get amount() { return this.form.get('amount'); }

  public form = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    amount: [null, [Validators.required, Validators.min(0)]]
  });

  constructor(private fb: FormBuilder, private bsModalRef: BsModalRef) {
  }


  ngOnInit(): void {
  }

  assignCertificate() {
    if(this.form.valid) {
      this.onGiftCertificateAssign.emit(this.form.value);
    }
  }

  cancel() {
    this.bsModalRef.hide();
  }
}
