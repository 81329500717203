import { Directive, HostBinding, HostListener, Input, OnChanges, OnDestroy } from '@angular/core';
import { combineLatest, merge, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { TableComponent } from '../components/table/table.component';

@Directive({
  selector: '[columnSort]'
})
export class ColumnSortDirective implements OnDestroy {

  @HostBinding('class')
  elementClass = 'cursor-pointer sortable';

  @Input('columnSort') 
  columnName!: string;

  destroyed$ = new Subject();

  constructor(private table: TableComponent) { }

  @HostListener('click')
  toggleSort() {
    this.table.toggleSortBy(this.columnName);
  }

  ngOnInit() {
    const form$ = merge(this.table.form.valueChanges, of(this.table.form.value));

    const sortBy$ = form$.pipe(
      map(value => value.sortBy as string)
    );

    const sortDescending$ = form$.pipe(
      map(value => value.sortDescending as boolean)
    );

    combineLatest([sortBy$, sortDescending$]).pipe(
      takeUntil(this.destroyed$)
    ).subscribe(([sortBy, sortDescending]) => {
      if(sortBy == this.columnName) {
        this.elementClass = 'cursor-pointer sortable ' + (sortDescending ? 'desc' : 'asc');
      } else {
        this.elementClass = 'cursor-pointer sortable none';
      }
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}
