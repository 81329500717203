<div class="modal-header">
    <h4 class="modal-title pull-left">Search Player</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="cancel()">
        <span class="modal-close" aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <h6  class="page-header">Player List</h6>
    <div class="table-responsive">
        <gcl-lib-table
            [totalItems]="total$ | async" 
            [sortBy]="'firstName'" 
            [enableCreate]="true"
            [showPageOptions]="false"
            [tableClass]="'table table-striped table-hover table-sm'"
            (onCreate)="openCreateModal()"
            (onPage)="pagination$.next($event)" >
            <thead>
                <tr>
                    <th columnSort="firstName">Name</th>
                    <th columnSort="email">Email</th>
                    <th columnSort="cellphone">Cellphone</th>
                    <th width="100" class="text-center">Manage</th>
                </tr>
            </thead>
            <tbody>
                <tr [class.selected-row]="player.id === selectedUser?.id" *ngFor="let player of records$ | async" (click)="onSelect(player)">
                    <td>{{player.firstName}} {{player.lastName}}</td>
                    <td>{{player.email}}</td>
                    <td>{{player.cellphone}}</td>
                    <td class="text-center">
                        <a class="select-button" type="button" (click)="openEditModal(player)">VIEW</a>
                    </td>
                </tr>
                <tr *ngIf="(total$ | async) == 0">
                    <td colspan="5">No players.</td>
                </tr>
            </tbody>
        </gcl-lib-table>
    </div>
    <div class="d-flex justify-content-end align-items-center">
        <button class="btn btn-outline-light-green" (click)="cancel()">Cancel</button>
        <button class="btn btn-light-green ml-2" [disabled]="selectedUser === null ? true : false" (click)="selectUser()">Select Customer</button>
    </div>
</div>
