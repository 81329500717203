import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { GolfProductReservationTypes } from 'projects/shared/src/lib/enumerations/golf-product-reservation-type';
import { ReservationTypeDetails } from 'projects/shared/src/lib/models/reservation-type';
import { ReservationTypeService } from 'projects/shared/src/lib/services/reservation-type.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'gcl-admin-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss']
})
export class ProductFormComponent implements OnInit {

  public GolfProductReservationTypes = GolfProductReservationTypes;
  public reservationTypes$!: Observable<ReservationTypeDetails[]>;

  get course() { return this.form.get("course") as FormControl }
  get name() { return this.form.get("name") as FormControl }
  get isEightteenHoles() { return this.form.get("isEightteenHoles") as FormControl }
  get cart() { return this.form.get("cart") as FormControl }
  get price() { return this.form.get("price") as FormControl }
  get notes() { return this.form.get("notes") as FormControl }
  get accountCode() { return this.form.get("accountCode") as FormControl }
  get cost() { return this.form.get("cost") as FormControl }
  get reservation_types() { return this.form.get("reservation_types") as FormControl }

  public form = this.fb.group({
    course: [null, [Validators.required]],
    name: [null, [Validators.required]],
    isEightteenHoles: [false, [Validators.required]],
    cart: [false, [Validators.required]],
    price: [null, [Validators.required]],
    notes: [null, []],
    accountCode: [null, []],
    cost: [null, []],
    reservation_types: [null, [Validators.required]]
  });

  constructor(
    private fb: FormBuilder,
    public route: ActivatedRoute,
    private reservationTypeService: ReservationTypeService,
  ) { 
    this.reservationTypes$ = this.reservationTypeService.getAll();
  }

  ngOnInit(): void {
  }

}
