import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { PlayersRoutingModule } from './players-routing.module';
import { PlayerCreateComponent } from './player-create/player-create.component';
import { PlayerEditComponent } from './player-edit/player-edit.component';
import { PlayerViewComponent } from './player-view/player-view.component';
import { PlayerListComponent } from './player-list/player-list.component';

import { SharedModule } from 'projects/shared/src/public-api';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';


@NgModule({
  declarations: [PlayerCreateComponent, PlayerEditComponent, PlayerViewComponent, PlayerListComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    FontAwesomeModule,

    PlayersRoutingModule
  ]
})
export class PlayersModule { }
