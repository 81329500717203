import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { shareReplay, takeUntil } from 'rxjs/operators';

import { Course } from 'projects/shared/src/lib/models/course';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { CourseService } from 'projects/shared/src/lib/services/course.service';

@Component({
  selector: 'gcl-admin-popular-tee-times',
  templateUrl: './popular-tee-times.component.html',
  styleUrls: ['./popular-tee-times.component.scss']
})
export class PopularTeeTimesComponent implements OnInit, OnDestroy {

  @Input()
  courseId?: number;

  public course$!: Observable<Course>;
  public viewMode: string = "day";

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(private courseService: CourseService, private authService: AuthService) { }

  ngOnInit(): void {
    if (this.courseId) {
      this.course$ = this.courseService.get(this.courseId)
        .pipe(
          shareReplay(1),
          takeUntil(this.destroyed$)
        );
    } else {
      this.course$ = this.authService.course$
        .pipe(
          shareReplay(1),
          takeUntil(this.destroyed$)
        )
    }
  }

  public getHour(timeStr: string): number {
    const placeholder = new Date();
    const date = new Date(placeholder.toDateString() + " " + timeStr);
    return date.getHours();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public onViewMode(mode: string): void {
    this.viewMode = mode;
  }

}
