<nav class="navbar d-flex justify-content-between align-items-center">
    <div *ngIf="userCourses$ | async as courses">
        <!-- Single -->
        <div class="btn-group" *ngIf="courses.length == 1">
            <div id="button-alignment" type="button" class="text-dark" aria-controls="dropdown-alignment">
                <img class="img-fluid" src="assets/golf.svg" /> <strong class="px-4" *ngIf="course$ | async as course">{{course.full_name}}</strong> <span class="caret pl-3"></span>
            </div>
        </div>

        <!-- Multi-Select -->
        <ng-container *ngIf="courses.length > 1">
            <ng-container *ngIf="(isOnDashboard$ | async) && !(authService.viewManager$ | async)">
                <div class="btn-group">
                    <div id="button-alignment" type="button" class="text-dark" aria-controls="dropdown-alignment">
                        <img class="img-fluid" src="assets/golf-disabled.svg" /> <strong class="px-4">All Courses</strong>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="(disableCourseDropdown$ | async)">
                <div class="btn-group">
                    <div id="button-alignment" type="button" class="text-dark" aria-controls="dropdown-alignment">
                        <img class="img-fluid" src="assets/golf-disabled.svg" /> <strong class="text-lowercase px-4" *ngIf="course$ | async as course">{{course.full_name}}</strong> <span class="caret pl-3"></span>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!(disableCourseDropdown$ | async) && (!(isOnDashboard$ | async) || (authService.viewManager$ | async))">
                <div class="btn-group" dropdown>
                    <div id="button-alignment" dropdownToggle type="button" class="dropdown-toggle text-dark" aria-controls="dropdown-alignment">
                        <img class="img-fluid" src="assets/golf.svg" /> <strong class="text-lowercase px-4" *ngIf="course$ | async as course">{{course.full_name}}</strong> <span class="caret pl-3"></span>
                    </div>
                    <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-alignment">
                        <li role="menuitem" *ngFor="let course of courses">
                            <button type="button" class="btn btn-link dropdown-item" (click)="selectCourse(course)">{{course.full_name}}</button>
                        </li>
                    </ul>
                </div>
            </ng-container>
        </ng-container>
    </div>

    <div class="d-flex justify-content-between align-items-center">
        <div class="">
            <div class="btn-group px-7" routerLinkActive="active-link" #rl="routerLinkActive" dropdown>
                <button id="button-basic" dropdownToggle class="btn btn-link shadow-none dropdown-toggle" aria-controls="dropdown-basic">
                    <fa-icon
                        [class.text-black]="!rl.isActive"
                        [class.text-primary]="rl.isActive"
                        [icon]="userIcon" size="lg">
                    </fa-icon>
                    <span class="px-2 text-dark">{{userName$ | async}}</span>
                    <small class="text-muted user-role">{{userRole$ | async}}</small>
                </button>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                    role="menu" aria-labelledby="button-basic">
                    <li role="menuitem"><a class="dropdown-item" [routerLink]="['/account']">Profile</a></li>
                    <ng-container *ngIf="authService.isAdministratorRole$ | async">
                        <li *ngIf="!(authService.viewManager$ | async)" role="menuitem"><button type="button" (click)="viewManager()" class="dropdown-item" >Switch to Manager  Portal</button></li>
                        <li *ngIf="(authService.viewManager$ | async)" role="menuitem"><button type="button" (click)="viewAdmin()" class="dropdown-item" >Switch to Admin Portal</button></li>
                    </ng-container>
                    <li class="divider dropdown-divider"></li>
                    <li role="menuitem">
                        <button type="button" class="btn btn-link dropdown-item" (click)="logout()">Logout</button>
                    </li>
                </ul>
            </div>
        </div>
        <div class="px-3">
            <ng-container *ngIf="(authService.viewManager$ | async)">
                <a routerLinkActive #rla="routerLinkActive" [routerLink]="['/manager/staff']">
                    <fa-icon
                        [class.text-black]="!(rla.isActive)"
                        [class.text-primary]="rla.isActive" 
                        [icon]="usersIcon" size="lg">
                    </fa-icon>
                </a>
            </ng-container>
            <ng-container *ngIf="!(authService.viewManager$ | async)">
                <a routerLinkActive #rla="routerLinkActive" [routerLink]="['/admin/staff']">
                    <fa-icon
                        [class.text-black]="!(rla.isActive)"
                        [class.text-primary]="rla.isActive" 
                        [icon]="usersIcon" size="lg">
                    </fa-icon>
                </a>
            </ng-container>
        </div>
        <div class="px-3">
            <fa-icon [icon]="faQuestionCircle" size="lg"></fa-icon>
        </div>
        <div class="px-3">
            <fa-icon 
                class="settings" 
                [icon]="faCog" 
                size="lg" 
                [popover]="menuPopover" 
                placement="bottom right" 
                [outsideClick]="true"
                [class.text-green]="isOnSettings$ | async">
            </fa-icon>
        </div>
    </div>
</nav>

<ng-template #menuPopover>
    <ul class="menu-popover">
        <li role="menuitem"><a class="dropdown-item" (click)="hidePopover(); drawerCountsClicked();">EOD DRAWER COUNT</a></li>
        <!-- <li role="menuitem"><a class="dropdown-item" (click)="hidePopover(); generateReportsClicked();">GENERATE REPORT</a></li> -->
        <li role="menuitem"><a class="dropdown-item" (click)="hidePopover(); manageOrdersClicked();">MANAGE ORDERS</a></li>
        <li role="menuitem"><a class="dropdown-item" (click)="hidePopover(); manageGiftCertificatesClicked();">MANAGE GIFT CERTIFICATE(S)</a></li>
        <li role="menuitem"><a class="dropdown-item" (click)="hidePopover(); manageBanners();">MANAGE BANNERS</a></li>
    </ul>
</ng-template>
