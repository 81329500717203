import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { Course } from 'projects/shared/src/lib/models/course';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { ReportService } from 'projects/shared/src/lib/services/report.service';
import { TopSellers } from 'projects/shared/src/lib/models/top-sellers';

@Component({
  selector: 'gcl-admin-add-on-top-sellers',
  templateUrl: './add-on-top-sellers.component.html',
  styleUrls: ['./add-on-top-sellers.component.scss']
})
export class AddOnTopSellersComponent implements OnInit, OnDestroy {

  @Input()
  courseId?: number;

  @Input()
  take: number = 10;

  public records$!: Observable<Array<TopSellers>>;

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(private reportService: ReportService, private authService: AuthService) { }

  ngOnInit(): void {
    if (this.courseId) {
      this.getProducts(this.courseId);
    } else {
      this.authService.course$
        .pipe(
          takeUntil(this.destroyed$)
        )
        .subscribe((course: Course) => {
          this.getProducts(course.id);
        });
    }
  }

  private getProducts(courseId: number) {
    this.records$ = this.reportService.getTopSellers(courseId);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
