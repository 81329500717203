export enum ScheduleTypes {
    General = "General",
    GolfPass = "GolfPass",
}

export function GetScheduleTypeDisplayName(type?: ScheduleTypes): string {
    switch (type) {
        case ScheduleTypes.GolfPass:
            return "Golf Pass";
        case ScheduleTypes.General:
            return "General";
        default:
            return "N/A";
    }
}