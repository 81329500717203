import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddReservationComponent } from './add-reservation/add-reservation.component';
import { ReservationDetailsComponent } from './reservation-details/reservation-details.component';
import { LayoutComponent } from './layout/layout.component';
import { ConfirmModalGuard } from 'projects/shared/src/lib/guards/confirm-modal.guard';
import { AddSqueezeTimeComponent } from './add-squeeze-time/add-squeeze-time.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { RegisterCheckoutComponent } from './register-checkout/register-checkout.component';

const routes: Routes = [
  {
    path: 'reservation/:id/checkout',
    component: CheckoutComponent,
    canDeactivate: [ConfirmModalGuard],
  },
  {
    path: ':id/register-checkout',
    component: RegisterCheckoutComponent,
    canDeactivate: [ConfirmModalGuard]
  },
  {
    path: ':orderId/reservation-details',
    component: ReservationDetailsComponent
  },
  {
    path: 'reservation/:orderId/squeeze-time',
    component: AddSqueezeTimeComponent
  },
  {
    path: 'add-reservation',
    component: AddReservationComponent
  },
  {
    path: '',
    component: LayoutComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TeeSheetRoutingModule { }
