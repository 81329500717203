import { Component, Input, OnInit } from '@angular/core';
import { GetReservationTypesDisplayName } from 'projects/shared/src/lib/enumerations/reservation-type';
import { ProductService } from 'projects/shared/src/lib/services/product.service';
import { OrderDetails } from 'projects/shared/src/public-api';
import { combineLatest, of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map, switchMap, take } from 'rxjs/operators';
import { getFirstTeeTime } from '../../utils/get-first-tee-time';

@Component({
  selector: 'gcl-admin-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {
  GetReservationTypesDisplayName = GetReservationTypesDisplayName;

  @Input('order') order!: OrderDetails;

  cartCount$!: Observable<number>;

  constructor(private productService: ProductService) { }

  ngOnInit(): void {
    this.cartCount$ = of(this.order).pipe(
      switchMap(order => {
        if(order?.productorders && order.productorders.length > 0) {
          let productIds$ = order.productorders.map(p => this.productService.get(<number>p.product).pipe(take(1)));
          return combineLatest(productIds$).pipe(
            take(1),
            map(products => products.filter(p => p.name === 'Golf Cart').length)
          );
        }
        else return of(0);
      })
    );
  }

  ngAfterViewInit() {

  }

  public getNine(order: OrderDetails) {
    return order?.tee_times?.[0]?.nine ?? 'N/A'
  }

  getTeeTime(order: OrderDetails) {
    let teeTime = order?.tee_times?.[0];
    
    if(!teeTime || !teeTime?.datestr || !teeTime?.timestr) {
      return new Date();
    }

    return getFirstTeeTime(order);
  }

}
