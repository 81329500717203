import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'gcl-admin-redeem-gift-card-modal',
  templateUrl: './redeem-gift-card-modal.component.html',
  styleUrls: ['./redeem-gift-card-modal.component.scss']
})
export class RedeemGiftCardModalComponent implements OnInit {
  @Output() redemptionCode = new EventEmitter<string>();

  get code() { return this.form.get('code'); }

  public form = this.fb.group({
    code: ['', [Validators.required]],
  });

  constructor(private modalRef: BsModalRef, private fb: FormBuilder) { }

  ngOnInit(): void {
  }

  redeemCode() {
    this.redemptionCode.emit(this.form.get('code')?.value);
  }

  cancel() {
    this.code?.setValue('');
    this.modalRef.hide();
  }
}
