import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { OnPageEvent } from 'projects/shared/src/lib/models/on-page-event';
import { OrderDetails } from 'projects/shared/src/lib/models/order';
import { OrderService } from 'projects/shared/src/lib/services/order.service';
import { SortDirections } from 'projects/shared/src/lib/enumerations/sort-directions';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { OrderStatuses } from 'projects/shared/src/lib/enumerations/order-status';
import { OrderPaymentTypes } from 'projects/shared/src/lib/enumerations/order-payment-types';

@Component({
  selector: 'gcl-admin-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit, OnDestroy {

  pagination$ = new BehaviorSubject<OnPageEvent>({
    itemsPerPage: 10,
    page: 1,
    sortBy: 'id',
    sortDescending: true
  });

  total$: BehaviorSubject<number> = new BehaviorSubject(1);
  records$: ReplaySubject<OrderDetails[]> = new ReplaySubject(1);

  private destory$: Subject<boolean> = new Subject();

  constructor(private orderService: OrderService, private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.pagination$
      .pipe(
        takeUntil(this.destory$)
      )
      .subscribe(() => {
        this.getPageResult();
      });
  }

  ngOnDestroy() {
    this.destory$.next(true);
    this.destory$.complete();
  }

  private getPageResult() {
    this.authService.course$.pipe(
      takeUntil(this.destory$)
    ).subscribe(course => {
      const pagedResult = this.orderService.query({
        skip: (this.pagination$.value.page - 1) * this.pagination$.value.itemsPerPage,
        take: this.pagination$.value.itemsPerPage,
        sortColumns: [{
          column: this.pagination$.value.sortBy,
          direction: this.pagination$.value.sortDescending ? SortDirections.Descending : SortDirections.Ascending,
        }],
        search: this.pagination$.value.search,

        course: course?.id,
        statuses: {
          isEqual: false,
          statuses: [
            OrderStatuses.Cancelled,
          ]
        }
      });

      pagedResult.filteredCount$.subscribe((total) => this.total$.next(total));

      pagedResult.records$.subscribe((records) => {
        records.forEach(r => r.amountRefunded = this.getAmountRefunded(r));
        records.map(r => {
          r.paymentTypes = r.payments?.reduce((acc, payment) => acc + payment.type +", ", "");
          r.paymentTypes = r.paymentTypes ? r.paymentTypes.slice(0, -2) : r.paymentTypes; 
        });
        this.records$.next(records)});
    });
  }

  public goToCreate() {
    this.router.navigate(['/admin', 'orders', 'create']);
  }

  getAmountRefunded(order: OrderDetails) {
    let digitalRefundTotal = order.payments.filter(op => op.type == OrderPaymentTypes.DigitalRefund).reduce((total, op) => total + op.amount, 0);
    let cashRefundTotal = order.payments.filter(op => op.type == OrderPaymentTypes.CashRefund).reduce((total, op) => total + op.amount, 0);
    let creditBalanceRefundTotal = order.payments.filter(op => op.type == OrderPaymentTypes.CreditBalanceRefund).reduce((total, op) => total + op.amount, 0);
    return -1 * (digitalRefundTotal + cashRefundTotal + creditBalanceRefundTotal);
  }

}
