import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Course } from 'projects/shared/src/lib/models/course';
import { AdminReportQuery } from 'projects/shared/src/lib/queries/admin-report-query';

import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { CategoryService } from 'projects/shared/src/lib/services/category.service';
import { CourseService } from 'projects/shared/src/lib/services/course.service';
import { ReportService } from 'projects/shared/src/lib/services/report.service';
import { UserService } from 'projects/shared/src/lib/services/user.service';
import { OnPageEvent, User } from 'projects/shared/src/public-api';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, takeUntil, tap } from 'rxjs/operators';
import { calculateEventAgingTotals, calculateInventoryTotals, calculateMemberAgingTotals, calculateRoundsPlayedTotals, calculateSalesTotals } from '../../utils/aggregate-utils';
import { ConsolidatedReportByDateQuery } from 'projects/shared/src/lib/queries/consolidated-report-by-date-query';
import { CashDrawerEntryReportByDateQuery } from 'projects/shared/src/lib/queries/cash-drawer-entry-report-by-date-query';
import { ConsolidatedReportByDate } from 'projects/shared/src/lib/models/consolidated-report-by-date';

enum ReportOutputType {
  SalesTaxReport = 'SalesTaxReport',
  InventoryReport = 'InventoryReport',
  EventAgingReport = 'EventAgingReport',
  RoundsPlayedReport = 'RoundsPlayedReport',
  MemberAgingReport = 'MemberAgingReport',
  CertificatesAgingReport = 'CertificatesAgingReport',
  TipAgingReport = 'TipAgingReport',
  Consolidated = 'consolidated',
  EodDrawerCount = 'eod-drawer-count'
}
interface DropdownModel {
  name: any, id: number, selected: boolean, totalOrders: number
}

@Component({
  selector: 'gcl-admin-reports-table',
  templateUrl: './reports-table.component.html',
  styleUrls: ['./reports-table.component.scss']
})
export class ReportsTableComponent implements OnInit {
  @Input() enableCreate: boolean = false;
  @Input() courseId!: number;
  productSKU!: string;
  accountingCode = new FormControl();

  pagination$ = new BehaviorSubject<OnPageEvent>({
    itemsPerPage: 10,
    page: 1,
    sortBy: 'id',
    sortDescending: true
  });
  inventoryPagination$ = new BehaviorSubject<OnPageEvent>({
    itemsPerPage: 10,
    page: 1,
    sortBy: 'id',
    sortDescending: true
  });
  eventAgingPagination$ = new BehaviorSubject<OnPageEvent>({
    itemsPerPage: 10,
    page: 1,
    sortBy: 'id',
    sortDescending: true
  });
  roundsPlayedPagination$ = new BehaviorSubject<OnPageEvent>({
    itemsPerPage: 10,
    page: 1,
    sortBy: 'id',
    sortDescending: true
  });
  memberAgingPagination$ = new BehaviorSubject<OnPageEvent>({
    itemsPerPage: 10,
    page: 1,
    sortBy: 'id',
    sortDescending: true
  });
  certificatesAgingPagination$ = new BehaviorSubject<OnPageEvent>({
    itemsPerPage: 10,
    page: 1,
    sortBy: 'id',
    sortDescending: true
  });
  tipAgingPagination$ = new BehaviorSubject<OnPageEvent>({
    itemsPerPage: 10,
    page: 1,
    sortBy: 'id',
    sortDescending: true
  });
  consolidatedPagination$ = new BehaviorSubject<OnPageEvent>({
    itemsPerPage: 10,
    page: 1,
    sortBy: 'id',
    sortDescending: true
  });
  cashDrawerEntryPagination$ = new BehaviorSubject<OnPageEvent>({
    itemsPerPage: 10,
    page: 1,
    sortBy: 'id',
    sortDescending: true
  });

  selectAllCourseGroup = true;
  categoryFilterRequiredReports = [
    ReportOutputType.SalesTaxReport,
    ReportOutputType.InventoryReport
  ];
  dateFilterRequiredReports = [
    ReportOutputType.SalesTaxReport,
    ReportOutputType.EventAgingReport,
    ReportOutputType.RoundsPlayedReport,
    ReportOutputType.MemberAgingReport,
    ReportOutputType.CertificatesAgingReport,
    ReportOutputType.TipAgingReport,
    ReportOutputType.Consolidated,
    ReportOutputType.EodDrawerCount,
  ]
  accountingFilterRequiredReports = [
    ReportOutputType.SalesTaxReport
  ]

  beginDate$ = new BehaviorSubject<Date>(new Date().addDays(-7));
  endDate$ = new BehaviorSubject<Date>(new Date());

  employeeId$ = new BehaviorSubject<number | null>(null);

  total$: BehaviorSubject<number> = new BehaviorSubject(1);
  records$: ReplaySubject<any[]> = new ReplaySubject(1);

  inventoryTotal$: BehaviorSubject<number> = new BehaviorSubject(1);
  inventoryRecords$: ReplaySubject<any[]> = new ReplaySubject(1);

  eventAgingTotal$: BehaviorSubject<number> = new BehaviorSubject(1);
  eventAgingRecords$: ReplaySubject<any[]> = new ReplaySubject(1);

  roundsPlayedTotal$: BehaviorSubject<number> = new BehaviorSubject(1);
  roundsPlayedRecords$: ReplaySubject<any[]> = new ReplaySubject(1);

  memberAgingTotal$: BehaviorSubject<number> = new BehaviorSubject(1);
  memberAgingRecords$: ReplaySubject<any[]> = new ReplaySubject(1);

  certificatesAgingTotal$: BehaviorSubject<number> = new BehaviorSubject(1);
  certificatesAgingRecords$: ReplaySubject<any[]> = new ReplaySubject(1);

  tipAgingTotal$: BehaviorSubject<number> = new BehaviorSubject(1);
  tipAgingRecords$: ReplaySubject<any[]> = new ReplaySubject(1);

  consolidatedTotal$: BehaviorSubject<number> = new BehaviorSubject(1);
  consolidatedRecords$: ReplaySubject<any[]> = new ReplaySubject(1);

  cashDrawerEntryTotal$: BehaviorSubject<number> = new BehaviorSubject(1);
  cashDrawerEntryRecords$: ReplaySubject<any[]> = new ReplaySubject(1);

  private destroy$: Subject<boolean> = new Subject();

  public reportOutputType: ReportOutputType = ReportOutputType.InventoryReport;

  ReportOutputType = ReportOutputType;
  public nonEmptyResults: boolean = true;

  public users$!: Observable<User[]>;
  public course$!: Observable<Course[]>;
  public categories$!: Observable<DropdownModel[]>;
  selectedCourse$!: any;
  query: AdminReportQuery = {
    skip: (this.pagination$.value.page - 1) * this.pagination$.value.itemsPerPage,
    take: this.pagination$.value.itemsPerPage,
    beginDate: new Date(),
    endDate: new Date(),
    categoryId: 0,
  };
  currentRecords: any;
  selectedCourseGroupId: any;

  constructor(private reportService: ReportService, private userService: UserService,
    private courseService: CourseService, private categorieService: CategoryService,
    public authService: AuthService) { }

  ngOnInit(): void {
    this.authService.course$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(course => {
      this.selectedCourse$ = course;
      this.pagination$.next(this.pagination$.value)
      this.inventoryPagination$.next(this.inventoryPagination$.value)
      this.eventAgingPagination$.next(this.eventAgingPagination$.value)
      this.roundsPlayedPagination$.next(this.roundsPlayedPagination$.value)
      this.memberAgingPagination$.next(this.memberAgingPagination$.value)

      if (this.reportOutputType == ReportOutputType.InventoryReport) {
        this.getInventoryCategories(course);
      } else if (this.reportOutputType == ReportOutputType.SalesTaxReport
        || this.reportOutputType == ReportOutputType.MemberAgingReport) {
        this.getCategories(course);
      }
    });

    this.course$ = this.courseService.getAll().pipe(takeUntil(this.destroy$));

    this.accountingCode.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe((result) => {
      this.getAdminPageResult()
    });

    combineLatest([this.pagination$, this.beginDate$, this.endDate$])
      .pipe(
        shareReplay(1),
        takeUntil(this.destroy$)
      )
      .subscribe(([]) => {
        this.getAdminPageResult();
      });

    combineLatest([this.inventoryPagination$, this.beginDate$, this.endDate$])
      .pipe(
        shareReplay(1),
        takeUntil(this.destroy$)
      )
      .subscribe(([]) => {
        this.getAdminInventoryPageResult();
      });

    combineLatest([this.eventAgingPagination$, this.beginDate$, this.endDate$])
      .pipe(
        shareReplay(1),
        takeUntil(this.destroy$)
      )
      .subscribe(([]) => {
        this.getEventAgingPageResult();
      });

    combineLatest([this.roundsPlayedPagination$, this.beginDate$, this.endDate$])
      .pipe(
        shareReplay(1),
        takeUntil(this.destroy$)
      )
      .subscribe(([]) => {
        this.getRoundsPlayedPageResult();
      });

    combineLatest([this.memberAgingPagination$, this.beginDate$, this.endDate$])
      .pipe(
        shareReplay(1),
        takeUntil(this.destroy$)
      )
      .subscribe(([]) => {
        this.getMemberAgingPageResult();
      });

    combineLatest([this.consolidatedPagination$, this.beginDate$, this.endDate$])
      .pipe(
        shareReplay(1),
        takeUntil(this.destroy$)
      )
      .subscribe(([]) => {
        this.getConsolidatedPageResult();
      });

    combineLatest([this.cashDrawerEntryPagination$, this.beginDate$, this.endDate$])
      .pipe(
        shareReplay(1),
        takeUntil(this.destroy$)
      )
      .subscribe(([]) => {
        this.getCashDrawerEntryPageResult();
      });

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  beginDateChanged(beginDate: Date) {
    this.beginDate$.next(beginDate);
    this.getCategories(this.selectedCourse$);
  }

  endDateChanged(endDate: Date) {
    this.endDate$.next(endDate);
    this.getCategories(this.selectedCourse$);
  }
  getCategories(course: Course) {
    this.categories$ = this.reportService.getCategories({ courseId: course?.id, beginDate: this.query.beginDate, endDate: this.query.endDate })
      .pipe(
        map((categories: any) => {
          return categories?.records?.map((category: any) => ({
            id: Number(category.id),
            name: category.name,
            selected: false,
            totalOrders: Number(category.totalOrders) || 0
          }));
        }),
        takeUntil(this.destroy$),
        shareReplay(1)
      );
  }

  onReportTypeChange() {
    if (this.reportOutputType == ReportOutputType.InventoryReport) {
      this.getInventoryCategories(this.selectedCourse$);
    } else if (this.reportOutputType == ReportOutputType.SalesTaxReport) {
      this.getCategories(this.selectedCourse$);
    }
  }

  getInventoryCategories(course: Course) {
    this.categories$ = this.reportService.getInventoryCategories({ courseId: course?.id })
      .pipe(
        map((categories: any) => {
          return categories?.records?.map((category: any) => ({
            id: Number(category.id),
            name: category.name,
            selected: false,
            totalOrders: Number(category.totalOrders) || 0
          }));
        }),
        takeUntil(this.destroy$),
        shareReplay(1)
      );
  }

  selectCourseGroup(courseGroup?: DropdownModel) {
    if (courseGroup) {
      this.selectAllCourseGroup = false;
      this.selectedCourseGroupId = courseGroup.id;
    } else {
      this.selectAllCourseGroup = true;
    }
    this.categories$ = this.categories$.pipe(
      map(categories => {
        // Deselect all categories
        categories.forEach(category => category.selected = false);
        // Select the specified course group, if provided
        if (courseGroup) {
          courseGroup.selected = true;
        }
        return categories;
      }),
      takeUntil(this.destroy$),
      shareReplay(1),
    )

    if (this.reportOutputType == ReportOutputType.SalesTaxReport) {
      this.getAdminPageResult()
    } else if (this.reportOutputType == ReportOutputType.InventoryReport) {
      this.getAdminInventoryPageResult()
    } else if (this.reportOutputType == ReportOutputType.EventAgingReport) {
      this.getEventAgingPageResult()
    } else if (this.reportOutputType == ReportOutputType.RoundsPlayedReport) {
      this.getRoundsPlayedPageResult()
    } else if (this.reportOutputType == ReportOutputType.MemberAgingReport) {
      this.getMemberAgingPageResult()
    } else if (this.reportOutputType == ReportOutputType.Consolidated) {
      this.getConsolidatedPageResult()
    } else if (this.reportOutputType == ReportOutputType.EodDrawerCount) {
      this.getCashDrawerEntryPageResult()
    }
  }

  private getAdminPageResult() {
    if (!this.selectedCourse$ || !this.selectedCourse$.id) {
      console.log('course not selected')
      return
    }
    this.query.courseId = this.selectedCourse$.id;

    if (this.selectAllCourseGroup) {
      delete this.query.categoryId;
    } else {
      this.query.categoryId = this.selectedCourseGroupId;
    }

    this.query.beginDate = this.beginDate$.value;
    this.query.endDate = this.endDate$.value;
    this.query.skip = (this.pagination$.value.page - 1) * this.pagination$.value.itemsPerPage;
    this.query.take = this.pagination$.value.itemsPerPage;
    this.query.accountingCode = this.accountingCode.value
    if (this.pagination$.value.sortBy) {
      this.query.sort = this.pagination$.value.sortBy;
    }
    if (this.pagination$.value.sortDescending !== null) {
      this.query.sortDescending = this.pagination$.value.sortDescending;
    }

    const pagedResult$ = this.reportService.getAdminReport(this.query).pipe(shareReplay(1));

    pagedResult$.subscribe((result) => {
      this.currentRecords = result.records
      this.records$.next(result.records);
      this.total$.next(result.totalCount);
    });
  }

  private getAdminInventoryPageResult() {
    if (!this.selectedCourse$ || !this.selectedCourse$.id) {
      console.log('course not selected')
      return
    }
    this.query.courseId = this.selectedCourse$.id;

    if (this.selectAllCourseGroup) {
      delete this.query.categoryId;
    } else {
      this.query.categoryId = this.selectedCourseGroupId;
    }

    this.query.skip = (this.inventoryPagination$.value.page - 1) * this.inventoryPagination$.value.itemsPerPage;
    this.query.take = this.inventoryPagination$.value.itemsPerPage;
    this.query.accountingCode = this.accountingCode.value
    if (this.inventoryPagination$.value.sortBy) {
      this.query.sort = this.inventoryPagination$.value.sortBy;
    }
    if (this.inventoryPagination$.value.sortDescending !== null) {
      this.query.sortDescending = this.inventoryPagination$.value.sortDescending;
    }

    const pagedResult$ = this.reportService.getAdminInventoryReport(this.query).pipe(shareReplay(1));

    pagedResult$.subscribe((result) => {
      this.currentRecords = result.records
      this.inventoryRecords$.next(result.records);
      this.inventoryTotal$.next(result.totalCount);
    });
  }


  private getEventAgingPageResult() {
    if (!this.selectedCourse$ || !this.selectedCourse$.id) {
      console.log('course not selected')
      return
    }
    this.query.courseId = this.selectedCourse$.id;
    this.query.beginDate = this.beginDate$.value;
    this.query.endDate = this.endDate$.value;
    this.query.skip = (this.eventAgingPagination$.value.page - 1) * this.eventAgingPagination$.value.itemsPerPage;
    this.query.take = this.eventAgingPagination$.value.itemsPerPage;
    if (this.eventAgingPagination$.value.sortBy) {
      this.query.sort = this.eventAgingPagination$.value.sortBy;
    }
    if (this.eventAgingPagination$.value.sortDescending !== null) {
      this.query.sortDescending = this.eventAgingPagination$.value.sortDescending;
    }

    const pagedResult$ = this.reportService.getEventAgingReport(this.query).pipe(shareReplay(1));

    pagedResult$.subscribe((result) => {
      this.currentRecords = result.records
      this.eventAgingRecords$.next(result.records);
      this.eventAgingTotal$.next(result.totalCount);
    });
  }

  private getRoundsPlayedPageResult() {
    if (!this.selectedCourse$ || !this.selectedCourse$.id) {
      console.log('course not selected')
      return
    }
    this.query.courseId = this.selectedCourse$.id;
    this.query.beginDate = this.beginDate$.value;
    this.query.endDate = this.endDate$.value;
    this.query.skip = (this.roundsPlayedPagination$.value.page - 1) * this.roundsPlayedPagination$.value.itemsPerPage;
    this.query.take = this.roundsPlayedPagination$.value.itemsPerPage;
    if (this.roundsPlayedPagination$.value.sortBy) {
      this.query.sort = this.roundsPlayedPagination$.value.sortBy;
    }
    if (this.roundsPlayedPagination$.value.sortDescending !== null) {
      this.query.sortDescending = this.roundsPlayedPagination$.value.sortDescending;
    }

    const pagedResult$ = this.reportService.getRoundsPlayedReport(this.query).pipe(shareReplay(1));

    pagedResult$.subscribe((result) => {
      this.currentRecords = result.records
      this.roundsPlayedRecords$.next(result.records);
      this.roundsPlayedTotal$.next(result.totalCount);
    });
  }

  private getMemberAgingPageResult() {
    if (!this.selectedCourse$ || !this.selectedCourse$.id) {
      console.log('course not selected')
      return
    }
    this.query.courseId = this.selectedCourse$.id;
    this.query.beginDate = this.beginDate$.value;
    this.query.endDate = this.endDate$.value;
    this.query.skip = (this.memberAgingPagination$.value.page - 1) * this.memberAgingPagination$.value.itemsPerPage;
    this.query.take = this.memberAgingPagination$.value.itemsPerPage;
    if (this.memberAgingPagination$.value.sortBy) {
      this.query.sort = this.memberAgingPagination$.value.sortBy;
    }
    if (this.memberAgingPagination$.value.sortDescending !== null) {
      this.query.sortDescending = this.memberAgingPagination$.value.sortDescending;
    }

    const pagedResult$ = this.reportService.getMemberAgingReport(this.query).pipe(shareReplay(1));

    pagedResult$.subscribe((result) => {
      this.currentRecords = result.records
      this.memberAgingRecords$.next(result.records);
      this.memberAgingTotal$.next(result.totalCount);
    });
  }

  private getConsolidatedPageResult() {
    if (!this.selectedCourse$ || !this.selectedCourse$.id) {
      console.log('course not selected')
      return
    }
    let beginDate = new Date(this.beginDate$.value.getTime());
    let endDate = new Date(this.endDate$.value.getTime());

    beginDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    let query: ConsolidatedReportByDateQuery = {
      skip: (this.consolidatedPagination$.value.page - 1) * this.consolidatedPagination$.value.itemsPerPage,
      take: this.consolidatedPagination$.value.itemsPerPage,
      courseId: this.selectedCourse$.id,
      beginDate: beginDate,
      endDate: endDate,
    };

    const pagedResult$ = this.reportService.getConsolidatedReportByDate(query).pipe(shareReplay(1));

    pagedResult$.subscribe((result) => {
      this.consolidatedRecords$.next(result.records);
      this.consolidatedTotal$.next(result.totalCount);
    });
  }

  private getCashDrawerEntryPageResult() {
    if (!this.selectedCourse$ || !this.selectedCourse$.id) {
      console.log('course not selected')
      return
    }
    let beginDate = new Date(this.beginDate$.value.getTime());
    let endDate = new Date(this.endDate$.value.getTime());

    beginDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    let query: CashDrawerEntryReportByDateQuery = {
      skip: (this.cashDrawerEntryPagination$.value.page - 1) * this.cashDrawerEntryPagination$.value.itemsPerPage,
      take: this.cashDrawerEntryPagination$.value.itemsPerPage,
      courseId: this.selectedCourse$.id,
      beginDate: beginDate,
      endDate: endDate,
    };

    const pagedResult$ = this.reportService.getCashDrawerEntryReportByDate(query).pipe(shareReplay(1));

    pagedResult$.subscribe((result) => {
      this.cashDrawerEntryRecords$.next(result.records);
      this.cashDrawerEntryTotal$.next(result.totalCount);
      this.nonEmptyResults = result.records.some(record => record.course != "");
    });
  }

  generateReport() {
    if (this.reportOutputType == ReportOutputType.SalesTaxReport) {
      this.generateSalesReport()
    } else if (this.reportOutputType == ReportOutputType.InventoryReport) {
      this.generateInventoryReport()
    } else if (this.reportOutputType == ReportOutputType.EventAgingReport) {
      this.generateEventAgingReport()
    } else if (this.reportOutputType == ReportOutputType.RoundsPlayedReport) {
      this.generateRoundsPlayReport()
    } else if (this.reportOutputType == ReportOutputType.MemberAgingReport) {
      this.generateMemberAgingReport()
    } else if (this.reportOutputType == ReportOutputType.Consolidated) {
      this.generateConsolidatedReport()
    }
  }

  generateReportForRow(row: ConsolidatedReportByDate) {
    let beginDate = new Date(row.date);
    beginDate.setHours(0, 0, 0, 0);

    let endDate = new Date(row.date);
    endDate.setHours(23, 59, 59, 999);

    this.reportService.getConsolidatedReportPdf(this.query.courseId as number, beginDate, endDate).subscribe(blob => {
      this.exportPdf(blob, 'consolidated-report.pdf');
    });
  }

  generateDrawerCountReport() {
    let beginDate = new Date(this.beginDate$.value.getTime());
    let endDate = new Date(this.endDate$.value.getTime());

    beginDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    this.reportService.getDrawerCountReportPdf(this.courseId, beginDate, endDate).subscribe(blob => {
      this.exportPdf(blob, 'drawer-count-report.pdf');
    });

    return;
  }

  generateSalesReport() {
    this.reportService.getAdminReportPdf(this.query.courseId, this.beginDate$.value, this.endDate$.value, this.query.categoryId, this.query.accountingCode).subscribe(blob => {
      this.exportPdf(blob, 'admin-report.pdf');
    });
  }

  generateInventoryReport() {
    this.reportService.getAdminInventoryReportPdf(this.query.courseId, this.beginDate$.value, this.endDate$.value, this.query.categoryId, this.query.accountingCode).subscribe(blob => {
      this.exportPdf(blob, 'inventory-on-hand-report.pdf');
    });
  }

  generateEventAgingReport() {
    this.reportService.getEventAgingReportPdf(this.query.courseId, this.beginDate$.value, this.endDate$.value).subscribe(blob => {
      this.exportPdf(blob, 'new-event-aging-report.pdf');
    });
  }

  generateRoundsPlayReport() {
    this.reportService.getRoundsPlayedReportPdf(this.query.courseId, this.beginDate$.value, this.endDate$.value).subscribe(blob => {
      this.exportPdf(blob, 'rounds-played-report.pdf');
    });
  }

  generateMemberAgingReport() {
    this.reportService.getMemberAgingReportPdf(this.query.courseId, this.beginDate$.value, this.endDate$.value).subscribe(blob => {
      this.exportPdf(blob, 'member-aging-report.pdf');
    });
  }

  generateConsolidatedReport() {
    this.reportService.getConsolidatedReportPdf(this.query.courseId as number, this.beginDate$.value, this.endDate$.value).subscribe(blob => {
      this.exportPdf(blob, 'consolidated-report.pdf');
    });
  }

  private exportPdf(blob: Blob, fileName: string) {
    // https://stackoverflow.com/questions/52154874/angular-6-downloading-file-from-rest-api         

    var pdfBlob = new Blob([blob], { type: "application/pdf" });

    const data = window.URL.createObjectURL(pdfBlob);

    let link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }

  generateDrawerCountReportForRow(row: any) {
    let beginDate = new Date(row.timestamp);
    beginDate.setHours(0, 0, 0, 0);

    let endDate = new Date(row.timestamp);
    endDate.setHours(23, 59, 59, 999);

    this.reportService.getDrawerCountReportPdf(this.courseId, beginDate, endDate).subscribe(blob => {
      this.exportPdf(blob, 'drawer-count-report.pdf');
    });

    return;
  }

  convertToCSV(objArray: any) {
    if (this.reportOutputType == ReportOutputType.SalesTaxReport) {
      return this.convertSalesToCSV(objArray)
    } else if (this.reportOutputType == ReportOutputType.InventoryReport) {
      return this.convertInventoryReportToCSV(objArray)
    } else if (this.reportOutputType == ReportOutputType.EventAgingReport) {
      return this.convertEventAgingReportToCSV(objArray)
    } else if (this.reportOutputType == ReportOutputType.RoundsPlayedReport) {
      return this.convertRoundsPlayedReportToCSV(objArray)
    } else if (this.reportOutputType == ReportOutputType.MemberAgingReport) {
      return this.convertMemberAgingReportToCSV(objArray)
    }
    return "";
  }

  convertInventoryReportToCSV(objArray: any) {
    var arrayRes = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;

    // Define the order of keys in the CSV
    var keyOrder = ['categoryName', 'accountCode', 'pdescription', 'cost', 'onHand', 'cost', 'price', 'price'];
    var str = 'Group,Accounting Code,Description,Cost,On Hand,Total Cost,Retail,Total Retail' + '\r\n';;
    // var str = keyOrder.join(',') + '\r\n';

    const groupedByCategoryName = arrayRes.reduce((acc: any, record: any) => {
      const { categoryName } = record;
      acc[categoryName] = acc[categoryName] || [];
      acc[categoryName].push(record);
      return acc;
    }, {});

    const categoryNameTotalInfoMap = calculateInventoryTotals(groupedByCategoryName);

    for (var l = 0; l < Object.keys(groupedByCategoryName).length; l++) {
      const array = groupedByCategoryName[Object.keys(groupedByCategoryName)[l]];
      for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var j = 0; j < keyOrder.length; j++) {
          var index = keyOrder[j];
          if (line !== '') line += ',';
          let val = array[i][index];

          if (index === 'cost' || index === 'price' || index === 'onHand') {
            val = '$' + (val || 0);
          }
          if (index === 'accountCode' && val === null) {
            val = ''
          }
          if (val === undefined) {
            val = ' '
          }
          line += val;
        }
        str += line + '\r\n';
      }

      const totalInfo = categoryNameTotalInfoMap[Object.keys(groupedByCategoryName)[l]];
      var line = '';
      for (var i = 0; i < keyOrder.length; i++) {
        var index = keyOrder[i];
        if (line !== '') line += ',';
        let val = totalInfo[index];
        if (index === 'cost' || index === 'price') {
          val = '$' + Number(val).toFixed(2) || 0;
        }
        if (val === undefined) {
          val = ' '
        }
        line += val;
      }
      str += line + '\r\n';
    }

    return str;
  }

  convertEventAgingReportToCSV(objArray: any) {
    var array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;

    // Define the order of keys in the CSV
    var keyOrder = ['eventDate', 'name', 'finalTotalAmount', 'daysAged', 'finalTotalAmount', 'contact'];
    var str = 'Event Date,Event Name,Current Amount,Days Aged,Remaining Amount,Contact' + '\r\n';;
    // var str = keyOrder.join(',') + '\r\n';

    for (var i = 0; i < array.length; i++) {
      var line = '';
      for (var j = 0; j < keyOrder.length; j++) {
        var index = keyOrder[j];
        if (line !== '') line += ',';
        let val = array[i][index];

        if (index === 'finalTotalAmount') {
          val = '$' + (val || 0);
        }
        if (val === undefined) {
          val = ' '
        }
        line += val;
      }
      str += line + '\r\n';
    }

    const total = calculateEventAgingTotals(array);
    var line = '';
    for (var j = 0; j < keyOrder.length; j++) {
      var index = keyOrder[j];
      if (line !== '') line += ',';
      let val = " ";
      if (index === 'finalTotalAmount') {
        val = '$' + (total || 0);
      }
      if (val === undefined) {
        val = ' '
      }
      line += val;
    }
    str += line + '\r\n';

    return str;
  }

  convertRoundsPlayedReportToCSV(objArray: any) {
    var array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;

    // Define the order of keys in the CSV
    var keyOrder = ['name', 'totalRoundsPlayed', 'totalAmount', 'totalCost'];
    var str = 'DESCRIPTION,TOTAL ROUNDS PLAYED,TOTAL AMOUNT,COST,MARGIN' + '\r\n';;
    // var str = keyOrder.join(',') + '\r\n';

    for (var i = 0; i < array.length; i++) {
      var line = '';
      for (var j = 0; j < keyOrder.length; j++) {
        var index = keyOrder[j];
        if (line !== '') line += ',';
        let val = array[i][index];

        if (index === 'totalAmount' || index === 'totalCost') {
          val = '$' + (val || 0);
        }
        line += val;
      }
      str += line + '\r\n';
    }

    const totalInfo = calculateRoundsPlayedTotals(array);
    var line = '';
    for (var j = 0; j < keyOrder.length; j++) {
      var index = keyOrder[j];
      if (line !== '') line += ',';
      let val = " ";
      if (index === 'totalAmount') {
        val = '$' + (totalInfo.totalAmount || 0);
      }
      if (index === 'totalCost') {
        val = '$' + (totalInfo.totalCost || 0);
      }
      if (index === 'totalRoundsPlayed') {
        val = totalInfo.totalRoundsPlayed;
      }
      if (val === undefined) {
        val = ' '
      }
      line += val;
    }
    str += line + '\r\n';

    return str;
  }

  convertMemberAgingReportToCSV(objArray: any) {
    var array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;

    // Define the order of keys in the CSV
    var keyOrder = ['firstName', 'created_at', 'currentAmount', 'daysAged',
      'total', 'phoneNumber', 'lastPayment', 'amount'];
    var str = 'MEMBER,SINCE,CURRENT AMOUNT,DAYS AGED,TOTAL,PHONE #,LAST PAYMENT,AMOUNT' + '\r\n';;
    // var str = keyOrder.join(',') + '\r\n';

    for (var i = 0; i < array.length; i++) {
      var line = '';
      for (var j = 0; j < keyOrder.length; j++) {
        var index = keyOrder[j];
        if (line !== '') line += ',';
        let val = array[i][index];

        if (index === 'total' || index === 'amount') {
          val = '$' + (val || 0);
        }
        if (index === "firstName") {
          let lastName = array[i]["lastName"];
          val = lastName ? val + " " + lastName : val;
        }
        line += val;
      }
      str += line + '\r\n';
    }

    const totalInfo = calculateMemberAgingTotals(array);
    var line = '';
    for (var j = 0; j < keyOrder.length; j++) {
      var index = keyOrder[j];
      if (line !== '') line += ',';
      let val = " ";
      if (index === 'amount') {
        val = '$' + (totalInfo.amount || 0).toFixed(2);
      }
      if (index === 'currentAmount') {
        val = '$' + (totalInfo.currentAmount || 0).toFixed(2);
      }
      if (val === undefined) {
        val = ' '
      }
      line += val;
    }
    str += line + '\r\n';

    return str;
  }

  convertSalesToCSV(objArray: any) {
    var arrayRes = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;

    // Define the order of keys in the CSV
    var keyOrder = ['categoryName', 'accountCode', 'pdescription', 'quantity', 'unitprice', 'tax', 'taxrate', 'ntsq', 'ntsr', 'totalRevenue'];
    var str = 'Group,Accounting Code,Description,Quantity,Revenue,Tax,Tax Rate,NTS Quantity,NTS Revenue,Total Revenue' + '\r\n';;
    // var str = keyOrder.join(',') + '\r\n';
    const groupedByCategoryName = arrayRes.reduce((acc: any, record: any) => {
      const { categoryName } = record;
      acc[categoryName] = acc[categoryName] || [];
      acc[categoryName].push(record);
      return acc;
    }, {});

    const categoryNameTotalInfoMap = calculateSalesTotals(groupedByCategoryName);

    for (var l = 0; l < Object.keys(groupedByCategoryName).length; l++) {
      const array = groupedByCategoryName[Object.keys(groupedByCategoryName)[l]];
      for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var j = 0; j < keyOrder.length; j++) {
          var index = keyOrder[j];
          if (line !== '') line += ',';
          let val = array[i][index];

          if (index === 'unitprice') {
            val = '$' + Number((array[i]['unitprice'] - array[i]['cost']) * array[i]['quantity']).toFixed(2) || 0;
          }
          if (index === 'totalRevenue') {
            val = '$' + Number(((array[i]['unitprice'] - (array[i]['cost'] || 0)) * array[i]['quantity']) - array[i]['tax']).toFixed(2) || 0;
          }
          if (index === 'ntsq' || index === 'ntsr') {
            val = ''
          }
          if (index === 'taxrate') {
            val += '%'
          }
          if (index === 'accountCode' && val === null) {
            val = ''
          }
          line += val ? val : "";
        }
        str += line + '\r\n';
      }

      const totalInfo = categoryNameTotalInfoMap[Object.keys(groupedByCategoryName)[l]];
      var line = '';
      for (var i = 0; i < keyOrder.length; i++) {
        var index = keyOrder[i];
        if (line !== '') line += ',';
        let val = totalInfo[index];
        if (index === 'unitprice' || index === 'totalRevenue') {
          val = '$' + Number(val).toFixed(2) || 0;
        }
        if (val === undefined) {
          val = ' '
        }
        line += val;
      }
      str += line + '\r\n';

    }

    return str;
  }



  exportCSV() {
    this.query.take = 100000;
    let pagedResult$: Observable<any>;
    let reportName = "";

    if (this.reportOutputType == ReportOutputType.SalesTaxReport) {
      pagedResult$ = this.reportService.getAdminReport(this.query).pipe(shareReplay(1));
      reportName = "sales_tax_report";
    } else if (this.reportOutputType == ReportOutputType.InventoryReport) {
      pagedResult$ = this.reportService.getAdminInventoryReport(this.query).pipe(shareReplay(1));
      reportName = "inventory_on_hand_report"
    } else if (this.reportOutputType == ReportOutputType.EventAgingReport) {
      pagedResult$ = this.reportService.getEventAgingReport(this.query).pipe(shareReplay(1));
      reportName = "new_event_aging_report"
    } else if (this.reportOutputType == ReportOutputType.RoundsPlayedReport) {
      pagedResult$ = this.reportService.getRoundsPlayedReport(this.query).pipe(shareReplay(1));
      reportName = "rounds_played_report"
    } else if (this.reportOutputType == ReportOutputType.MemberAgingReport) {
      pagedResult$ = this.reportService.getMemberAgingReport(this.query).pipe(shareReplay(1));
      reportName = "member_aging_report"
    } else {
      pagedResult$ = of(null);
    }

    pagedResult$?.subscribe((result) => {
      if (result) {
        this.currentRecords = result.records
        let csv = this.convertToCSV(this.currentRecords);
        var pdfBlob = new Blob(['\ufeff' + csv], { type: "text/csv;charset=utf-8;" });

        const data = window.URL.createObjectURL(pdfBlob);

        let link = document.createElement('a');
        link.href = data;
        let dateStr = new Date().toDateString()
        link.download = `${reportName}_${dateStr}.csv`;
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

        setTimeout(function () {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      }
    }, (err) => { });
  }

  getDateString(timestamp: string) {
    let date = new Date(timestamp);

    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    return `${month < 10 ? '0' : ''}${month}-${dt < 10 ? '0' : ''}${dt}-${year}`
  }

}
