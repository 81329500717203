import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';
import { map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';

import { UserService } from 'projects/shared/src/lib/services/user.service';
import { UserDetails } from 'projects/shared/src/public-api';
import { ConfirmationModalService } from 'projects/shared/src/lib/services/confirmation-modal.service';
import { PlayerFormComponent } from 'projects/shared/src/lib/components/player-form/player-form.component';

@Component({
  selector: 'gcl-admin-player-edit',
  templateUrl: './player-edit.component.html',
  styleUrls: ['./player-edit.component.scss']
})
export class PlayerEditComponent implements OnInit, OnDestroy {

  @ViewChild(PlayerFormComponent) playerFormComponent!: PlayerFormComponent;

  public faChevronLeft = faChevronLeft;

  public userId$!: Observable<number>;
  public user$!: Observable<UserDetails>;
  private destroyed$ = new Subject();

  constructor(private userService: UserService, public route: ActivatedRoute, private router: Router, private toastr: ToastrService, private confirmationModalService: ConfirmationModalService) { }

  ngOnInit(): void {
    this.userId$ = this.route.params.pipe(
      map(params => params.playerId)
    );

    this.user$ = this.userId$.pipe(
      switchMap(userId => this.userService.get(userId)),
      shareReplay(1)
    );
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public openCancelModal(user: UserDetails) {
    this.confirmationModalService.showConfirmationModal({
      title: 'Confirm Cancel',
      message: 'Are you sure you want to discard changes and go back?'
    }).subscribe(confirm => {
      if (confirm === true) {
        this.router.navigate(['/manager', 'add-on', user.id, 'view']);
      }
    })
  }

  public openSaveModal() {
    this.confirmationModalService.showConfirmationModal({
      title: 'Confirm Save',
      message: 'Do you want to save these changes?'
    }).subscribe(confirm => {
      if (confirm === true) {
        this.submit();
      }
    })
  }

  public submit(): void {
    if (this.playerFormComponent.form.valid) {
      this.playerFormComponent.form.patchValue({
        username: this.playerFormComponent.username.value || this.playerFormComponent.email.value,
      });

      this.user$.pipe(
        switchMap(user => this.userService.update(user.id, { ...user, ...this.playerFormComponent.form.value })),
        takeUntil(this.destroyed$)
      ).subscribe((user: UserDetails) => {
        this.playerFormComponent.form.setErrors({});

        this.toastr.success("User updated successfully!");
        this.router.navigate(["/admin", "players", user.id, "view"]);
      }, (err: HttpErrorResponse) => {
        err.error.message.forEach((message: any) => {
          if (message.messages.length > 0) {
            message.messages.forEach((mes: any) => {
              const field = mes.field[0];
              const formField = this.playerFormComponent.form.get(field);
              if (formField) {
                formField.setErrors({
                  error: mes.message
                });
              }
            })
          }
        })
      });
    } else {
      Object.values(this.playerFormComponent.form.controls).forEach(control => {
        control.markAsTouched();
        control.markAsDirty();
      });
    }
  }

}
