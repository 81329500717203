import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { Round, RoundDetails } from '../models/round';
import { PagedResult } from '../queries/paged-result';
import { RoundQuery } from '../queries/round-query';
import { CONFIG_TOKEN, SharedModuleConfig } from '../shared.config';
import { StrapiQueryableService } from './strapi-queryable.service';

declare var Qs: any;

@Injectable({
  providedIn: 'root'
})
export class RoundService extends StrapiQueryableService<Round, RoundDetails, RoundQuery> {

  constructor(protected httpClient: HttpClient, @Inject(CONFIG_TOKEN) protected config: SharedModuleConfig) {
    super({ path: 'rounds' }, httpClient, config);
  }

  protected buildQuery(query?: RoundQuery): string {
    let queryObj = super.buildBaseQueryObj(query);

    // Specific Query filters.
    if (query) {
      if (query.search) {
        queryObj._where.push({
          _or: [{
            id_contains: query.search
          }]
        });
      }

      if (query.schedule) {
        queryObj._where.push({ schedule: query.schedule });
      }
      if (query.course) {
        queryObj._where.push({ course: query.course });
      }
      if (query.status) {
        queryObj._where.push({ status: query.status });
      }
      if (query.startDate) {
        queryObj._where.push({ datestr: query.startDate });
      }
      if (query.endDate) {
        queryObj._where.push({ datestr: query.endDate });
      }
      if (query.startTod) {
        queryObj._where.push({ starttod: query.startTod });
      }
      if (query.turnTod) {
        queryObj._where.push({ turnTod: query.turnTod });
      }
      if (query.holes) {
        queryObj._where.push({ holes: query.holes });
      }

    }

    return Qs.stringify(queryObj);
  }

  public query(query?: RoundQuery): PagedResult<RoundDetails> {
    const total$ = this.count();
    const filteredTotal$ = this.count(query);
    const queryStr = this.buildQuery(query);
    const records$ = this.httpClient.get<RoundDetails[]>(`${this.config.apiUrl}/${this.pathConfig.path}?${queryStr}`)
      .pipe(
        shareReplay(1)
      );
    return new PagedResult(records$, filteredTotal$, total$);
  }

  public count(query?: RoundQuery): Observable<number> {
    const queryStr = this.buildQuery(query);
    return this.httpClient.get<number>(`${this.config.apiUrl}/${this.pathConfig.path}/count?${queryStr}`)
      .pipe(
        shareReplay(1)
      );
  }

  public getAllByUserId(userId: number): Observable<RoundDetails[]> {
    return this.httpClient.get<RoundDetails[]>(`${this.config.apiUrl}/rounds?_limit=10000&users_permissions_user=${userId}`);
  }

  public getUserRounds(): Observable<RoundDetails[]> {
    return this.httpClient.get<RoundDetails[]>(`${this.config.apiUrl}/rounds/mine`).pipe(shareReplay(1));
  }

  public getRoundsByDate(date: string, start?: string, end?: string, holes?: number, course?: number, cart?: boolean): Observable<RoundDetails[]> {
    let params: HttpParams = new HttpParams();
    if (start) {
      params = params.set("start", start.toString());
    }
    if (end) {
      params = params.set("end", end.toString());
    }
    if (holes) {
      params = params.set("holes", holes.toString());
    }
    if (course) {
      params = params.set("course", course.toString());
    }
    if(typeof cart === 'boolean') {
      params = params.set("cart", cart ? 'true' : 'false');
    }

    return this.httpClient.get<RoundDetails[]>(`${this.config.apiUrl}/rounds/choose/${date}`, { params: params }).pipe(shareReplay(1));
  }

  public bookRound(roundId: number): Observable<RoundDetails> {
    return this.httpClient.post<RoundDetails>(`${this.config.apiUrl}/rounds/book/${roundId}`, {});
  }

  public releaseRound(roundId: number): Observable<RoundDetails> {
    return this.httpClient.post<RoundDetails>(`${this.config.apiUrl}/rounds/release/${roundId}`, {});
  }
}

