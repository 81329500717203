import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { OnPageEvent, UserDetails } from 'projects/shared/src/public-api';
import { SortDirections } from '../../../../../../shared/src/lib/enumerations/sort-directions';
import { UserService } from '../../../../../../shared/src/lib/services/user.service';

@Component({
  selector: 'gcl-admin-player-search-modal',
  templateUrl: './player-search-modal.component.html',
  styleUrls: ['./player-search-modal.component.css']
})
export class PlayerSearchModalComponent implements OnInit, OnDestroy {

  @Output()
  select$: EventEmitter<UserDetails> = new EventEmitter<UserDetails>();

  pagination$ = new BehaviorSubject<OnPageEvent>({
    itemsPerPage: 10,
    page: 1,
    sortBy: 'firstName',
    sortDescending: false
  });

  total$: BehaviorSubject<number> = new BehaviorSubject(1);
  records$: ReplaySubject<UserDetails[]> = new ReplaySubject(1);

  private destory$: Subject<boolean> = new Subject();

  constructor(private bsModalRef: BsModalRef, private userService: UserService) { }

  ngOnInit(): void {
    this.pagination$
      .pipe(
        takeUntil(this.destory$)
      )
      .subscribe(() => {
        this.getPageResult();
      });
  }

  ngOnDestroy() {
    this.destory$.next(true);
    this.destory$.complete();
  }

  private getPageResult() {
    const pagedResult = this.userService.query({
      skip: (this.pagination$.value.page - 1) * this.pagination$.value.itemsPerPage,
      take: this.pagination$.value.itemsPerPage,
      sortColumns: [{
        column: this.pagination$.value.sortBy,
        direction: this.pagination$.value.sortDescending ? SortDirections.Descending : SortDirections.Ascending,
      }],
      search: this.pagination$.value.search,
      roles: ["player"]
    });

    pagedResult.filteredCount$.subscribe((total) => this.total$.next(total));
    pagedResult.records$.subscribe((records) => this.records$.next(records));
  }

  public onSelect(user: UserDetails): void {
    this.select$.emit(user);
  }

  public cancel(): void {
    this.bsModalRef.hide();
  }
}
