<div class="d-flex justify-content-start py-2 bg-light">
    <a class="text-light-green"  [routerLink]="['/admin','golf-passes']">
        <fa-icon [icon]="faChevronLeft" class="px-3"></fa-icon> <span>Back to Golf Pass List</span>
    </a>
</div>
<div class="container-fluid py-2">
    <div class="row">
        <div class="col-12">
            <form [formGroup]="form">
                <div class="d-flex justify-content-end py-2">
                    <button type="button" (click)="openCancelModal()" class="btn btn-outline-light-green px-5 mx-1">Cancel</button>
                    <button type="button" (click)="openSaveModal()" class="btn btn-light-green px-5 mx-1">Save</button>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-6">

                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label >Name</label>
                                            <input type="text" class="form-control" formControlName="name" name="name" [class.is-invalid]="name?.invalid && name?.touched" />
                                            <div *ngIf="name?.invalid && name?.touched" class="invalid-feedback">
                                                <div *ngIf="name?.errors?.required">
                                                    Please enter a name.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label >Price</label>
                                            <input type="number" class="form-control" formControlName="price" name="price" [class.is-invalid]="price?.invalid && price?.touched" />
                                            <div *ngIf="price?.invalid && price?.touched" class="invalid-feedback">
                                                <div *ngIf="price?.errors?.required">
                                                    Please enter a price.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label >Available</label>
                                            <gcl-lib-radio-select-list formControlName="available"
                                                [name]="'available'"
                                                [options]="[{text: 'Yes', value: true}, {text: 'No', value: false}]">
                                            </gcl-lib-radio-select-list>
                                            <div *ngIf="available?.invalid && available?.touched" class="invalid-feedback">
                                                <div *ngIf="available?.errors?.required">
                                                    Available is required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label >Taxable</label>
                                            <gcl-lib-radio-select-list formControlName="taxable"
                                                [name]="'taxable'"
                                                [options]="[{text: 'Yes', value: true}, {text: 'No', value: false}]">
                                            </gcl-lib-radio-select-list>
                                            <div *ngIf="taxable?.invalid && taxable?.touched" class="invalid-feedback">
                                                <div *ngIf="taxable?.errors?.required">
                                                    Taxable required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-6">

                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label >Description</label>
                                            <textarea type="text" class="form-control" formControlName="description" name="description" [class.is-invalid]="description?.invalid && description?.touched" rows="5"></textarea>
                                            <div *ngIf="description?.invalid && description?.touched" class="invalid-feedback">
                                                <div *ngIf="description?.errors?.required">
                                                    Please enter a description.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-6">
                                <div class="d-flex align-items-center">
                                    <input #fileUpload type="file" accept="application/pdf" class="d-none" (change)="onFileChanged($event)" />
                                    <button class="btn btn-light-green" (click)="fileUpload.click()">{{!!guidelines.value ? 'Change' : 'Upload'}} Guidelines PDF</button>    
                                    <div class="ml-2" *ngIf="guidelines.value">{{guidelines.value.name}}</div>
                                </div>
                                <div *ngIf="!guidelines.value && guidelines.touched" class="invalid-feedback d-block">
                                    <div *ngIf="!guidelines.value && !description.value">
                                        Guidelines or a description must be provided.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
