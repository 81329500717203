<div class="container-fluid">
    <div class="row vh-100 justify-content-center align-items-center">
        <div class="col-11 col-sm-8 col-md-6 col-lg-5 col-xl-3">
            <h1 class="text-center my-4">Admin</h1>
            <div class="border rounded bg-light p-3 mb-3">
                <gcl-lib-login (onLogin)="goHome()"></gcl-lib-login>
            </div>
            <div class="d-flex justify-content-center">
                <a [routerLink]="['/forgot-password']">Forgot your password?</a>
            </div>
        </div>
    </div>
</div>