import { HttpClient } from "@angular/common/http";
import { Inject } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { shareReplay, switchMap } from "rxjs/operators";

import { Base } from "../models/base";
import { CONFIG_TOKEN, SharedModuleConfig, SharedPathConfig } from "../shared.config";

export class StrapiService<TBase extends Base, TDetails extends Base> {
  private changed$: BehaviorSubject<string> = new BehaviorSubject('');
  private cache$?: Observable<TDetails[]>;

  constructor(protected pathConfig: SharedPathConfig, protected httpClient: HttpClient, @Inject(CONFIG_TOKEN) protected config: SharedModuleConfig) { }

  public getAll(): Observable<TDetails[]> {
    if (!this.cache$) {
      this.cache$ = this.changed$
        .pipe(
          switchMap(() => this.httpClient.get<TDetails[]>(`${this.config.apiUrl}/${this.pathConfig.path}?_limit=10000`)),
          shareReplay(1)
        );
    }
    return this.cache$;
  }

  public get(id: number): Observable<TDetails> {
    return this.httpClient.get<TDetails>(`${this.config.apiUrl}/${this.pathConfig.path}/${id}`)
      .pipe(
        shareReplay(1)
      );
  }

  public create(record: Partial<TBase>): Observable<TDetails> {
    return this.httpClient.post<TDetails>(`${this.config.apiUrl}/${this.pathConfig.path}`, record);
  }

  public update(id: number, record: Partial<TBase>): Observable<TDetails> {
    return this.httpClient.put<TDetails>(`${this.config.apiUrl}/${this.pathConfig.path}/${id}`, record);
  }

  public delete(id: number): Observable<TDetails> {
    return this.httpClient.delete<TDetails>(`${this.config.apiUrl}/${this.pathConfig.path}/${id}`);
  }

}
