import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { EventDetails } from 'projects/shared/src/lib/models/event';
import { EventSpaceDetails } from 'projects/shared/src/lib/models/event-space';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { EventSpaceService } from 'projects/shared/src/lib/services/event-space.service';
import { EventService } from 'projects/shared/src/lib/services/event.service';
import { OrderService } from 'projects/shared/src/lib/services/order.service';
import { UserService } from 'projects/shared/src/lib/services/user.service';
import { OrderDetails, UserDetails } from 'projects/shared/src/public-api';
import { Observable, Subject } from 'rxjs';
import { shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { PlayerSelectModalComponent } from '../../../shared/components/player-select-modal/player-select-modal.component';
import { ConfirmationModalService } from 'projects/shared/src/lib/services/confirmation-modal.service';

@Component({
  selector: 'gcl-admin-event-view',
  templateUrl: './event-view.component.html',
  styleUrls: ['./event-view.component.scss']
})
export class EventViewComponent implements OnInit, OnDestroy {
  faChevronLeft = faChevronLeft

  public event$!: Observable<EventDetails>;
  public eventUser$!: Observable<UserDetails>;
  public eventOrder$!: Observable<OrderDetails>;

  private destroyed$ = new Subject<void>();

  constructor(
    private eventService: EventService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private orderService: OrderService,
    private confirmationModalService: ConfirmationModalService,
    private router: Router, 
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.event$ = this.activatedRoute.params.pipe(
      switchMap(p => this.eventService.get(p['eventId'])),
      takeUntil(this.destroyed$),
      shareReplay(1)
    );

    this.eventUser$ = this.event$.pipe(
      switchMap(e => this.userService.get((e.order?.users_permissions_user as number) ?? 0)),
      takeUntil(this.destroyed$),
      shareReplay(1),
    );

    this.eventOrder$ = this.event$.pipe(
      switchMap(e => this.orderService.get((e.order.id as number) ?? 0)),
      takeUntil(this.destroyed$),
      shareReplay(1),
    )
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
  
  openDeleteModal() {
    this.event$.pipe(switchMap(event => { 
      return this.confirmationModalService.showConfirmationModal({
        title: 'Confirm Deletion',
        message: `You are about to delete this event${event.teetimes.length ? ' and unblock its rounds' : ''}. This action cannot be undone.`
      })
    })).subscribe(accept => {
      if (accept) {
        this.delete();
      }
    })
  }

  private delete(): void {
    this.event$.pipe(
      switchMap((event: EventDetails) => this.eventService.delete(event.id)),
      shareReplay(1),
      takeUntil(this.destroyed$)
    ).subscribe((event: EventDetails) => {
      this.toastr.success("Event deleted successfully!");
      this.router.navigate(["/admin", "events"]);
    })
  }

  public getQueryParams() {
    return this.route.snapshot.queryParams;
  }
}
