<div class="d-flex flex-column h-100">
    <div class="d-flex justify-content-start py-2 bg-light no-flex">
        <a class="text-light-green"  [routerLink]="['/admin','players']">
            <fa-icon [icon]="faChevronLeft" class="px-3"></fa-icon> <span>Back to Player List</span>
        </a>
    </div>
    <div class="container-fluid py-2 fill-flex overflow-y-auto">
    <div class="row">
        <div class="col-12">
            <div class="col-12">
                <div class="d-flex justify-content-end py-2">
                    <button type="submit" class="btn btn-light-green px-5"
                        [disabled]="disableSubmit$ | async" 
                        (click)="submit()">Save</button>
                </div>
                <gcl-lib-player-form></gcl-lib-player-form>
            </div>
        </div>
    </div>
</div>