<div class="d-flex justify-content-start py-2 bg-light">
    <a class="text-light-green" [routerLink]="['/admin','staff']">
        <fa-icon [icon]="faChevronLeft" class="px-3"></fa-icon> <span>Back to Staff List</span>
    </a>
</div>

<div class="container-fluid py-2">
    <div class="row" *ngIf="user$ | async as user">
        <div class="col-12">
            <h6 class="page-header">Staff View</h6>
            <div class="d-flex justify-content-end py-2">
                <a [routerLink]="['/admin','staff', user.id, 'edit']" class="btn btn-light-green mx-1">Edit</a>
                <button (click)="openDeleteModal()" type="button" class="btn btn-outline-light-green mx-1">Delete</button>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-3">
                            <div class="form-group">
                                <label >First Name</label>
                                <p>{{user?.firstName}}</p>    
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-group">
                                <label >Last Name</label>
                                <p>{{user?.lastName}}</p>    
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-group">
                                <label >Role</label>
                                <p>{{user?.role?.name}}</p>    
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-group">
                                <label >Date of Birth</label>
                                <p>{{user?.dateOfBirth | date:"longDate"}}</p>    
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <div class="form-group">
                                <label >Cell Phone</label>
                                <p>{{user?.cellphone}}</p>    
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group">
                                <label >Username</label>
                                <p>{{user?.username}}</p>    
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group">
                                <label >Email</label>
                                <p>{{user?.email}}</p>    
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <div class="form-group">
                                <label >Confirmed</label>
                                <p>{{user?.confirmed ? 'Yes' : 'No'}}</p>    
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group">
                                <label >Blocked</label>
                                <p>{{user?.blocked ? 'Yes' : 'No'}}</p>    
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <div class="form-group">
                                <label >License Provider</label>
                                <p>{{user?.licenseProvider}}</p>    
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group">
                                <label >License Number</label>
                                <p>{{user?.licenseNumber}}</p>    
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group">
                                <label >License Expires</label>
                                <p>{{user?.licenseExpires}}</p>    
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label>Courses</label>
                                <div *ngFor="let course of user?.courses">
                                    <div>{{course.name}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>