<div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="cancel()">
        <span class="modal-close" aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p>{{message}}</p>
    <div class="form-group">
        <label>Amount</label>
        <input class="form-control" [(ngModel)]="value"/>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="cancel()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="submit()">Submit</button>
</div>