import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { SharedModule } from 'projects/shared/src/public-api';
import { CheckoutCategoryListComponent } from './components/checkout-category-list/checkout-category-list.component';
import { CheckoutPayCashComponent } from './components/checkout-pay-cash/checkout-pay-cash.component';
import { CheckoutPayDigitalComponent } from './components/checkout-pay-digital/checkout-pay-digital.component';
import { CheckoutRefundCashComponent } from './components/checkout-refund-cash/checkout-refund-cash.component';
import { ViewProductComponent } from './components/view-product/view-product.component';
import { SharedCheckoutComponent } from './shared-checkout/shared-checkout.component';
import { CheckoutPayReaderComponent } from './components/checkout-pay-reader/checkout-pay-reader.component';
import { CheckoutItemComponent } from './components/checkout-item/checkout-item.component';
import { ViewGolfPassComponent } from './components/view-golf-pass/view-golf-pass.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { CustomPaymentModalComponent } from './components/custom-payment-modal/custom-payment-modal.component';

@NgModule({
  declarations: [
    CheckoutCategoryListComponent,
    CheckoutPayCashComponent,
    CheckoutPayDigitalComponent,
    ViewProductComponent,
    CheckoutRefundCashComponent,
    SharedCheckoutComponent,
    CheckoutPayReaderComponent,
    CheckoutItemComponent,
    ViewGolfPassComponent,
    CustomPaymentModalComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    RouterModule,
    CollapseModule,
    FontAwesomeModule,
    ModalModule,
    BsDropdownModule,
    PopoverModule,
  ],
  exports: [
    SharedCheckoutComponent,
    CheckoutItemComponent
  ]
})
export class CheckoutModule { }
