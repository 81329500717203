<form [formGroup]="registrationForm">
    <small class="text-danger d-block mb-2" *ngIf="registrationForm.errors?.invalidPassword">
        Invalid password. Please try again.
    </small>
    <small class="text-danger d-block mb-2" *ngIf="(password?.touched && confirmPassword?.touched) && registrationForm.errors?.invalidPasswordMatch">
        Password do not match. Please try again.
    </small>
    <div class="form-row">
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="username">User Name</label>
                <input id="username" type="text" formControlName="username" placeholder="User Name" class="form-control"
                    [class.is-invalid]="username.touched && username.invalid" />
                <div *ngIf="username?.invalid && username?.touched" class="invalid-feedback">
                    <div *ngIf="username?.errors?.required">
                        Please enter a username.
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="email">Email</label>
                <input id="email" type="email" formControlName="email" placeholder="Email" class="form-control"
                    [class.is-invalid]="email.touched && email.invalid" />
                <div *ngIf="email?.invalid && email?.touched" class="invalid-feedback">
                    <div *ngIf="email?.errors?.required">
                        Please enter an email.
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="firstName">First Name</label>
                <input id="firstName" type="text" formControlName="firstName" placeholder="First Name" class="form-control"
                    [class.is-invalid]="firstName.touched && firstName.invalid" />
                <div *ngIf="firstName?.invalid && firstName?.touched" class="invalid-feedback">
                    <div *ngIf="firstName?.errors?.required">
                        Please enter a first name.
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="lastName">Last Name</label>
                <input id="lastName" type="text" formControlName="lastName" placeholder="Last Name" class="form-control"
                    [class.is-invalid]="lastName.touched && lastName.invalid" />
                <div *ngIf="lastName?.invalid && lastName?.touched" class="invalid-feedback">
                    <div *ngIf="lastName?.errors?.required">
                        Please enter a last name.
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="password">Password</label>
                <input id="password" type="password" formControlName="password" placeholder="Password" class="form-control"
                    [class.is-invalid]="password.touched && password.invalid" />
                <div *ngIf="password?.invalid && password?.touched" class="invalid-feedback">
                    <div *ngIf="password?.errors?.required">
                        Please enter a password.
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="confirmPassword">Confirm Password</label>
                <input id="confirmPassword" type="password" formControlName="confirmPassword" placeholder="Confirm Password" class="form-control"
                    [class.is-invalid]="confirmPassword.touched && confirmPassword.invalid" />
                <div *ngIf="confirmPassword?.invalid && confirmPassword?.touched" class="invalid-feedback">
                    <div *ngIf="confirmPassword?.errors?.required">
                        Please enter a confirm password.
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="form-group">
        <label for="cellPhone">Cell Phone</label>
        <input id="cellphone" type="text" formControlName="cellphone" placeholder="Cell Phone" class="form-control"
            [class.is-invalid]="cellphone.touched && cellphone.invalid" />
        <div *ngIf="cellphone?.invalid && cellphone?.touched" class="invalid-feedback">
            <div *ngIf="cellphone?.errors?.required">
                Please enter a cell phone.
            </div>
        </div>
    </div>

    <div class="form-group">
        <label for="dateOfBirth">Date of Birth</label>
        <gcl-lib-date-picker formControlName="dateOfBirth" 
                name="dateOfBirth"
                [class.is-invalid]="dateOfBirth?.invalid && dateOfBirth?.touched"></gcl-lib-date-picker>
        <div *ngIf="dateOfBirth?.invalid && dateOfBirth?.touched" class="invalid-feedback">
            <div *ngIf="dateOfBirth?.errors?.required">
                Please enter a date of birth.
            </div>
        </div>
    </div>
</form>