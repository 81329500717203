<div class="d-flex justify-content-end py-2">
    <button type="button" class="btn btn-light-green px-3 mx-1" (click)="onSubmit()" *ngIf="canEdit">
        <span *ngIf="reservationType.value != ReservationTypes.Blocked">Next</span>
        <span *ngIf="reservationType.value == ReservationTypes.Blocked">Save</span>
    </button>
    <button type="button" class="btn btn-outline-light-green" (click)="onCancel()">Cancel</button>
</div>

<div class="border">
    <gcl-lib-two-pane-horizontal-resizable 
        [width]="'60%'"
        [containerClass]="'container-fluid h-100 p-0'"
        [rightColumnClass]="'col h-100 p-0'">
        <div slot="left" class="h-100 d-flex flex-column">
            <ng-container *ngTemplateOutlet="left"></ng-container>
        </div>
        <div slot="right" class="bg-default" style="height:50vh!important; overflow: auto;">
            <ng-container *ngIf="reservationType.value != ReservationTypes.Blocked">
                <ng-container *ngTemplateOutlet="right"></ng-container>
            </ng-container>
        </div>
    </gcl-lib-two-pane-horizontal-resizable>

    <!-- Mobile -->
    <div class="row d-block d-md-none">
        <div  class="col-12">
            <ng-container *ngTemplateOutlet="left"></ng-container>
        </div>
        <div class="col-12 py-4">
            <ng-container *ngTemplateOutlet="right"></ng-container>
        </div>
    </div>
</div>

<!-- Templates -->
<ng-template #left>
    <div class="p-4">
        <form [formGroup]="form">
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label>Reservation Type</label>
                        <select class="form-control" formControlName="reservationType" >
                            <option *ngFor="let reservationType of reservationTypes$ | async" [value]="reservationType.slug" [disabled]="reservationTypeIsDisabled(reservationType)">{{reservationType.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-6" *ngIf="reservationType.value != ReservationTypes.Group && allowSqueezeTime && canEdit">
                    <div class="form-group float-right">
                        <button type="button" class="btn btn-outline-light-green" (click)="onAddSqueezeTime()">Squeeze Tee Time</button>
                    </div>
                </div>
            </div>
        </form>
        
        <hr class="break mb-4" />

        <ng-container *ngIf="reservationType.value != ReservationTypes.Blocked && reservationType.value != ReservationTypes.Event">
            <ng-container *ngTemplateOutlet="reservationForm"></ng-container>
        </ng-container>
        
        <ng-container *ngIf="reservationType.value == ReservationTypes.Blocked">
            <ng-container *ngTemplateOutlet="blocked"></ng-container>
        </ng-container>
    </div>
</ng-template>

<ng-template #blocked>
    <ng-container *ngIf="reservationType.value == ReservationTypes.Blocked">
        <form [formGroup]="form">
            <div class="form-group">
                <label for="reason">Reason</label>
                <textarea cols="5" class="form-control" formControlName="reason"></textarea>
                <div *ngIf="reason.errors?.required" class="text-danger">
                    Reason is required
                </div>
            </div>
        </form>
    </ng-container>
</ng-template>

<ng-template #reservationForm>
    <div class="row" >
        <ng-container  *ngIf="user$ | async as player">
            <div class="col-4">
                <div class="form-group">
                    <label>Customer First Name</label>
                    <p>{{player.firstName || "N/A"}}</p>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                    <label>Customer Last Name</label>
                    <p>{{player.lastName || "N/A"}}</p>
                </div>
            </div>
        </ng-container>
        <div class="col-lg-4 col-12 ml-auto" *ngIf="canEdit">
            <div class="row">
                <div class="col">
                    <button *ngIf="!(user$ | async)" class="btn btn-outline-light-green float-right mb-1" type="button" (click)="openSelectCustomerModal()">
                        <span>Select Customer</span>
                    </button>
                    <button *ngIf="(user$ | async) as user" class="btn btn-outline-light-green float-right mb-1" type="button" (click)="openSelectCustomerModal(user)">
                        <span>Change Customer</span>
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <span *ngIf="customerId.errors?.required && submitted" class="text-danger float-right">
                        A customer is required.
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="user$ | async as player">
        <div class="col-6">
            <div class="form-group">
                <label>Email</label>
                <p>{{player.email || "N/A"}}</p>
            </div>
        </div>
        <div class="col-6">
            <div class="form-group">
                <label>Phone</label>
                <p>{{player.cellphone || "N/A"}}</p>
            </div>
        </div>
    </div>

    <ng-container *ngIf="reservationType.value == ReservationTypes.SingleParty">
        <ng-container *ngTemplateOutlet="singleParty"></ng-container>
    </ng-container>
    <ng-container *ngIf="reservationType.value == ReservationTypes.Group">
        <ng-container *ngTemplateOutlet="group"></ng-container>
    </ng-container>
    <ng-container *ngIf="reservationType.value == ReservationTypes.League">
        <ng-container *ngTemplateOutlet="league"></ng-container>
    </ng-container>
</ng-template>

<ng-template #singleParty>
    <form [formGroup]="form">
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <label>People in party</label>
                    <select class="form-control" formControlName="quantity">
                        <option *ngFor="let c of [].constructor(availableSlots); let slot = index" [value]="slot+1">{{slot+1}}</option>
                    </select>
                    <div *ngIf="quantity.errors?.required" class="text-danger">
                        People in party is required
                    </div>
                    <div *ngIf="quantity.errors?.min" class="text-danger">
                        <p>The People in party field must be greater than or equal to {{quantity.errors?.min.min}}.</p>
                    </div>
                    <div *ngIf="quantity.errors?.max" class="text-danger">
                        <p>The People in party field must be less than or equal to {{quantity.errors?.max.max}}.</p>
                    </div>
                </div>
            </div>
            <div class="col-6 d-flex align-items-center">
                <div class="form-group mb-0">
                    <label class="mb-0">Cart Included</label>
                    <gcl-lib-toggle-switch formControlName="hasCart"></gcl-lib-toggle-switch>
                    <div *ngIf="hasCart.errors?.required" class="text-danger">
                        Cart Included is required
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6" *ngIf="course$ | async as course">
                <div class="form-group">
                    <label>Course Length</label>
                    <select class="form-control" formControlName="courseLength">
                        <option [value]="9">9 Holes</option>
                        <option [value]="18" *ngIf="course.Back9">18 holes</option>
                        <option [value]="27" *ngIf="course.Third9">27 holes</option>
                    </select>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Description</label>
                    <textarea class="form-control" formControlName="description">
                    </textarea>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #group>
    <form [formGroup]="form">
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <label>People in party</label>
                    <input class="form-control" type="number" min="1" formControlName="quantity" />
                    <div *ngIf="quantity.errors?.required" class="text-danger">
                        <small>People in party is required</small>
                    </div>
                    <div *ngIf="quantity.errors?.min">
                        <small>The People in party field must be greater than or equal to {{quantity.errors?.min.min}}.</small>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group mb-0">
                    <label class="mb-0">Cart Included</label>
                    <gcl-lib-toggle-switch formControlName="hasCart"></gcl-lib-toggle-switch>
                    <div *ngIf="hasCart.errors?.required" class="text-danger">
                        <small>Cart Included is required</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6" *ngIf="course$ | async as course">
                <div class="form-group">
                    <label>Course Length</label>
                    <select class="form-control" formControlName="courseLength">
                        <option [value]="9">9 Holes</option>
                        <option [value]="18" *ngIf="course.Back9">18 holes</option>
                        <option [value]="27" *ngIf="course.Third9">27 holes</option>
                    </select>
                </div>
            </div>
        </div>

        <!-- <hr class="break mb-4" />

        <div class="row">
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label>Start Time</label>
                    <div class="row">
                      <div class="col-12 col-md-6">
                        <input class="form-control" type="date" formControlName="startdate" />
                        <div *ngIf="startdate.errors?.required" class="text-danger">
                            <small>Start Date is required</small>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <input class="form-control" type="time" formControlName="starttime" />
                        <div *ngIf="starttime.errors?.required" class="text-danger">
                            <small>Start Time is required</small>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label>End Time</label>
                    <div class="row">
                      <div class="col-12 col-md-6">
                        <input class="form-control" type="date" formControlName="enddate" />
                        <div *ngIf="enddate.errors?.required" class="text-danger">
                            <small>End Date is required</small>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <input class="form-control" type="time" formControlName="endtime" />
                        <div *ngIf="endtime.errors?.required" class="text-danger">
                            <small>End time is required</small>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-5 mr-auto">
                <label>Is This Recurring?</label>
                <gcl-lib-radio-select-list formControlName="isRecurring"
                    [name]="'isRecurring'"
                    [options]="[{text: 'Yes', value: true}, {text: 'No', value: false}]"></gcl-lib-radio-select-list>
            </div>
        </div> -->
    </form>
</ng-template>

<ng-template #league>
    <form [formGroup]="form">
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <label>People in party</label>
                    <input class="form-control" type="number" min="1" formControlName="quantity" />
                    <div *ngIf="quantity.errors?.required" class="text-danger">
                        <small>People in party is required</small>
                    </div>
                    <div *ngIf="quantity.errors?.min">
                        <small>The People in party field must be greater than or equal to {{quantity.errors?.min.min}}.</small>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group mb-0">
                    <label class="mb-0">Cart Included</label>
                    <gcl-lib-toggle-switch formControlName="hasCart"></gcl-lib-toggle-switch>
                    <div *ngIf="hasCart.errors?.required" class="text-danger">
                        <small>Cart Included is required</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6" *ngIf="course$ | async as course">
                <div class="form-group">
                    <label>Course Length</label>
                    <select class="form-control" formControlName="courseLength">
                        <option [value]="9">9 Holes</option>
                        <option [value]="18" *ngIf="course.Back9">18 holes</option>
                        <option [value]="27" *ngIf="course.Third9">27 holes</option>
                    </select>
                </div>
            </div>
        </div>

        <!-- <hr class="break mb-4" />

        <div class="row">
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label>Start Time</label>
                    <div class="row">
                      <div class="col-12 col-md-6">
                        <input class="form-control" type="date" formControlName="startdate" />
                        <div *ngIf="startdate.errors?.required" class="text-danger">
                            <small>Start Date is required</small>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <input class="form-control" type="time" formControlName="starttime" />
                        <div *ngIf="starttime.errors?.required" class="text-danger">
                            <small>Start Time is required</small>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label>End Time</label>
                    <div class="row">
                      <div class="col-12 col-md-6">
                        <input class="form-control" type="date" formControlName="enddate" />
                        <div *ngIf="enddate.errors?.required" class="text-danger">
                            <small>End Date is required</small>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <input class="form-control" type="time" formControlName="endtime" />
                        <div *ngIf="endtime.errors?.required" class="text-danger">
                            <small>End time is required</small>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-5 mr-auto">
                <label>Is This Recurring?</label>
                <gcl-lib-radio-select-list formControlName="isRecurring"
                    [name]="'isRecurring'"
                    [options]="[{text: 'Yes', value: true}, {text: 'No', value: false}]"></gcl-lib-radio-select-list>
            </div>
        </div> -->
    </form>
</ng-template>

<ng-template #right>
    <div class="p-4">
        <ng-container *ngIf="reservationType.value == ReservationTypes.SingleParty">
            <div *ngIf="golfproducts.errors?.required" class="text-danger">
                Golf Products are required.
            </div>
            <ng-container  *ngFor="let control of golfproducts.controls; index as i">
                <hr class="break mb-4" *ngIf="i == 1" />
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label *ngIf="i == 0">Booking Customer</label>
                            <label *ngIf="i > 0">
                                <ng-container *ngIf="getUserById(i) | async as player; else noPlayer">
                                    {{player.username ? player.username : player.email}}
                                </ng-container>
                                <ng-template #noPlayer>
                                    Guest {{i}}
                                </ng-template>
                            </label>
                            <!-- <select 
                                *ngIf="i != 0"
                                class="form-control"
                                [formControl]="getFormControl(players.controls[i])">
                                <option [ngValue]="null">Guest #{{i}}</option>
                                <option 
                                    *ngFor="let user of users$ | async"
                                    [ngValue]="user.id"
                                >
                                    {{user.username ? user.username : user.email}}
                                </option>
                            </select> -->
                            <div class="d-flex justify-content-between align-items-center">
                                <gcl-lib-search-golf-products
                                [formControl]="getFormControl(golfproducts.controls[i])"
                                [reservationType]="getReservationTypeIdBySlug(reservationType.value) | async"
                                [holes]="courseLength?.value"
                                [cart]="hasCart.value"
                                style="flex: 1"></gcl-lib-search-golf-products>

                                <div *ngIf="i > 0" class="mb-3">
                                    <button class="btn btn-danger player" *ngIf="getUserById(i) | async as player; else noPlayerBtn" (click)="removePlayer(i)">Remove Player</button>
                                    <ng-template #noPlayerBtn>
                                        <button class="btn btn-light-green player" (click)="openSelectCustomerModal(null, i)">Select Player</button>
                                    </ng-template>
                                </div>
                            </div>
                            <div *ngIf="control.errors?.required" class="text-danger">
                                Golf Products is required.
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="reservationType.value === ReservationTypes.Event">
            <form [formGroup]="form">
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label>Event</label>
                            <select class="form-control" formControlName="event">
                                <option [ngValue]="null">Select...</option>
                                <option *ngFor="let event of events$ | async" [ngValue]="event.id">{{event?.event_space?.name}} | {{event.name}} | {{event.start | date: 'shortTime'}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </form>
        </ng-container>

        <ng-container *ngIf="reservationType.value != ReservationTypes.SingleParty && reservationType.value !== ReservationTypes.Event">
            <form [formGroup]="form">
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label>Guest/League Name</label>
                            <input class="form-control" type="text" formControlName="groupLeagueName" placeholder="Group/League Name" />
                            <div *ngIf="groupLeagueName.errors?.required" class="text-danger">
                                <small>Group/League Name is required</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label>Guest(s) - Golf Product Type</label>
                            <gcl-lib-search-golf-products 
                                [formControl]="getFormControl(golfproduct)"
                                [reservationType]="getReservationTypeIdBySlug(reservationType.value) | async"
                                [holes]="courseLength?.value"
                                [cart]="hasCart.value"></gcl-lib-search-golf-products>
                        </div>
                        <div *ngIf="golfproducts.errors?.required" class="text-danger">
                            Golf Products is required.
                        </div>
                    </div>
                </div>
            </form>
        </ng-container>
    </div>
</ng-template>
