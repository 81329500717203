import { OrderDetails, PromoCodeDetails } from "projects/shared/src/public-api";

export function getOrderDiscountAmount(order: OrderDetails) {
    // let golfOrderDiscountTotal = order.golforders
    //   .map(go => (go.unitprice * go.quantity) - go.extprice)
    //   .reduce((prev, acc) => { return acc + prev }, 0);

    // let productOrderDiscountTotal = order.productorders
    //   .map(po => (po.unitprice * po.quantity) - po.extprice)
    //   .reduce((prev, acc) => { return acc + prev }, 0);

    // let golfPassOrderDiscountTotal = order.golfpassorders
    //   .map(go => (go.unitprice * go.quantity) - go.extprice)
    //   .reduce((prev, acc) => { return acc + prev }, 0);

    let orderDiscountTotal = 0;

    if(order.promo_code) {
      let promoCode = <PromoCodeDetails>(order.promo_code);
      let discountAmount = promoCode.discountAmount ?? 0;
      let discountPercent = promoCode.discountPercent ?? 0;

      orderDiscountTotal += discountAmount;
      orderDiscountTotal += (((discountPercent) / 100) * order.subtotal);
    }

    // return golfOrderDiscountTotal + productOrderDiscountTotal + golfPassOrderDiscountTotal + orderDiscountTotal;
    return orderDiscountTotal;
}