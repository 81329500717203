<div class="input-group mb-3">
    <input type="search" class="form-control" placeholder="Enter Round" 
        [(ngModel)]="search"
        [typeaheadOptionsLimit]="7"
        [typeaheadMinLength]="1"
        [typeahead]="records$"

        (typeaheadOnSelect)="setRecord($event)"

        typeaheadOptionField="id"
        [typeaheadAsync]="true">
    <button  *ngIf="multiSelect" class="btn btn-outline-secondary" type="button" id="button-addon2" (click)="addRecord()">Add</button>
</div>
<ng-content *ngIf="multiSelect"></ng-content>