import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { CONFIG_TOKEN } from '../shared.config';

import { StrapiQueryableService } from './strapi-queryable.service';
import { SharedModuleConfig } from '../../public-api';
import { CreditBalanceTransaction, CreditBalanceTransactionDetails } from '../models/credit-balance-transaction';
import { CreditBalanceTransactionQuery } from '../queries/credit-balance-transaction-query';
import { PagedResult } from '../queries/paged-result';

declare var Qs: any;

@Injectable({
  providedIn: 'root'
})
export class CreditBalanceTransactionService extends StrapiQueryableService<CreditBalanceTransaction, CreditBalanceTransactionDetails, CreditBalanceTransactionQuery> {

  constructor(protected httpClient: HttpClient, @Inject(CONFIG_TOKEN) protected config: SharedModuleConfig) {
    super({ path: 'credit-balance-transactions' }, httpClient, config);
  }

  protected buildQuery(query?: CreditBalanceTransactionQuery): string {
    let queryObj = super.buildBaseQueryObj(query);

    // Specific Query filters.
    if (query) {
      if (query.search) {
        queryObj._where.push({
          _or: [{
            id_contains: query.search
          }, {
            type_contains: query.search
          }, {
            amount_contains: query.search
          }, {
            ['order.id_contains']: query.search
          }]
        });
      }

      if (query.id) {
        queryObj._where.push({ id: query.id });
      }
      if (query.type) {
        queryObj._where.push({ transactionType: query.type });
      }
      if (query.order) {
        queryObj._where.push({ order: query.order });
      }
      if (query.user) {
        queryObj._where.push({ user: query.user });
      }
      if (query.orderPayment) {
        queryObj._where.push({ order_payment: query.orderPayment });
      }
    }

    return Qs.stringify(queryObj);
  }

  public query(query?: CreditBalanceTransactionQuery): PagedResult<CreditBalanceTransactionDetails> {
    const total$ = this.count();
    const filteredTotal$ = this.count(query);
    const queryStr = this.buildQuery(query);
    const records$ = this.httpClient.get<CreditBalanceTransactionDetails[]>(`${this.config.apiUrl}/${this.pathConfig.path}?${queryStr}`)
      .pipe(
        shareReplay(1)
      );
    return new PagedResult(records$, filteredTotal$, total$);
  }

  public count(query?: CreditBalanceTransactionQuery): Observable<number> {
    const queryStr = this.buildQuery(query);
    return this.httpClient.get<number>(`${this.config.apiUrl}/${this.pathConfig.path}/count?${queryStr}`)
      .pipe(
        shareReplay(1)
      );
  }

  public getBalance(userId: number): Observable<number> {
    return this.httpClient.get<number>(`${this.config.apiUrl}/credit-balance-transactions/balance/${userId}`);
  }
}
