<div class="p-2" *ngIf="course$ | async as course">
    <div class="d-flex justify-content-between font-weight-bold pb-4">
        <h6 class="font-weight-bold">Popular Tee Times</h6>
        <div class="btn-group" role="group" aria-label="Basic example">
            <button class="btn btn-outline-dark" type="button" 
                [class.active]="viewMode === 'day'"
                (click)="onViewMode('day')">Day</button>
            <button class="btn btn-outline-dark" type="button" 
                [class.active]="viewMode === 'week'"
                (click)="onViewMode('week')">Week</button>
        </div>
    </div>
    <div class="pt-4">
        <gcl-lib-tee-times-bar-chart
            [startHour]="getHour(course.open_time)"
            [endHour]="getHour(course.close_time)"
            [viewMode]="viewMode"
            [courseId]="course.id"></gcl-lib-tee-times-bar-chart>
    </div>
</div>