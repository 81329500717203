<gcl-lib-table 
[totalItems]="total$ | async" 
[sortBy]="'id'" 
(onPage)="pagination$.next($event)" 
[tableClass]="'table table-striped table-hover'" 
[enableCreate]="true"
(onCreate)="onCreateClick()"
>
<thead>
    <tr>
        <th columnSort="code">GIFT CERTIFICATE CODE</th>
        <th columnSort="created_at">TIMESTAMP</th>
        <th columnSort="type">TYPE</th>
        <th columnSort="status">STATUS</th>
        <th columnSort="purchased_user.email">PURCHASED CUSTOMER</th>
        <th columnSort="redeemed_user.email">REDEEMED CUSTOMER</th>
        <th>BALANCE</th>
        <!-- <th width="100" class="text-center">MANAGE</th> -->
    </tr>
</thead>
<tbody>
    <tr *ngFor="let gc of records$ | async">
        <td>{{gc.code}}</td>
        <td>{{gc.created_at | date: 'medium'}}</td>
        <td>{{gc.type}}</td>
        <td>{{gc.status}}</td>
        <td>{{gc?.purchased_user?.email ? gc?.purchased_user?.email : 'N/A'}}</td>
        <td>{{gc?.redeemed_user?.email ? gc?.redeemed_user?.email : 'N/A'}}</td>
        <td>{{gc?.balance | currency}}</td>
        <!-- <td class="text-center">
            <div class="btn-group btn-group-sm">
                <a [routerLink]="['/admin','gift-certificates', gc.id, 'view']" class="btn btn-link btn-primary-light">View</a>
            </div>
        </td> -->
    </tr>
    <tr *ngIf="(total$ | async) == 0">
        <td colspan="10">No gift certificates.</td>
    </tr>
</tbody>
</gcl-lib-table>
