import { Component, OnInit, TemplateRef } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';

import { ToastrService } from 'ngx-toastr';

import { InputModalService } from 'projects/shared/src/lib/services/input-modal.service';
import { OrderPaymentService } from 'projects/shared/src/lib/services/order-payment.service';
import { Order, OrderDetails, OrderPayment } from 'projects/shared/src/public-api';
import { CheckoutService } from '../../checkout.service';
import { OrderPaymentTypes } from 'projects/shared/src/lib/enumerations/order-payment-types';
import { OrderService } from 'projects/shared/src/lib/services/order.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CustomPaymentModalComponent } from '../custom-payment-modal/custom-payment-modal.component';
import { take } from 'rxjs/operators';
import { PrinterDetails } from 'projects/shared/src/lib/models/printer';
import { PrinterService } from 'projects/shared/src/lib/services/printer.service';

interface PaymentAmount {
  amount: number;
  selected: boolean;
  isCustom: boolean;
}

@Component({
  selector: 'gcl-admin-checkout-pay-cash',
  templateUrl: './checkout-pay-cash.component.html',
  styleUrls: ['./checkout-pay-cash.component.scss']
})
export class CheckoutPayCashComponent implements OnInit {
  public isCollapsed: boolean = false;

  public order$!: Observable<OrderDetails>;
  public paymentAmounts: PaymentAmount[] = [];

  public paymentAmountSelected: boolean = false;

  public modalRef!: BsModalRef;
  public email: string = '';

  public submitted: boolean = false;

  public printers$!: Observable<PrinterDetails[]>;
  public printerArr: number[] = [];
  public disableEmail: boolean = true;

  constructor(
    private orderService: OrderService,
    private checkoutService: CheckoutService,
    private orderPaymentService: OrderPaymentService,
    private toastr: ToastrService,
    private inputModalService: InputModalService,
    private modalService: BsModalService,
    private printerService: PrinterService
  ) { }

  ngOnInit(): void {
    this.order$ = this.checkoutService.order$;

    this.order$.subscribe((order) => {
      if (order.course) {
        this.printers$ = this.printerService.query({
          course: order.course.id
        }).records$.pipe();
      }
    });

    this.checkoutService.difference$.subscribe((difference: number) => {
      this.paymentAmounts = [];

      this.paymentAmounts.push({
        amount: difference,
        selected: false,
        isCustom: false
      });

      let rounded = Math.ceil(difference);
      rounded = rounded == difference ? rounded + 10 : rounded;
      for (let i = 0; i < 4; i++) {
        this.paymentAmounts.push({
          amount: rounded + (i * 10),
          selected: false,
          isCustom: false
        });
      }

      this.paymentAmounts.push({
        amount: 0,
        selected: false,
        isCustom: true
      });
    });
  }

  public setPaymentAmount(amount: PaymentAmount): void {
    this.paymentAmounts.forEach((pa) => pa.selected = false);
    amount.selected = true;
  }

  public async openCustomAmountModal() {
    // this.inputModalService.showConfirmationModal({
    //   title: 'Enter Custom Amount'
    // }).subscribe((amount: number) => {
    //   this.order$.subscribe((order: OrderDetails) => {
    //     let custom = this.paymentAmounts.find(pa => pa.isCustom) as PaymentAmount;
    //     custom.amount = amount;
    //     this.setPaymentAmount(custom);
    //   });
    // });

    let order = await this.order$.pipe(take(1)).toPromise();

    let customModalRef = this.modalService.show(CustomPaymentModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        order,
        showEmail: false,
        submitButtonText: 'Select'
      },
      class: "modal-md modal-dialog-centered"
    });

    customModalRef.content?.onCustomAmountSelect.subscribe(confirmation => {
      if (confirmation.amount && confirmation.amount > 0) {
        let custom = this.paymentAmounts.find(pa => pa.isCustom) as PaymentAmount;
        custom.amount = confirmation.amount;
        this.setPaymentAmount(custom);
        customModalRef.hide();
      }
    })
  };

  public getSelectedPayment(): PaymentAmount | undefined {
    return this.paymentAmounts.find(pa => pa.selected);
  }

  public getChangeDue(order: OrderDetails): number {
    let difference = (this.getSelectedPayment()?.amount ?? 0) - (order.finaltotal);
    return Math.max(difference, 0);
  }

  public anyPaymentSelected(): boolean {
    return this.paymentAmounts.find(pa => pa.selected) != undefined;
  }

  public selectPaymentAmount(): void {
    this.paymentAmountSelected = true;
  }

  public checkout(order: OrderDetails): void {
    this.submitted = true;

    const payment = this.getSelectedPayment();
    if (payment) {
      let submissions = [];

      submissions.push(this.orderPaymentService.create({
        amount: payment.amount,
        order: order.id,
        success: true,
        type: OrderPaymentTypes.Cash
      }));

      if (payment.amount > order.finaltotal) {
        submissions.push(this.orderPaymentService.create({
          amount: order.finaltotal - payment.amount,
          order: order.id,
          success: true,
          type: OrderPaymentTypes.CashRefund
        }));
      }

      combineLatest(submissions)
        .subscribe(
          () => {
              this.orderService.recalculate(order.id).subscribe(async () => await this.checkoutService.checkoutSucces("Payment is successful.", order.id, this.printerArr, this.email));
          },
          (err) => {
            this.displayError("An error occurred while processing this payment.");
          }
        );
    } else {
      this.displayError("A payment needs to be selected.");
    }
  }

  updatePrinters(event: any) {
    if (event.target.checked) {
      if (this.printerArr.indexOf(event.target.value) < 0) {
        this.printerArr.push(event.target.value);
      }
    } else {
      if (this.printerArr.indexOf(event.target.value) > -1) {
          this.printerArr.splice(this.printerArr.indexOf(event.target.value), 1);
      }
    }
  }

  public async openReceiptModal(emailModalTemplate: TemplateRef<any>, order: OrderDetails) {
    let printers = await this.printers$.pipe(take(1)).toPromise();

    this.printerArr = [];

    if (order.users_permissions_user) {
      this.email = order.users_permissions_user.email;
      this.disableEmail = true;
    } else {
      this.email = '';
      this.disableEmail = false;
    }

    if (printers.length > 0) {
      this.modalRef = this.modalService.show(emailModalTemplate, { ignoreBackdropClick: true, class: 'modal-dialog-centered' });
    } else {
      if (order.users_permissions_user) {
        this.emailReceipt(order);
      }
      else {
        this.modalRef = this.modalService.show(emailModalTemplate, { ignoreBackdropClick: true, class: 'modal-dialog-centered' });
      }
    }
  }

  public emailReceipt(order: OrderDetails): void {
    this.submitted = true;

    if (this.email || this.printerArr.length !== 0) {
      this.modalRef?.hide();
      this.checkout(order);
    }
    else {
      this.displayError('Please enter a valid email or select a printer.');
    }
  }

  private displayError(message: string): void {
    this.toastr.error(message);
    this.submitted = false;
  }
}