import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { OnPageEvent, GolfPassDetails } from 'projects/shared/src/public-api';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { GolfPassService } from 'projects/shared/src/lib/services/golf-pass.service';
import { SortDirections } from 'projects/shared/src/lib/enumerations/sort-directions';
import { GolfPassQuery } from 'projects/shared/src/lib/queries/golfpass-query';

@Component({
  selector: 'gcl-admin-golf-pass-table',
  templateUrl: './golf-pass-table.component.html',
  styleUrls: ['./golf-pass-table.component.scss']
})
export class GolfPassTableComponent implements OnInit, OnDestroy {
  @Input('courseId') courseId?: number;
  @Input('enableCreate') enableCreate?: boolean = true;
  @Input('enableManage') enableManage?: boolean = true;
  @Input() id_nin?: number[] | null;

  pagination$ = new BehaviorSubject<OnPageEvent>({
    itemsPerPage: 10,
    page: 1,
    sortBy: 'id',
    sortDescending: true
  });

  total$: BehaviorSubject<number> = new BehaviorSubject(1);
  records$: ReplaySubject<GolfPassDetails[]> = new ReplaySubject(1);

  selectedPass: GolfPassDetails | null = null;

  private destory$: Subject<boolean> = new Subject();

  @Output() onRowClicked: EventEmitter<GolfPassDetails | null> = new EventEmitter<GolfPassDetails | null>();

  constructor(private router: Router, private golfpassService: GolfPassService) { }

  ngOnInit(): void {
    this.pagination$
    .pipe(
      takeUntil(this.destory$)
    )
    .subscribe(() => {
      this.getPageResult();
    });
  }

  ngOnDestroy() {
    this.destory$.next(true);
    this.destory$.complete();
  }

  private getPageResult() {
    let query: GolfPassQuery = {
      skip: (this.pagination$.value.page - 1) * this.pagination$.value.itemsPerPage,
      take: this.pagination$.value.itemsPerPage,
      sortColumns: [{
        column: this.pagination$.value.sortBy,
        direction: this.pagination$.value.sortDescending ? SortDirections.Descending : SortDirections.Ascending,
      }],
      search: this.pagination$.value.search,
    };

    if(this.courseId) {
      query.course = this.courseId
    }

    if(this.id_nin && this.id_nin.length > 0) {
      query.id_nin = this.id_nin;
    }

    const pagedResult = this.golfpassService.query(query);

    pagedResult.filteredCount$.subscribe((total) => this.total$.next(total));
    pagedResult.records$.subscribe((records) => this.records$.next(records));
  }

  public goToCreate() {
    this.router.navigate(['/admin', 'golf-passes', 'create']);
  }

  public onSelect(pass: GolfPassDetails): void {
    this.selectedPass = (this.selectedPass?.id !== pass.id) ? pass : null;

    this.onRowClicked.emit(this.selectedPass);
  }

}
