import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormArray, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map, share, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { Course } from 'projects/shared/src/lib/models/course';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { GolfProductService } from 'projects/shared/src/lib/services/golf-product.service';
import { UserService } from 'projects/shared/src/lib/services/user.service';
import { GolfProductDetails, NewAdminOrder, NewGolfOrder, OrderDetails, UpdateAdminOrder, UserDetails } from 'projects/shared/src/public-api';
import { OrderStatuses } from 'projects/shared/src/lib/enumerations/order-status';
import { ReservationTypes } from 'projects/shared/src/lib/enumerations/reservation-type';
import { OrderService } from 'projects/shared/src/lib/services/order.service';

import { PlayerCreateModalComponent } from '../player-create-modal/player-create-modal.component';
import { PlayerEditModalComponent } from '../player-edit-modal/player-edit-modal.component';
import { PlayerSelectModalComponent } from '../player-select-modal/player-select-modal.component';
import { ReservationTypeService } from 'projects/shared/src/lib/services/reservation-type.service';
import { ReservationTypeDetails } from 'projects/shared/src/lib/models/reservation-type';
import { EventDetails } from 'projects/shared/src/lib/models/event';
import { EventService } from 'projects/shared/src/lib/services/event.service';
import { getStartAndEndOfDay } from 'projects/shared/src/lib/utili/get-start-and-end-of-day';
import { SortDirections } from 'projects/shared/src/lib/enumerations/sort-directions';
import { BlockedReason } from '../../models/blocked-reason';

@Component({
  selector: 'gcl-admin-reservation-form',
  templateUrl: './reservation-form.component.html',
  styleUrls: ['./reservation-form.component.scss']
})
export class ReservationFormComponent implements OnInit, OnDestroy, AfterViewInit {
  public ReservationTypes = ReservationTypes;
  public OrderStatuses = OrderStatuses;

  get reservationType() { return this.form.get("reservationType") as FormControl }
  get customerId() { return this.form.get("customerId") as FormControl }
  get quantity() { return this.form.get("quantity") as FormControl }
  get hasCart() { return this.form.get("hasCart") as FormControl }
  get courseLength() { return this.form.get("courseLength") as FormControl }
  get description() { return this.form.get("description") as FormControl }
  get golfproducts() { return this.form.get("golfproducts") as FormArray }
  get players() { return this.form.get("players") as FormArray }
  get groupLeagueName() { return this.form.get("groupLeagueName") as FormControl }
  get golfproduct() { return this.form.get("golfproduct") as FormControl }
  get starttime() { return this.form.get("starttime") as FormControl }
  get startdate() { return this.form.get("startdate") as FormControl }
  get endtime() { return this.form.get("endtime") as FormControl }
  get enddate() { return this.form.get("enddate") as FormControl }
  get isRecurring() { return this.form.get("isRecurring") as FormControl }
  get event() { return this.form.get("event") as FormControl }
  get reason() { return this.form.get("reason") as FormControl }

  public form = this.fb.group({
    reservationType: [ReservationTypes.SingleParty, [Validators.required]],
    customerId: [null, [Validators.required]],
    quantity: [1, [Validators.required, Validators.min(1)]],
    hasCart: [true, [Validators.required]],
    courseLength: [9],
    description: ['', []],
    golfproducts: this.fb.array([]),
    players: this.fb.array([]),

    golfproduct: [null],
    groupLeagueName: [null],
    starttime: [null],
    startdate: [null],
    endtime: [null],
    enddate: [null],
    isRecurring: [false],

    event: [null],

    reason: [null, [Validators.minLength(1), Validators.maxLength(100)]]
  });
  public submitted: boolean = false;

  public course$!: Observable<Course>;
  public user$!: Observable<UserDetails | null>;
  public users$!: Observable<UserDetails[]>;

  @Input()
  canEdit: boolean = true;

  @Input()
  disableSingleBookingReservation: boolean = false;

  @Input()
  disableGroupReservation: boolean = false;

  @Input()
  disableBlockReservation: boolean = false;

  @Input()
  availableSlots!: number;

  @Input()
  slots?: number;

  @Input()
  order?: OrderDetails;

  @Input()
  allowSqueezeTime: boolean = false;

  @Input()
  date?: Date;

  @Output()
  blocked: EventEmitter<BlockedReason> = new EventEmitter<BlockedReason>();

  @Output()
  submit: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  addSqueezeTime: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  cancel: EventEmitter<any> = new EventEmitter<any>();

  private destroyed$ = new Subject();

  public reservationTypes$!: Observable<ReservationTypeDetails[]>;
  
  public events$!: Observable<EventDetails[]>;

  constructor(
    private fb: FormBuilder, 
    private userService: UserService, 
    private authService: AuthService, 
    private golfproductService: GolfProductService, 
    private bsModalService: BsModalService, 
    public route: ActivatedRoute, 
    private toastr: ToastrService, 
    private orderService: OrderService,
    private reservationTypeService: ReservationTypeService,
    private eventService: EventService,
  ) { }

  ngOnInit(): void {
    this.quantity.setValidators([Validators.required, Validators.min(1), Validators.max(this.availableSlots)]);
    this.quantity.updateValueAndValidity();

    if (this.disableSingleBookingReservation) {
      this.form.patchValue({
        reservationType: ReservationTypes.Group,
      });
    }

    if (this.order) {
      this.initForm(this.order);
      this.user$ = this.userService.get(this.order.users_permissions_user?.id as number);
    } else {
      this.resetForm(this.slots);
      this.initFormListeners();
    }

    this.course$ = this.authService.course$
      .pipe(
        takeUntil(this.destroyed$)
      );

    this.users$ = this.userService.getAll();

    this.reservationTypes$ = this.reservationTypeService.getAll().pipe(shareReplay(1), takeUntil(this.destroyed$));

    if(this.date) {
      let { startOfDay, endOfDay } = getStartAndEndOfDay(this.date);
      this.events$ = this.course$.pipe(switchMap(course => {
        return this.eventService.query({
          course: course.id, 
          start_gte: startOfDay, 
          start_lte: endOfDay,
          take: 1000,
          sortColumns: [{
            column: 'start',
            direction: SortDirections.Ascending,
          }],
        }).records$;
      }))
    }
  }

  ngAfterViewInit(): void {
    if (!this.order && this.canEdit) {
      this.openSelectCustomerModal();
    }
  }

  private initFormListeners(): void {
    this.quantity.valueChanges
      .pipe(
        takeUntil(this.destroyed$)
      ).subscribe(() => {
        if (this.reservationType.value == ReservationTypes.SingleParty) {
          this.updateSinglePartyGolfProducts();
          this.initBookingCustomerListener();
        } else {
          this.golfproduct.patchValue(null);
        }
      });

    this.hasCart.valueChanges
      .pipe(
        takeUntil(this.destroyed$)
      ).subscribe(() => {
        if (this.reservationType.value == ReservationTypes.SingleParty) {
          this.updateSinglePartyGolfProducts();
          this.initBookingCustomerListener();
        } else {
          this.golfproduct.patchValue(null);
        }
      });

    this.courseLength.valueChanges
      .pipe(
        takeUntil(this.destroyed$)
      ).subscribe(() => {
        if (this.reservationType.value == ReservationTypes.SingleParty) {
          this.updateSinglePartyGolfProducts();
          this.initBookingCustomerListener();
        } else {
          this.golfproduct.patchValue(null);
        }
      });

    this.reservationType.valueChanges
      .pipe(
        takeUntil(this.destroyed$)
      ).subscribe(() => {
        this.resetForm();
        this.initBookingCustomerListener();
      });

    if (this.reservationType.value == ReservationTypes.SingleParty) {
      this.initBookingCustomerListener();
    }
  }

  private initBookingCustomerListener(): void {
    if (this.golfproducts.length > 0) {
      const bookingCustomerGPControl = this.golfproducts.controls[0];
      bookingCustomerGPControl.valueChanges.pipe(
        takeUntil(this.destroyed$)
      ).subscribe((val) => {
        for (let i = 1; i < this.quantity.value; i++) {
          const control = this.golfproducts.controls[i];
          if (!control.value) {
            control.patchValue(val);
          }
        }
      });
    }
  }

  private initForm(order: OrderDetails): void {
    const user = order.users_permissions_user;
    const quantity = order.golforders.reduce((total, go) => go.quantity + total, 0);

    const golfproducts: Array<Observable<GolfProductDetails>> = [];
    const userIds: Array<number | null> = [];

    order.golforders.forEach(go => {
      golfproducts.push(this.golfproductService.get(go.golfproduct as number));
      userIds.push(<number>go.player ?? null);
    });

    combineLatest(golfproducts)
      .subscribe((golfproducts) => {
        const carts = golfproducts.reduce((total, go) => (go.cart ? 1 : 0) + total, 0);

        this.form.patchValue({
          customerId: user?.id,
          quantity: quantity,
          hasCart: order.cart,
          courseLength: golfproducts[0].holes,  // All golf products should have the same course length selected.
          description: order?.description ?? ''
        });

        if (!this.canEdit) {
          Object.values(this.form.controls).forEach((control) => control.disable());
        }

        // All customers shares the same golf product.
        if (this.reservationType.value == ReservationTypes.Group || this.reservationType.value == ReservationTypes.League) {
          this.form.patchValue({
            golfproduct: golfproducts[0]
          });
          this.golfproduct.disable();
        } else {
          this.initGolfProducts(golfproducts, userIds);
        }

        this.initFormListeners();
      });
  }

  private initGolfProducts(golfproducts: Array<GolfProductDetails>, userIds: Array<number | null>): void {
    this.clearGolfProducts(this.golfproducts.length, 0);
    golfproducts.forEach((golfproduct, idx) => {
      this.golfproducts.push(this.fb.control({ value: golfproduct, disabled: !this.canEdit }));
      this.players.push(this.fb.control({ value: userIds?.[idx] ?? null, disabled: !this.canEdit }));
    });
  }

  private resetForm(slots: number = 1): void {
    this.form.patchValue({
      quantity: slots,
      hasCart: true,
      courseLength: 9,

      golfproduct: null,
      groupLeagueName: null,
      starttime: null,
      startdate: null,
      endtime: null,
      enddate: null,
      isRecurring: false,
    });
    this.submitted = false;

    this.clearGolfProducts(this.golfproducts.length, 0);
    this.addGolfProducts(slots);

    // if([ReservationTypes.Event, ReservationTypes.Blocked].includes(this.reservationType.value)) {
    //   this.form.patchValue({
    //     customerId: null,
    //   });

    //   this.user$ = of(null);
    // }
  }

  private addGolfProducts(total: number, value: any = undefined): void {
    for (let i = 0; i < total; i++) {
      this.golfproducts.push(this.fb.control(value));
    }

    for(let i = 0; i < this.golfproducts.length; i++) {
      if(!this.players.at(i)) {
        this.players.insert(i, this.fb.control(null));
      }
    }
  }

  private clearGolfProducts(start: number, end: number): void {
    for (let i = start; i > end; i--) {
      this.golfproducts.removeAt(i - 1);
    }
    this.golfproducts.clear();
  }

  public updateSinglePartyGolfProducts(): void {
    const products = this.golfproducts.length;
    const numOfGolfers = this.quantity.value;
    if (products < numOfGolfers) {
      this.addGolfProducts(numOfGolfers - products);
    } else if (products > numOfGolfers) {
      for (let i = products; i > numOfGolfers; i--) {
        this.golfproducts.removeAt(i - 1);
        this.players.removeAt(i - 1);
      }
    } else {
      this.clearGolfProducts(this.golfproducts.length, 0);
      this.addGolfProducts(numOfGolfers);
    }
  }

  public getFormControl(control: AbstractControl): FormControl {
    return control as FormControl;
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public onCancel(): void {
    this.cancel.emit();
  }

  public onAddSqueezeTime(): void {
    this.addSqueezeTime.emit(this.order?.id as number);
  }

  public async onSubmit(): Promise<void> {
    this.submitted = true;

    this.clearFormErrors();
    this.validateForm();

    if (this.form.valid) {
      if (this.reservationType.value == ReservationTypes.Blocked) {
        this.blocked.emit({reason: this.reason.value});
      }
      else if(this.reservationType.value === ReservationTypes.Event) {
        this.blocked.emit({event: this.event.value});
      } 
      else {
        if (!this.order || (this.order && this.addSqueezeTime)) {
          this.submit.emit(await this.getNewOrder());
        } else {
          this.submit.emit(await this.getUpdatedOrder());
        }
      }
    } else {
      this.toastr.error("Reservation is invalid.");
    }
  }

  private async getNewOrder(): Promise<Partial<NewAdminOrder>> {
    return {
      status: OrderStatuses.Submitted,
      users_permissions_user: this.customerId.value,
      golforders: await this.getGolfOrders(),
      productorders: [],  // Will be filled in on the register/checkout page.
      reservationType: this.reservationType.value,
      cart: this.hasCart.value,
      description: this.description.value
    };
  }

  private async getGolfOrders(): Promise<Array<Partial<NewGolfOrder>>> {
    let golforders: Array<Partial<NewGolfOrder>> = [];

    if (this.reservationType.value == ReservationTypes.SingleParty) {
      const golfproducts: Array<GolfProductDetails> = this.golfproducts.value;
      const playerIds: Array<number> = this.players.value;

      let players = await combineLatest(playerIds.map(p => (p !== null && p !== undefined) ? this.userService.get(p).pipe(take(1)) : of(null))).pipe(take(1)).toPromise();

      golforders = golfproducts?.map((gp, idx) => ({
        golfproduct: gp.id,
        quantity: 1,
        unitprice: gp.price,
        extprice: gp.price,
        player: idx === 0 ? this.customerId.value ?? null : playerIds?.[idx] ?? null,
        email: players.find(p => !!p && p.id === playerIds[idx])?.email ?? ''
      }));
    } else {
      const golfproduct: GolfProductDetails = this.golfproduct.value;
      for (let i = 0; i < this.quantity.value; i++) {
        golforders.push({
          golfproduct: golfproduct.id,
          quantity: 1,
          unitprice: golfproduct.price,
          extprice: golfproduct.price,
        });
      }
    }

    return golforders;
  }

  private async getUpdatedOrder(): Promise<Partial<UpdateAdminOrder>> {
    return {
      status: OrderStatuses.Submitted,
      users_permissions_user: this.customerId.value,
      golforders: await this.getGolfOrders(),
      productorders: [],
      reservationType: this.reservationType.value,
      cart: this.hasCart.value,
      description: this.description.value,
    };
  }

  private validateForm(): void {
    const reservationType = this.reservationType.value;

    if ([ReservationTypes.SingleParty, ReservationTypes.Group, ReservationTypes.League].includes(reservationType)) {
      const customerId = this.customerId.value;
      if (!customerId) {
        this.customerId.setErrors({
          required: true
        });
      }
    }

    switch (reservationType) {
      case ReservationTypes.SingleParty:
        this.validateSinglePartReservation();
        break;
      case ReservationTypes.Group:
        this.validateGroupReservation();
        break;
      case ReservationTypes.League:
        this.validateLeagueReservation();
        break;
      case ReservationTypes.Event:
        this.validateEventReservation();
        break;
      default:
        this.validateBlockTime();
        break;
    }
  }

  private clearFormErrors(): void {
    this.form.setErrors(null);
    this.reservationType.setErrors(null);
    this.customerId.setErrors(null);
    this.quantity.setErrors(null);
    this.hasCart.setErrors(null);
    this.courseLength.setErrors(null);
    this.golfproducts.setErrors(null);
    this.players.setErrors(null);
    this.groupLeagueName.setErrors(null);
    this.golfproduct.setErrors(null);
    this.starttime.setErrors(null);
    this.startdate.setErrors(null);
    this.endtime.setErrors(null);
    this.enddate.setErrors(null);
    this.isRecurring.setErrors(null);
    this.event.setErrors(null);
  }

  private validateSinglePartReservation(): void {
    const numOfPlayers = this.quantity.value;

    if (this.golfproducts.length == 0) {
      this.golfproducts.setErrors({
        required: true
      });
    }

    this.golfproducts.controls.forEach((cc, index) => {
      if (cc.value == undefined || (index >= numOfPlayers)) {
        cc.setErrors({
          required: true
        })
      }
    });
  }

  private validateGroupReservation(): void {
    if (!this.groupLeagueName.value) {
      this.groupLeagueName.setErrors({
        required: true
      });
    }
    if (!this.golfproduct.value) {
      this.golfproduct.setErrors({
        required: true
      });
    }

    // if (!this.starttime.value) {
    //   this.starttime.setErrors({
    //     required: true
    //   });
    // }
    // if (!this.startdate.value) {
    //   this.startdate.setErrors({
    //     required: true
    //   });
    // }
    // if (!this.endtime.value) {
    //   this.endtime.setErrors({
    //     required: true
    //   });
    // }
    // if (!this.enddate.value) {
    //   this.enddate.setErrors({
    //     required: true
    //   });
    // }

    // if ((this.starttime && this.startdate) && (this.endtime && this.enddate)) {
    //   const start = new Date(this.startdate + " " + this.starttime);
    //   const end = new Date(this.enddate + " " + this.endtime);
    //   if (start.compareDate(end) == 1) {
    //     this.form.setErrors({
    //       daterange: "The start date needs to be set before the end date."
    //     });
    //   }
    // }
  }

  private validateLeagueReservation(): void {
    if (!this.groupLeagueName.value) {
      this.groupLeagueName.setErrors({
        required: true
      });
    }
    if (!this.golfproduct.value) {
      this.golfproduct.setErrors({
        required: true
      });
    }

    // if (!this.starttime.value) {
    //   this.starttime.setErrors({
    //     required: true
    //   });
    // }
    // if (!this.startdate.value) {
    //   this.startdate.setErrors({
    //     required: true
    //   });
    // }
    // if (!this.endtime.value) {
    //   this.endtime.setErrors({
    //     required: true
    //   });
    // }
    // if (!this.enddate.value) {
    //   this.enddate.setErrors({
    //     required: true
    //   });
    // }

    // if ((this.starttime && this.startdate) && (this.endtime && this.enddate)) {
    //   const start = new Date(this.startdate + " " + this.starttime);
    //   const end = new Date(this.enddate + " " + this.endtime);
    //   if (start.compareDate(end) == 1) {
    //     this.form.setErrors({
    //       daterange: "The start date needs to be set before the end date."
    //     });
    //   }
    // }
  }

  private validateBlockTime(): void {
    if (!this.reason.value) {
      this.reason.setErrors({
        required: true
      });
    }
  }

  private validateEventReservation() {
    const eventId = this.event.value;

    if(!eventId) {
      this.event.setErrors({
        required: true
      })
    }
  }

  public getFormErrors() {
    return this.form.errors;
  }

  public openSelectCustomerModal(selectedUser: UserDetails | null = null, index: number | null = null): void {
    const selectModalRef = this.bsModalService.show(PlayerSelectModalComponent, {
      class: "modal-lg modal-dialog-centered customer-modal",
      ignoreBackdropClick: true,
      initialState: {
        selectedUser: selectedUser,
        previousUser: selectedUser
      }
    });

    if (selectModalRef.content) {
      selectModalRef.content.select$
        .pipe(
          tap(((user: UserDetails | null) => {
            if(!index || index === 0) {
              this.user$ = of(user);
              this.form.patchValue({ customerId: user?.id });
            }
            else {
              this.players.at(index).patchValue(user?.id ?? null);
            }
            selectModalRef.hide();
          })),
          takeUntil(this.destroyed$)
        ).subscribe();

      selectModalRef.content.openCreateModal$
        .pipe(
          takeUntil(this.destroyed$)
        )
        .subscribe(() => {
          this.openCreateModal(index);
          selectModalRef.hide();
        });

      selectModalRef.content.openEditModal$
        .pipe(
          takeUntil(this.destroyed$)
        )
        .subscribe((_user: UserDetails) => {
          this.openEditModal(_user, index);
          selectModalRef.hide();
        });
    }
  }

  public openEditModal(user: UserDetails, index: number | null): void {
    const editModalRef = this.bsModalService.show(PlayerEditModalComponent, {
      class: "modal-lg modal-dialog-centered",
      ignoreBackdropClick: true,
      initialState: {
        userId: user.id
      }
    });

    if (editModalRef.content) {
      editModalRef.content.submit$
        .pipe(
          tap((user: UserDetails) => {
            if(!index || index === 0) {
              this.user$ = of(user);
              this.form.patchValue({ customerId: user?.id });
            }
            else {
              this.players.at(index).patchValue(user?.id ?? null);
            }
            editModalRef.hide();
          }),
          takeUntil(this.destroyed$)
        ).subscribe();

      editModalRef.content.cancel$
        .pipe(
          takeUntil(this.destroyed$)
        )
        .subscribe(() => editModalRef.hide());
    }
  }

  public openCreateModal(index: number | null): void {
    const createModalRef = this.bsModalService.show(PlayerCreateModalComponent, {
      ignoreBackdropClick: true,
      class: "modal-lg modal-dialog-centered"
    });

    if (createModalRef.content) {
      createModalRef.content.submit$
        .pipe(
          tap((user: UserDetails) => {
            if(!index || index === 0) {
              this.user$ = of(user);
              this.form.patchValue({ customerId: user?.id });
            }
            else {
              this.players.at(index).patchValue(user?.id ?? null);
            }
            createModalRef.hide();
          }),
          takeUntil(this.destroyed$)
        ).subscribe();

      createModalRef.content.cancel$
        .pipe(
          takeUntil(this.destroyed$)
        )
        .subscribe(() => createModalRef.hide());
    }
  }

  public getUserById(index: number | null): Observable<UserDetails | null> {
    if(index === null || index === undefined) {
      return of(null);
    }

    let userId = this.players?.at(index)?.value;

    if(userId === null || userId === undefined) {
      return of(null);
    }

    return this.users$.pipe(
      switchMap(users => {
        let existingUser = users.find(u => u.id === userId);
        if(existingUser) {
          return of(existingUser);
        }
        else {
          return this.userService.get(userId).pipe(take(1));
        }
      }),
      take(1)
    )
  }

  public removePlayer(index: number | null) {
    if(index === null || index === undefined) {
      return;
    }

    let control = this.players?.at(index);
    if(control) {
      control.patchValue(null);
    }
  }

  public reservationTypeIsDisabled(reservationType: ReservationTypeDetails) {
    if(reservationType.slug === ReservationTypes.SingleParty) {
      return this.disableSingleBookingReservation;
    }
    if(reservationType.slug === ReservationTypes.Group || reservationType.slug === ReservationTypes.League) {
      return this.disableGroupReservation;
    }
    if(reservationType.slug === ReservationTypes.Blocked) {
      return this.disableBlockReservation;
    }
    else {
      return false;
    }
  }

  public getReservationTypeIdBySlug(slug: string): Observable<number | undefined> {
    return this.reservationTypes$.pipe(            
      map(reservationTypes => reservationTypes.find(r => r.slug === slug)?.id ?? undefined),
      shareReplay(1),
      takeUntil(this.destroyed$)
    );
  }
}
