<div class="d-flex flex-column h-100">
    <div class="d-flex justify-content-start py-2 bg-light no-flex">
        <a class="text-light-green"  [routerLink]="routerLink$ | async">
            <fa-icon [icon]="faChevronLeft" class="px-3"></fa-icon> <span>Back to Course Details</span>
        </a>
    </div>
    <div class="container-fluid py-2 fill-flex overflow-y-auto">
        <div class="row">
            <div class="col-12">
                <form [formGroup]="form" (ngSubmit)="submit()">
                    <div class="d-flex justify-content-end py-2">
                        <button type="submit" class="btn btn-success" [disabled]="form.invalid">Save</button>
                    </div>
                    <div class="row border mx-1">
                        <div class="col-12 col-lg-7 p-0">
                            <div class="row mx-0 pt-3">
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label >Name</label>
                                        <input type="text" class="form-control" formControlName="name" name="name" [class.is-invalid]="name?.invalid && name?.touched" />
                                        <div *ngIf="name?.invalid && name?.touched" class="invalid-feedback">
                                            <div *ngIf="name?.errors?.required">
                                                Please enter a name.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label>Type</label>
                                        <select class="custom-select" formControlName="type" name="type"  
                                        [hasNullOption]="false"
                                        enum="EventSpaceType"
                                        [value]="type?.value"
                                        [class.is-invalid]="type?.invalid && type?.touched">
                                        </select>                                        
                                        <div *ngIf="type?.invalid && type?.touched" class="invalid-feedback">
                                            <div *ngIf="type?.errors?.required">
                                                Please select a type.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>                  
                        </div>
                        <div class="bg-light col-12 col-lg-5 p-0">
                        
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>