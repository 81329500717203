import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'gcl-admin-generate-gift-certificates-modal',
  templateUrl: './generate-gift-certificates-modal.component.html',
  styleUrls: ['./generate-gift-certificates-modal.component.scss']
})
export class GenerateGiftCertificatesModalComponent implements OnInit {
  @Output() generateCertificates = new EventEmitter<number>();
  public get count() { return this.form.get('count')}

  public form: FormGroup = this.fb.group({
    count: [1, [Validators.required]]
  })

  constructor(private modalRef: BsModalRef, private fb: FormBuilder) { }

  ngOnInit(): void {
  }

  cancel(): void {
    this.count?.setValue(0);
    this.modalRef.hide();
  }

  generate() {
    this.generateCertificates.emit(this.form.get('count')?.value);
  }
}
