import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { GolfPassCreateComponent } from './golf-pass-create/golf-pass-create.component';
import { GolfPassEditComponent } from './golf-pass-edit/golf-pass-edit.component';
import { GolfPassListComponent } from './golf-pass-list/golf-pass-list.component';
import { GolfPassViewComponent } from './golf-pass-view/golf-pass-view.component';

const routes: Routes = [
  {
    path: 'create',
    component: GolfPassCreateComponent
  },
  {
    path: ':golfpassid/edit',
    component: GolfPassEditComponent
  },
  {
    path: ':golfpassid/view',
    component: GolfPassViewComponent
  },
  {
    path: '',
    component: GolfPassListComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GolfPassesRoutingModule { }
