export enum RoundStatuses {
    Open = "Open",
    Booked = "Booked",
    Reserved = "Reserved",
    Active = "Active",
    Completed = "Completed",
    Closed = "Closedd"
}

export function GetRoundStatusesDisplayName(status: RoundStatuses): string {
    switch (status) {
        case RoundStatuses.Open:
            return "Open";
        case RoundStatuses.Booked:
            return "Booked";
        case RoundStatuses.Reserved:
            return "Reserved";
        case RoundStatuses.Active:
            return "Active";
        case RoundStatuses.Completed:
            return "Completed";
        case RoundStatuses.Closed:
            return "Closed";
        default:
            return "N/A";
    }
}