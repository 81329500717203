<div class="modal-header">
    <h4 class="modal-title pull-left">Create Player</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">
      <span class="modal-close" aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
      <div class="d-flex flex-column h-100">
          <div class="container-fluid py-2 fill-flex overflow-y-auto">
            <div class="row">
                <div class="col-12">
                      <gcl-lib-player-form [showAddPaymentMethodButton]="false" [showRedeemGiftCertificateButton]="false"></gcl-lib-player-form>
                </div>
            </div>
            <div class="row py-2">
                <div class="col">
                    <div class="float-right">
                        <button type="button" (click)="onCancel()" class="btn btn-outline-light-green px-5">Cancel</button>
                        <button type="button" (click)="submit()" class="btn btn-light-green px-5">Save</button>
                    </div>
                </div>
            </div>
          </div>
      </div>      
  </div>
  