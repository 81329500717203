import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, shareReplay, takeUntil } from 'rxjs/operators';

import { OnPageEvent } from 'projects/shared/src/lib/models/on-page-event';
import { UserDetails } from 'projects/shared/src/public-api';
import { UserService } from 'projects/shared/src/lib/services/user.service';
import { SortDirections } from 'projects/shared/src/lib/enumerations/sort-directions';


@Component({
  selector: 'gcl-admin-staff-list',
  templateUrl: './staff-list.component.html',
  styleUrls: ['./staff-list.component.scss']
})
export class StaffListComponent implements OnInit, OnDestroy {
  pagination$ = new BehaviorSubject<OnPageEvent>({
    itemsPerPage: 10,
    page: 1,
    sortBy: 'firstName',
    sortDescending: false
  });

  total$: BehaviorSubject<number> = new BehaviorSubject(1);
  records$: ReplaySubject<UserDetails[]> = new ReplaySubject(1);

  private destory$: Subject<boolean> = new Subject();

  constructor(private userService: UserService, private router: Router) { }

  ngOnInit(): void {
    this.pagination$
      .pipe(
        takeUntil(this.destory$)
      )
      .subscribe(() => {
        this.getPageResult();
      });
  }

  ngOnDestroy() {
    this.destory$.next(true);
    this.destory$.complete();
  }

  private getPageResult() {
    const pagedResult = this.userService.query({
      skip: (this.pagination$.value.page - 1) * this.pagination$.value.itemsPerPage,
      take: this.pagination$.value.itemsPerPage,
      sortColumns: [{
        column: this.pagination$.value.sortBy,
        direction: this.pagination$.value.sortDescending ? SortDirections.Descending : SortDirections.Ascending,
      }],
      search: this.pagination$.value.search,
      roles: ["manager", "administrator"]
    });

    pagedResult.filteredCount$.subscribe((total) => this.total$.next(total));
    pagedResult.records$.subscribe((records) => this.records$.next(records));
  }

  public goToCreate() {
    this.router.navigate(['/admin', 'staff', 'create']);
  }
}
