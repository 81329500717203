import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { v4 as uuidv4 } from 'uuid';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';

import { UserService } from 'projects/shared/src/lib/services/user.service';
import { UserDetails } from 'projects/shared/src/public-api';
import { AsyncValidatorService } from 'projects/shared/src/lib/services/async-validator.service';
import { PlayerFormComponent } from 'projects/shared/src/lib/components/player-form/player-form.component';

@Component({
  selector: 'gcl-admin-player-create',
  templateUrl: './player-create.component.html',
  styleUrls: ['./player-create.component.scss']
})
export class PlayerCreateComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(PlayerFormComponent) playerFormComponent!: PlayerFormComponent;

  public faChevronLeft = faChevronLeft;
  public disableSubmit$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private destroyed$ = new Subject();

  constructor(private userService: UserService, public route: ActivatedRoute, private router: Router, private toastr: ToastrService) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.playerFormComponent.form.valueChanges
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(() => this.disableSubmit$.next(this.playerFormComponent.form.invalid));
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public submit(): void {
    this.disableSubmit$.next(true);

    if (this.playerFormComponent.form.valid) {
      this.playerFormComponent.form.patchValue({
        username: this.playerFormComponent.username.value || this.playerFormComponent.email.value,
      });

      this.userService.create({
        ...this.playerFormComponent.form.value,
        ...{
          role: 3,  // Player
          password: uuidv4()
        }
      })
        .pipe(
          takeUntil(this.destroyed$)
        )
        .subscribe((user: UserDetails) => {
          this.userService.generateAndSendTempPasswordEmail(user.id).subscribe(() => {
            this.toastr.success("User created successfully!");
            this.router.navigate(["/admin", "players", user.id, "view"]);
          });
        }, (err: HttpErrorResponse) => {
          err.error.message.forEach((message: any) => {
            if (message.messages.length > 0) {
              message.messages.forEach((mes: any) => {
                const field = mes.field[0];
                const formField = this.playerFormComponent.form.get(field);
                if (formField) {
                  formField.setErrors({
                    error: mes.message
                  });
                }
              })
            }
          })
        });
    } else {
      Object.values(this.playerFormComponent.form.controls).forEach(control => {
        control.markAsTouched();
        control.markAsDirty();
      });
    }
  }

}
