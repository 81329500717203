<div class="d-flex flex-column h-100">
    <div class="d-flex justify-content-start py-2 bg-light no-flex">
        <a class="text-light-green"  [routerLink]="['/admin','schedules']" *ngIf="!(scheduleId$ | async)">
            <fa-icon [icon]="faChevronLeft" class="px-3"></fa-icon> <span>Back to Schedules</span>
        </a>
        <a class="text-light-green"  [routerLink]="['/admin','schedules', scheduleId, 'view']" *ngIf="scheduleId$ | async as scheduleId">
            <fa-icon [icon]="faChevronLeft" class="px-3"></fa-icon> <span>Back to Schedules View</span>
        </a>
    </div>
    <div class="container-fluid py-2 fill-flex overflow-y-auto">
        <div class="row">
            <div class="col-12">
                <form [formGroup]="form" (ngSubmit)="submit()">
                    <div class="d-flex justify-content-end py-2">
                        <button type="submit" class="btn btn-light-green px-5" *ngIf="!form.untouched && form.valid">Save</button>
                        <button type="button" class="btn btn-secondary px-5 disabled" *ngIf="form.untouched || form.invalid">Save</button>
                    </div>
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-8">
                                    <div class="row">
                                        <div class="col">
                                            <div class="form-group">
                                                <label >Name</label>
                                                <input type="text" class="form-control" formControlName="name" name="name" [class.is-invalid]="name?.invalid && name?.touched" />
                                                <div *ngIf="name?.invalid && name?.touched" class="invalid-feedback">
                                                    <div *ngIf="name?.errors?.required">
                                                        Please enter a name.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label >Active</label>
                                                <select class="custom-select" formControlName="active" name="active" [class.is-invalid]="active?.invalid && active?.touched">
                                                    <option [value]="false">No</option>
                                                    <option [value]="true">Yes</option>
                                                </select>
                                                <div *ngIf="active?.invalid && active?.touched" class="invalid-feedback">
                                                    <div *ngIf="active?.errors?.required">
                                                        Please enter a active.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="type" >Schedule Type</label>
                                                <select class="custom-select" formControlName="type" name="type"  
                                                    [hasNullOption]="false"
                                                    enum="ScheduleTypes"
                                                    [value]="type?.value"
                                                    [class.is-invalid]="type?.invalid && type?.touched">
                                                </select>
                                                <div *ngIf="type?.invalid && type?.touched" class="invalid-feedback">
                                                    <div *ngIf="type?.errors?.required">
                                                        Please select a schedule type.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6" [ngClass]="{'d-none':type.value !== ScheduleTypes.GolfPass}">
                                            <div class="form-group">
                                                <label for="golfPass" >Golf Pass</label>
                                                <select class="form-control" formControlName="golfPass" name="golfPass" 
                                                    [class.is-invalid]="golfPass?.invalid && golfPass?.touched">
                                                    <option value="">Select Golf Pass</option>
                                                    <option *ngFor="let golfpass of golfpasses$ | async" [value]="golfpass.id">{{golfpass.name}}</option>
                                                </select>
                                                <div *ngIf="golfPass?.invalid && golfPass?.touched" class="invalid-feedback">
                                                    <div *ngIf="golfPass?.errors?.required">
                                                        Please select a golf pass.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="form-group">
                                                <label >Start Time of Day</label>
                                                <input type="text" class="form-control" formControlName="starttod" name="starttod" [class.is-invalid]="starttod?.invalid && starttod?.touched" />
                                                <div *ngIf="starttod?.invalid && starttod?.touched" class="invalid-feedback">
                                                    <div *ngIf="starttod?.errors?.required">
                                                        Please enter a starttod.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label >End Time of Day</label>
                                                <input type="text" class="form-control" formControlName="endtod" name="endtod" [class.is-invalid]="endtod?.invalid && endtod?.touched" />
                                                <div *ngIf="endtod?.invalid && endtod?.touched" class="invalid-feedback">
                                                    <div *ngIf="endtod?.errors?.required">
                                                        Please enter a endtod.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="form-group">
                                                <label >First 9</label>
                                                <select class="custom-select" formControlName="first9" name="first9" [class.is-invalid]="first9?.invalid && first9?.touched"
                                                    [hasNullOption]="false"
                                                    enum="NineTypes"
                                                    [value]="first9?.value">
                                                </select>
                                                <div *ngIf="first9?.invalid && first9?.touched" class="invalid-feedback">
                                                    <div *ngIf="first9?.errors?.required">
                                                        Please enter a first 9.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label >Second 9</label>
                                                <select class="custom-select" formControlName="second9" name="second9" [class.is-invalid]="second9?.invalid && second9?.touched"
                                                    [hasNullOption]="false"
                                                    enum="NineTypes"
                                                    [value]="second9?.value">
                                                </select>
                                                <div *ngIf="second9?.invalid && second9?.touched" class="invalid-feedback">
                                                    <div *ngIf="second9?.errors?.required">
                                                        Please enter a second 9.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="d-flex justify-content-center">
                                                <div class="form-group flex-fill">
                                                    <label >Monday</label>
                                                    <gcl-lib-toggle-switch formControlName="monday"></gcl-lib-toggle-switch>
                                                    <div *ngIf="monday?.invalid && monday?.touched" class="invalid-feedback">
                                                        <div *ngIf="monday?.errors?.required">
                                                            Please enter a monday.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group flex-fill">
                                                    <label >Tuesday</label>
                                                    <gcl-lib-toggle-switch formControlName="tuesday"></gcl-lib-toggle-switch>
                                                    <div *ngIf="tuesday?.invalid && tuesday?.touched" class="invalid-feedback">
                                                        <div *ngIf="tuesday?.errors?.required">
                                                            Please enter a tuesday.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group flex-fill">
                                                    <label >Wednesday</label>
                                                    <gcl-lib-toggle-switch formControlName="wednesday"></gcl-lib-toggle-switch>
                                                    <div *ngIf="wednesday?.invalid && wednesday?.touched" class="invalid-feedback">
                                                        <div *ngIf="wednesday?.errors?.required">
                                                            Please enter a wednesday.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group flex-fill">
                                                    <label >Thursday</label>
                                                    <gcl-lib-toggle-switch formControlName="thursday"></gcl-lib-toggle-switch>
                                                    <div *ngIf="thursday?.invalid && thursday?.touched" class="invalid-feedback">
                                                        <div *ngIf="thursday?.errors?.required">
                                                            Please enter a thursday.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group flex-fill">
                                                    <label >Friday</label>
                                                    <gcl-lib-toggle-switch formControlName="friday"></gcl-lib-toggle-switch>
                                                    <div *ngIf="friday?.invalid && friday?.touched" class="invalid-feedback">
                                                        <div *ngIf="friday?.errors?.required">
                                                            Please enter a friday.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group flex-fill">
                                                    <label >Saturday</label>
                                                    <gcl-lib-toggle-switch formControlName="saturday"></gcl-lib-toggle-switch>
                                                    <div *ngIf="saturday?.invalid && saturday?.touched" class="invalid-feedback">
                                                        <div *ngIf="saturday?.errors?.required">
                                                            Please enter a saturday.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group flex-fill">
                                                    <label >Sunday</label>
                                                    <gcl-lib-toggle-switch formControlName="sunday"></gcl-lib-toggle-switch>
                                                    <div *ngIf="sunday?.invalid && sunday?.touched" class="invalid-feedback">
                                                        <div *ngIf="sunday?.errors?.required">
                                                            Please enter a sunday.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>Discount Amount</label>
                                                <input type="text" class="form-control" formControlName="golfPassDiscountAmount" name="golfPassDiscountAmount" [class.is-invalid]="golfPassDiscountAmount?.invalid && golfPassDiscountAmount?.touched" />
                                                <div *ngIf="golfPassDiscountAmount?.invalid && golfPassDiscountAmount?.touched" class="invalid-feedback">
                                                    <div *ngIf="golfPassDiscountAmount?.errors?.required">
                                                        Please enter a discount amount.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label >Discount Percentage</label>
                                                <input type="text" class="form-control" formControlName="golfPassDiscountPercent" name="golfPassDiscountPercent" [class.is-invalid]="golfPassDiscountPercent?.invalid && golfPassDiscountPercent?.touched" />
                                                <div *ngIf="golfPassDiscountPercent?.invalid && golfPassDiscountPercent?.touched" class="invalid-feedback">
                                                    <div *ngIf="golfPassDiscountPercent?.errors?.required">
                                                        Please enter a discount Percentage.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label >Description</label>
                                        <textarea type="text" class="form-control" formControlName="description" name="description" [class.is-invalid]="description?.invalid && description?.touched" rows="5"></textarea>
                                        <div *ngIf="description?.invalid && description?.touched" class="invalid-feedback">
                                            <div *ngIf="description?.errors?.required">
                                                Please enter a description.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="golfproduct">Golf Product</label>
                                                <select class="custom-select" formControlName="golfproduct" name="golfproduct" [class.is-invalid]="golfproduct?.invalid && golfproduct?.touched">
                                                    <option *ngFor="let golfproduct of golfproducts$ | async" [value]="golfproduct.id">{{golfproduct.name}}</option>
                                                </select>
                                                <div *ngIf="golfproduct?.invalid && golfproduct?.touched" class="invalid-feedback">
                                                    <div *ngIf="golfproduct?.errors?.required">
                                                        Please select a golf product.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
