import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'gcl-admin-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss']
})
export class EventListComponent implements OnInit, OnDestroy {
  public courseId$!: Observable<number>;
  private destroyed$ = new Subject();

  constructor(private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
    this.courseId$ = this.authService.course$.pipe(
      map(course => course.id),
      takeUntil(this.destroyed$)
    )
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  onCreate() {
    this.router.navigate(['/admin', 'events', 'create']);
  }
}
