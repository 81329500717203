<gcl-lib-table 
[totalItems]="total$ | async" 
[sortBy]="'id'" 
[tableClass]="'table table-striped table-hover'" 
[showPageOptions]="false"
[showPagination]="false"
[showSearch]="false"
[createLabel]="'add'"
[enableCreate]="enableAdd"
(onCreate)="onAddClick()"
>
<thead>
    <tr>
        <th>PASS NAME</th>
        <th>COURSE NAME</th>
        <th>PRICE</th>
        <th>STATUS</th>
        <th width="100" class="text-center">MANAGE</th>
    </tr>
</thead>
<tbody>
    <tr *ngFor="let pass of records$ | async">
        <td>{{pass?.name}}</td>
        <td>{{pass?.course?.name}}</td>
        <td>{{pass?.price | currency}}</td>
        <td>{{pass?.available ? 'Active' : 'Inactive'}}</td>
        <td class="text-center">
            <div class="btn-group btn-group-sm">
                <a [routerLink]="['/admin','golf-passes', pass.id, 'view']" class="btn btn-link btn-primary-light">View</a>
                <a *ngIf="enableRemove" class="btn btn-link text-danger" (click)="onRemoveClick(pass)">Remove</a>
            </div>
        </td>
    </tr>
    <tr *ngIf="((records$ | async)?.length) == 0">
        <td colspan="9">No golf passes.</td>
    </tr>
</tbody>
</gcl-lib-table>
