import { Component, EventEmitter, Output, Input, HostListener, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'gcl-lib-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ToggleSwitchComponent),
    multi: true
  }],
})
export class ToggleSwitchComponent implements ControlValueAccessor {

  private propagateChange = (_: any) => { };
  private propagateTouched = () => { };

  @Output()
  toggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  public _value: boolean = true;

  constructor() { }

  public clicked() {
    this._value = !this._value;

    // Progates the new value to Reactive Angular Form.
    this.propagateChange(this._value);
    this.toggle.emit(this._value);
    
    this.propagateTouched();
  }

  // Sets the value of this input initially.
  writeValue(value: boolean): void {
    this._value = value;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  // Don't care about these.
  setDisabledState?(isDisabled: boolean): void { }
}
