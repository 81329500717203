import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import { UserService } from 'projects/shared/src/lib/services/user.service';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { CartService } from 'projects/shared/src/lib/services/cart.service';
import { CartDetails, DeviceDetails, UserDetails } from 'projects/shared/src/public-api';
import { ConfirmationModalService } from 'projects/shared/src/lib/services/confirmation-modal.service';

@Component({
  selector: 'gcl-admin-cart-edit',
  templateUrl: './cart-edit.component.html',
  styleUrls: ['./cart-edit.component.scss']
})
export class CartEditComponent implements OnInit, OnDestroy {
  public faChevronLeft = faChevronLeft;

  get status() { return this.form.get("status") as FormControl }
  get user() { return this.form.get("user") as FormControl }

  public form = this.fb.group({
    status: [null, [Validators.required]],
    user: [null, []],
  });

  public cartId$!: Observable<number>;
  public cart$!: Observable<CartDetails>;

  public devices$!: Observable<Array<DeviceDetails>>;
  public users$!: Observable<Array<UserDetails>>;

  private destroyed$ = new Subject();

  constructor(private fb: FormBuilder, public authService: AuthService, private cartService: CartService, private userService: UserService, public route: ActivatedRoute, private confirmationModalService: ConfirmationModalService, private router: Router, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.users$ = this.userService.getAll()
      .pipe(
        shareReplay(1),
        takeUntil(this.destroyed$)
      );

    this.cartId$ = this.route.params.pipe(
      map(params => params.cartId)
    );

    this.cart$ = this.cartId$.pipe(
      switchMap(cartId => this.cartService.get(cartId)),
      tap((cart: CartDetails) => this.form.patchValue({
        status: cart.status,
        user: cart.user?.id || null,
      })),
      shareReplay(1),
      takeUntil(this.destroyed$)
    );
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public submit(): void {
    this.form.markAllAsTouched();
    if(this.form.invalid) {
      return;
    }

    this.cart$.pipe(
      switchMap(cart => this.cartService.update(cart.id, { ...cart, ...this.form.value })),
      takeUntil(this.destroyed$)
    ).subscribe((cart: CartDetails) => {
      this.form.setErrors({});

      this.toastr.success("Cart updated successfully!");
      this.router.navigate(["/manager","carts", cart.id,"view"]);
    }, err => {
      this.toastr.error("An error has occurred. Please try again.")
    });
  }

  public openCancelModal(cart: CartDetails) {
    this.confirmationModalService.showConfirmationModal({
      title: 'Confirm Cancel',
      message: 'Are you sure you want to discard changes and go back?'
    }).subscribe(confirm => {
      if(confirm === true) {
        this.router.navigate(['/manager','carts', cart.id,'view']);
      }
    }) 
  }
  
  public openSaveModal() {
    this.confirmationModalService.showConfirmationModal({
      title: 'Confirm Save',
      message: 'Do you want to save these changes?'
    }).subscribe(confirm => {
      if(confirm === true) {
        this.submit();
      }
    }) 
  }
}
