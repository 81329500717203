<div class="d-flex flex-column h-100">
    <div class="d-flex justify-content-start py-2 bg-light no-flex">
        <a class="text-light-green" [routerLink]="['/admin','promo-codes']" [queryParams]="getQueryParams()">
            <fa-icon [icon]="faChevronLeft" class="px-3"></fa-icon> <span>Back to Promo Code List</span>
        </a>
    </div>
    
    <div class="container-fluid py-2 fill-flex overflow-y-auto">
        <div class="row"  *ngIf="promocode$ | async as promocode">
            <div class="col-12">
                <h6 class="page-header">Promo Code View</h6>
                <div class="d-flex justify-content-end py-2">
                    <a [routerLink]="['/admin','promo-codes', promocode.id, 'edit']" class="btn btn-light-green mx-1">Edit</a>
                    <a [routerLink]="['/admin','promo-codes', promocode.id, 'clone']" class="btn btn-outline-light-green mx-1">Clone</a>
                    <button (click)="openDeleteModal()" type="button" class="btn btn-outline-light-green mx-1">Delete</button>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-8">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label >Code</label>
                                            <p>{{promocode.code}}</p>    
                                        </div>
                                    </div>
                                    
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label >Active</label>
                                            <p>{{promocode.active ? "Yes" : "No"}}</p>    
                                        </div>
                                    </div>
                                    
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label >Active Date From</label>
                                            <p>{{(promocode?.activeDateFrom | date: 'shortDate') || "N/A"}}</p>    
                                        </div>
                                    </div>
                                    
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label >Active Date To</label>
                                            <p>{{(promocode?.activeDateTo | date: 'shortDate') || "N/A"}}</p>    
                                        </div>
                                    </div>
                                    
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label >Discount Amount</label>
                                            <p>{{(promocode?.discountAmount | currency) || "N/A"}}</p>    
                                        </div>
                                    </div>
                                    
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label >Discount Percent</label>
                                            <p>{{(promocode?.discountPercent) || 0}}%</p>    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
