<div class="d-flex flex-column h-100">
    <div class="d-flex justify-content-start py-2 bg-light no-flex">
        <a class="text-light-green"  [routerLink]="routerLink$ | async">
            <fa-icon [icon]="faChevronLeft" class="px-3"></fa-icon> <span>Back to Course Details</span>
        </a>
    </div>
    <div class="container-fluid py-2 fill-flex overflow-y-auto" *ngIf="eventSpace$ | async as eventSpace">
        <div class="row">
            <div class="col-12">
                <div class="d-flex justify-content-end py-2">
                    <button class="btn btn-success" [routerLink]="editLink$ | async">Edit</button>
                </div>
                <div class="row border mx-1">
                    <div class="col-12 col-lg-7 p-0">
                        <div class="row mx-0 pt-3">
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label>Name</label>
                                    <p>{{eventSpace.name}}</p>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label>Type</label>
                                    <p>{{eventSpace.type}}</p>
                                </div>
                            </div>
                        </div>                  
                    </div>
                    <div class="bg-light col-12 col-lg-5 p-0">
                    
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>