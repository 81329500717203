const icons: { [ext: string]: string } = {
    ".3ds": "icon_3ds.svg",
    ".cdr": "icon_cdr.svg",
    ".dwg": "icon_dwg.svg",
    ".eps": "icon_eps.svg",
    ".fla": "icon_fla.svg",
    ".indd": "icon_indd.svg",
    ".jpg": "icon_jpg.svg",
    ".pdf": "icon_pdf.svg",
    ".png": "icon_png.svg",
    ".psd": "icon_psd.svg",
    ".raw": "icon_raw.svg",
    ".swf": "icon_swf.svg"
}

export function getExtensionIcon(extension: string | undefined) {
    return extension ? icons[extension] || 'icon_generic.svg' : 'icon_generic.svg';
}