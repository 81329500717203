import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { shareReplay, switchMap, takeUntil } from 'rxjs/operators';

import { TabsetComponent } from 'ngx-bootstrap/tabs';

import { CourseDetails } from 'projects/shared/src/lib/models/course';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { CourseService } from 'projects/shared/src/lib/services/course.service';
import { ReportService } from 'projects/shared/src/lib/services/report.service';
import { BaseUtilization, Utilization } from 'projects/shared/src/lib/models/utilization';

interface LineChartPoint {
  name: string;
  value: number;
}

interface LineChartData {
  name: string;
  series: Array<LineChartPoint>
}

@Component({
  selector: 'gcl-admin-course-utilization',
  templateUrl: './course-utilization.component.html',
  styleUrls: ['./course-utilization.component.scss']
})
export class CourseUtilizationComponent implements OnInit {

  @Input()
  courseId?: number;

  public viewMode: string = "day";
  public course$!: Observable<CourseDetails>;
  public records$!: Observable<Utilization>;

  public dayData: Array<LineChartData> = [];
  public weekData: Array<LineChartData> = [];
  public monthData: Array<LineChartData> = [];
  public colorScheme = {
    domain: ['#0ACC70']
  };

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(private authService: AuthService, private courseService: CourseService, private reportService: ReportService) { }

  ngOnInit(): void {
    if (this.courseId) {
      this.course$ = this.courseService.get(this.courseId as number)
        .pipe(
          shareReplay(1),
          takeUntil(this.destroyed$)
        );
    } else {
      this.course$ = this.authService.course$
        .pipe(
          switchMap(course => this.courseService.get(course.id)),
          shareReplay(1),
          takeUntil(this.destroyed$)
        );
    }

    this.records$ = this.course$.pipe(
      switchMap((course) => this.reportService.getUtilization(course.id)),
      shareReplay(1),
      takeUntil(this.destroyed$)
    );

    this.records$.subscribe(utilization => {
      this.dayData = [{
        name: "Daily",
        series: utilization.daily.map(t => ({
          name: t.datestr,
          value: t.booked
        }))
      }];
      this.weekData = [{
        name: "Week",
        series: utilization.weekly.map(t => ({
          name: t.week,
          value: t.booked
        }))
      }];
      this.monthData = [{
        name: "Monthly",
        series: utilization.monthly.map(t => ({
          name: t.month,
          value: t.booked
        }))
      }];
    })
  }

  public onViewMode(mode: string): void {
    this.viewMode = mode;
  }

}
