import { Component, EventEmitter, forwardRef, Input, OnInit, Output, Self } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface RadioSelectListOption {
  text: string;
  value: any
}

@Component({
  selector: 'gcl-lib-radio-select-list',
  templateUrl: './radio-select-list.component.html',
  styleUrls: ['./radio-select-list.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RadioSelectListComponent),
    multi: true
  }],
})
export class RadioSelectListComponent implements OnInit, ControlValueAccessor {

  @Input()
  options: Array<RadioSelectListOption> = [];
  @Input()
  name!: string;

  @Output()
  changed: EventEmitter<any> = new EventEmitter<any>();

  public _value: any;

  private propagateChange = (_: any) => { };
  private propagateTouched = () => { };

  constructor() { }

  ngOnInit(): void {
  }

  onSelectRadio(val: any) {
    this._value = val;
    this.propagateChange(this._value);
    this.propagateTouched();
    this.changed.emit(this._value);
  }

  writeValue(val: any): void {
    this._value = val;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

}

