import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { StrapiQueryableService } from './strapi-queryable.service';
import { CONFIG_TOKEN, SharedModuleConfig } from '../shared.config';

import { PromoCode, PromoCodeDetails } from '../models/promo-code';
import { PagedResult } from '../queries/paged-result';
import { PromoCodeQuery } from '../queries/promo-code-query';

declare var Qs: any;

@Injectable({
  providedIn: 'root'
})
export class PromoCodeService extends StrapiQueryableService<PromoCode, PromoCodeDetails, PromoCodeQuery> {

  constructor(protected httpClient: HttpClient, @Inject(CONFIG_TOKEN) protected config: SharedModuleConfig) {
    super({ path: 'promo-codes' }, httpClient, config);
  }

  protected buildQuery(query?: PromoCodeQuery): string {
    let queryObj = super.buildBaseQueryObj(query);

    // Specific Query filters.
    if (query) {
      if (query.search) {
        queryObj._where.push({
          _or: [{
            id_contains: query.search
          }, {
            code_contains: query.search
          }]
        });
      }
      if (query.id) {
        queryObj._where.push({ id: query.id });
      }
      if (query.code) {
        queryObj._where.push({ name: query.code });
      }
      if (query.active) {
        queryObj._where.push({ addon: query.active });
      }
      if (query.course) {
        queryObj._where.push({ course: query.course });
      }
    }

    return Qs.stringify(queryObj);
  }

  public query(query?: PromoCodeQuery): PagedResult<PromoCodeDetails> {
    const total$ = this.count();
    const filteredTotal$ = this.count(query);
    const queryStr = this.buildQuery(query);
    const records$ = this.httpClient.get<PromoCodeDetails[]>(`${this.config.apiUrl}/${this.pathConfig.path}?${queryStr}`)
      .pipe(
        shareReplay(1)
      );
    return new PagedResult(records$, filteredTotal$, total$);
  }

  public count(query?: PromoCodeQuery): Observable<number> {
    const queryStr = this.buildQuery(query);
    return this.httpClient.get<number>(`${this.config.apiUrl}/${this.pathConfig.path}/count?${queryStr}`)
      .pipe(
        shareReplay(1)
      );
  }

}