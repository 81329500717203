export enum ReservationTypes {
    SingleParty = "SingleParty",
    Group = "Group",
    League = "League",
    Blocked = "Blocked",
    Event = "Event",
}

export function GetReservationTypesDisplayName(reservation: ReservationTypes): string {
    switch (reservation) {
        case ReservationTypes.SingleParty:
            return "Single Party";
        case ReservationTypes.Group:
            return "Group";
        case ReservationTypes.League:
            return "League";
        case ReservationTypes.Blocked:
            return "Blocked";
        case ReservationTypes.Event:
            return "Event";
        default:
            return "N/A";
    }
}