import { Component, OnInit } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Observable } from "rxjs";
import { delay } from 'rxjs/operators';

import { LoadingScreenService } from "../../services/loading-screen.service";

@Component({
  selector: 'gcl-lib-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.css']
})
export class LoadingScreenComponent implements OnInit {
  public loading$!: Observable<boolean>;
  public faSpinner = faSpinner;

  constructor(private loadingScreenService: LoadingScreenService) { }

  ngOnInit() {
    this.loading$ = this.loadingScreenService.loading$.pipe(delay(50));
  }

}
