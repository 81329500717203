<div class="d-flex flex-column h-100">
    <div class="d-flex justify-content-start py-2 bg-light no-flex">
        <a class="text-light-green"  [routerLink]="['/manager','products']"*ngIf="!(productId$ | async)">
            <fa-icon [icon]="faChevronLeft" class="px-3"></fa-icon> <span>Back to Golf Product List</span>
        </a>
        <a class="text-light-green"  [routerLink]="['/manager','products', productId, 'view']" *ngIf="productId$ | async as productId">
            <fa-icon [icon]="faChevronLeft" class="px-3"></fa-icon> <span>Back to Golf Product View</span>
        </a>
    </div>
    <div class="container-fluid py-2 fill-flex overflow-y-auto">
        <div class="row" *ngIf="((product$ | async) && (productId$ | async)) || !(productId$ | async)">
            <div class="col-12">
                <div class="d-flex justify-content-end py-2">
                    <button type="button" (click)="submit()" class="btn btn-light-green px-5">Save</button>
                </div>
                <div class="card">
                    <div class="card-body">
                        <gcl-admin-product-form></gcl-admin-product-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
