<div class="p-2">
    <div class="d-flex justify-content-between font-weight-bold">
        <h6 class="font-weight-bold">% of Utilization</h6>
        <div class="btn-group" role="group" aria-label="Basic example">
            <button class="btn btn-outline-dark" type="button"
                [class.active]="viewMode === 'day'"
                (click)="onViewMode('day')">Day</button>
            <button class="btn btn-outline-dark" type="button"
                [class.active]="viewMode === 'week'"
                (click)="onViewMode('week')">Week</button>
            <button class="btn btn-outline-dark" type="button"
                [class.active]="viewMode === 'month'"
                (click)="onViewMode('month')">Month</button>
        </div>
    </div>
    <div *ngIf="records$ | async" class="py-2">
        <div class="border" *ngIf="(viewMode == 'day')" [style.height]="'250px'">
            <ngx-charts-line-chart *ngIf="(dayData && dayData[0] && dayData[0].series.length > 0);else empty"
                [scheme]="colorScheme"
                [results]="dayData">
                <ng-template #tooltipTemplate let-model="model">
                    <span>Date: {{model.name}}</span><br/>
                    <span>Booked: {{model.value}}</span>
                 </ng-template>
                <ng-template #seriesTooltipTemplate let-model="model">
                    <ng-container *ngIf="model[0]">                        
                        <span>Date: {{model[0].name}}</span><br/>
                        <span>Booked: {{model[0].value}}</span>
                    </ng-container>
                 </ng-template>
            </ngx-charts-line-chart>
        </div>
        <div class="border" *ngIf="(viewMode == 'week');" [style.height]="'250px'">
            <ngx-charts-line-chart *ngIf="(weekData && weekData[0] && weekData[0].series.length > 0);else empty"
                [scheme]="colorScheme"
                [results]="weekData">
                <ng-template #tooltipTemplate let-model="model">
                    <span>Date: {{model.name}}</span><br/>
                    <span>Booked: {{model.value}}</span>
                 </ng-template>
                <ng-template #seriesTooltipTemplate let-model="model">
                    <ng-container *ngIf="model[0]">                        
                        <span>Date: {{model[0].name}}</span><br/>
                        <span>Booked: {{model[0].value}}</span>
                    </ng-container>
                 </ng-template>
            </ngx-charts-line-chart>
        </div>
        <div class="border" *ngIf="(viewMode == 'month');" [style.height]="'250px'">
            <ngx-charts-line-chart *ngIf="(monthData && monthData[0] && monthData[0].series.length > 0);else empty"
                [scheme]="colorScheme"
                [results]="monthData">
                <ng-template #tooltipTemplate let-model="model">
                    <span>Date: {{model.name}}</span><br/>
                    <span>Booked: {{model.value}}</span>
                 </ng-template>
                <ng-template #seriesTooltipTemplate let-model="model">
                    <ng-container *ngIf="model[0]">                        
                        <span>Date: {{model[0].name}}</span><br/>
                        <span>Booked: {{model[0].value}}</span>
                    </ng-container>
                 </ng-template>
            </ngx-charts-line-chart>
        </div>
    </div>

    <ng-template #empty>
        <p class="p-2">No data available.</p>
    </ng-template>
</div>