<div class="d-flex flex-column h-100">
    <div class="container-fluid py-2 fill-flex overflow-y-auto">
        <div class="row">
            <div class="col-12">
                <h6 class="page-header">Promo Codes List</h6>
                <gcl-lib-table [totalItems]="total$ | async" 
                    [itemsPerPage]="initialPagination.itemsPerPage"
                    [sortBy]="initialPagination.sortBy" 
                    [sortDescending]="initialPagination.sortDescending"
                    [page]="initialPagination.page" 
                    [searchTxt]="initialPagination.search"
                    (onPage)="pagination$.next($event)" [tableClass]="'table table-striped table-hover'"
                    (onCreate)="goToCreate()">
                    <thead>
                        <tr>
                            <th columnSort="code">Code</th>
                            <th columnSort="active">Active</th>
                            <th width="100" class="text-center">Manage</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let promocode of records$ | async">
                            <td>{{promocode.code}}</td>
                            <td>{{promocode.active}}</td>
                            <td class="text-center">
                                <div class="btn-group btn-group-sm">
                                    <a [routerLink]="['/admin','promo-codes', promocode.id, 'view']"
                                        [queryParams]="getQueryParams()" class="btn btn-link btn-primary-light">View</a>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="(total$ | async) == 0">
                            <td colspan="7">No promo codes.</td>
                        </tr>
                    </tbody>
                </gcl-lib-table>
            </div>
        </div>
    </div>
</div>
