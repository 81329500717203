export enum CreditBalanceTransactionTypes {
    RainCheck = "RainCheck",
    OrderPayment = "OrderPayment",
    GiftCertificate = "GiftCertificate",
}

export function GetCreditBalanceTransactionTypesDisplayName(status: CreditBalanceTransactionTypes): string {
    switch (status) {
        case CreditBalanceTransactionTypes.RainCheck:
            return "Rain Check";
        case CreditBalanceTransactionTypes.OrderPayment:
            return "Order Payment";
        case CreditBalanceTransactionTypes.GiftCertificate:
            return "Gift Certificate";
        default:
            return "N/A";
    }
}