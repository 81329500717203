<div class="d-flex flex-column h-100">
    <!-- Filters -->
    <div class="d-flex justify-content-start px-3 crud-filters no-flex">
        <form [formGroup]="filters">
            <div class="form-row">
                <div class="form-group m-0 pr-4">
                    <select class="form-control" formControlName="scheduletype" name="scheduletype"  
                        [hasNullOption]="true"
                        enum="ScheduleTypes"
                        [value]="scheduletype"
                        [class.is-invalid]="scheduletype?.invalid && scheduletype?.touched">
                    </select>
                </div>
            </div>
        </form>
    </div>

    <div class="container-fluid py-2 h-100 overflow-y-auto">
        <div class="row">
            <div class="col-12" *ngIf="pagedResult$ | async as pagedResult">
                <h6  class="page-header">Schedules</h6>
                <gcl-lib-table 
                    [totalItems]="pagedResult.filteredCount$ | async" 
                    [itemsPerPage]="initialPagination.itemsPerPage" 
                    [sortBy]="initialPagination.sortBy" 
                    [sortDescending]="initialPagination.sortDescending"
                    [page]="initialPagination.page"
                    [searchTxt]="initialPagination.search"
                    (onPage)="pagination$.next($event)" 
                    [tableClass]="'table table-striped table-hover'" 
                    (onCreate)="goToCreate()">
                    <thead>
                        <tr>
                            <th columnSort="name">Name</th>
                            <th columnSort="starttod">Start Time of Day</th>
                            <th columnSort="endtod">End Time of Day</th>
                            <th columnSort="turn">Turn</th>
                            <th columnSort="active">Active</th>
                            <th columnSort="type">Schedule Type</th>
                            <th width="100" class="text-center">Manage</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let schedule of pagedResult.records$ | async">
                            <td>{{schedule.name}}</td>
                            <td>{{schedule.starttod}}</td>
                            <td>{{schedule.endtod}}</td>
                            <td>{{schedule.turn}}</td>
                            <td>{{schedule.active ? 'Yes' : 'No'}}</td>
                            <td>{{GetScheduleTypeDisplayName(schedule.type)}}</td>
                            <td class="text-center">
                                <div class="btn-group btn-group-sm">
                                    <a [routerLink]="['/admin','schedules', schedule.id, 'view']" [queryParams]="getQueryParams()" class="btn btn-link btn-primary-light">View</a>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="(pagedResult.filteredCount$ | async) == 0">
                            <td colspan="7">No schedules.</td>
                        </tr>
                    </tbody>
                </gcl-lib-table>
            </div>
        </div>
    </div>
</div>

