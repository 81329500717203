<div class="d-flex flex-column h-100" *ngIf="order$ | async as order">
    <h5 class="font-weight-bold ml-4">Cash</h5>
    <div class="fill-flex overflow-y-auto container-fluid" *ngIf="paymentAmountSelected === false else paymentSelected">
        <div class="row">
            <div class="col-4 mb-4 px-4 payment-select-container text-center" *ngFor="let payment of paymentAmounts" >
                <ng-container *ngIf="!payment.isCustom">
                    <button type="button" class="btn btn-default btn-block payment-option fixed-amount w-100 h-100 d-flex justify-content-center align-items-center text-uppercase" 
                        (click)="setPaymentAmount(payment)"
                        [ngClass]="{'active-amount': payment.selected }">
                        {{payment.amount | currency}}
                    </button>
                </ng-container>
                <ng-container *ngIf="payment.isCustom">
                    <button type="button" class="btn btn-default btn-block payment-option fixed-amount w-100 h-100 d-flex justify-content-center align-items-center text-uppercase" 
                        (click)="openCustomAmountModal()"
                        [ngClass]="{'active-amount': payment.selected }">
                        Custom Amount
                    </button>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="d-flex flex-row justify-content-center m-4" *ngIf="anyPaymentSelected() && paymentAmountSelected === false">
        <button class="btn btn-light-green btn-block" (click)="selectPaymentAmount()">Continue</button>
    </div>

    <ng-template #paymentSelected>
        <div class="fill-flex overflow-y-auto font-weight-bold d-flex flex-column h-100 p-4 justify-content-between align-items-center">
            <div class="w-100 text-center">
                <div class="font-size-x-large">Change Due</div>
                <div class="font-size-jumbo">{{getChangeDue(order) | currency}}</div>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center w-100">
                <div class="d-flex justify-content-between align-items-center w-50">
                    <div>Total</div>
                    <div>{{order?.finaltotal | currency}}</div>
                </div>
                <div class="d-flex justify-content-between align-items-center w-50">
                    <div>Tendered Amount</div>
                    <div>{{getSelectedPayment()?.amount | currency}}</div>
                </div>
            </div>
            <div class="w-100 d-flex flex-row justify-content-end">
                <button class="btn btn-outline-light-green mr-2" [disabled]="submitted" (click)="checkout(order)">No Receipt</button>
                <button class="btn btn-light-green btn-receipt" [disabled]="submitted" (click)="openReceiptModal(receiptEmail, order)">Receipt</button>
            </div>
        </div>
    </ng-template>

    <ng-template #receiptEmail>
        <div class="modal-header">
          <h4 class="modal-title pull-left">Email Receipt</h4>
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span class="modal-close" aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <label for="email">Email</label>
                <input class="form-control" id="email" [(ngModel)]="email" [disabled]="disableEmail ? true : false" />
            </div>
            <div class="form-group" *ngIf="printers$ | async as printers">
                <label>Printers</label>
                <div class="custom-control custom-checkbox" *ngFor="let printer of printers">
                    <input class="custom-control-input" type="checkbox" [id]="'printer-'+printer.id.toString()" value="{{printer.id}}" (change) ="updatePrinters($event)">
                    <label class="custom-control-label" [for]="'printer-'+printer.id.toString()">{{printer.name}}</label>
                </div>
            </div>
            <div class="d-flex justify-content-end">
                <button class="btn btn-outline-light-green mr-2" (click)="modalRef.hide()">Cancel</button>
                <button class="btn btn-light-green" [disabled]="submitted" (click)="emailReceipt(order)">Continue</button>
            </div>
        </div>
      </ng-template>
</div>