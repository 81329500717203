<div *ngIf="readonly" class="text-center text-muted">
	<p>{{date | date: "LLLL yyyy"}}</p>
</div>

<div class="input-group" *ngIf="!readonly"
	[class.input-group-lg]="large">
    <div class="input-group-prepend">
        <button class="btn btn-outline-primary" [class.border-0]="!bordered" type="button" (click)="decrement()">
            <fa-icon [icon]="faChevronLeft"></fa-icon>
        </button>
    </div>
    <input *ngIf="!minDate && !maxDate" type="text" [class.border-0]="!bordered"
			[placement]="placement"
			[(ngModel)]="date"
           	[bsConfig]="bsConfig"
			(bsValueChange)="onSelectDate($event)"
			[placeholder]="placeholder"
			[bsConfig]="{ dateInputFormat: format }"
			class="form-control text-center"
			bsDatepicker>
		<!-- Min date Only -->
	<input *ngIf="minDate && !maxDate" type="text" [class.border-0]="!bordered" 
			[placement]="placement"
			[(ngModel)]="date"
           	[bsConfig]="bsConfig"
			(bsValueChange)="onSelectDate($event)"
			[minDate]="minDate"
			[placeholder]="placeholder"
			[bsConfig]="{ dateInputFormat: format }"
			class="form-control text-center"
			bsDatepicker>
		<!-- Max date Only -->
    <input *ngIf="!minDate && maxDate" type="text" [class.border-0]="!bordered" 
			[placement]="placement"
			[(ngModel)]="date"
           	[bsConfig]="bsConfig"
			(bsValueChange)="onSelectDate($event)"
			[maxDate]="maxDate"
			[placeholder]="placeholder"
			[bsConfig]="{ dateInputFormat: format }"
			class="form-control text-center"
			bsDatepicker>
			<!-- Min & Max date Only -->
	<input *ngIf="minDate && maxDate" type="text" [class.border-0]="!bordered" 
			[placement]="placement"
			[(ngModel)]="date"
           	[bsConfig]="bsConfig"
			(bsValueChange)="onSelectDate($event)"
			[minDate]="minDate"
			[maxDate]="maxDate"
			[placeholder]="placeholder"
			[bsConfig]="{ dateInputFormat: format }"
			class="form-control text-center"
			bsDatepicker>
    <div class="input-group-append">
        <button class="btn btn-outline-primary" [class.border-0]="!bordered" type="button" (click)="increment()">
            <fa-icon [icon]="faChevronRight"></fa-icon>
        </button>
    </div>
</div>