<div class="d-flex flex-column h-100">
    <div class="d-flex justify-content-start py-2 bg-light no-flex">
        <a class="text-light-green"  [routerLink]="['/admin','events']" [queryParams]="getQueryParams()">
            <fa-icon [icon]="faChevronLeft" class="px-3"></fa-icon> <span>Back to Event List</span>
        </a>
    </div>
    <div class="container-fluid py-2 fill-flex overflow-y-auto" *ngIf="event$ | async as event">
        <div class="row">
            <div class="col-12">      
                <h6 class="page-header">Event Details</h6>          
                <div class="d-flex justify-content-end py-2">
                    <a [routerLink]="['/admin','tee-sheet', event?.order?.id, 'register-checkout']" class="btn btn-light-green">Checkout</a>
                    <a [routerLink]="['/admin','events', event.id, 'edit']" class="btn btn-light-green">Edit</a>
                    <button (click)="openDeleteModal()" type="button" class="btn btn-outline-light-green mx-1">Delete</button>
                </div>
                <div class="row border mx-1">
                    <div class="col-12 col-lg-7 p-0">
                        <div class="row mx-0 pt-3">
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label>Name</label>
                                    <p>{{event?.name}}</p>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label>Description</label>
                                    <p>{{event?.description}}</p>
                                </div>
                            </div>
                        </div>    
                        <div class="row mx-0">
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label>Event Type</label>
                                    <p>{{event?.type}}</p>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label>Event Space</label>
                                    <p>{{event?.event_space?.name}}</p>
                                </div>
                            </div>
                        </div>                                      
                        <div class="row mx-0">
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label for="courseLength">Course Length</label>
                                    <p>{{event?.courseLength}}</p>
                                </div>
                            </div>
                        </div>        
                        <div class="row mx-0">
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label for="start">Start Date/Time</label>
                                    <p>{{event?.start | date: 'medium'}}</p>
                                </div>   
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label for="end">End Date/Time</label>
                                    <p>{{event?.end | date: 'medium'}}</p>
                                </div>   
                            </div>
                        </div>      
                        <hr class="my-0 mx-3" />      
                        <ng-container *ngIf="eventUser$ | async as user">
                            <div class="d-flex justify-content-start pt-3 px-3">
                                <div class="form-group">
                                    <label>Customer Name</label>
                                    <p>{{user?.firstName}} {{user?.lastName}}</p>
                                </div>
                            </div>
                            <div class="row mx-0">
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label>Email</label>
                                        <p>{{user?.email}}</p>    
                                    </div>   
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label>Phone</label>
                                        <p>{{user?.cellphone}}</p>    
                                    </div>   
                                </div>
                            </div> 
                        </ng-container>
                        <div class="row mx-0">
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label for="participants">People in party</label>
                                    <p>{{event?.participants}}</p>
                                </div>   
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label for="carts">Number of Carts</label>
                                    <p>{{event?.carts}}</p>
                                </div>   
                            </div>
                        </div>           
                    </div>
                    <div class="bg-light col-12 col-lg-5 p-0">
                        <div class="d-flex justify-content-between align-items-center pt-4 px-4">
                            <div class="font-weight-bold">Itemized List</div>
                            <button class="btn btn-light-green px-5" [routerLink]="['/admin','tee-sheet', event?.order?.id, 'register-checkout']">+ Products</button>
                        </div>
                        <ng-container *ngIf="eventOrder$ | async as eventOrder">
                            <gcl-admin-order-line-items [order]="eventOrder"></gcl-admin-order-line-items>
                        </ng-container>
                        <!-- <div class="row m-0 px-3 pt-4">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="eventAmount">Event Total Price</label>
                                    <input class="form-control" type="number" min="0" formControlName="eventAmount" [class.is-invalid]="eventAmount?.invalid && eventAmount?.touched"/> 
                                    <div *ngIf="eventAmount?.invalid && eventAmount?.touched" class="invalid-feedback">
                                        <div *ngIf="eventAmount?.errors?.required">
                                            Please enter an event amount.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="my-0 mx-4" />
                        <div class="row m-0 px-3 pt-3">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="depositAmount">Deposit Amount</label>
                                    <input class="form-control" type="number" min="0" formControlName="depositAmount" [class.is-invalid]="depositAmount?.invalid && depositAmount?.touched"/> 
                                    <div *ngIf="depositAmount?.invalid && depositAmount?.touched" class="invalid-feedback">
                                        <div *ngIf="depositAmount?.errors?.required">
                                            Please enter an deposit amount.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                             -->
                    </div>
                </div>

            </div>
            <ng-container *ngIf="eventOrder$ | async as eventOrder">
                <div class="container-fluid py-2 mx-1 fill-flex overflow-y-auto" *ngIf="eventOrder.payments && eventOrder.payments.length > 0">
                    <div class="row">
                        <div class="col-12">
                            <gcl-admin-order-payment-table [order]="eventOrder"></gcl-admin-order-payment-table>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>