<div class="row">
    <div class="col-12 col-sm-6 col-md-4 col-lg-2">
        <gcl-lib-date-picker
            [readonly]="readonly"
            [date]="date"
            [bordered]="false"
            [format]="format"
            [viewMode]="viewMode"
            [incrementBy]="incrementBy"
            (selectDate)="onSelectDate($event)"></gcl-lib-date-picker>
    </div>
    <div class="col-12 col-sm-6 col-md-8 col-lg-10">
        <div class="d-flex align-content-center text-center dates-slider" style="overflow: auto;">
            <div *ngFor="let dd of dates" 
                [class.cursor-pointer]="!readonly"
                [class.disabled]="readonly"
                [class.date-in-range]="((dd.compareDate(currentDate.addDays(0)) == 1) && (dd.compareDate(currentDate.addDays(range - 1)) == -1)) 
                    || ((dd.compareDate(currentDate.addDays(0)) == 0) || (dd.compareDate(currentDate.addDays(range - 1)) == 0))"
                [class.rounded-left]="(dd.compareDate(currentDate.addDays(0)) == 0)"
                [class.rounded-right]="(dd.compareDate(currentDate.addDays(range - 1)) == 0)"
                [ngClass]="{'date-selected': dd.compareDate(currentDate) == 0}"
                (click)="onSelectDate(dd)">
                <div class="date-item"
                    [ngClass]="{'date-current border rounded-circle': dd.compareDate(date) == 0}">{{dd.getDate()}}</div>
            </div>
        </div>
    </div>
</div>