import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { EventSpaceType } from 'projects/shared/src/lib/enumerations/event-space-type';
import { EventSpaceDetails } from 'projects/shared/src/lib/models/event-space';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { EventSpaceService } from 'projects/shared/src/lib/services/event-space.service';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'gcl-admin-event-space-edit',
  templateUrl: './event-space-edit.component.html',
  styleUrls: ['./event-space-edit.component.scss']
})
export class EventSpaceEditComponent implements OnInit, OnDestroy {
  faChevronLeft = faChevronLeft;

  eventSpace$!: Observable<EventSpaceDetails>;

  cancelLink$!: Observable<any[]>;

  routerLink$!: Observable<any[]>;

  get name() { return this.form.get('name') }
  get type() { return this.form.get('type') }

  public form = this.fb.group({
    name: ['', [Validators.required]],
    course: [null, [Validators.required]],
    type: [EventSpaceType.General, [Validators.required]]
  })

  private destroyed$ = new Subject();

  constructor(
    private fb: FormBuilder, 
    private activatedRoute: ActivatedRoute,
    private eventSpaceService: EventSpaceService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.eventSpace$ = this.activatedRoute.params.pipe(
      switchMap(p => this.eventSpaceService.get(p['eventSpaceId'] as number))
    );

    this.routerLink$ = this.activatedRoute.params.pipe(
      map(p => ['/admin', 'courses', p['courseId'], 'view'])
    )

    this.cancelLink$ = this.activatedRoute.params.pipe(
      map(p => ['/admin', 'courses', p['courseId'], 'spaces', p['eventSpaceId'], 'view'])
    )

    this.eventSpace$.pipe(take(1)).subscribe(space => this.form.patchValue(space));
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  async submit() {
    let space = await this.eventSpace$.pipe(take(1)).toPromise();

    this.eventSpaceService.update(space.id, this.form.value).subscribe(
      space => {
        this.router.navigate(['/admin', 'courses', space.course.id, 'spaces', space.id, 'view']);
      },
      err => {
        console.error(err?.message);
      }
    )
  }
}
