import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, combineLatest } from 'rxjs';
import { map, takeUntil, tap, shareReplay, switchMap, take } from 'rxjs/operators';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';

import { environment } from 'projects/admin/src/environments/environment';
import { PromoCodeDetails } from 'projects/shared/src/lib/models/promo-code';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { PromoCodeService } from 'projects/shared/src/lib/services/promo-code.service';
import { ConfirmationModalService } from 'projects/shared/src/lib/services/confirmation-modal.service';

@Component({
  selector: 'gcl-admin-promo-code-edit',
  templateUrl: './promo-code-edit.component.html',
  styleUrls: ['./promo-code-edit.component.scss']
})
export class PromoCodeEditComponent implements OnInit, OnDestroy {
  public apiUrl = environment.apiUrl;
  public faChevronLeft = faChevronLeft;

  get code() { return this.form.get("code") as FormControl }
  get active() { return this.form.get("active") as FormControl }
  get activeDateFrom() { return this.form.get("activeDateFrom") as FormControl }
  get activeDateTo() { return this.form.get("activeDateTo") as FormControl }
  get discountAmount() { return this.form.get("discountAmount") as FormControl }
  get discountPercent() { return this.form.get("discountPercent") as FormControl }
  get courseId() { return this.form.get("courseId") as FormControl }

  public form = this.fb.group({
    code: [null, Validators.required],
    active: [false, Validators.required],
    activeDateFrom: [null],
    activeDateTo: [null],
    discountAmount: [null],
    discountPercent: [null],
    courseId: [null],
  });

  public promocodeId$!: Observable<number>;
  public promocode$!: Observable<PromoCodeDetails>;

  private destroyed$ = new Subject<boolean>();

  constructor(
    private fb: FormBuilder,
    private promocodeService: PromoCodeService,
    private authService: AuthService,
    public route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private confirmationModalService: ConfirmationModalService
  ) { }

  ngOnInit(): void {
    this.promocodeId$ = this.route.params.pipe(
      map(params => params.promocodeId)
    );

    combineLatest([this.promocodeId$, this.authService.course$])
      .pipe(
        takeUntil(this.destroyed$)
      ).subscribe(([promocodeId, course]) => {
        this.promocode$ = this.promocodeService.get(promocodeId).pipe(
          tap(promocode => {
            this.form.patchValue({
              code: promocode.code,
              active: promocode.active,
              activeDateFrom: promocode.activeDateFrom,
              activeDateTo: promocode.activeDateTo,
              discountAmount: promocode.discountAmount,
              discountPercent: promocode.discountPercent,
              course: promocode.courseId
            });
          }),
          shareReplay(1)
        );
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public submit(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.promocode$
      .pipe(
        switchMap(promocode => this.promocodeService.update(promocode.id, { ...promocode, ...this.form.value })),
        take(1),
        takeUntil(this.destroyed$)
      )
      .subscribe((promocode: PromoCodeDetails) => {
        this.form.setErrors({});

        this.toastr.success("Promo Codes updated successfully!");
        this.router.navigate(["/admin", "promo-codes", promocode.id, "view"]);
      }, err => {
        this.toastr.error("An error has occurred. Please try again.")
      });
  }

  public openCancelModal(promocode: PromoCodeDetails) {
    this.confirmationModalService.showConfirmationModal({
      title: 'Confirm Cancel',
      message: 'Are you sure you want to discard changes and go back?'
    }).subscribe((confirm) => {
      if (confirm === true) {
        this.router.navigate(['/admin', 'promo-codes', promocode.id, 'view'])
      }
    });
  }

  public openSaveModal() {
    this.confirmationModalService.showConfirmationModal({
      title: 'Confirm Save',
      message: 'Do you want to save these changes?'
    }).subscribe((confirm) => {
      if (confirm === true) {
        this.submit();
      }
    });
  }
}
