<div class="d-flex justify-content-start py-2 bg-light">
    <a class="text-light-green" [routerLink]="['/admin','courses']" [queryParams]="getQueryParams()">
        <fa-icon [icon]="faChevronLeft" class="px-3"></fa-icon> <span>Back to Course List</span>
    </a>
</div>

<div class="container-fluid py-2">
    <div class="row" *ngIf="course$ | async as course">
        <div class="col-12">
            <h6 class="page-header">Course View</h6>
                <div class="d-flex justify-content-end py-2">
                    <button *ngIf="!course.stripeAccountId" (click)="connectStripe()" type="button" class="btn btn-outline-warning mx-1">Connect Stripe Account</button>
                    <button *ngIf="course.stripeAccountId" (click)="disconnectStripe()" type="button" class="btn btn-outline-warning mx-1">Disconnect Stripe Account</button>
                    <a [routerLink]="['/admin','courses', course.id, 'edit']" class="btn btn-light-green mx-1">Edit</a>
                    <button (click)="openDeleteModal()" type="button" class="btn btn-outline-light-green mx-1">Delete</button>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="card my-2">
                            <div class="card-body">
                                <div class="row" *ngIf="course?.customer">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label >Customer Name</label>
                                            <p>{{course?.customer?.name}}</p>    
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label >Course Name</label>
                                            <p>{{course.name}}</p>    
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label >Course Full Name</label>
                                            <p>{{course.full_name}}</p>    
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <label>Course Address</label>
                                        <address>
                                            {{course.address1}} <br/>
                                            {{course.address2}} <br/>
                                            {{course.city}} <br/>
                                            {{course.state}} <br/>
                                            {{course.postalCode}}
                                        </address>
                                    </div>
                                    <div class="col-6">
                                        <label>Opening Time</label>
                                        <p>{{course.open_time}}</p> 

                                        <label>Closing Time</label>
                                        <p>{{course.close_time}}</p> 
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <label>Time Zone</label>
                                        <p>{{course.timezone}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card my-2">
                            <div class="card-body">
                                <label>Course Details</label>
                                <div class ="row">
                                    <div class="col-4">
                                        <label>Holes</label>
                                        <p>{{course.holes}}</p>
                                    </div>
                                    <div class="col-4">
                                        <label>Par</label>
                                        <p>{{course.par}}</p>
                                    </div>
                                    <div class="col-4">
                                        <label>Course Length</label>
                                        <p>{{course.courselength}}</p>
                                    </div>
                                    <div class="col-4">
                                        <label>Slope</label>
                                        <p>{{course.slope}}</p>
                                    </div>
                                    <div class="col-4">
                                        <label>Rating</label>
                                        <p>{{course.rating}}</p>
                                    </div>
                                    <div class="col-4">
                                        <label>Taxrate</label>
                                        <p>{{course.taxrate}}%</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-4">
                                        <label>Dwell</label>
                                        <p>{{course.dwell}}</p> 
                                    </div>
                                    <div class="col-4">
                                        <label>Turn</label>
                                        <p>{{course.turn}}</p> 
                                    </div>
                                    <div class="col-4">
                                        <label>Starting Drawer Count</label>
                                        <p>{{course.startingDrawerCount}}</p> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card my-2">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-4">
                                        <label>From Email</label>
                                        <p>{{course.fromEmail || "N/A"}}</p>
                                    </div>
                                    <div class="col-4">
                                        <label>Mobile Active</label>
                                        <p>{{course.mobileActive ? "Yes" : "No"}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="d-md-flex justify-content-center mx-auto text-center py-6" *ngIf="course.logo">
                            <img [src]="apiUrl + course.logo?.url" [alt]="course.logo?.alternativeText" class="img-fluid"/>
                        </div>
                        <h3 class="d-flex justify-content-between">Card Readers <button class="btn btn-primary btn-sm" (click)="addReader()"><fa-icon [icon]="addIcon" ></fa-icon> Add Reader</button></h3>
                        <div class="list-group mb-3" *ngIf="readers$ | async as readers">
                            <div class="list-group-item" *ngIf="readers.length == 0">
                                No readers are currently linked to this course location.
                            </div>
                            <div class="list-group-item" *ngFor="let reader of readers">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div>
                                        <div class="d-flex align-items-center mb-1">
                                            <h5 class="mb-0">{{reader.label}}</h5>
                                            <span class="ml-2 badge" [class.badge-success]="reader.status == 'online'" [class.badge-error]="reader.status == 'offline'" >{{reader.status}}</span>
                                        </div>
                                        <div class="d-flex justify-content-start">
                                            <small class="mr-2"><span class="mr-1 badge badge-secondary">Serial No</span>{{reader.serial_number}}</small>
                                            <small class="mr-2"><span class="mr-1 badge badge-secondary">IP Address</span>{{reader.ip_address}}</small>
                                            <small class="mr-2"><span class="mr-1 badge badge-secondary">Version</span>{{reader.device_sw_version}}</small>
                                        </div>
                                    </div>
                                    <div>
                                        <button class="btn btn-primary btn-sm mr-2" (click)="renameTerminal(reader)"><fa-icon [icon]="editIcon" ></fa-icon></button>
                                        <button class="btn btn-danger btn-sm" (click)="deleteTerminal(reader.id)"><fa-icon [icon]="deleteIcon" ></fa-icon></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h3 class="d-flex justify-content-between">Printers <button class="btn btn-primary btn-sm" (click)="addPrinter()"><fa-icon [icon]="addIcon" ></fa-icon> Add Printer</button></h3>
                        <div class="list-group" *ngIf="printers$ | async as printers">
                            <div class="list-group-item" *ngIf="printers.length == 0">
                                No printers are currently linked to this course location.
                            </div>
                            <div class="list-group-item" *ngFor="let printer of printers">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div>
                                        <div class="d-flex justify-content-start">
                                            <small class="mr-2"><span class="mr-1 badge badge-secondary">Printer Domain Name</span>{{printer.ipAddress}}</small>
                                            <small class="mr-2"><span class="mr-1 badge badge-secondary">Device Name</span>{{printer.name}}</small>
                                        </div>
                                    </div>
                                    <div>
                                        <button class="btn btn-danger btn-sm" (click)="deletePrinter(printer.id)"><fa-icon [icon]="deleteIcon" ></fa-icon></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" >
                    <div class="col-12">
                        <h6 class="page-header">Event Space List</h6>
                        <gcl-admin-event-space-table [course]="course.id" (onCreate)="onCreateSpace(course)"></gcl-admin-event-space-table>
                    </div>
                </div>
        </div>
    </div>
</div>
