import { Component, EventEmitter, OnInit, Output, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subject, Observer, combineLatest } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators';

import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

import { RoundDetails } from '../../models/round';
import { RoundService } from '../../services/round.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'gcl-lib-search-rounds',
  templateUrl: './search-rounds.component.html',
  styleUrls: ['./search-rounds.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SearchRoundsComponent),
    multi: true
  }],
})
export class SearchRoundsComponent implements OnInit, ControlValueAccessor {
  private propagateChange = (_: any) => { };
  private propagateTouched = () => { };

  public records$!: Observable<Array<RoundDetails>>;
  public search: string = "";
  public _value!: RoundDetails;

  private selectedItem?: RoundDetails;

  private destroy$: Subject<boolean> = new Subject();

  @Input()
  multiSelect: boolean = false;

  @Output()
  addItem: EventEmitter<RoundDetails> = new EventEmitter<RoundDetails>();

  constructor(private authService: AuthService, private roundService: RoundService) { }

  ngOnInit(): void {
    this.getPagedResults();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  getPagedResults() {
    const search$ = new Observable((observer: Observer<string>) => {
      observer.next(this.search);
    }).pipe(
      takeUntil(this.destroy$)
    );

    const course$ = this.authService.course$
      .pipe(
        takeUntil(this.destroy$)
      );

    this.records$ = combineLatest([search$, course$]).pipe(
      switchMap(([search, course]) => {
        const pagedResult = this.roundService.query({
          take: 10,
          course: course?.id,
          search: search
        });
        return pagedResult.records$;
      }),
      takeUntil(this.destroy$)
    );
  }

  public setRecord(event: TypeaheadMatch) {
    this.selectedItem = event.item;
    this.propagateChange(this.selectedItem);
    
    this.propagateTouched();
  }

  public addRecord() {
    if (this.selectedItem) {
      this.addItem.emit(this.selectedItem);

      // Clears search box
      this.selectedItem = undefined;
      this.search = "";
    }
  }

  // Sets the value of this input initially.
  writeValue(value: RoundDetails): void {
    this.selectedItem = value;
    this.search = this.selectedItem?.id.toString();
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  // Don't care about these.
  setDisabledState?(isDisabled: boolean): void { }

}
