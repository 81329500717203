import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PromoCodeCreateComponent } from './promo-code-create/promo-code-create.component';
import { PromoCodeEditComponent } from './promo-code-edit/promo-code-edit.component';
import { PromoCodeListComponent } from './promo-code-list/promo-code-list.component';
import { PromoCodeViewComponent } from './promo-code-view/promo-code-view.component';

const routes: Routes = [
  {
    path: 'create',
    component: PromoCodeCreateComponent
  },
  {
    path: ':promocodeId/view',
    component: PromoCodeViewComponent
  },
  {
    path: ':promocodeId/clone',
    component: PromoCodeCreateComponent
  },
  {
    path: ':promocodeId/edit',
    component: PromoCodeEditComponent
  },
  {
    path: '',
    component: PromoCodeListComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PromoCodesRoutingModule { }
