import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ToastrModule } from 'ngx-toastr';

import { SharedModule } from 'projects/shared/src/public-api';

import { GolfPassesRoutingModule } from './golf-passes-routing.module';
import { GolfPassCreateComponent } from './golf-pass-create/golf-pass-create.component';
import { GolfPassEditComponent } from './golf-pass-edit/golf-pass-edit.component';
import { GolfPassViewComponent } from './golf-pass-view/golf-pass-view.component';
import { GolfPassListComponent } from './golf-pass-list/golf-pass-list.component';


@NgModule({
  declarations: [GolfPassCreateComponent, GolfPassEditComponent, GolfPassViewComponent, GolfPassListComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,

    SharedModule,
    CarouselModule.forRoot(),
    TabsModule.forRoot(),
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    FontAwesomeModule,
    
    GolfPassesRoutingModule
  ]
})
export class GolfPassesModule { }
