<div class="h-100">
    <h6 class="font-weight-bold">Golf Product Utilization</h6>
    <p><small>Report based on last year of sales</small></p>
    <div class="container-fluid" *ngIf="records$ | async as records">
        <div class="row" *ngIf="records.length > 0; else empty">
            <div class="col-12 col-md-6 donut-chart-container">
                <ngx-charts-pie-chart
                    [results]="doughnutchartdata"
                    [scheme]="doughnutchartcolors"
                    [arcWidth]="0.5"
                    [doughnut]="true">
                </ngx-charts-pie-chart>
            </div>
            <div class="col-12 col-md-6">
                <div style="overflow: auto; max-height: 245px;">
                    <div *ngFor="let gp of golfproducts$ | async">
                        <small><span class="font-bold">{{gp.name}}</span> <span class="float-right">{{gp.total || 0}} <span class="text-muted">({{gp.percentage || 0}}%)</span></span></small>
                        <progressbar [value]="gp.percentage || 0" [max]="100" [class]="gp.backgroundColor"></progressbar>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #empty>
            <div class="row">
                <div class="col-12">
                    <small>There are no data.</small>
                </div>
            </div>
        </ng-template>
    </div>
</div>