<div class="d-flex flex-column h-100">
    <div class="d-flex justify-content-start py-2 bg-light no-flex">
        <a class="text-light-green" [routerLink]="['/admin','players']" [queryParams]="getQueryParams()">
            <fa-icon [icon]="faChevronLeft" class="px-3"></fa-icon> <span>Back to Customer List</span>
        </a>
    </div>
    
    <div class="container-fluid py-2 fill-flex overflow-y-auto">
        <div class="row" *ngIf="user$ | async as user">
            <div class="col-12">
                <h6 class="page-header">Customer Details</h6>
                <div class="d-flex justify-content-end py-2">
                    <a [routerLink]="['/admin','players', user.id, 'edit']" class="btn btn-light-green mx-1">Edit</a>
                    <button (click)="openDeleteModal()" type="button" class="btn btn-outline-light-green mx-1">Delete</button>
                </div>
                <div class="row border mx-1">
                    <div class="col-12 col-lg-7 p-0">
                        <div class="row mx-0 pt-3">
                            <div class="col-12 col-md-6 p-0">
                                <div class="row mx-0">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>First Name</label>
                                            <p>{{user?.firstName}}</p>    
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>Last Name</label>
                                            <p>{{user?.lastName}}</p>    
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 p-0">
                                
                            </div>
                        </div>
                        <div class="row mx-0 pt-3">
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label>Email</label>
                                    <p>{{user?.email}}</p>    
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label>Username</label>
                                    <p>{{user?.username}}</p>    
                                </div>
                            </div>
                        </div>                                      
                        <div class="row mx-0 pt-3">
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label>Cell Phone</label>
                                    <p>{{user?.cellphone}}</p>    
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label>Date of Birth</label>
                                    <p>{{user?.dateOfBirth | date:"longDate"}}</p>     
                                </div>
                            </div>
                        </div>         
                        <hr class="my-0 mx-3" />         
                        <div class="mt-3 mx-3 font-weight-bold">Payment Methods</div>                     
                            <gcl-lib-stripe-payment-methods                        
                            [userId]="user?.id"
                            [showNewCardEntry]="false"
                            [showUserCreditBalance]="true"
                            ></gcl-lib-stripe-payment-methods>

                    </div>
                    <div class="bg-light col-12 col-lg-5 p-0">
                        <div class="row m-0 pt-3">
                            <div class="col-12">
                                <div class="form-group">
                                    <label>License Number</label>
                                    <p>{{user?.licenseNumber ? user.licenseNumber : 'N/A'}}</p>    
                                </div>
                            </div>
                        </div>
                        <div class="row m-0 pt-3">
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label>State / Provider</label>
                                    <p>{{user?.licenseProvider ? user.licenseProvider : 'N/A'}}</p>    
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label>License Expires</label>
                                    <p>{{user?.licenseExpires ? user.licenseExpires : 'N/A'}}</p>    
                                </div>
                            </div>
                        </div>
                        <div class="row m-0 pt-3">
                            <div class="col-6 col-md-4">
                                <div class="form-group">
                                    <label >Confirmed</label>
                                    <p>{{user?.confirmed ? 'Yes' : 'No'}}</p>    
                                </div>
                            </div>
                            <div class="col-6 col-md-4">
                                <div class="form-group">
                                    <label >Blocked</label>
                                    <p>{{user?.blocked ? 'Yes' : 'No'}}</p>    
                                </div>
                            </div>
                            <div class="col-6 col-md-4">
                                <div class="form-group">
                                    <label >Is Guest?</label>
                                    <p>{{user?.isGuest  ? 'Yes' : 'No'}}</p>    
                                </div>
                            </div>
                        </div>
                        <hr class="my-0 mx-3" />
                    </div>
                </div>
                <div class="mt-3 mx-1">
                    <div class="font-weight-bold mb-2">Customer Pass(es)</div>
                    <gcl-admin-user-golf-pass-table 
                        [userId]="user.id" 
                        [enableAdd]="false" 
                        [enableRemove]="false"
                    ></gcl-admin-user-golf-pass-table>
                </div>
                <div class="mt-3 mx-1">
                    <div class="font-weight-bold mb-2">Customer Orders</div>
                    <gcl-admin-order-table [userId]="user.id"></gcl-admin-order-table>
                </div>
                <div class="mt-3 mx-1">
                    <div class="font-weight-bold mb-2">Credit Balance History</div>
                    <gcl-admin-credit-balance-transaction-table [userId]="user.id"></gcl-admin-credit-balance-transaction-table>
                </div>
            </div>
        </div>
    </div>
</div>

