import { Component, OnDestroy, OnInit, QueryList, TemplateRef, ViewChildren } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, shareReplay, take, takeUntil } from 'rxjs/operators';

import { faCog, faQuestionCircle, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { Course } from 'projects/shared/src/lib/models/course';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ReportService } from 'projects/shared/src/lib/services/report.service';
import { PopoverDirective } from 'ngx-bootstrap/popover';

@Component({
  selector: 'gcl-admin-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  public userIcon = faUser;
  public usersIcon = faUsers;
  public faCog = faCog;
  public faQuestionCircle = faQuestionCircle;

  public userName$!: Observable<string>;
  public userRole$!: Observable<string>;
  public userCourses$!: Observable<Array<Course>>;
  public course$!: Observable<Course>;

  public isCollapsed: boolean = true;
  public disableCourseDropdownUrls: Array<string> = ['view', 'create', 'edit', 'checkout', 'reservation-details', 'add-reservation', 'squeeze-time', 'register-checkout'];
  public settingsRoutes = ['gift-certificates', 'orders', 'reports'];
  public isOnDashboard$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public isOnSettings$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public disableCourseDropdown$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private destroy$: Subject<boolean> = new Subject();

  modalRef!: BsModalRef;
  bsRangeValue: Date[] = [new Date(), new Date().addDays(1)];
  minDate: Date = new Date('1/1/2021');

  @ViewChildren(PopoverDirective) popovers!: QueryList<PopoverDirective>;


  constructor(private router: Router, public authService: AuthService, private modalService: BsModalService, private reportService: ReportService) {
    // For on refresh page.
    this.onRouteChange();
  }

  ngOnInit(): void {
    // For Angular Route changes
    this.onRouteChange();

    this.userName$ = this.authService.userName$;
    this.userRole$ = this.authService.viewManager$
      .pipe(
        map((viewManager: boolean) => !viewManager ? "Administrator" : "Manager"),
        shareReplay(1),
        takeUntil(this.destroy$),
      );
    this.userCourses$ = this.authService.userCourses$
    this.course$ = this.authService.course$;
  }

  onRouteChange(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe((event: Event) => {
        const ne = event as NavigationEnd;
        const routes = ne.url.split("/");

        this.disableCourseDropdown$.next(routes.some(r => {
          const parts = r.split(";"); // Filters out query params.
          return this.disableCourseDropdownUrls.includes(parts[0]);
        }));
        let currentRoute = routes[routes.length - 1];
        this.isOnDashboard$.next((currentRoute == "dashboard"));
        this.isOnSettings$.next((routes.some(r => this.settingsRoutes.includes(r))));
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  selectCourse(course: Course): void {
    this.authService.setCourse(course);
    // this.authService.viewManager$
    //   .pipe(
    //     take(1),
    //     takeUntil(this.destroy$)
    //   )
    //   .subscribe((viewManager) => {
    //     if (!viewManager) {
    //       this.router.navigateByUrl("/admin/dashboard");
    //     } else {
    //       this.router.navigateByUrl("/manager/dashboard");
    //     }
    //   })
  }

  viewManager() {
    this.authService.viewManager();
    this.router.navigateByUrl("/manager/dashboard");
  }

  viewAdmin() {
    this.authService.viewAdmin();
    this.router.navigateByUrl("/admin/dashboard");
  }

  logout() {
    this.authService.logout();
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-dialog-centered', ignoreBackdropClick: true });
  }

  public hidePopover() {
    this.popovers.forEach((popover: PopoverDirective) => {
      popover.hide();
    });  
  }

  manageBanners() {
    this.authService.viewManager$
      .pipe(
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe((viewManager) => {
        if (!viewManager) {
          this.router.navigateByUrl("/admin/banners");
        } else {
          this.router.navigateByUrl("/manager/banners");
        }
    })
  }

  manageGiftCertificatesClicked() {
    this.authService.viewManager$
      .pipe(
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe((viewManager) => {
        if (!viewManager) {
          this.router.navigateByUrl("/admin/gift-certificates");
        } else {
          this.router.navigateByUrl("/manager/gift-certificates");
      }
    })
  }

  manageOrdersClicked() {
    this.authService.viewManager$
      .pipe(
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe((viewManager) => {
        if (!viewManager) {
          this.router.navigateByUrl("/admin/orders");
        } else {
          this.router.navigateByUrl("/manager/orders");
      }
    })
  }

  generateReportsClicked() {
    this.authService.viewManager$
      .pipe(
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe((viewManager) => {
        if (!viewManager) {
          this.router.navigateByUrl("/admin/reports");
        } else {
          this.router.navigateByUrl("/manager/reports");
      }
    })
  }

  drawerCountsClicked() {
    this.authService.viewManager$
      .pipe(
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe((viewManager) => {
        if (!viewManager) {
          this.router.navigateByUrl("/admin/reports/drawer-counts");
        } else {
          this.router.navigateByUrl("/manager/reports/drawer-counts");
      }
    })
  }
}
