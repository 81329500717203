import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, forkJoin, Observable, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import { Order, OrderDetails, UpdateAdminOrder } from 'projects/shared/src/lib/models/order';
import { OrderService } from 'projects/shared/src/lib/services/order.service';
import { ConfirmationModalService } from 'projects/shared/src/lib/services/confirmation-modal.service';
import { GetNineTypesDisplayName, NineTypes } from 'projects/shared/src/lib/enumerations/nine-type';
import { getDateTime } from 'projects/shared/src/lib/utili/get-datetime';
import { Reservation } from '../../../shared/components/tee-sheet-table/tee-sheet-table.component';
import { OrderStatuses } from 'projects/shared/src/lib/enumerations/order-status';

@Component({
  selector: 'gcl-admin-reservation-details',
  templateUrl: './reservation-details.component.html',
  styleUrls: ['./reservation-details.component.scss']
})
export class ReservationDetailsComponent implements OnInit, OnDestroy {
  OrderStatuses = OrderStatuses;
  public faChevronLeft = faChevronLeft;

  public selectedDate!: Date;
  public selectedTime!: Date;
  public nineType!: string;

  private orderId$!: Observable<number>;
  public order$!: Observable<OrderDetails>;
  public reservation!: Reservation;
  public canEdit: boolean = true;
  private queryParam$!: Observable<string>;

  private destroyed$ = new Subject();

  constructor(public route: ActivatedRoute, private router: Router, private confirmationModalService: ConfirmationModalService, private orderService: OrderService) { }

  ngOnInit(): void {
    this.orderId$ = this.route.params.pipe(
      map(params => params.orderId)
    );

    this.order$ = this.orderId$.pipe(
      switchMap((orderId) => this.orderService.get(orderId)),
      takeUntil(this.destroyed$)
    );

    this.queryParam$ = this.route.params.pipe(
      map(params => params.queryParam),
      takeUntil(this.destroyed$)
    );

    combineLatest([this.queryParam$, this.order$])
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(([params, order]) => {
        if (params) {
          this.reservation = JSON.parse(params) as Reservation;

          this.selectedDate = new Date(this.reservation.selectedDate);
          let teeTime = order.tee_times[0];
          this.selectedTime = getDateTime(teeTime.datestr, teeTime.timestr);
          this.nineType = GetNineTypesDisplayName(teeTime.nine);

          this.canEdit = order.status !== OrderStatuses.Paid || this.selectedTime >= new Date();
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public goBack(): void {
    this.confirmationModalService.showConfirmationModal({
      title: 'Go Back?',
      message: 'You may have unsaved changes? This action cannot be undone.'
    }).subscribe(accept => {
      if (accept) {
        this.router.navigate(['/admin', 'tee-sheet', {
          queryParam: JSON.stringify({ selectedDate: this.selectedDate })
        }]);
      }
    });
  }

  public cancel(): void {
    this.confirmationModalService.showConfirmationModal({
      title: 'Confirm Cancellation',
      message: 'Are you sure you want to cancel this order?'
    }).subscribe(async accept => {
      if (accept) {
        let order = await this.order$.pipe(take(1)).toPromise();
        await this.orderService.cancel(order.id).pipe(take(1)).toPromise();

        this.router.navigate(['/admin', 'tee-sheet', {
          queryParam: JSON.stringify({ selectedDate: this.selectedDate })
        }]);
      }
    });
  }

  public submit(updatedOrder: Partial<UpdateAdminOrder>): void {
    this.order$.pipe(
      switchMap((order) => this.orderService.adminUpdate(order.id, updatedOrder)),
      takeUntil(this.destroyed$)
    ).subscribe((updatedOrder) => {
      this.router.navigate(["/admin", "tee-sheet", "reservation", updatedOrder.id, "checkout"]);
    });
  }

  public addSqueezeTime(orderId: number): void {
    this.queryParam$.pipe(
      takeUntil(this.destroyed$)
    )
      .subscribe((queryParam) => {
        this.router.navigate(["/admin", "tee-sheet", "reservation", orderId, "squeeze-time", {
          queryParam: queryParam
        }]);
      });
  }

}
