import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'gcl-lib-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  get email() { return this.loginForm.get('email') as FormControl; }
  get password() { return this.loginForm.get('password') as FormControl; }
  // get rememberMe() { return this.loginForm.get('rememberMe') as FormControl; }

  loginForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]],
    // rememberMe: [true]
  });

  @Output()
  onLogin = new EventEmitter();

  constructor(private fb: FormBuilder, private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
  }

  submit() {
    this.authService.login(this.email?.value, this.password?.value)
      .subscribe(result => {
        this.loginForm.setErrors({});
        this.onLogin.emit();
      }, err => {
        this.loginForm.setErrors({
          invalidPassword: true
        })
      });
  }

}
