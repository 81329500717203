import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, of, Subject } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

import { PaymentMethod } from '@stripe/stripe-js';
import { StripeInstance } from 'ngx-stripe';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { Order, OrderDetails, StrapiPaymentIntent } from 'projects/shared/src/public-api';
import { CheckoutService, CheckoutState } from '../../checkout.service';
import { StripeService } from 'projects/shared/src/lib/services/stripe.service';
import { StripePaymentToken } from 'projects/shared/src/lib/models/stripe-payment-token';
import { StripeCreateCardComponent } from 'projects/shared/src/lib/stripe/stripe-create-card/stripe-create-card.component';
import { OrderPaymentService } from 'projects/shared/src/lib/services/order-payment.service';
import { forkJoin } from 'rxjs';
import { OrderService } from 'projects/shared/src/lib/services/order.service';
import { CustomPaymentModalComponent } from '../custom-payment-modal/custom-payment-modal.component';
import * as moment from 'moment';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';

@Component({
  selector: 'gcl-admin-checkout-pay-digital',
  templateUrl: './checkout-pay-digital.component.html',
  styleUrls: ['./checkout-pay-digital.component.scss']
})
export class CheckoutPayDigitalComponent implements OnInit {
  public isCollapsed: boolean = false;

  public order$!: Observable<OrderDetails>;
  public paymentModalRef!: BsModalRef;

  public stripe!: StripeInstance;
  private newPaymentToken?: string;

  private destory$: Subject<boolean> = new Subject<boolean>();

  modalRef!: BsModalRef;
  email: string = '';

  public customPaymentModalRef!: BsModalRef<CustomPaymentModalComponent>;
  selectedPaymentMethodId?: string;
  paymentAmount: number | null = null;
  last4?: string = '';
  completedPayment: boolean = false;

  printerArr: number[] = [];

  constructor(
    private checkoutService: CheckoutService, 
    private toastrService: ToastrService, 
    private stripeService: StripeService, 
    private modalService: BsModalService,
    private orderService: OrderService,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.stripe = this.stripeService.createInstance();

    this.order$ = this.checkoutService.order$;
  }

  private createPaymentIntent(order: OrderDetails, amount: number): Observable<StrapiPaymentIntent> {
    return this.stripeService.createPaymentIntent(order.users_permissions_user?.id as number, order.id, amount)
      .pipe(
        takeUntil(this.destory$)
      );
  }

  ngOnDestroy(): void {
    this.destory$.next(true);
    this.destory$.unsubscribe();
  }

  async setPaymentMethod(method: PaymentMethod) {
    let order = await this.checkoutService.order$.pipe(take(1)).toPromise();

    this.last4 = method.card?.last4 ?? undefined;
    this.selectedPaymentMethodId = method.id;

    this.customPaymentModalRef = this.modalService.show(CustomPaymentModalComponent,
      {
        ignoreBackdropClick: true,
        initialState: {
          last4: this.last4,
          order,
        },
        class: 'modal-md modal-dialog-centered'
      }
    );

    this.customPaymentModalRef.content?.onCustomAmountSelect
    .pipe(takeUntil(this.destory$))
    .subscribe(async confirmation => {
      if(confirmation?.amount && confirmation?.amount > 0) {
        this.paymentAmount = confirmation.amount;
        this.email = confirmation.email ?? '';
        this.printerArr = confirmation.printerArr;
        await this.submit();
      }
    });
  }

  public onShowNewPayment(): void {
    this.order$
      .pipe(take(1))
      .subscribe((order) => {
        this.paymentModalRef = this.modalService.show(StripeCreateCardComponent, {
          ignoreBackdropClick: true,
          class: 'modal-lg modal-dialog-centered',
          initialState: {
            stripe: this.stripe,
            userId: order.users_permissions_user?.id
          }
        });

        this.paymentModalRef.content.cancel
          .pipe(
            takeUntil(this.destory$)
          )
          .subscribe(() => this.paymentModalRef.hide());

        this.paymentModalRef.content.submit
          .pipe(
            takeUntil(this.destory$)
          )
          .subscribe(async (payment: StripePaymentToken) => {
            if (!payment.error) {                          
              if (payment.save) {
                this.toastrService.success("Payment Added");
                this.selectedPaymentMethodId = payment.paymentMethodId ?? undefined;    
                this.stripeService.reloadPaymentList$.next(order.users_permissions_user?.id as number);
                try {
                  let method = await this.stripeService.getPaymentMethod(this.selectedPaymentMethodId ?? '').toPromise();
                  this.last4 = method?.card?.last4;
                }
                catch(err) {
                  this.last4 = '****'
                }
              } else {
                this.newPaymentToken = payment.token;
                this.last4 = payment.last4 ?? '****';
              }

              this.paymentModalRef.hide();

              this.customPaymentModalRef = this.modalService.show(CustomPaymentModalComponent,
                {
                  ignoreBackdropClick: true,
                  initialState: {
                    last4: this.last4,
                    order,
                  },
                  class: 'modal-md modal-dialog-centered'
                }
              );

              this.customPaymentModalRef.content?.onCustomAmountSelect
              .pipe(takeUntil(this.destory$))
              .subscribe(async confirmation => {
                if (confirmation.amount && confirmation.amount > 0) {
                  this.paymentAmount = confirmation.amount;
                  this.email = confirmation.email ?? '';
                  this.printerArr = confirmation.printerArr;
                  await this.submit();
                }
              });

            } else {
              this.toastrService.error(payment.error);
            }
          });
      });
  }

  public isPaymentSelectedOrCreated(): boolean {
    return (this.newPaymentToken != undefined) || (this.selectedPaymentMethodId != undefined);
  }

  public async submit() {
    if(!this.paymentAmount || (!this.selectedPaymentMethodId && !this.newPaymentToken)) {
      this.displayError('Select a payment method and enter an amount');
      this.customPaymentModalRef.hide();
      return;
    }

    let order = await this.order$.pipe(take(1)).toPromise();

    if(this.paymentAmount > order.finaltotal) {
      this.displayError('Payment amount cannot be more than balance');
      return;
    }

    if((this.paymentAmount !== order.finaltotal) && (order.finaltotal - this.paymentAmount) < 0.5) {
      this.displayError('Partial payments cannot cause order balance to go below $0.5');
      return;
    }

    let clientSecret$ = this.createPaymentIntent(order, this.paymentAmount).pipe(take(1));
    
    clientSecret$
      .pipe(
        switchMap((clientSecret) => {
          const payment = this.newPaymentToken ? { payment_method: { card: { token: this.newPaymentToken as string } } } : { payment_method: this.selectedPaymentMethodId as string };
          return forkJoin([of(order), this.stripe.confirmCardPayment(clientSecret.clientSecret, payment).pipe(take(1))]);
        }),
        takeUntil(this.destory$)
      )
      .subscribe(
        async ([order, result]) => {
          let email = order?.users_permissions_user?.email ?? this.email ?? '';
          await this.checkoutService.checkoutSucces("Payment is successful.", order.id, this.printerArr, email, true);
          this.checkoutService.onOrderChanged$.next();
          this.completedPayment = true;
          this.customPaymentModalRef.hide();
        },
        (error) => this.displayError("An error occurred processing your payment. Please try again."),
        () => {
          this.customPaymentModalRef.hide();
        }
      );
  }

  async exitCheckout(order: OrderDetails) {
    let adminPath: any[] = ['/admin', 'tee-sheet'];
    let managerPath: any[] = ['/manager', 'tee-sheet'];

    if(order?.tee_times?.[0]?.datestr) {
      let extra = {queryParam: JSON.stringify({selectedDate: moment(order?.tee_times?.[0]?.datestr).toISOString()})};
      adminPath.push(extra);
      managerPath.push(extra);
    }

    let isViewManager = this.authService.viewManager$.pipe(take(1));

    if(isViewManager) {
      this.router.navigate(adminPath);
    }
    else {
      this.router.navigate(managerPath);
    }
  }

  makeAnotherPayment(order: OrderDetails) {
    this.email = '';
    this.newPaymentToken = undefined;
    this.selectedPaymentMethodId = undefined;
    this.paymentAmount = null;
    this.completedPayment = false;
  }

  private displayError(message: string) {
    this.toastrService.error(message);
  }
}
