<div class="d-flex flex-column h-100">
    <!-- Filters -->
    <div class="d-flex justify-content-start px-3 crud-filters no-flex">
        <form [formGroup]="filters">
            <div class="form-row">
                <div class="form-group m-0 pr-4">
                    <select class="form-control" name="isCart" formControlName="isCart">
                        <option [value]="null">Has Cart</option>
                        <option [value]="false">No</option>
                        <option [value]="true">Yes</option>
                    </select>
                </div>
                <div class="form-group m-0 pr-4">
                    <select class="form-control" name="isEighteenHoles" formControlName="isEighteenHoles">
                        <option [value]="null">Select # of Holes</option>
                        <option [value]="false">9 holes</option>
                        <option [value]="true">18 holes</option>
                    </select>
                </div>
            </div>
        </form>
    </div>

    <div class="container-fluid py-2 fill-flex overflow-y-auto">
        <div class="row">
            <div class="col-12">
                <h6  class="page-header">Golf Product List</h6>
                <gcl-lib-table 
                    [totalItems]="total$ | async" 
                    [itemsPerPage]="initialPagination.itemsPerPage" 
                    [sortBy]="initialPagination.sortBy" 
                    [sortDescending]="initialPagination.sortDescending"
                    [page]="initialPagination.page"
                    [searchTxt]="initialPagination.search"
                    (onPage)="pagination$.next($event)" 
                    [tableClass]="'table table-striped table-hover'" 
                    (onCreate)="goToCreate()">
                    <thead>
                        <tr>
                            <th columnSort="name">Name</th>
                            <th columnSort="holes">Holes</th>
                            <th columnSort="cart">Cart</th>
                            <th width="25%" columnSort="notes">Notes</th>
                            <th columnSort="price">Price</th>
                            <th columnSort="accountCode">Account Code</th>
                            <th columnSort="cost">Cost</th>
                            <th width="100" class="text-center">Manage</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let product of records$ | async">
                            <td>{{product.name}}</td>
                            <td>{{product.holes}}</td>
                            <td>{{product.cart ? 'Yes' : 'No'}}</td>
                            <td>{{product.notes}}</td>
                            <td>{{product.price | currency}}</td>
                            <td>{{product.accountCode}}</td>
                            <td>{{product.cost | currency}}</td>
                            <td class="text-center">
                                <div class="btn-group btn-group-sm">
                                    <a [routerLink]="['/admin','products', product.id, 'view']" [queryParams]="getQueryParams()" class="btn btn-link btn-primary-light">View</a>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="(total$ | async) == 0">
                            <td colspan="6">No products.</td>
                        </tr>
                    </tbody>
                </gcl-lib-table>
            </div>
        </div>
    </div>
</div>
