import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { OnPageEvent, OrderDetails } from 'projects/shared/src/public-api';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { OrderService } from 'projects/shared/src/lib/services/order.service';
import { SortDirections } from 'projects/shared/src/lib/enumerations/sort-directions';
import { OrderStatuses } from 'projects/shared/src/lib/enumerations/order-status';
import { OrderQuery } from 'projects/shared/src/lib/queries/order-query';

@Component({
  selector: 'gcl-admin-order-table',
  templateUrl: './order-table.component.html',
  styleUrls: ['./order-table.component.scss']
})
export class OrderTableComponent implements OnInit, OnDestroy {
  @Input('courseId') courseId?: number;
  @Input('userId') userId?: number;
  @Input('statuses') statuses?: Array<OrderStatuses>;
  @Input('includesStatuses') includesStatuses?: boolean = true;

  pagination$ = new BehaviorSubject<OnPageEvent>({
    itemsPerPage: 10,
    page: 1,
    sortBy: 'id',
    sortDescending: true
  });

  total$: BehaviorSubject<number> = new BehaviorSubject(1);
  records$: ReplaySubject<OrderDetails[]> = new ReplaySubject(1);

  private destory$: Subject<boolean> = new Subject();

  constructor(private router: Router, private orderService: OrderService) { }

  ngOnInit(): void {
    this.pagination$
    .pipe(
      takeUntil(this.destory$)
    )
    .subscribe(() => {
      this.getPageResult();
    });
  }

  ngOnDestroy() {
    this.destory$.next(true);
    this.destory$.complete();
  }

  private getPageResult() {
    let query: OrderQuery = {
      skip: (this.pagination$.value.page - 1) * this.pagination$.value.itemsPerPage,
      take: this.pagination$.value.itemsPerPage,
      sortColumns: [{
        column: this.pagination$.value.sortBy,
        direction: this.pagination$.value.sortDescending ? SortDirections.Descending : SortDirections.Ascending,
      }],
      search: this.pagination$.value.search,
    };

    if(this.courseId) {
      query.course = this.courseId
    }

    if(this.userId) {
      query.users_permissions_user = this.userId
    }

    if(this.statuses && this.statuses.length > 0) {
      let statuses: any = {
        statuses: this.statuses
      }

      if(typeof this.includesStatuses === 'boolean') {
        statuses.isEqual = this.includesStatuses;
      }
      else {
        statuses.isEqual = true;
      }
      query.statuses = statuses;
    }

    const pagedResult = this.orderService.query(query);

    pagedResult.filteredCount$.subscribe((total) => this.total$.next(total));
    pagedResult.records$.subscribe((records) => this.records$.next(records));
  }

  public goToCreate() {
    this.router.navigate(['/admin', 'orders', 'create']);
  }

}
