import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CartEditComponent } from './cart-edit/cart-edit.component';
import { CartListComponent } from './cart-list/cart-list.component';
import { CartViewComponent } from './cart-view/cart-view.component';

const routes: Routes = [
  {
    path: ':cartId/view',
    component: CartViewComponent
  },
  {
    path: ':cartId/edit',
    component: CartEditComponent
  },
  {
    path: '', 
    component: CartListComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CartsRoutingModule { }
