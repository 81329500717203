import { ToggleSwitchComponent } from './toggle-switch/toggle-switch.component';
import { LoginComponent } from './login/login.component';
import { TableComponent } from './table/table.component';
import { EnumSelectComponent } from './enum-select/enum-select.component';
import { CounterComponent } from './counter/counter.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { RegisterationComponent } from './registeration/registeration.component';
import { TwoPaneHorizontalResizableComponent } from './two-pane-horizontal-resizable/two-pane-horizontal-resizable.component';
import { CounterButtonComponent } from './counter-button/counter-button.component';
import { TextToggleSwitchComponent } from './text-toggle-switch/text-toggle-switch.component';
import { RadioSelectListComponent } from './radio-select-list/radio-select-list.component';
import { DatePickerSliderComponent } from './date-picker-slider/date-picker-slider.component';
import { LoadingScreenComponent } from './loading-screen/loading-screen.component';
import { SearchGolfProductsComponent } from './search-golf-products/search-golf-products.component';
import { SearchGolfOrdersComponent } from './search-golf-orders/search-golf-orders.component';
import { SearchProductOrdersComponent } from './search-product-orders/search-product-orders.component';
import { SearchProductsComponent } from './search-products/search-products.component';
import { SearchRoundsComponent } from './search-rounds/search-rounds.component';
import { SearchOrdersComponent } from './search-orders/search-orders.component';
import { SearchUsersComponent } from './search-users/search-users.component';
import { TeeTimesBarChartComponent } from './tee-times-bar-chart/tee-times-bar-chart.component';
import { UserOrderSummaryComponent } from './user-order-summary/user-order-summary.component';
import { MapsComponent } from './maps/maps.component';
import { PaginationContentComponent } from './pagination-content/pagination-content.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { InputModalComponent } from './input-modal/input-modal.component';
import { StateDropdownComponent } from './state-dropdown/state-dropdown.component';
import { AddFilesModalComponent } from './add-files-modal/add-files-modal.component';
import { DropzoneComponent } from './dropzone/dropzone.component';
import { AddReaderComponent } from './add-reader/add-reader.component';
import { AddPrinterComponent } from './add-printer/add-printer.component';
import { RegisterationFormComponent } from './registeration-form/registeration-form.component';
import { GolfPassGuidlinesComponent } from './golf-pass-guidlines/golf-pass-guidlines.component';
import { PlayerFormComponent } from './player-form/player-form.component';
import { RefundModalComponent } from './refund-modal/refund-modal.component';
import { UserCreditBalanceComponent } from './user-credit-balance/user-credit-balance.component';

export let Components = [
    ToggleSwitchComponent,
    LoginComponent,
    TableComponent,
    EnumSelectComponent,
    CounterComponent,
    DatePickerComponent,
    TimePickerComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,
    UserEditComponent,
    RegisterationComponent,
    TwoPaneHorizontalResizableComponent,
    CounterButtonComponent,
    TextToggleSwitchComponent,
    RadioSelectListComponent,
    DatePickerSliderComponent,
    LoadingScreenComponent,
    SearchGolfProductsComponent,
    SearchRoundsComponent, 
    SearchGolfOrdersComponent, 
    SearchProductOrdersComponent, 
    SearchProductsComponent,
    SearchOrdersComponent,
    SearchUsersComponent,
    TeeTimesBarChartComponent,
    UserOrderSummaryComponent,
    MapsComponent,
    PaginationContentComponent,
    ConfirmationModalComponent,
    InputModalComponent,
    StateDropdownComponent,
    AddFilesModalComponent,
    DropzoneComponent,
    AddReaderComponent,
    AddPrinterComponent,
    RegisterationFormComponent,
    GolfPassGuidlinesComponent,
    PlayerFormComponent,
    RefundModalComponent,
    UserCreditBalanceComponent
]