import { Inject, Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { CONFIG_TOKEN, SharedModuleConfig } from '../shared.config';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    @Inject(CONFIG_TOKEN) private config: SharedModuleConfig
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.user$.pipe(
      map((user) => {
        console.log(user);
        let allowedRoles = route.data.roles as Array<string>;

        return allowedRoles.includes(user.role.name);
      })
    );
  }
}
