<div class="d-flex justify-content-start py-2 bg-light no-flex">
    <a class="text-light-green cursor-pointer" (click)="cancel()">
        <fa-icon [icon]="faChevronLeft" class="px-3"></fa-icon> <span>Back to Tee Sheet</span>
    </a>
</div>
<div class="container-fluid py-2">
    <div class="row">
        <div class="col-12">
            <gcl-lib-date-picker-slider
                [date]="selectedDate"
                [format]="'MMMM YYYY'"
                [readonly]="true"></gcl-lib-date-picker-slider>
            <h5  class="text-center font-weight-bold">{{selectedDate | date: "fullDate"}} at {{selectedTime | date: 'shortTime'}} - {{nineType}}</h5>
            <h6  class="page-header">Tee Sheet</h6>

            <gcl-admin-reservation-form
                [availableSlots]="reservation.availableSlots"
                [disableSingleBookingReservation]="reservation.groupReservationOnly"
                [disableGroupReservation]="reservation.singleBookingReservationOnly"
                [slots]="reservation.slots"
                [date]="selectedDate"
                (blocked)="blocked($event)"
                (submit)="submit($event)"
                (cancel)="cancel()"></gcl-admin-reservation-form>
        </div>
    </div>
</div>
