import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { environment } from 'projects/admin/src/environments/environment';
import { OrderDetails } from 'projects/shared/src/public-api';
import { GolfPassOrderDetails } from 'projects/shared/src/lib/models/golfpass-order';
import { GolfPassService } from 'projects/shared/src/lib/services/golf-pass.service';

@Component({
  selector: 'gcl-admin-view-golf-pass',
  templateUrl: './view-golf-pass.component.html',
  styleUrls: ['./view-golf-pass.component.scss']
})
export class ViewGolfPassComponent implements OnInit, OnDestroy {
  public apiUrl = environment.apiUrl;

  public order$!: Observable<OrderDetails>;
  public golfpassOrder!: GolfPassOrderDetails;
  public taxrate: number = 0;

  @Output()
  remove: EventEmitter<void> = new EventEmitter<void>();

  private destroy$: Subject<any> = new Subject();

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onRemoveItem() {
    this.remove.emit();
  }

}
