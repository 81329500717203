import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { CategoryDetails, OrderDetails, ProductOrderDetails } from 'projects/shared/src/public-api';
import { CategoryService } from 'projects/shared/src/lib/services/category.service';
import { environment } from 'projects/admin/src/environments/environment';
import { CheckoutService } from '../../checkout.service';

@Component({
  selector: 'gcl-admin-view-product',
  templateUrl: './view-product.component.html',
  styleUrls: ['./view-product.component.scss']
})
export class ViewProductComponent implements OnInit, OnDestroy {
  public apiUrl = environment.apiUrl;

  public order$!: Observable<OrderDetails>;
  public productOrder!: ProductOrderDetails;
  public category$!: Observable<CategoryDetails>;
  public quantity: number = 0;
  public taxrate: number = 0;

  public min: number = 1;

  @Output()
  setQuantity: EventEmitter<{ orderId: number, productId: number, quantity: number }> = new EventEmitter<{ orderId: number, productId: number, quantity: number }>();
  @Output()
  remove: EventEmitter<{ orderId:number, productId: number }> = new EventEmitter<{ orderId: number, productId: number }>();

  private destroy$: Subject<any> = new Subject();

  constructor(public bsModalRef: BsModalRef, private categoryService: CategoryService) { }

  ngOnInit(): void {
    this.category$ = this.categoryService.get(this.productOrder.product?.category as number);
    this.quantity = this.productOrder?.quantity || 1;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public updateQuantity(qty: number): void {
    this.quantity = qty;
  }

  public onSetQuantity(orderId: number) {
    this.setQuantity.emit({
      orderId: orderId,
      productId: this.productOrder.product.id,
      quantity: this.quantity
    });
  }

  public onRemoveProduct(orderId: number) {
    this.remove.emit({
      orderId: orderId,
      productId: this.productOrder.product.id
    });
  }
}
