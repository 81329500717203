import { Component, Input, OnInit, Output, ViewChild, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StripeCardNumberComponent, StripeInstance } from 'ngx-stripe';
import { PaymentMethod, SetupIntent, StripeError } from '@stripe/stripe-js';

import { StripeService } from '../../services/stripe.service';
import { StripePaymentToken } from '../../models/stripe-payment-token';
import { ConfirmationModalService } from '../../services/confirmation-modal.service';

@Component({
  selector: 'gcl-lib-stripe-edit-card',
  templateUrl: './stripe-edit-card.component.html',
  styleUrls: ['./stripe-edit-card.component.css']
})
export class StripeEditCardComponent implements OnInit, OnDestroy {

  @Input()
  stripe!: StripeInstance;

  @Input()
  userId!: number;

  @Input()
  paymentMethod!: PaymentMethod;

  @Output()
  cancel: EventEmitter<any> = new EventEmitter();

  @Output()
  submit: EventEmitter<StripePaymentToken> = new EventEmitter();

  @ViewChild(StripeCardNumberComponent) card!: StripeCardNumberComponent;

  public submitted: boolean = false;
  private destroy$: Subject<any> = new Subject();

  get name() { return this.paymentForm.get('name') as FormControl; }
  get addrLine1() { return this.paymentForm.get('addrLine1') as FormControl; }
  get addrLine2() { return this.paymentForm.get('addrLine2') as FormControl; }
  get addrCity() { return this.paymentForm.get('addrCity') as FormControl; }
  get addrState() { return this.paymentForm.get('addrState') as FormControl; }
  get addrZip() { return this.paymentForm.get('addrZip') as FormControl; }
  get save() { return this.paymentForm.get('save') as FormControl; }

  public paymentForm: FormGroup = this.fb.group({
    name: ['', [Validators.required]],
    addrLine1: ['', [Validators.required]],
    addrLine2: ['', []],
    addrCity: ['', [Validators.required]],
    addrState: ['', [Validators.required]],
    addrZip: ['', [Validators.required]],
    save: [false, [Validators.required]],
  });

  constructor(public bsModalRef: BsModalRef, private bsModalService: BsModalService, private fb: FormBuilder, private stripeService: StripeService, private toastrService: ToastrService, private confirmationModalService: ConfirmationModalService) { }

  ngOnInit(): void {
    this.paymentForm.patchValue({
      name: this.paymentMethod.billing_details.name,
      addrLine1: this.paymentMethod.billing_details.address?.line1,
      addrLine2: this.paymentMethod.billing_details.address?.line2,
      addrCity: this.paymentMethod.billing_details.address?.city,
      addrState: this.paymentMethod.billing_details.address?.state,
      addrZip: this.paymentMethod.billing_details.address?.postal_code,
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public confirmDeletePaymentMethod(): void {
    this.confirmationModalService.showConfirmationModal({
      title: 'Confirm Deletion',
      message: 'You are about to delete this payment method? This action cannot be undone.'
    }).subscribe(accept => {
      if (accept) {
        this.deletePaymentMethod();
      }
    });
  }


  private deletePaymentMethod(): void {
    this.stripeService.deletePaymentMethod(this.paymentMethod.id)
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(
        () => {
          this.submit.emit({
            deleted: true
          });
        },
        (error) => this.emitError(error)
      );
  }

  public onCancel(): void {
    this.cancel.emit();
  }

  public onSubmit(): void {
    if (this.paymentForm.valid) {
      this.submitted = true;
      this.createSetupToken();
    } else {
      this.emitError("Payment form is not valid.");
    }
  }

  private createSetupToken(): void {
    this.stripeService.createSetupIntent(this.userId)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((intent) => {
        this.stripe.confirmCardSetup(intent.clientSecret, {
          payment_method: {
            card: this.card.element,
            billing_details: {
              name: this.name.value,
              address: {
                line1: this.addrLine1.value,
                line2: this.addrLine2.value,
                city: this.addrCity.value,
                state: this.addrState.value,
                postal_code: this.addrZip.value,
                country: "US",
              }
            }
          },
        })
          .pipe(
            takeUntil(this.destroy$)
          ).subscribe(
            (result: {
              setupIntent?: SetupIntent;
              error?: StripeError;
            }) => {
              if (!result.error) {
                // Deletes previous payment method.
                this.stripeService.deletePaymentMethod(this.paymentMethod.id)
                  .subscribe(() => {
                    this.submit.emit({
                      paymentMethodId: result.setupIntent?.payment_method as string
                    });
                  });
              } else {
                this.emitError(result.error?.message || "An error occurred when adding the payment.");
              }
            },
            (error) => this.emitError(error));
      });
  }

  private emitError(error: string): void {
    this.toastrService.error(error);
    this.submitted = false;
  }
}
