import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountChangePasswordComponent } from 'projects/admin/src/app/account/account-change-password/account-change-password.component';
import { AccountEditComponent } from 'projects/admin/src/app/account/account-edit/account-edit.component';
import { AccountViewComponent } from 'projects/admin/src/app/account/account-view/account-view.component';

const routes: Routes = [
  {
    path: '',
    component: AccountViewComponent,
  },
  {
    path: 'change-password',
    component: AccountChangePasswordComponent,
  },
  {
    path: 'edit',
    component: AccountEditComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule { }
