import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { Cart, CartDetails } from '../models/cart';
import { CartQuery } from '../queries/cart-query';
import { PagedResult } from '../queries/paged-result';
import { CONFIG_TOKEN, SharedModuleConfig } from '../shared.config';
import { StrapiQueryableService } from './strapi-queryable.service';

declare var Qs: any;

@Injectable({
  providedIn: 'root'
})
export class CartService extends StrapiQueryableService<Cart, CartDetails, CartQuery> {

  constructor(protected httpClient: HttpClient, @Inject(CONFIG_TOKEN) protected config: SharedModuleConfig) {
    super({ path: 'carts' }, httpClient, config);
  }

  protected buildQuery(query?: CartQuery): string {
    let queryObj = super.buildBaseQueryObj(query);

    // Specific Query filters.
    if (query) {
      if (query.search) {
        queryObj._where.push({
          _or: [{
            id_contains: query.search
          }, {
            name_contains: query.search
          }]
        });
      }

      if (query.id) {
        queryObj._where.push({ id: query.id });
      }
      if (query.course) {
        queryObj._where.push({ course: query.course });
      }
      if (query.name) {
        queryObj._where.push({ name: query.name });
      }
      if (query.status) {
        queryObj._where.push({ status: query.status });
      }
      if (query.uuid) {
        queryObj._where.push({ uuid: query.uuid });
      }
      if (query.user) {
        queryObj._where.push({ user: query.user });
      }
      if (query.device) {
        queryObj._where.push({ device: query.device });
      }
    }

    return Qs.stringify(queryObj);
  }

  public query(query?: CartQuery): PagedResult<CartDetails> {
    const total$ = this.count();
    const filteredTotal$ = this.count(query);
    const queryStr = this.buildQuery(query);
    const records$ = this.httpClient.get<CartDetails[]>(`${this.config.apiUrl}/${this.pathConfig.path}?${queryStr}`)
      .pipe(
        shareReplay(1)
      );
    return new PagedResult(records$, filteredTotal$, total$);
  }

  public count(query?: CartQuery): Observable<number> {
    const queryStr = this.buildQuery(query);
    return this.httpClient.get<number>(`${this.config.apiUrl}/${this.pathConfig.path}/count?${queryStr}`)
      .pipe(
        shareReplay(1)
      );
  }
}
