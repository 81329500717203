import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { OnPageEvent, UserDetails } from 'projects/shared/src/public-api';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { map, shareReplay, switchMap, take, takeUntil } from 'rxjs/operators';
import { SortDirections } from 'projects/shared/src/lib/enumerations/sort-directions';
import { EventQuery } from 'projects/shared/src/lib/queries/event-query';
import { EventSpaceDetails } from 'projects/shared/src/lib/models/event-space';
import { EventSpaceService } from 'projects/shared/src/lib/services/event-space.service';

@Component({
  selector: 'gcl-admin-event-space-table',
  templateUrl: './event-space-table.component.html',
  styleUrls: ['./event-space-table.component.scss']
})
export class EventSpaceTableComponent implements OnInit, OnDestroy {
  @Input() enableCreate: boolean = true;
  @Input() course?: number;
  @Output() onCreate: EventEmitter<void> = new EventEmitter();

  pagination$ = new BehaviorSubject<OnPageEvent>({
    itemsPerPage: 10,
    page: 1,
    sortBy: 'id',
    sortDescending: true
  });

  total$: BehaviorSubject<number> = new BehaviorSubject(1);
  records$: ReplaySubject<EventSpaceDetails[]> = new ReplaySubject(1);

  private destory$: Subject<boolean> = new Subject();

  constructor(
    private router: Router, 
    private eventSpaceService: EventSpaceService,
  ) { }

  ngOnInit(): void {
    this.pagination$
    .pipe(
      takeUntil(this.destory$)
    )
    .subscribe(() => {
      this.getPageResult();
    });
  }

  ngOnDestroy() {
    this.destory$.next(true);
    this.destory$.complete();
  }

  private getPageResult() {
    let query: EventQuery = {
      skip: (this.pagination$.value.page - 1) * this.pagination$.value.itemsPerPage,
      take: this.pagination$.value.itemsPerPage,
      sortColumns: [{
        column: this.pagination$.value.sortBy,
        direction: this.pagination$.value.sortDescending ? SortDirections.Descending : SortDirections.Ascending,
      }],
      search: this.pagination$.value.search,
    };

    if(this.course) {
      query['course'] = this.course;
    }

    const pagedResult = this.eventSpaceService.query(query);

    pagedResult.filteredCount$.subscribe((total) => this.total$.next(total));
    pagedResult.records$.subscribe((records) => this.records$.next(records));
  }

  onCreateClick() {
    this.onCreate.emit();
  }
}
