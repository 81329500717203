import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { CONFIG_TOKEN, SharedModuleConfig } from '../shared.config';
import { GolfProductUtilization } from '../models/golf-product-utilization';
import { PopularTeeTime } from '../models/popular-tee-time';
import { TopSellers } from '../models/top-sellers';
import { Utilization } from '../models/utilization';
import { ConsolidatedReportByDateQuery } from '../queries/consolidated-report-by-date-query';
import { PagedResult } from '../queries/paged-result';
import { ConsolidatedReportByDate } from '../models/consolidated-report-by-date';
import { GratuityReportQuery } from '../queries/gratuity-report-query';
import { GratuityReportRecord } from '../models/gratuity-report-record';

import { CashDrawerEntryReportByDateQuery } from '../queries/cash-drawer-entry-report-by-date-query';
import { CashDrawerEntryReportByDate } from '../models/cash-drawer-entry';
import { AdminReportQuery } from '../queries/admin-report-query';
import { InventoryReportRecord } from '../models/inventory-report-record';
import { EventAgingReportRecord } from '../models/event-aging-report-record';
import { RoundsPlayedReportRecord } from '../models/rounds-played-report-record';
import { MemberAgingReportRecord } from '../models/member-aging-report-record';

declare var Qs: any;

interface ConsolidatedReportByDayResult {
  records: ConsolidatedReportByDate[];
  filteredCount: number;
  totalCount: number;
};

interface GratuityReportResult {
  records: GratuityReportRecord[];
  filteredCount: number;
  totalCount: number;
};

interface CashDrawerEntryReportByDayResult {
  records: CashDrawerEntryReportByDate[];
  filteredCount: number;
  totalCount: number;
};

interface InventoryReportResult {
  records: InventoryReportRecord[];
  filteredCount: number;
  totalCount: number;
};

interface EVentAginReportResult {
  records: EventAgingReportRecord[];
  filteredCount: number;
  totalCount: number;
};

interface RoundsPlayedReportResult {
  records: RoundsPlayedReportRecord[];
  filteredCount: number;
  totalCount: number;
};

interface MemberAginReportResult {
  records: MemberAgingReportRecord[];
  filteredCount: number;
  totalCount: number;
};

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(protected httpClient: HttpClient, @Inject(CONFIG_TOKEN) protected config: SharedModuleConfig) { }

  public getGolfProductUtilization(courseId?: number): Observable<Array<GolfProductUtilization>> {
    let params: HttpParams = new HttpParams();
    if (courseId) {
      params = params.set('course', courseId.toString());
    }
    return this.httpClient.get<Array<GolfProductUtilization>>(`${this.config.apiUrl}/reports/golf-product-utilization`, { params: params }).pipe(shareReplay(1));
  }

  public getTopSellers(courseId?: number): Observable<Array<TopSellers>> {
    let params: HttpParams = new HttpParams();
    if (courseId) {
      params = params.set('course', courseId.toString());
    }
    return this.httpClient.get<Array<TopSellers>>(`${this.config.apiUrl}/reports/top-sellers`, { params: params }).pipe(shareReplay(1));
  }

  public getPopularTeeTimes(courseId?: number, period?: "lastday" | "lastweek"): Observable<Array<PopularTeeTime>> {
    let params: HttpParams = new HttpParams();
    if (courseId) {
      params = params.set('course', courseId.toString());
    }
    if (period) {
      params = params.set("period", period);
    }
    return this.httpClient.get<Array<PopularTeeTime>>(`${this.config.apiUrl}/reports/popular-tee-times`, { params: params }).pipe(shareReplay(1));
  }

  public getUtilization(courseId?: number): Observable<Utilization> {
    let params: HttpParams = new HttpParams();
    if (courseId) {
      params = params.set('course', courseId.toString());
    }
    return this.httpClient.get<Utilization>(`${this.config.apiUrl}/reports/utilization`, { params: params }).pipe(shareReplay(1));
  }

  public getDailyReportPdf(courseId: number, dateRange: Date[]): Observable<Blob> {
    let params: HttpParams = new HttpParams();
    params = params.set('beginDate', dateRange[0].toLocaleDateString());
    params = params.set('endDate', dateRange[1].toLocaleDateString());

    return this.httpClient.get(`${this.config.apiUrl}/reports/daily-report/${courseId}`, { params: params, responseType: 'blob' });
  }

  public getConsolidatedReportPdf(courseId: number, beginDate: Date, endDate: Date): Observable<Blob> {
    let params: HttpParams = new HttpParams();
    params = params.set('beginDate', beginDate.toISOString());
    params = params.set('endDate', endDate.toISOString());

    return this.httpClient.get(`${this.config.apiUrl}/reports/consolidated-report/${courseId}`, { params: params, responseType: 'blob' });
  }


  public getDrawerCountReportPdf(courseId: number, beginDate: Date, endDate: Date): Observable<Blob> {
    let params: HttpParams = new HttpParams();
    params = params.set('beginDate', beginDate.toISOString());
    params = params.set('endDate', endDate.toISOString());

    return this.httpClient.get(`${this.config.apiUrl}/reports/drawer-count-report/${courseId}`, { params: params, responseType: 'blob' });
  }

  public getAdminReportPdf(courseId: number | undefined, beginDate: Date, endDate: Date, categoryId: number | undefined, accountingCode: string | undefined): Observable<Blob> {
    let params: HttpParams = new HttpParams();
    params = params.set('beginDate', beginDate.toISOString());
    params = params.set('endDate', endDate.toISOString());
    params = params.set('categoryId', categoryId ? categoryId.toString() : '');
    params = params.set('accountingCode', accountingCode? accountingCode: '');
    params = params.set('courseId', courseId? courseId.toString(): '');
    
    return this.httpClient.get(`${this.config.apiUrl}/reports/admin-reportPDF`, { params: params, responseType: 'blob' });
  }

  public getGratuityReportPdf(courseId: number, beginDate: Date, endDate: Date, skip: number, take: number, employeeId?: number): Observable<Blob> {
    let params: HttpParams = new HttpParams();
    params = params.set('beginDate', beginDate.toISOString());
    params = params.set('endDate', endDate.toISOString());
    params = params.set('courseId', courseId.toString());
    params = params.set('skip', skip.toString());
    params = params.set('take', take.toString());

    if(employeeId) {
      params = params.set('employeeId', employeeId.toString());
    }

    return this.httpClient.get(`${this.config.apiUrl}/reports/gratuity-reportPDF`, { params, responseType: 'blob'});
  }

  public getConsolidatedReportByDate(query: ConsolidatedReportByDateQuery): Observable<ConsolidatedReportByDayResult> {
    return this.httpClient.get<ConsolidatedReportByDayResult>(`${this.config.apiUrl}/reports/consolidated-report-by-day?${Qs.stringify(query)}`);
  }
  
  public getGratuityReport(query: GratuityReportQuery): Observable<GratuityReportResult> {
    return this.httpClient.get<GratuityReportResult>(`${this.config.apiUrl}/reports/gratuity-report?${Qs.stringify(query)}`);
  }

  public getCashDrawerEntryReportByDate(query: CashDrawerEntryReportByDateQuery) {
    return this.httpClient.get<CashDrawerEntryReportByDayResult>(`${this.config.apiUrl}/reports/drawer-count-report-by-day?${Qs.stringify(query)}`)
  }

  public getEmployees(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.config.apiUrl}/reports/employees`);
  }

  public getAdminReport(query: AdminReportQuery): Observable<GratuityReportResult> {
    return this.httpClient.get<GratuityReportResult>(`${this.config.apiUrl}/reports/admin-report?${Qs.stringify(query)}`);
  }
  public getCategories(query: {courseId: number, beginDate: Date, endDate: Date}): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.config.apiUrl}/reports/admin-report-categories?${Qs.stringify(query)}`);
  }

  public getInventoryCategories(query: { courseId: number }): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.config.apiUrl}/reports/admin-inventory-report-categories?${Qs.stringify(query)}`);
  }

  public getAdminInventoryReport(query: AdminReportQuery): Observable<InventoryReportResult> {
    return this.httpClient.get<InventoryReportResult>(`${this.config.apiUrl}/reports/admin-inventory-report?${Qs.stringify(query)}`);
  }
 
  public getAdminInventoryReportPdf(courseId: number | undefined, beginDate: Date, endDate: Date, categoryId: number | undefined, accountingCode: string | undefined): Observable<Blob> {
    let params: HttpParams = new HttpParams();
    params = params.set('beginDate', beginDate.toISOString());
    params = params.set('endDate', endDate.toISOString());
    params = params.set('categoryId', categoryId ? categoryId.toString() : '');
    params = params.set('accountingCode', accountingCode? accountingCode: '');
    params = params.set('courseId', courseId? courseId.toString(): '');
    
    return this.httpClient.get(`${this.config.apiUrl}/reports/admin-inventory-reportPDF`, { params: params, responseType: 'blob' });
  }


  public getEventAgingReport(query: AdminReportQuery): Observable<EVentAginReportResult> {
    return this.httpClient.get<EVentAginReportResult>(`${this.config.apiUrl}/reports/new-event-aging-report?${Qs.stringify(query)}`);
  }
  public getEventAgingReportPdf(courseId: number | undefined, beginDate: Date, endDate: Date): Observable<Blob> {
    let params: HttpParams = new HttpParams();
    params = params.set('beginDate', beginDate.toISOString());
    params = params.set('endDate', endDate.toISOString());
    params = params.set('courseId', courseId? courseId.toString(): '');
    
    return this.httpClient.get(`${this.config.apiUrl}/reports/new-event-aging-reportPDF`, { params: params, responseType: 'blob' });
  }


  public getRoundsPlayedReport(query: AdminReportQuery): Observable<RoundsPlayedReportResult> {
    return this.httpClient.get<RoundsPlayedReportResult>(`${this.config.apiUrl}/reports/rounds-played-report?${Qs.stringify(query)}`);
  }

  public getRoundsPlayedReportPdf(courseId: number | undefined, beginDate: Date, endDate: Date): Observable<Blob> {
    let params: HttpParams = new HttpParams();
    params = params.set('beginDate', beginDate.toISOString());
    params = params.set('endDate', endDate.toISOString());
    params = params.set('courseId', courseId? courseId.toString(): '');
    return this.httpClient.get(`${this.config.apiUrl}/reports/rounds-played-reportPDF`, { params: params, responseType: 'blob' });
  }

  public getMemberAgingReport(query: AdminReportQuery): Observable<MemberAginReportResult> {
    return this.httpClient.get<MemberAginReportResult>(`${this.config.apiUrl}/reports/member-aging-report?${Qs.stringify(query)}`);
  }

  public getMemberAgingReportPdf(courseId: number | undefined, beginDate: Date, endDate: Date): Observable<Blob> {
    let params: HttpParams = new HttpParams();
    params = params.set('beginDate', beginDate.toISOString());
    params = params.set('endDate', endDate.toISOString());
    params = params.set('courseId', courseId? courseId.toString(): '');
    return this.httpClient.get(`${this.config.apiUrl}/reports/member-aging-reportPDF`, { params: params, responseType: 'blob' });
  }

 
}