<div class="d-flex flex-column h-100">
    <div class="d-flex justify-content-start py-2 bg-light no-flex">
        <a class="text-light-green" [routerLink]="['/manager','products']">
            <fa-icon [icon]="faChevronLeft" class="px-3"></fa-icon> <span>Back to Golf Product List</span>
        </a>
    </div>
    
    <div class="container-fluid py-2 fill-flex overflow-y-auto">
        <div class="row"  *ngIf="product$ | async as product">
            <div class="col-12">
                <h6 class="page-header">Golf Product View</h6>
                <div class="d-flex justify-content-end py-2">
                    <a [routerLink]="['/manager','products', product.id, 'edit']" class="btn btn-light-green mx-1">Edit</a>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label >Name</label>
                                            <p>{{product.name}}</p>    
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label >Holes</label>
                                            <p>{{product.holes}}</p>    
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label >Price</label>
                                            <p>{{product.price | currency}}</p>    
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label >Cart</label>
                                            <p>{{product.cart  ? 'Yes' : 'No'}}</p>    
                                        </div>
                                    </div>
                                    <div class="col-6" *ngIf="product.reservationType">
                                        <div class="form-group">
                                            <label >Reservation Type</label>
                                            <p>{{GetGolfProductReservationTypesDisplayName(product.reservationType)}}</p>    
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label >Notes</label>
                                            <p>{{product.notes || 'N/A'}}</p>    
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label >Account Code</label>
                                            <p>{{product.accountCode | currency}}</p>    
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label >Cost</label>
                                            <p>{{product.cost}}</p>    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
