<div class="d-flex flex-column h-100" *ngIf="order$ | async as order">
    <ng-container *ngIf="!completedPayment; else remainingBalance;">
        <h5 class="font-weight-bold ml-4">Digital</h5>
        <div class="">
            <gcl-lib-stripe-payment-methods
                [selectedPaymentMethodId]="selectedPaymentMethodId"
                [userId]="order.users_permissions_user?.id"
                (select)="setPaymentMethod($event)"
                (newPayment)="onShowNewPayment()"></gcl-lib-stripe-payment-methods>
        </div>
    </ng-container>
    <ng-template #remainingBalance>
        <div class="fill-flex overflow-y-auto font-weight-bold d-flex flex-column h-100 p-4 justify-content-between align-items-center">
            <div class="w-100 text-center">
                <div class="font-size-x-large">REMAINING BALANCE</div>
                <div class="font-size-jumbo mt-3">{{order.finaltotal | currency}}</div>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center w-100">
                <div class="d-flex justify-content-between align-items-center w-50">
                    <div>Total Paid</div>
                    <div>{{this.paymentAmount | currency}}</div>
                </div>
            </div>
            <div class="w-100 d-flex flex-row justify-content-end">
                <button class="btn btn-light-green btn-wide cursor-pointer mr-2" *ngIf="order.finaltotal >= 0.5" (click)="makeAnotherPayment(order)">Make Another Payment</button>
                <button class="btn btn-light-green btn-wide cursor-pointer" (click)="exitCheckout(order)">Continue</button>
            </div>
        </div>
    </ng-template>
</div>
