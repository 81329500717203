<div>
    <h6 class="font-weight-bold">Top Sellers</h6>
    <p><small>Report based on lasy year of sales</small></p>
    <div class="" style="overflow: auto; max-height: 250px;">
        <ng-container *ngFor="let product of records$ | async">
            <div class="p-2 border-bottom border-default">
                <div class="d-flex justify-content-between font-weight-bold">
                    <small>{{product.name}}</small>
                    <span>{{product.total | currency}}</span>
                </div>
                <div class="d-flex justify-content-end text-muted">
                    <small>
                        <span>{{product.price | currency}}/ea.</span>
                        <span class="pl-2">{{product.quantity}} Units</span>
                    </small>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="(records$ | async)?.length == 0">
            <div class="p-2">
                <div class="d-flex justify-content-between">
                    <small>No Products.</small>
                </div>
            </div>
        </ng-container>
    </div>
</div>