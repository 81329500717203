import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';

import { OrdersRoutingModule } from './orders-routing.module';
import { OrderListComponent } from './order-list/order-list.component';
import { OrderViewComponent } from './order-view/order-view.component';
import { OrderEditComponent } from './order-edit/order-edit.component';
import { OrderCreateComponent } from './order-create/order-create.component';
import { SharedModule } from 'projects/shared/src/public-api';
import { CheckoutModule } from '../../shared/modules/checkout/checkout.module';
import { AdminSharedModule } from '../../shared/admin-shared.module';
import { OrderRefundComponent } from './order-refund/order-refund.component';
import { OrderRefundCreditBalanceComponent } from './order-refund-credit-balance/order-refund-credit-balance.component';


@NgModule({
  declarations: [OrderListComponent, OrderViewComponent, OrderEditComponent, OrderCreateComponent, OrderRefundComponent, OrderRefundCreditBalanceComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,

    SharedModule,
    CarouselModule.forRoot(),
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    FontAwesomeModule,

    AdminSharedModule,

    OrdersRoutingModule,    
  ]
})
export class OrdersModule { }
