import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrderCreateComponent } from './order-create/order-create.component';
import { OrderEditComponent } from './order-edit/order-edit.component';
import { OrderListComponent } from './order-list/order-list.component';
import { OrderRefundCreditBalanceComponent } from './order-refund-credit-balance/order-refund-credit-balance.component';
import { OrderRefundComponent } from './order-refund/order-refund.component';
import { OrderViewComponent } from './order-view/order-view.component';

const routes: Routes = [
  {
    path: 'create',
    component: OrderCreateComponent
  },
  {
    path: ':orderId/view',
    component: OrderViewComponent
  },
  {
    path: ':orderId/edit',
    component: OrderEditComponent
  },
  {
    path: ':orderId/refund',
    component: OrderRefundComponent

  },
  {
    path: ':orderId/refund-credit-balance',
    component: OrderRefundCreditBalanceComponent

  },
  {
    path: '',
    component: OrderListComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrdersRoutingModule { }
