import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl } from '@angular/forms';
import { BehaviorSubject, combineLatest, forkJoin, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, shareReplay, takeUntil, takeWhile } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';

import { OnPageEvent } from 'projects/shared/src/lib/models/on-page-event';
import { CartStatuses, GetCartStatusesDisplayName } from 'projects/shared/src/lib/enumerations/cart-status';
import { SortDirections } from 'projects/shared/src/lib/enumerations/sort-directions';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { CartService } from 'projects/shared/src/lib/services/cart.service';
import { CartDetails } from 'projects/shared/src/public-api';

@Component({
  selector: 'gcl-admin-cart-list',
  templateUrl: './cart-list.component.html',
  styleUrls: ['./cart-list.component.scss']
})
export class CartListComponent implements OnInit, OnDestroy {
  public getCartStatusDisplayName = GetCartStatusesDisplayName;

  get status() { return this.filters.get("status") as FormControl; }

  filters = this.fb.group({
    status: [null],
  });

  pagination$ = new BehaviorSubject<OnPageEvent>({
    itemsPerPage: 10,
    page: 1,
    sortBy: 'id',
    sortDescending: true
  });

  total$: BehaviorSubject<number> = new BehaviorSubject(1);
  records$: ReplaySubject<CartDetails[]> = new ReplaySubject(1);

  private destroyed$: Subject<boolean> = new Subject();

  constructor(private fb: FormBuilder, private cartService: CartService, private authService: AuthService, private router: Router, private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.pagination$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        this.getPageResult();
      });

    this.filters.valueChanges
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(() => this.getPageResult());
  }

  private getPageResult() {
    this.authService.course$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(course => {
      const pagedResult = this.cartService.query({
        skip: (this.pagination$.value.page - 1) * this.pagination$.value.itemsPerPage,
        take: this.pagination$.value.itemsPerPage,
        sortColumns: [{
          column: this.pagination$.value.sortBy,
          direction: this.pagination$.value.sortDescending ? SortDirections.Descending : SortDirections.Ascending,
        }],
        search: this.pagination$.value.search,

        course: course?.id,
        status: this.status?.value == "null" ? null : this.status?.value
      });

      pagedResult.filteredCount$.subscribe((total) => this.total$.next(total));
      pagedResult.records$.subscribe((records) => this.records$.next(records));
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  public clearAllCarts(): void {
    this.records$
      .pipe(
        map(records => records.filter(record => (record.status == CartStatuses.assigned || record.status == CartStatuses.reserved))),
        takeUntil(this.destroyed$)
      )
      .subscribe(records => {
        let clearCarts: Array<Observable<CartDetails>> = [];
        records.forEach(record => {
          clearCarts.push(this.getClearCartObs(record));
        });

        forkJoin(clearCarts).pipe(
          shareReplay(1),
          takeUntil(this.destroyed$)
        ).subscribe(() => {
          this.toastrService.success("All carts successfully cleared!");
          this.getPageResult();
        });
      });
  }

  private getClearCartObs(cart: CartDetails): Observable<CartDetails> {
    return this.cartService.update(cart.id, {
      name: cart.name,
      status: CartStatuses.available,
      uuid: cart.uuid,
      course: cart.course?.id,
      user: cart.user?.id,
      device: cart.device?.id,
    }).pipe(
      takeUntil(this.destroyed$)
    )
  }

  public clearCart(cart: CartDetails): void {
    this.getClearCartObs(cart)
      .subscribe(cc => {
        this.toastrService.success("Cart successfully cleared!");
        this.getPageResult();
      });
  }

  public goToCreate() {
    this.router.navigate(['/manager', 'carts', 'create']);
  }
}
