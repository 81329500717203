import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'golfProductPrice'
})
// To be used after the currency pipe, expects a dollar sign.
export class GolfProductPricePipe implements PipeTransform {

  transform(value: string | null, ...args: unknown[]): string | null {
    if(!value) {
      return null;
    }
    if(!value.includes('$')) {
      return value;
    }
    if(value.length < 7) {
      let replacement = '';
      for(let i = 0; i < (7 - value.length); i++) {
        replacement += ' ';
      }
      replacement = replacement + '$';
      value = value.replace('$', replacement);
      return value;
    }
    else {
      return value;
    }
  }

}
