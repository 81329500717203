import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, tap, takeUntil, switchMap, shareReplay } from 'rxjs/operators';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';

import { environment } from 'projects/admin/src/environments/environment';
import { CourseDetails } from 'projects/shared/src/lib/models/course';
import { CourseService } from 'projects/shared/src/lib/services/course.service';
import { MediaService } from 'projects/shared/src/lib/services/media.service';
import { ConfirmationModalService } from 'projects/shared/src/lib/services/confirmation-modal.service';
import { BsModalService } from 'ngx-bootstrap/modal';

import * as moment from 'moment-timezone';

@Component({
  selector: 'gcl-admin-course-edit',
  templateUrl: './course-edit.component.html',
  styleUrls: ['./course-edit.component.scss']
})
export class CourseEditComponent implements OnInit, OnDestroy {
  public apiUrl = environment.apiUrl;
  public faChevronLeft = faChevronLeft;

  get name() { return this.form.get("name") as FormControl }
  get full_name() { return this.form.get("full_name") as FormControl }
  get address() { return this.form.get("address") as FormControl }
  get holes() { return this.form.get("holes") as FormControl }
  get par() { return this.form.get("par") as FormControl }
  get courselength() { return this.form.get("courselength") as FormControl }
  get slope() { return this.form.get("slope") as FormControl }
  get rating() { return this.form.get("rating") as FormControl }
  get taxrate() { return this.form.get("taxrate") as FormControl }
  get dwell() { return this.form.get("dwell") as FormControl }
  get turn() { return this.form.get("turn") as FormControl }
  get startingDrawerCount() { return this.form.get("startingDrawerCount") as FormControl }
  get open_time() { return this.form.get("open_time") as FormControl }
  get close_time() { return this.form.get("close_time") as FormControl }
  get description() { return this.form.get("description") as FormControl }
  get email() { return this.form.get("email") as FormControl }
  get fromEmail() { return this.form.get("fromEmail") as FormControl }
  get mobileActive() { return this.form.get("mobileActive") as FormControl }
  get address1() { return this.form.get("address1") as FormControl }
  get address2() { return this.form.get("address2") as FormControl }
  get city() { return this.form.get("city") as FormControl }
  get state() { return this.form.get("state") as FormControl }
  get postalCode() { return this.form.get("postalCode") as FormControl }
  get timezone() { return this.form.get("timezone") as FormControl }

  public form = this.fb.group({
    name: [null, [Validators.required]],
    full_name: [null, [Validators.required]],
    address: [null, [Validators.required]],
    holes: [null, [Validators.required]],
    par: [null, [Validators.required]],
    courselength: [null, [Validators.required]],
    slope: [null, [Validators.required]],
    rating: [null, [Validators.required]],
    taxrate: [null, [Validators.required]],
    dwell: [null, [Validators.required]],
    turn: [null, [Validators.required]],
    startingDrawerCount: [null, [Validators.required]],
    open_time: [null, [Validators.required]],
    close_time: [null, [Validators.required]],
    description: [null, []],
    email: [null, [Validators.required]],
    fromEmail: [null, [Validators.required]],
    mobileActive: [false, [Validators.required]],
    address1: [null, []],
    address2: [null, []],
    city: [null, []],
    state: [null, []],
    postalCode: [null, []],
    timezone: [null, [Validators.required]],
  });

  public courseId$!: Observable<number>;
  public course$!: Observable<CourseDetails>;

  private destroyed$: Subject<boolean> = new Subject();

  public timezones: string[] = moment.tz.zonesForCountry('US');

  constructor(private fb: FormBuilder, private courseService: CourseService, private mediaService: MediaService, public route: ActivatedRoute, private router: Router, private toastr: ToastrService, private confirmationService: ConfirmationModalService, private modalService: BsModalService) { }

  ngOnInit(): void {
    this.courseId$ = this.route.params.pipe(
      map(params => params.courseId)
    );

    this.course$ = this.courseId$.pipe(
      switchMap(courseId => this.courseService.get(courseId)),
      tap(course => {
        this.form.patchValue({
          name: course?.name,
          full_name: course?.full_name,
          address: course?.address,
          holes: course?.holes,
          par: course?.par,
          courselength: course?.courselength,
          slope: course?.slope,
          rating: course?.rating,
          taxrate: course?.taxrate,
          dwell: course?.dwell,
          turn: course?.turn,
          startingDrawerCount: course?.startingDrawerCount,
          open_time: course?.open_time,
          close_time: course?.close_time,
          description: course?.description,
          customer: course?.customer,
          email: course?.email,
          fromEmail: course?.fromEmail,
          mobileActive: course?.mobileActive,
          address1: course?.address1,
          address2: course?.address2,
          city: course?.city,
          state: course?.state,
          postalCode: course?.postalCode,
          timezone: course?.timezone
        });

        if (course.logo) {
          this.setLogoImageUrl(this.apiUrl + course.logo?.url || null);
        }
      }),
      shareReplay(1),
      takeUntil(this.destroyed$)
    );
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public openCancelModal(course: CourseDetails) {
    this.confirmationService.showConfirmationModal({
      title: 'Confirm Cancel',
      message: 'Are you sure you want to discard changes and go back?'
    }).subscribe(confirm => {
      if (confirm === true) {
        this.router.navigate(['/admin', 'courses', course.id, 'view']);
      }
    })
  }

  public openSaveModal() {
    this.confirmationService.showConfirmationModal({
      title: 'Confirm Save',
      message: 'Do you want to save these changes?'
    }).subscribe(confirm => {
      if (confirm === true) {
        this.form.markAllAsTouched();
        if (this.form.invalid) {
          return;
        }
    
        this.course$.pipe(
          switchMap(course => this.courseService.update(course.id, { ...course, ...this.form.value })),
          takeUntil(this.destroyed$)
        ).subscribe((course: CourseDetails) => {
          if (this.logoImage) {
            this.mediaService.uploadLogoToCourse(course.id, this.logoImage)
              .then(() => {
                this.onSuccess(course.id);
              });
          } else {
            this.onSuccess(course.id);
          }
        }, err => {
          this.toastr.error("An error has occurred. Please try again.")
        });
      }
    })
  }
  
  private onSuccess(courseId: number): void {
    this.form.setErrors({});

    this.toastr.success("Course updated successfully!");
    this.router.navigate(["/admin", "courses", courseId, "view"]);
  }

  public logoImage!: File;
  public onFileChange(event: Event) {
    const target = event.target as HTMLInputElement;

    if (target.files && target.files.length) {
      const image = target.files[0];
      this.logoImage = image;

      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (_event) => {
        this.setLogoImageUrl(reader.result);
      };
    }
  }

  public logoImageUrl!: string | ArrayBuffer;
  private setLogoImageUrl(url: string | ArrayBuffer | null): void {
    if (url) {
      this.logoImageUrl = url;
    }
  }

}
