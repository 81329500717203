import { Component, Input, OnInit, Output, ViewChild, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import { SetupIntent, StripeError } from '@stripe/stripe-js';
import { StripeCardNumberComponent, StripeInstance } from 'ngx-stripe';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { StripePaymentToken } from '../../models/stripe-payment-token';
import { StripeService } from '../../services/stripe.service';

@Component({
  selector: 'gcl-lib-stripe-create-card',
  templateUrl: './stripe-create-card.component.html',
  styleUrls: ['./stripe-create-card.component.css']
})
export class StripeCreateCardComponent implements OnInit, OnDestroy {

  @Input()
  stripe!: StripeInstance;

  @Input()
  userId!: number;

  @Input()
  forceSave: boolean = false;

  @Output()
  cancel: EventEmitter<any> = new EventEmitter();

  @Output()
  submit: EventEmitter<StripePaymentToken> = new EventEmitter();

  @ViewChild(StripeCardNumberComponent) card!: StripeCardNumberComponent;

  public submitted: boolean = false;
  private destroy$: Subject<any> = new Subject();

  get name() { return this.paymentForm.get('name') as FormControl; }
  get addrLine1() { return this.paymentForm.get('addrLine1') as FormControl; }
  get addrLine2() { return this.paymentForm.get('addrLine2') as FormControl; }
  get addrCity() { return this.paymentForm.get('addrCity') as FormControl; }
  get addrState() { return this.paymentForm.get('addrState') as FormControl; }
  get addrZip() { return this.paymentForm.get('addrZip') as FormControl; }
  get save() { return this.paymentForm.get('save') as FormControl; }

  public paymentForm: FormGroup = this.fb.group({
    name: ['', [Validators.required]],
    addrLine1: ['', [Validators.required]],
    addrLine2: ['', []],
    addrCity: ['', [Validators.required]],
    addrState: ['', [Validators.required]],
    addrZip: ['', [Validators.required]],
    save: [false, [Validators.required]],
  });

  constructor(public bsModalRef: BsModalRef, private fb: FormBuilder, private stripeService: StripeService) { }

  ngOnInit(): void {
    if (this.forceSave) {
      this.paymentForm.patchValue({
        save: true
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onCancel(): void {
    this.cancel.emit();
  }

  public onSubmit(): void {
    if (this.paymentForm.valid) {
      this.submitted = true;

      if (this.save.value) {
        this.createSetupToken();
      } else {
        this.createPaymentToken();
      }
    } else {
      this.emitError("Payment form is not valid.");
    }
  }

  private createSetupToken(): void {
    this.stripeService.createSetupIntent(this.userId)
      .pipe(
        switchMap((intent) => this.stripe.confirmCardSetup(intent.clientSecret, {
          payment_method: {
            card: this.card.element,
            billing_details: {
              name: this.name.value,
              address: {
                line1: this.addrLine1.value,
                line2: this.addrLine2.value,
                city: this.addrCity.value,
                state: this.addrState.value,
                postal_code: this.addrZip.value,
                country: "US",
              }
            }
          },
        })),
        takeUntil(this.destroy$)
      ).subscribe(
        (result: {
          setupIntent?: SetupIntent;
          error?: StripeError;
        }) => {
          if (!result.error) {
            this.submit.emit({
              paymentMethodId: result.setupIntent?.payment_method as string,
              save: this.save.value
            });
          } else {
            this.emitError(result.error?.message || "An error occurred when adding the payment.");
          }
        },
        (error) => this.emitError(error)
      );
  }

  private createPaymentToken(): void {
    this.stripe.createToken(this.card.element, {
      name: this.name.value,
      address_line1: this.addrLine1.value,
      address_line2: this.addrLine2.value,
      address_city: this.addrCity.value,
      address_state: this.addrState.value,
      address_zip: this.addrZip.value,
      address_country: "US",
    })
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(
        (paymentToken) => {
          if (!paymentToken.error) {
            this.submit.emit({
              token: paymentToken.token?.id as string,
              last4: paymentToken.token?.card?.last4
            });
          } else {
            this.emitError(paymentToken?.error?.message || "An error occurred when adding the payment.");
          }
        },
        (error) => this.emitError(error));
  }

  private emitError(error: string): void {
    this.submit.emit({
      error: error
    });
    this.submitted = false;
  }
}
