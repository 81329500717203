import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, forkJoin } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import { NewAdminOrder, Order } from 'projects/shared/src/lib/models/order';
import { OrderService } from 'projects/shared/src/lib/services/order.service';
import { ConfirmationModalService } from 'projects/shared/src/lib/services/confirmation-modal.service';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { OrderStatuses } from 'projects/shared/src/lib/enumerations/order-status';
import { GetNineTypesDisplayName, NineTypes } from 'projects/shared/src/lib/enumerations/nine-type';
import { getDateTime } from 'projects/shared/src/lib/utili/get-datetime';
import { ToastrService } from 'ngx-toastr';
import { Reservation } from '../../../shared/components/tee-sheet-table/tee-sheet-table.component';
import { BlockedReason } from '../../../shared/models/blocked-reason';

@Component({
  selector: 'gcl-admin-add-reservation',
  templateUrl: './add-reservation.component.html',
  styleUrls: ['./add-reservation.component.scss']
})
export class AddReservationComponent implements OnInit, OnDestroy {
  public faChevronLeft = faChevronLeft;

  public selectedDate!: Date;
  public selectedTime!: Date;
  public nineType!: string;
  public reservation!: Reservation;

  private destroyed$ = new Subject();

  constructor(private authService: AuthService, public route: ActivatedRoute, private router: Router, private confirmationModalService: ConfirmationModalService, private orderService: OrderService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        const queryParam = params.queryParam;
        if (queryParam) {
          this.reservation = JSON.parse(queryParam) as Reservation;

          this.selectedDate = new Date(this.reservation.selectedDate);
          const timeStr = Object.keys(this.reservation.selectedTimes)[0];
          this.selectedTime = new Date(timeStr);
          this.nineType = GetNineTypesDisplayName(this.reservation.nineType);
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public cancel(): void {
    this.confirmationModalService.showConfirmationModal({
      title: 'Confirm Cancellation',
      message: 'You may have unsaved changes? This action cannot be undone.'
    }).subscribe(accept => {
      if (accept) {
        this.router.navigate(['/admin', 'tee-sheet', {
          queryParam: JSON.stringify({ selectedDate: this.selectedDate })
        }]);
      }
    });
  }

  public blocked(blockedReason: BlockedReason): void {
    const times = Object.keys(this.reservation.selectedTimes);
    const endtimestr = times.length > 1 ? new Date(times[times.length - 1]) : undefined;
    
    this.authService.course$
      .pipe(
        take(1),
        switchMap(course => this.orderService.adminBlock({
          course: course.id,
          datestr: this.selectedDate.toISODate(),
          timestr: this.selectedTime.getTimeString(false),
          endtimestr: endtimestr?.getTimeString(false),
          nine: this.reservation.nineType,
          blockReason: blockedReason.reason ?? '',
          event: blockedReason.event,
          blockPlayerCount: this.reservation.slots + this.reservation.blockedSlots
        })),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        this.toastr.success("Successfully blocked time.");
        this.router.navigate(["/admin", "tee-sheet"]);
      });
  }

  public submit(newOrder: Partial<NewAdminOrder>): void {
    this.authService.course$
      .pipe(
        take(1),
        takeUntil(this.destroyed$)
      )
      .subscribe((course) => {
        this.submitOrders(course.id, newOrder)
          .pipe(
            takeUntil(this.destroyed$)
          )
          .subscribe((order: Order) => {
            this.router.navigate(["/admin", "tee-sheet", "reservation", order.id, "checkout"]);
          });
      })
  }

  private submitOrders(courseId: number, newOrder: Partial<NewAdminOrder>): Observable<Order> {
    const times = Object.keys(this.reservation.selectedTimes);

    const startTime = times.shift() as string;
    const start = new Date(startTime);

    let order = Object.assign({
      status: OrderStatuses.Submitted,
      course: courseId,
      datestr: start.toISODate(),
      timestr: start.getTimeString(false),
      nine: this.reservation.nineType
    }, newOrder);

    const endTime = times.pop();
    if (endTime != undefined) {
      const end = new Date(endTime);
      order.endtimestr = end.getTimeString(false);
    }

    return this.orderService.adminSubmit(order);
  }

}
