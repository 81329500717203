import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';

import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { environment } from 'projects/admin/src/environments/environment';
import { OnPageEvent } from 'projects/shared/src/lib/models/on-page-event';
import { ScheduleService } from 'projects/shared/src/lib/services/schedule.service';
import { ScheduleDetails } from 'projects/shared/src/lib/models/schedule';
import { SortDirections } from 'projects/shared/src/lib/enumerations/sort-directions';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { CategoryDetails } from 'projects/shared/src/public-api';

@Component({
  selector: 'gcl-admin-schedule-list',
  templateUrl: './schedule-list.component.html',
  styleUrls: ['./schedule-list.component.scss']
})
export class ScheduleListComponent implements OnInit, OnDestroy {
  public apiUrl = environment.apiUrl;

  get categoryId() { return this.filters.value.categoryId; }

  filters = this.fb.group({
    categoryId: [null]
  });

  pagination$ = new BehaviorSubject<OnPageEvent>({
    itemsPerPage: 10,
    page: 1,
    sortBy: 'id',
    sortDescending: true
  });

  total$: BehaviorSubject<number> = new BehaviorSubject(1);
  records$: ReplaySubject<ScheduleDetails[]> = new ReplaySubject(1);
  categories$!: Observable<CategoryDetails[]>;

  private destroy$: Subject<any> = new Subject();

  constructor(private fb: FormBuilder, private authService: AuthService, private scheduleService: ScheduleService, private router: Router) { }

  ngOnInit(): void {
    this.pagination$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.getPageResult();
      });

    this.filters.valueChanges
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.getPageResult());
  }

  private getPageResult() {
    this.authService.course$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(course => {
      const pagedResult = this.scheduleService.query({
        skip: (this.pagination$.value.page - 1) * this.pagination$.value.itemsPerPage,
        take: this.pagination$.value.itemsPerPage,
        sortColumns: [{
          column: this.pagination$.value.sortBy,
          direction: this.pagination$.value.sortDescending ? SortDirections.Descending : SortDirections.Ascending,
        }],
        search: this.pagination$.value.search,

        course: course?.id
      });

      pagedResult.filteredCount$.subscribe((total) => this.total$.next(total));
      pagedResult.records$.subscribe((records) => this.records$.next(records));
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  public goToCreate() {
    this.router.navigate(['/manager', 'schedules', 'create']);
  }
}
