<div class="d-flex flex-column h-100" *ngIf="order$ | async as order">
    <nav class="navbar navbar-expand-md navbar-light py-md-0 px-md-0" style="flex: 0 0 auto;">
        <button class="navbar-toggler ml-auto" type="button" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation" data-bs-toggle="collapse"  data-bs-target="#navbarNavDropdown" 
            (click)="isCollapsed = !isCollapsed">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavDropdown" [collapse]="isCollapsed">
            <div class="navbar-nav mr-auto h-100" style="cursor: pointer;">
                <a class="nav-item border-right nav-link text-dark" *ngFor="let tab of parentCategoryTabs" 
                    [ngClass]="{'active font-weight-bold': selectedParentTab === tab.index}"
                    (click)="parentCategoryClicked(tab)">
                    {{tab.label}}
                </a>
            </div>
        </div>
    </nav>
    <ng-container *ngIf="selectedParentTab == -1">
        <div style="flex: 1; overflow-y: auto;">
            <div class="p-4 product-grid">
                <div class="text-danger" *ngIf="!order.users_permissions_user">A customer must be selected before a golf pass can be purchased.</div>
                <div 
                    class="p-4 product-select d-flex justify-content-between" 
                    [class.disabled-golf-pass]="!order.users_permissions_user"
                    *ngFor="let golfpass of golfpasses$ | async"
                    (click)="addGolfPass(order, golfpass)">
                    <h6 class="m-0 font-weight-bold">{{golfpass.name | uppercase}}</h6>
                    <h6 class="m-0 font-weight-bold">{{golfpass.price | currency}}</h6>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="selectedParentTab != -1 && (categoryProducts$ | async) as categoryProducts">
        <div *ngIf="categoryProducts.length > 0 && categoryProducts[selectedParentTab] && categoryProducts[selectedParentTab].length > 0" style="flex: 1; overflow-y: auto;">
            <div class="p-4 product-grid">
                <div 
                    class="p-4 product-select d-flex justify-content-between align-items-center" 
                    *ngFor="let product of categoryProducts[selectedParentTab]"
                    (click)="addProduct(order, product)">
                    <h6 class="m-0 font-weight-bold">{{product.name | uppercase}}</h6>
                    <h6 class="m-0 font-weight-bold">{{product.price | currency}}</h6>
                </div>
            </div>

        </div>
    </ng-container>

    <ng-container *ngIf="childCategoryTabs && childCategoryTabs.length > 0 && (categoryProductsById$ | async) as categoryProductsById">
        <nav class="navbar child-navbar navbar-expand-md navbar-light py-md-0 px-md-0" style="flex: 0 0 auto;">
            <button class="navbar-toggler ml-auto" type="button" aria-controls="navbarChildNavDropdown" aria-expanded="false" aria-label="Toggle navigation" data-bs-toggle="collapse"  data-bs-target="#navbarChildNavDropdown" 
            (click)="isChildCollapsed = !isChildCollapsed">
            <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarChildNavDropdown" [collapse]="isChildCollapsed">
                <div class="navbar-nav mr-auto h-100" style="cursor: pointer;">
                    <a class="nav-item border-right nav-link text-dark" *ngFor="let tab of childCategoryTabs" 
                        [ngClass]="{'active font-weight-bold': selectedChildCategory === tab.categoryId}"
                        (click)="childCategoryClicked(tab)">
                        {{tab.label}}
                    </a>
                </div>
            </div>
        </nav>
        <div style="flex: 1; overflow-y: auto;" *ngIf="categoryProductsById[selectedChildCategory]">
            <div class="p-4 product-grid">
                <div 
                    class="p-4 product-select d-flex justify-content-between align-items-center" 
                    *ngFor="let product of categoryProductsById[selectedChildCategory]"
                    (click)="addProduct(order, product)">
                    <h6 class="m-0 font-weight-bold">{{product.name | uppercase}}</h6>
                    <h6 class="m-0 font-weight-bold">{{product.price | currency}}</h6>
                </div>
            </div>
        </div>
    </ng-container>
</div>
