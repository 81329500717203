import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from 'projects/shared/src/public-api';
import { ToastrModule } from 'ngx-toastr';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ModalModule } from 'ngx-bootstrap/modal';

import { CartsRoutingModule } from './carts-routing.module';
import { CartListComponent } from './cart-list/cart-list.component';
import { CartViewComponent } from './cart-view/cart-view.component';
import { CartEditComponent } from './cart-edit/cart-edit.component';

@NgModule({
  declarations: [CartListComponent, CartViewComponent, CartEditComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,

    SharedModule,
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    FontAwesomeModule,

    CartsRoutingModule,
  ]
})
export class CartsModule { }
