import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, combineLatest, BehaviorSubject } from 'rxjs';
import { map, tap, shareReplay, takeUntil } from 'rxjs/operators';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';

import { environment } from 'projects/admin/src/environments/environment';
import { GolfProductDetails } from 'projects/shared/src/lib/models/golfproduct';
import { GolfProductService } from 'projects/shared/src/lib/services/golf-product.service';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { ProductFormComponent } from '../../../shared/components/product-form/product-form.component';

@Component({
  selector: 'gcl-admin-product-create',
  templateUrl: './product-create.component.html',
  styleUrls: ['./product-create.component.scss']
})
export class ProductCreateComponent implements OnInit, OnDestroy {
  @ViewChild(ProductFormComponent) productFormComponent!: ProductFormComponent;

  public apiUrl = environment.apiUrl;
  public faChevronLeft = faChevronLeft;
  public disableSubmit$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public productId$!: Observable<number>;
  public product$!: Observable<GolfProductDetails>;

  private destroyed$ = new Subject();

  constructor(private golfProductService: GolfProductService, private toastr: ToastrService, private authService: AuthService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.productId$ = this.route.params.pipe(
      map(params => params.productId)
    );

    combineLatest([this.productId$, this.authService.course$])
      .pipe(
        takeUntil(this.destroyed$)
      ).subscribe(([productId, course]) => {
        this.productFormComponent.form.patchValue({
          course: course.id
        });

        if (productId) {
          this.product$ = this.golfProductService.get(productId)
            .pipe(
              tap(product => {
                this.productFormComponent.form.patchValue({
                  name: product.name,
                  isEightteenHoles: product.holes == 18 ? true : false,
                  cart: product.cart,
                  price: product.price,
                  notes: product.notes,
                  accountCode: product.accountCode,
                  cost: product.cost,
                  reservationType: product.reservationType,
                  reservation_types: product.reservation_types ? product.reservation_types.map(r => r.id) : []
                });
              }),
              shareReplay(1)
            );
        }
      });
  }

  ngAfterViewInit(): void {
    this.productFormComponent.form.valueChanges
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(() => this.disableSubmit$.next(this.productFormComponent.form.invalid));
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public submit(): void {
    this.productFormComponent.form.markAllAsTouched();
    if (this.productFormComponent.form.invalid) {
      return;
    }

    this.golfProductService.create({
      course: this.productFormComponent.course?.value,
      name: this.productFormComponent.name?.value,
      holes: (this.productFormComponent.isEightteenHoles?.value ? 18 : 9),
      cart: this.productFormComponent.cart?.value,
      price: this.productFormComponent.price?.value,
      notes: this.productFormComponent.notes?.value,
      accountCode: this.productFormComponent.accountCode?.value,
      cost: this.productFormComponent.cost?.value,
      reservation_types: this.productFormComponent.reservation_types?.value,
    })
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe((product: GolfProductDetails) => {
        this.productFormComponent.form.setErrors({});

        this.toastr.success("Product created successfully!");
        this.router.navigate(["/manager", "products", product.id, "view"]);
      }, err => {
        this.toastr.error("An error has occurred. Please try again.")
      });
  }
}
