<div class="p-4 bg-grey">
    <div class="d-flex top-filter">
        <div class="mr-4">
            <div class="mb-1 font-weight-bold">Report Type</div>
            <select [(ngModel)]="reportOutputType" (change)="onReportTypeChange()" name="reportOutputType"
                class="report-type-select form-control">
                <option [value]="ReportOutputType.InventoryReport">Inventory On Hand</option>
                <option [value]="ReportOutputType.SalesTaxReport" *ngIf="authService.isAdministratorRole$ | async">Sales Tax Report</option>
                <option [value]="ReportOutputType.EventAgingReport">New Event Aging</option>
                <option [value]="ReportOutputType.RoundsPlayedReport">Rounds Played</option>
                <option [value]="ReportOutputType.MemberAgingReport">Member Aging</option>
                <option [value]="ReportOutputType.CertificatesAgingReport">Certificates Aging</option>
                <option [value]="ReportOutputType.TipAgingReport">Tip & Gratuity Aging</option>
                <option [value]="ReportOutputType.Consolidated">Consolidated</option>
                <option [value]="ReportOutputType.EodDrawerCount">EOD Drawer Count</option>
            </select>
        </div>
        <div class="mr-4" *ngIf="accountingFilterRequiredReports.includes(reportOutputType)">
            <div class="mb-1 font-weight-bold">Accounting Code</div>
            <input type="text" class="form-control" name="name" [formControl]="accountingCode" placeholder="XXXX" />
        </div>
        <!-- <div class="mr-4">
            <div class="mb-1 font-weight-bold">Golf Course</div>
            <select class="report-type-select form-control" (change)="employeeChanged($event)">
                <option [value]="null">Select...</option>
                <option [value]="c.id" *ngFor="let c of course$ | async">{{c?.name}}</option>
            </select>
        </div> -->
        <ng-container *ngIf="dateFilterRequiredReports.includes(reportOutputType)">
            <div class="mr-4" *ngIf="beginDate$ | async as beginDate">
                <div class="mb-1 font-weight-bold">Start Date</div>
                <gcl-lib-date-picker class="mr-4" [placeholder]="'Start Date'" [date]="beginDate"
                    (selectDate)="beginDateChanged($event)"></gcl-lib-date-picker>
            </div>
            <div *ngIf="endDate$ | async as endDate">
                <div class="mb-1 font-weight-bold">End Date</div>
                <gcl-lib-date-picker [placeholder]="'End Date'" [date]="endDate"
                    (selectDate)="endDateChanged($event)"></gcl-lib-date-picker>
            </div>
        </ng-container>

        <!-- <div class="mr-4">
            <div class="mb-1 font-weight-bold">Product SKU</div>
            <input type="text" class="form-control" name="name" [(ngModel)]="productSKU" (change)="productSKUChange($event)" />
        </div> -->


        <!-- <div class="mr-4">
            <div class="mb-1 font-weight-bold">Course Groups</div>
            <select class="report-type-select form-control" (change)="employeeChanged($event)">
                <option [value]="null">All</option>
                <option [value]="c.id" *ngFor="let c of categories$ | async">{{c?.name}}</option>
            </select>
        </div> -->

        <div class="mr-4 ml-auto">
            <button class="btn btn-outline-light-green btn-sm ml-2 mt-4" type="button" (click)="generateReport()">
                Download PDF
            </button>
            <button class="btn btn-outline-light-green btn-sm ml-2 mt-4" type="button" (click)="exportCSV()">
                Download CSV
            </button>
        </div>

    </div>
    <div class="mr-4" *ngIf="categoryFilterRequiredReports.includes(reportOutputType)">
        <div class="d-flex justify-content-start py-2">
            <button class="btn px-5"
                [ngClass]="selectAllCourseGroup ? 'btn-light-green' : 'btn-outline-light-green group-btn'"
                (click)="selectCourseGroup()">All</button>
            <button class="category-btn btn px-5" *ngFor="let c of categories$ | async"
                [ngClass]="c?.selected ? 'btn-light-green' : 'btn-outline-light-green group-btn'"
                (click)="selectCourseGroup(c)" [disabled]="!c?.totalOrders">{{c?.name}}</button>
        </div>
    </div>
    <!--
    <ng-container *ngIf="reportOutputType === ReportOutputType.Consolidated">
        <hr/>
        <div class="mr-4">
            <div class="mb-1 font-weight-bold">Employee</div>
            <select class="report-type-select form-control" (change)="employeeChanged($event)">
                <option [value]="null">Select...</option>
                <option [value]="user.id" *ngFor="let user of users$ | async">{{user?.firstName}} {{user?.lastName}}</option>
            </select>
        </div>
    </ng-container> -->
</div>
<div class="container-fluid py-2 fill-flex">
    <div class="row">
        <div class="col-12">

            <h6 class="page-header">Report Outputs</h6>
            <gcl-lib-table [totalItems]="total$ | async" [sortBy]="'id'" (onPage)="pagination$.next($event)"
                [tableClass]="'table table-striped table-hover'" [enableCreate]="enableCreate" [showSearch]="false"
                [style.display]="reportOutputType === ReportOutputType.SalesTaxReport && (authService.isAdministratorRole$ | async)? 'block' : 'none'">
                <thead>
                    <tr>
                        <th columnSort="categoryName">GROUP</th>
                        <th columnSort="accountCode">Accounting Code</th>
                        <th columnSort="pdescription">DESCRIPTION</th>
                        <th columnSort="quantity">QTY</th>
                        <th>REVENUE</th>
                        <th columnSort="tax">TAX</th>
                        <th columnSort="taxrate">TAX RATE</th>
                        <th>NTS QTY</th>
                        <th>NTS REVENUE</th>
                        <th>TOTAL REVENUE</th>
                        <!-- <th width="200" class="text-center"></th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let record of records$ | async">
                        <td>{{record.categoryName}}</td>
                        <td>{{record.accountCode}}</td>
                        <td>{{record.pdescription}}</td>
                        <td>{{record.quantity }}</td>
                        <td>{{(record.unitprice - record.cost)*record.quantity | currency}}</td>
                        <td>{{record.tax | currency}}</td>
                        <td>{{record.taxrate? record.taxrate+'%' : '' }}</td>
                        <td>{{record.discountsTotal | currency}}</td>
                        <td>{{record.discountsTotal | currency}}</td>
                        <td>{{(((record.unitprice - record.cost)*record.quantity) - record.tax) | currency}}</td>
                        <!-- <td class="text-center">
                        <div class="btn-group btn-group-sm">PDF  -->
                        <!-- <a class="btn btn-link btn-primary-light" (click)="generateReportForRow(record)">GENERATE PDF</a> -->
                        <!-- </div>
                        <div class="btn-group btn-group-sm"> CSV</div> -->
                        <!-- </td> -->
                    </tr>
                    <tr *ngIf="(total$ | async) == 0">
                        <td colspan="10">No report data found.</td>
                    </tr>
                </tbody>
            </gcl-lib-table>

            <!-- <gcl-lib-table
                [totalItems]="gratuityTotal$ | async"
                [sortBy]="'id'"
                (onPage)="gratuityPagination$.next($event)"
                [tableClass]="'table table-striped table-hover'"
                [enableCreate]="enableCreate"
                [createLabel]="'generate pdf'"
                (onCreate)="generateGratuityReport()"
                [showSearch]="false"
                [style.display]="reportOutputType === ReportOutputType.SalesTaxReport ? 'block' : 'none'"
            >
            <thead>
                <tr>
                    <th columnSort="date">TIMESTAMP</th>
                    <th columnSort="firstName">FIRST NAME</th>
                    <th columnSort="lastName">LAST NAME</th>
                    <th columnSort="amount">TIP AMOUNT</th>
                    <th columnSort="orderId">ORDER NUMBER</th>
                    <th width="200" class="text-center">MANAGE</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let record of gratuityRecords$ | async">
                    <td>{{record.categoryName}}</td>
                    <td>{{record.firstName ? record.firstName : 'N/A'}}</td>
                    <td>{{record.lastName ? record.lastName : 'N/A'}}</td>
                    <td>{{record.amount | currency}}</td>
                    <td>{{record.orderId}}</td>
                    <td class="text-center">
                        <div class="btn-group btn-group-sm">
                            <a class="btn btn-link btn-primary-light">VIEW</a>
                        </div>
                    </td>
                </tr>
                <tr *ngIf="(gratuityTotal$ | async) == 0">
                    <td colspan="10">No gratuity report data found.</td>
                </tr>
            </tbody>
            </gcl-lib-table>             -->

            <!-- <gcl-lib-table [totalItems]="total$ | async" [sortBy]="'id'" (onPage)="pagination$.next($event)"
            [tableClass]="'table table-striped table-hover'" [enableCreate]="enableCreate" [showSearch]="false"
            [style.display]="reportOutputType === ReportOutputType.Consolidated ? 'block' : 'none'"> -->

            <gcl-lib-table [totalItems]="consolidatedTotal$ | async" [sortBy]="'id'"
                (onPage)="consolidatedPagination$.next($event)" [tableClass]="'table table-striped table-hover'"
                [enableCreate]="enableCreate" [createLabel]="'generate pdf'" (onCreate)="generateReport()"
                [showSearch]="false"
                [style.display]="reportOutputType === ReportOutputType.Consolidated ? 'block' : 'none'">
                <thead>
                    <tr>
                        <th columnSort="date">TIMESTAMP</th>
                        <th columnSort="debitsTotal">DEBITS TOTAL</th>
                        <th columnSort="creditsTotal">CREDITS TOTAL</th>
                        <th columnSort="cashDepositsTotal">CASH DEPOSITS</th>
                        <th columnSort="creditCardDepositsTotal">CREDIT CARD DEPOSITS</th>
                        <th columnSort="refundsTotal">REFUNDS</th>
                        <!-- <th columnSort="prepaids">PREPAIDS</th> -->
                        <!-- <th columnSort="voids">VOIDS</th> -->
                        <!-- <th columnSort="returns">RETURNS</th> -->

                        <th columnSort="discountsTotal">DISCOUNTS</th>
                        <!-- <th columnSort="comps">COMPS</th> -->
                        <th width="200" class="text-center">MANAGE</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let record of consolidatedRecords$ | async">
                        <td>{{record.date | date: 'mediumDate'}}</td>
                        <td>{{record.debitsTotal | currency}}</td>
                        <td>{{record.creditsTotal | currency}}</td>
                        <td>{{record.cashDepositsTotal | currency}}</td>
                        <td>{{record.creditCardDepositsTotal | currency}}</td>
                        <td>{{record.refundsTotal | currency}}</td>
                        <td>{{record.discountsTotal | currency}}</td>
                        <!-- <td>voids</td>
                    <td>returns</td>
                    <td>discounts</td>
                    <td>comps</td> -->
                        <td class="text-center">
                            <div class="btn-group btn-group-sm">
                                <a class="btn btn-link btn-primary-light"
                                    (click)="generateReportForRow(record)">GENERATE PDF</a>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="(consolidatedTotal$ | async) == 0">
                        <td colspan="10">No consolidated report data found.</td>
                    </tr>
                </tbody>
            </gcl-lib-table>

            <gcl-lib-table [sortBy]="'id'" [totalItems]="cashDrawerEntryTotal$ | async"
                (onPage)="cashDrawerEntryPagination$.next($event)" [tableClass]="'table table-striped table-hover'"
                [enableCreate]="enableCreate && nonEmptyResults" [createLabel]="'generate pdf'"
                (onCreate)="generateDrawerCountReport()" [showSearch]="false" searchPlaceholder="Search Report Outputs"
                [style.display]="reportOutputType === ReportOutputType.EodDrawerCount ? 'block' : 'none'">
                <thead>
                    <tr>
                        <th columnSort="timestamp">TIMESTAMP</th>
                        <th columnSort="course">COURSE</th>
                        <th columnSort="cashTotal">CASH TOTAL</th>
                        <th columnSort="startingCashAmount">STARTING CASH AMOUNT</th>
                        <th columnSort="depositTotal">DEPOSIT TOTAL</th>
                        <th columnSort="modifiedBy">MODIFIED BY</th>
                        <th width="100" class="text-center">MANAGE</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let drawerCount of cashDrawerEntryRecords$ | async">
                        <td>{{drawerCount.timestamp | date: 'mediumDate'}}</td>
                        <td>{{drawerCount.course}}</td>
                        <td>{{drawerCount.cashTotal | currency}}</td>
                        <td>{{drawerCount.startingCashAmount | currency}}</td>
                        <td>{{drawerCount.depositTotal | currency}}</td>
                        <td>{{drawerCount.modifiedBy}}</td>
                        <td class="text-center">
                            <div class="btn-group btn-group-sm text-nowrap" *ngIf="!drawerCount.course; else exists">
                                <a class="btn btn-link btn-primary-light"
                                    [routerLink]="['/admin', 'reports', 'drawer-counts', getDateString(drawerCount.timestamp)]">CREATE</a>
                            </div>
                            <ng-template #exists>
                                <div class="btn-group btn-group-sm text-nowrap">
                                    <a class="btn btn-link btn-primary-light"
                                        [routerLink]="['/admin', 'reports', 'drawer-counts', getDateString(drawerCount.timestamp)]">VIEW</a>
                                    <span class="btn px-0">|</span>
                                    <a class="btn btn-link btn-primary-light"
                                        (click)="generateDrawerCountReportForRow(drawerCount)">GENERATE PDF</a>
                                </div>
                            </ng-template>
                        </td>
                    </tr>
                    <tr *ngIf="(cashDrawerEntryTotal$ | async) == 0">
                        <td colspan="10">No drawer counts.</td>
                    </tr>
                </tbody>
            </gcl-lib-table>

            <gcl-lib-table [totalItems]="inventoryTotal$ | async" [sortBy]="'id'"
                (onPage)="inventoryPagination$.next($event)" [tableClass]="'table table-striped table-hover'"
                [enableCreate]="enableCreate" [showSearch]="false"
                [style.display]="reportOutputType === ReportOutputType.InventoryReport ? 'block' : 'none'">
                <thead>
                    <tr>
                        <th>GROUP</th>
                        <th>DESCRIPTION</th>
                        <th columnSort="cost">COST</th>
                        <th>ON HAND</th>
                        <th columnSort="cost">TOTAL COST</th>
                        <th columnSort="price">RETAIL</th>
                        <th columnSort="price">TOTAL RETAIL</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let record of inventoryRecords$ | async">
                        <td>{{record.categoryName}}</td>
                        <td>{{record.pdescription}}</td>
                        <td>{{record.cost | currency}}</td>
                        <td>{{0}}</td>
                        <td>{{record.cost | currency}}</td>
                        <td>{{record.price | currency}}</td>
                        <td>{{record.price | currency}}</td>
                    </tr>
                    <tr *ngIf="(inventoryTotal$ | async) == 0">
                        <td colspan="10">No inventory report data found.</td>
                    </tr>
                </tbody>
            </gcl-lib-table>

            <gcl-lib-table [totalItems]="eventAgingTotal$ | async" [sortBy]="'id'"
                (onPage)="eventAgingPagination$.next($event)" [tableClass]="'table table-striped table-hover'"
                [enableCreate]="enableCreate" [showSearch]="false"
                [style.display]="reportOutputType === ReportOutputType.EventAgingReport ? 'block' : 'none'">
                <thead>
                    <tr>
                        <th columnSort="startDate">EVENT DATE</th>
                        <th>EVENT NAME</th>
                        <th columnSort="finalTotalAmount">CURRENT AMOUNT</th>
                        <th>DAYS AGED</th>
                        <th columnSort="finalTotalAmount">REMAINING AMOUNT</th>
                        <th>CONTACT</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let record of eventAgingRecords$ | async">
                        <td>{{record.eventDate}}</td>
                        <td>{{record.name}}</td>
                        <td>{{record.finalTotalAmount | currency}}</td>
                        <td>{{record.daysAged}}</td>
                        <td>{{record.finalTotalAmount | currency}}</td>
                        <td>{{record.contact}}</td>
                    </tr>
                    <tr *ngIf="(inventoryTotal$ | async) == 0">
                        <td colspan="10">No new event aging report data found.</td>
                    </tr>
                </tbody>
            </gcl-lib-table>

            <gcl-lib-table [totalItems]="roundsPlayedTotal$ | async" [sortBy]="'id'"
                (onPage)="roundsPlayedPagination$.next($event)" [tableClass]="'table table-striped table-hover'"
                [enableCreate]="enableCreate" [showSearch]="false"
                [style.display]="reportOutputType === ReportOutputType.RoundsPlayedReport ? 'block' : 'none'">
                <thead>
                    <tr>
                        <th columnSort="name">DESCRIPTION</th>
                        <th columnSort="totalRoundsPlayed">TOTAL ROUNDS PLAYED</th>
                        <th columnSort="totalAmount">TOTAL AMOUNT</th>
                        <th columnSort="totalCost">COST</th>
                        <th columnSort="finalTotalAmount">MARGIN</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let record of roundsPlayedRecords$ | async">
                        <td>{{record.name}}</td>
                        <td>{{record.totalRoundsPlayed}}</td>
                        <td>{{record.totalAmount | currency}}</td>
                        <td>{{record.totalCost | currency}}</td>
                        <td>{{0}}</td>
                    </tr>
                    <tr *ngIf="(inventoryTotal$ | async) == 0">
                        <td colspan="10">No rounds played report data found.</td>
                    </tr>
                </tbody>
            </gcl-lib-table>

            <gcl-lib-table [totalItems]="memberAgingTotal$ | async" [sortBy]="'id'"
                (onPage)="memberAgingPagination$.next($event)" [tableClass]="'table table-striped table-hover'"
                [enableCreate]="enableCreate" [showSearch]="false"
                [style.display]="reportOutputType === ReportOutputType.MemberAgingReport ? 'block' : 'none'">
                <thead>
                    <tr>
                        <th columnSort="firstName">MEMBER</th>
                        <th columnSort="created_at">SINCE</th>
                        <th columnSort="currentAmount">CURRENT AMOUNT</th>
                        <th columnSort="daysAged">DAYS AGED</th>
                        <th>TOTAL</th>
                        <th columnSort="phoneNumber">PHONE #</th>
                        <th columnSort="lastPayment">LAST PAYMENT</th>
                        <th columnSort="amount">AMOUNT</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let record of memberAgingRecords$ | async">
                        <td>{{record.lastName ? record.firstName + " "+ record.lastName : record.firstName}}</td>
                        <td>{{record.created_at}}</td>
                        <td>{{record.currentAmount | currency}}</td>
                        <td>{{record.daysAged}}</td>
                        <td>{{0 | currency}}</td>
                        <td>{{record.phoneNumber}}</td>
                        <td>{{record.lastPayment}}</td>
                        <td>{{record.amount | currency}}</td>
                    </tr>
                    <tr *ngIf="(inventoryTotal$ | async) == 0">
                        <td colspan="10">No new Member aging report data found.</td>
                    </tr>
                </tbody>
            </gcl-lib-table>

            <gcl-lib-table [totalItems]="certificatesAgingTotal$ | async" [sortBy]="'id'"
                (onPage)="certificatesAgingPagination$.next($event)" [tableClass]="'table table-striped table-hover'"
                [enableCreate]="enableCreate" [showSearch]="false"
                [style.display]="reportOutputType === ReportOutputType.CertificatesAgingReport ? 'block' : 'none'">
                <thead>
                    <tr>
                        <th columnSort="number">Number</th>
                        <th columnSort="purchased_date">Purchase Date</th>
                        <th columnSort="purchaser">Purchaser</th>
                        <th columnSort="types">Types</th>
                        <th columnSort="exp_date">Exp. Date</th>
                        <th columnSort="purchased_amount">Purchased Amount</th>
                        <th columnSort="redeemed_amount">Redeemed Amount</th>
                        <th columnSort="exp_amount">Exp. Amount</th>
                        <td columnSort="balance">Balance</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let record of certificatesAgingRecords$ | async">
                        <td>{{record.number? record.number: ''}}</td>
                        <td>{{record.purchased_date? record.purchased_date: ''}}</td>
                        <td>{{record.lastName ? record.firstName + " "+ record.lastName : record.firstName}}</td>
                        <td>{{record.types? record.types : ''}}</td>
                        <td>{{record.exp_date? record.exp_date : 0}}</td>
                        <td>{{record.purchased_amount? record.purchased_amount : 0}}</td>
                        <td>{{record.redeemed_amount? record.redeemed_amount : 0}}</td>
                        <td>{{record.exp_amount? record.exp_amount: 0}}</td>
                        <td>{{record.balance? record.balance: 0}}</td>
                    </tr>
                    <tr *ngIf="(certificatesAgingTotal$ | async) == 0">
                        <td colspan="10">No new Member aging report data found.</td>
                    </tr>
                </tbody>
            </gcl-lib-table>

            <gcl-lib-table [totalItems]="tipAgingTotal$ | async" [sortBy]="'id'"
                (onPage)="tipAgingPagination$.next($event)" [tableClass]="'table table-striped table-hover'"
                [enableCreate]="enableCreate" [showSearch]="false"
                [style.display]="reportOutputType === ReportOutputType.TipAgingReport ? 'block' : 'none'">
                <thead>
                    <tr>
                        <th columnSort="EmployeeNum">Employee #</th>
                        <th columnSort="Name">Name</th>
                        <th columnSort="TotalTips">Total Tips</th>
                        <th columnSort="TotalGratuity">Total gratuity</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let record of certificatesAgingRecords$ | async">
                        <td>{{record.employee_num}}</td>
                        <td>{{record.lastName ? record.firstName + " "+ record.lastName : record.firstName}}</td>
                        <td>{{record.total_tips }}</td>
                        <td>{{record.total_gratuity}}</td>
                    </tr>
                    <tr *ngIf="(tipAgingTotal$ | async) == 0">
                        <td colspan="10">No Tip aging report data found.</td>
                    </tr>
                </tbody>
            </gcl-lib-table>
        </div>
    </div>
</div>