import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';

import { environment } from 'projects/admin/src/environments/environment';
import { GolfPassDetails } from 'projects/shared/src/lib/models/golfpass';
import { GolfPassService } from 'projects/shared/src/lib/services/golf-pass.service';
import { ConfirmationModalService } from 'projects/shared/src/lib/services/confirmation-modal.service';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';

@Component({
  selector: 'gcl-admin-golf-pass-create',
  templateUrl: './golf-pass-create.component.html',
  styleUrls: ['./golf-pass-create.component.scss']
})
export class GolfPassCreateComponent implements OnInit, OnDestroy {
  public apiUrl = environment.apiUrl;
  public faChevronLeft = faChevronLeft;

  get name() { return this.form.get("name") as FormControl }
  get price() { return this.form.get("price") as FormControl }
  get description() { return this.form.get("description") as FormControl }
  get available() { return this.form.get("available") as FormControl }
  get taxable() { return this.form.get("taxable") as FormControl }

  public form = this.fb.group({
    name: [null, [Validators.required]],
    price: [0, [Validators.required]],
    description: [null, []],
    available: [false, [Validators.required]],
    taxable: [true, [Validators.required]],
  });

  public guidelines = this.fb.control(null);

  private destroyed$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private golfpassService: GolfPassService,
    public route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private confirmationService: ConfirmationModalService
  ) { }

  ngOnInit(): void { }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public openCancelModal() {
    this.confirmationService.showConfirmationModal({
      title: 'Confirm Cancel',
      message: 'Are you sure you want to discard changes and go back?'
    }).subscribe(confirm => {
      if (confirm === true) {
        this.router.navigate(['/admin', 'golf-passes']);
      }
    })
  }

  public openSaveModal() {
    this.form.markAllAsTouched();
    this.guidelines.markAsTouched();

    if(this.form.invalid || (!this.guidelines.value && !this.description.value)) {
      return;
    }

    this.authService.course$
      .pipe(
        take(1),
        switchMap(course => 
          {
            return this.golfpassService.createWithGuidelines({
              course: course.id,
              name: this.name.value,
              price: this.price.value,
              description: this.description.value,
              available: this.available.value,
              taxable: this.taxable.value,
            }, this.guidelines.value);
          }
          ),
        takeUntil(this.destroyed$)
      )
      .subscribe((golfpass: GolfPassDetails) => {
        this.form.setErrors({});

        this.toastr.success("Golf pass created successfully!");
        this.router.navigate(["/admin", "golf-passes", golfpass.id, "view"]);
      }, err => {
        this.toastr.error("An error has occurred. Please try again.")
      });
  }

  public onFileChanged(event: any) {
    this.guidelines.patchValue(event.target.files[0]);
  }
}
