import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'projects/shared/src/lib/guards/auth.guard';
import { ManagerAuthGuard } from 'projects/shared/src/lib/guards/manager-auth.guard';
import { LayoutComponent } from './layout/layout.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard, ManagerAuthGuard],
    children: [
      {
        path: 'carts',
        loadChildren: () => import('./carts/carts.module').then(m => m.CartsModule)
      },
      {
        path: 'players',
        loadChildren: () => import('./players/players.module').then(m => m.PlayersModule)
      },
      {
        path: 'products',
        loadChildren: () => import('./products/products.module').then(m => m.ProductsModule)
      },
      {
        path: 'schedules',
        loadChildren: () => import('./schedules/schedules.module').then(m => m.SchedulesModule)
      },
      {
        path: 'add-on',
        loadChildren: () => import('./add-on/add-on.module').then(m => m.AddOnModule)
      },
      {
        path: 'tee-sheet',
        loadChildren: () => import('./tee-sheet/tee-sheet.module').then(m => m.TeeSheetModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'banners',
        loadChildren: () => import('../admin/banners/banners.module').then(m => m.BannersModule)
      },
      {
        path: 'gift-certificates',
        loadChildren: () => import('../admin/gift-certificate/gift-certificate.module').then(m => m.GiftCertificateModule)
      },
      {
        path: 'orders',
        loadChildren: () => import('../admin/orders/orders.module').then(m => m.OrdersModule)
      },
      {
        path: 'reports',
        loadChildren: () => import ('../admin/reports/reports.module').then(m => m.ReportsModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManagerRoutingModule { }
