import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormBuilder, Validators, AsyncValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { shareReplay, tap, takeUntil, switchMap, map, catchError, publishReplay } from 'rxjs/operators';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';

import { UserService } from 'projects/shared/src/lib/services/user.service';
import { CourseDetails, Course } from 'projects/shared/src/lib/models/course';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { CartService } from 'projects/shared/src/lib/services/cart.service';
import { DeviceService } from 'projects/shared/src/lib/services/device.service';
import { DeviceDetails, UserDetails, CartDetails } from 'projects/shared/src/public-api';

@Component({
  selector: 'gcl-admin-cart-create',
  templateUrl: './cart-create.component.html',
  styleUrls: ['./cart-create.component.scss']
})
export class CartCreateComponent implements OnInit, OnDestroy {

  public faChevronLeft = faChevronLeft;

  get name() { return this.form.get("name") as FormControl }
  get status() { return this.form.get("status") as FormControl }
  get device() { return this.form.get("device") as FormControl }
  get user() { return this.form.get("user") as FormControl }

  public form = this.fb.group({
    name: [null, [Validators.required, Validators.maxLength(10)]],
    status: [null, [Validators.required]],
    device: [null, [Validators.required]],
    user: [null, []],
  });

  public courses$!: Observable<Array<CourseDetails>>;
  public devices$!: Observable<Array<DeviceDetails>>;
  public users$!: Observable<Array<UserDetails>>;
  public carts$!: Observable<Array<CartDetails>>;

  private destroyed$ = new Subject();

  constructor(private fb: FormBuilder, public authService: AuthService, private cartService: CartService, private userService: UserService, private deviceService: DeviceService, public route: ActivatedRoute, private router: Router, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.devices$ = this.deviceService.getAll()
      .pipe(
        shareReplay(1),
        takeUntil(this.destroyed$)
      );
    this.users$ = this.userService.getAll()
      .pipe(
        shareReplay(1),
        takeUntil(this.destroyed$),
      );

    this.carts$ = this.cartService.query().records$.pipe(      
      shareReplay(1)
    );
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public submit(): void {
    this.form.markAllAsTouched();
    
    if(this.user.value === "null") {
      this.user.setValue(null);
    }
    if(this.status.value === "null") {
      this.status.setValue(null);
      this.status.updateValueAndValidity();
    }
    if(this.device.value === "null") {
      this.device.setValue(null);
      this.device.updateValueAndValidity();
    }

    this.carts$.subscribe(
      carts => {
        let nameErrors: any = {};
        if(this.duplicateCartName(carts)) {
          nameErrors['duplicateCartName'] = true;
        }

        if(this.name.getError('maxlength')) {
          nameErrors['maxLength'] = true;
        }

        if(this.name.getError('required')) {
          nameErrors['required'] = true;
        }

        if(Object.keys(nameErrors).length === 0) {
          nameErrors = null;
        }

        this.name.setErrors(nameErrors);

        if(this.form.invalid) {
          return;
        }
        else {
          this.authService.course$.pipe(
            switchMap(course =>       
              this.cartService.create({
                device: this.device.value,
                user: this.user.value,
                status: this.status.value,
                name: this.name.value,
                course: course.id
            })),
            takeUntil(this.destroyed$)
          )
          .subscribe((cart: CartDetails) => {
            this.toastr.success("Cart created successfully!");
            this.router.navigate(["/admin","carts", cart.id, "view"]);
          }, err => {
            this.toastr.error("An error has occurred. Please try again.")
          });
        }
      })
  }

  private duplicateCartName(carts: CartDetails[]): boolean {
    return carts.map(c => c.name).includes(this.name.value);
  }
}
