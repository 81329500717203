<div class="modal-header">
    <h4 class="modal-title pull-left">Generate Gift Certificate(s)</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="cancel()">
        <span class="modal-close" aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="form" (ngSubmit)="generate()">
        <div class="row mt-2">
            <div class="col-12">
                <div class="form-group">
                    <label for="count">Enter Number of Gift Certificates</label>
                    <input formControlName="count" id="count" type="number" class="form-control font-weight-bold" />
                    <div *ngIf="count?.invalid && count?.touched" class="text-danger mt-2">
                        <div *ngIf="count?.errors?.required">
                            Gift certificate count required.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end align-items-center">
            <button class="btn btn-outline-light-green" (click)="cancel()">Cancel</button>
            <button class="btn btn-light-green ml-2 px-5" type="submit" [disabled]="form.invalid">Generate</button>
        </div>
    </form>
</div>
