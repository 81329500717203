import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { EventSpaceType } from 'projects/shared/src/lib/enumerations/event-space-type';
import { EventDetails } from 'projects/shared/src/lib/models/event';
import { EventSpaceDetails } from 'projects/shared/src/lib/models/event-space';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { EventSpaceService } from 'projects/shared/src/lib/services/event-space.service';
import { EventService } from 'projects/shared/src/lib/services/event.service';
import { OrderService } from 'projects/shared/src/lib/services/order.service';
import { UserService } from 'projects/shared/src/lib/services/user.service';
import { OrderDetails, UserDetails } from 'projects/shared/src/public-api';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { map, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { PlayerSelectModalComponent } from '../../../shared/components/player-select-modal/player-select-modal.component';

@Component({
  selector: 'gcl-admin-event-edit',
  templateUrl: './event-edit.component.html',
  styleUrls: ['./event-edit.component.scss']
})
export class EventEditComponent implements OnInit, OnDestroy {
  faChevronLeft = faChevronLeft

  get name() { return this.form.get('name') }
  get description() { return this.form.get('description') }
  get event_space() { return this.form.get('event_space') }
  get type() { return this.form.get('type') }
  get start() { return this.form.get('start') }
  get startTime() { return this.form.get('startTime')}
  get end() { return this.form.get('end') }
  get endTime() { return this.form.get('endTime') }
  get participants() { return this.form.get('participants')}
  get carts() { return this.form.get('carts')}
  get courseLength() { return this.form.get('courseLength') }
  get user() { return this.form.get('user') }

  public form = this.fb.group({
    name: ['', [Validators.required]],
    description: ['', []],
    event_space: ['', [Validators.required]],
    type: [null, []],
    start: [null, [Validators.required]],
    startTime: [null, [Validators.required]],
    end: [null, []],
    endTime: [null, []],
    participants: [0, []],
    carts: [0, []],
    courseLength: [9, []],
    user: [null, [Validators.required]]
  });

  public eventSpaces$!: Observable<EventSpaceDetails[]>;
  public eventSpaceRequiresGolfProduct$!: Observable<boolean>;

  public event$!: Observable<EventDetails>;
  public eventSpace$: BehaviorSubject<EventSpaceDetails | null> = new BehaviorSubject<EventSpaceDetails | null>(null);
  public eventOrder$!: Observable<OrderDetails>;


  private destroyed$ = new Subject<void>();

  constructor(
    private fb: FormBuilder, 
    private authService: AuthService,
    private eventSpaceService: EventSpaceService,
    private eventService: EventService,
    private router: Router,
    private bsModalService: BsModalService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private orderService: OrderService,
  ) { }

  ngOnInit(): void {
    this.eventSpaces$ = this.authService.course$.pipe(
      switchMap(course => this.eventSpaceService.query({course: course.id}).records$)
    );

    this.event$ = this.activatedRoute.params.pipe(
      switchMap(p => this.eventService.get(p['eventId'] as number)),      
      takeUntil(this.destroyed$),
      shareReplay(1)
    );

    let user$ = this.event$.pipe(
      switchMap(e => e.order.users_permissions_user ? this.userService.get(e.order.users_permissions_user as number) : of(null)),
      takeUntil(this.destroyed$),
      shareReplay(1)
    )

    combineLatest([this.event$, user$]).subscribe(([event, user]) => this.form.patchValue({
      name: event.name,
      description: event.description,
      event_space: event.event_space?.id,
      type: event.type,
      start: event.start ? new Date(event.start) : null,
      startTime: event.start ? new Date(event.start) : null,
      end: event.end ? new Date(event.end) : null,
      endTime: event.end ? new Date(event.end) : null,
      participants: event.participants,
      cats: event.carts,
      courseLength: event.courseLength,
      user: user ?? null
    }))

    this.eventSpaceRequiresGolfProduct$ = this.eventSpace$.pipe(
      map(space => {
        if(!space) {
          return false;
        }
        return space.type === EventSpaceType.Course}
      )
    );

    // Golf Product fields should only be required if event space is of type Course
    this.event_space?.valueChanges.subscribe(async id => {
      try {
        let eventSpace = await this.eventSpaceService.get(id).toPromise();
        this.eventSpace$.next(eventSpace);

        if(eventSpace.type === EventSpaceType.Course) {
          this.carts?.setValidators([Validators.required]);
          this.carts?.setValue(0);
          this.courseLength?.setValidators([Validators.required]);
          this.courseLength?.setValue(9);
        }
        else {
          this.carts?.clearValidators();
          this.carts?.setValue(null);
          this.courseLength?.clearValidators();
          this.courseLength?.setValue(null);
        }

        this.carts?.updateValueAndValidity();
        this.courseLength?.updateValueAndValidity();
      }
      catch(e: any) {
        console.error(e?.message);
      }
    });

    this.eventOrder$ = this.event$.pipe(
      switchMap(e => this.orderService.get((e.order.id as number) ?? 0)),
      takeUntil(this.destroyed$),
      shareReplay(1),
    )
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  async openSelectCustomerModal() {
    const selectModalRef = this.bsModalService.show(PlayerSelectModalComponent, {
      class: "modal-lg modal-dialog-centered customer-modal",
      ignoreBackdropClick: true,
      initialState: {
        selectedUser: this.user?.value ?? null,
        previousUser: this.user?.value ?? null,
      }
    });

    if (selectModalRef.content) {
      selectModalRef.content.select$
        .pipe(
          tap(((user: UserDetails | null) => {
            this.user?.patchValue(user);
            selectModalRef.hide();
          })),
          takeUntil(this.destroyed$)
        ).subscribe();
    }
  }

  async submit() {
    let startDay = this.start?.value;
    let startTime = this.startTime?.value;

    let start = this.combineDates(startDay, startTime);

    let record = {
      ...(this.form.value),
      start
    };

    delete record.startTime;
    delete record.endTime;

    if(this.end?.value && this.endTime?.value) {
      record['end'] = this.combineDates(this.end.value, this.endTime.value);
    }

    let users_permissions_user = record?.user?.id ?? null;
    delete record.user;

    let event = await this.event$.pipe(take(1)).toPromise();

    this.authService.course$.pipe(
      take(1),
      switchMap(course => this.eventService.update(event.id, record).pipe(take(1))),
      switchMap(e => this.orderService.update(event?.order?.id, {users_permissions_user}))
    ).subscribe(
      (order) => {
        this.router.navigate(['/admin', 'events', event.id, 'view']);
      },
      (err) => {
        console.error(err?.message);
      }
    )
  }

  private combineDates(day: Date, time: Date): Date {
    return new Date(day.getFullYear(), day.getMonth(), day.getDate(), time.getHours(), time.getMinutes(), time.getSeconds());
  }
}
