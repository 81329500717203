<div class="d-flex flex-column h-100">
    <div class="d-flex justify-content-start py-2 bg-light no-flex">
        <a class="text-light-green"  [routerLink]="['/admin','events']">
            <fa-icon [icon]="faChevronLeft" class="px-3"></fa-icon> <span>Back to Event List</span>
        </a>
    </div>
    <div class="container-fluid py-2 fill-flex overflow-y-auto" *ngIf="event$ | async as event">
        <div class="row">
            <div class="col-12">
                <form [formGroup]="form" (ngSubmit)="submit()">
                    <div class="d-flex justify-content-end py-2">
                        <button type="button" [routerLink]="['/admin', 'events', event.id, 'view']"  class="btn btn-outline-light-green">Cancel</button>
                        <button type="submit" class="btn btn-success" [disabled]="form.invalid">Save</button>
                    </div>
                    <div class="row border mx-1">
                        <div class="col-12 col-lg-7 p-0">
                            <div class="row mx-0 pt-3">
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label >Name</label>
                                        <input type="text" class="form-control" formControlName="name" name="name" [class.is-invalid]="name?.invalid && name?.touched" />
                                        <div *ngIf="name?.invalid && name?.touched" class="invalid-feedback">
                                            <div *ngIf="name?.errors?.required">
                                                Please enter a name.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label>Description</label>
                                        <textarea type="text" class="form-control" formControlName="description" name="description" [class.is-invalid]="description?.invalid && description?.touched"></textarea>
                                        <div *ngIf="description?.invalid && description?.touched" class="invalid-feedback">
                                            <div *ngIf="description?.errors?.required">
                                                Please enter a description.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>    
                            <div class="row mx-0 pt-3">
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label>Event Type</label>
                                        <select class="custom-select" formControlName="type" name="type"  
                                        [hasNullOption]="true"
                                        enum="EventType"
                                        [value]="type?.value"
                                        [class.is-invalid]="type?.invalid && type?.touched">
                                        </select>                                        
                                        <div *ngIf="type?.invalid && type?.touched" class="invalid-feedback">
                                            <div *ngIf="type?.errors?.required">
                                                Please select a status.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label>Event Space</label>
                                        <select class="form-control" formControlName="event_space" [class.is-invalid]="event_space?.invalid && event_space?.touched">
                                            <option value="">Select...</option>
                                            <option *ngFor="let space of eventSpaces$ | async" [ngValue]="space.id">{{space.name}}</option>
                                        </select>
                                        <div *ngIf="event_space?.invalid && event_space?.touched" class="invalid-feedback">
                                            <div *ngIf="event_space?.errors?.required">
                                                Please select an event space.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>                                      
                            <div class="row mx-0 pt-3" *ngIf="eventSpaceRequiresGolfProduct$ | async">
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label for="courseLength">Course Length</label>
                                        <select class="form-control" formControlName="courseLength" [class.is-invalid]="courseLength?.invalid && courseLength?.touched">
                                            <option [ngValue]="null">Select...</option>
                                            <option [ngValue]="9">9 Holes</option>
                                            <option [ngValue]="18">18 Holes</option>
                                        </select>
                                        <div *ngIf="courseLength?.invalid && courseLength?.touched" class="invalid-feedback">
                                            <div *ngIf="courseLength?.errors?.required">
                                                Please enter a course length.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>        
                            <div class="row mx-0 pt-3">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="start">Start Date/Time</label>
                                        <div class="row m-0 align-items-center">
                                            <div class="col-6">
                                                <gcl-lib-date-picker formControlName="start" 
                                                name="start"
                                                [class.is-invalid]="start?.invalid && start?.touched"></gcl-lib-date-picker>
                                            </div>
                                            <div class="col-6">
                                                <timepicker formControlName="startTime" [class.is-invalid]="startTime?.invalid && startTime?.touched"></timepicker>
                                            </div>
                                        </div>
                                        <div *ngIf="start?.invalid && start?.touched" class="invalid-feedback">
                                            <div *ngIf="start?.errors?.required">
                                                Please enter a start date.
                                            </div>
                                        </div>
                                        <div *ngIf="startTime?.invalid && startTime?.touched" class="invalid-feedback">
                                            <div *ngIf="startTime?.errors?.required">
                                                Please enter a start time.
                                            </div>
                                        </div>
                                    </div>   
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="end">End Date/Time</label>
                                        <div class="row m-0 align-items-center">
                                            <div class="col-6">
                                                <gcl-lib-date-picker formControlName="end" 
                                                name="end"
                                                [class.is-invalid]="end?.invalid && end?.touched"></gcl-lib-date-picker>
                                            </div>
                                            <div class="col-6">
                                                <timepicker formControlName="endTime" [class.is-invalid]="endTime?.invalid && endTime?.touched"></timepicker>
                                            </div>
                                        </div>
                                        <div *ngIf="end?.invalid && end?.touched" class="invalid-feedback">
                                            <div *ngIf="end?.errors?.required">
                                                Please enter an end date.
                                            </div>
                                        </div>
                                    </div>   
                                </div>
                            </div>      
                            <hr class="my-0 mx-3" />      
                            <div class="d-flex justify-content-between pt-3 px-3">
                                <div class="form-group">
                                    <label>Customer Name</label>
                                    <p>{{user?.value?.firstName}} {{user?.value?.lastName}}</p>
                                </div>
                                <div>
                                    <button class="btn btn-light-green px-5" type="button" (click)="openSelectCustomerModal()">
                                        <ng-container *ngIf="!user?.value">Select Customer</ng-container>
                                        <ng-container *ngIf="user?.value">Change Customer</ng-container>
                                    </button>
                                </div>
                            </div>
                            <div class="row mx-0 pt-3">
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label>Email</label>
                                        <p>{{user?.value?.email}}</p>    
                                    </div>   
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label>Phone</label>
                                        <p>{{user?.value?.cellphone}}</p>    
                                    </div>   
                                </div>
                            </div> 
                            <div class="row mx-0 pt-3">
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label for="participants">People in party</label>
                                        <input class="form-control" type="number" formControlName="participants" id="participants" min="0" />
                                        <div *ngIf="participants?.invalid && participants?.touched" class="invalid-feedback">
                                            <div *ngIf="participants?.errors?.required">
                                                Please enter a party count.
                                            </div>
                                        </div>
                                    </div>   
                                </div>
                                <div class="col-12 col-md-6" *ngIf="eventSpaceRequiresGolfProduct$ | async">
                                    <div class="form-group">
                                        <label for="carts">Number of Carts</label>
                                        <input class="form-control" type="number" formControlName="carts" id="carts" min="0" />
                                        <div *ngIf="carts?.invalid && carts?.touched" class="invalid-feedback">
                                            <div *ngIf="carts?.errors?.required">
                                                Please enter a cart count.
                                            </div>
                                        </div>
                                    </div>   
                                </div>
                            </div>     
                            <!-- <div class="row mx-0 pt-3">
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label for="eventAmount">Event Total Price</label>
                                        <input class="form-control" type="number" min="0" formControlName="eventAmount" [class.is-invalid]="eventAmount?.invalid && eventAmount?.touched"/> 
                                        <div *ngIf="eventAmount?.invalid && eventAmount?.touched" class="invalid-feedback">
                                            <div *ngIf="eventAmount?.errors?.required">
                                                Please enter an event amount.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label for="depositAmount">Deposit Amount</label>
                                        <input class="form-control" type="number" min="0" formControlName="depositAmount" [class.is-invalid]="depositAmount?.invalid && depositAmount?.touched"/> 
                                        <div *ngIf="depositAmount?.invalid && depositAmount?.touched" class="invalid-feedback">
                                            <div *ngIf="depositAmount?.errors?.required">
                                                Please enter an deposit amount.
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>           -->
                        </div>
                        <div class="bg-light col-12 col-lg-5 p-0">
                            <div class="d-flex justify-content-between align-items-center pt-4 px-4">
                                <div class="font-weight-bold">Itemized List</div>
                                <button class="btn btn-light-green px-5 invisible" [routerLink]="['/admin','tee-sheet', event?.order?.id, 'register-checkout']">+ Products</button>
                            </div>
                            <ng-container *ngIf="eventOrder$ | async as eventOrder">
                                <gcl-admin-order-line-items [order]="eventOrder"></gcl-admin-order-line-items>
                            </ng-container>
                        </div>
                    </div>
                </form>
            </div>
            <ng-container *ngIf="eventOrder$ | async as eventOrder">
                <div class="container-fluid py-2 mx-1 fill-flex overflow-y-auto" *ngIf="eventOrder.payments && eventOrder.payments.length > 0">
                    <div class="row">
                        <div class="col-12">
                            <gcl-admin-order-payment-table [order]="eventOrder"></gcl-admin-order-payment-table>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>