<gcl-lib-table 
[totalItems]="total$ | async" 
[sortBy]="'id'" 
(onPage)="pagination$.next($event)" 
[tableClass]="'table table-striped table-hover'" 
[enableCreate]="enableCreate"
(onCreate)="onCreateClick()"
>
    <thead>
        <tr>
            <th columnSort="name">NAME</th>
            <th columnSort="type">TYPE</th>
            <th columnSort="course.name">COURSE</th>
            <th width="100" class="text-center">MANAGE</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let space of records$ | async">
            <td>{{space.name}}</td>
            <td>{{space.type ? space.type : 'N/A'}}</td>
            <td>{{space?.course?.name}}</td>
            <td class="text-center">
                <div class="btn-group btn-group-sm">
                    <a *ngIf="course" [routerLink]="['/admin', 'courses', course, 'spaces', space.id, 'view']" class="btn btn-link btn-primary-light">View</a>
                </div>
            </td>
        </tr>
        <tr *ngIf="(total$ | async) == 0">
            <td colspan="10">No event spaces.</td>
        </tr>
    </tbody>
</gcl-lib-table>
