import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';

import { UserService } from 'projects/shared/src/lib/services/user.service';
import { Roles } from 'projects/shared/src/lib/enumerations/roles';
import { CourseDetails } from 'projects/shared/src/lib/models/course';
import { CourseService } from 'projects/shared/src/lib/services/course.service';
import { UserDetails } from 'projects/shared/src/public-api';

import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { Course } from 'projects/shared/src/lib/models/course';
import { ConfirmationModalService } from 'projects/shared/src/lib/services/confirmation-modal.service';


@Component({
  selector: 'gcl-admin-staff-edit',
  templateUrl: './staff-edit.component.html',
  styleUrls: ['./staff-edit.component.scss']
})
export class StaffEditComponent implements OnInit, OnDestroy {
  public Roles = Roles;
  public faChevronLeft = faChevronLeft;

  public userCourses$!: Observable<Array<Course>>;
  public course$!: Observable<Course>;

  get role() { return this.form.get("role") as FormControl; }
  get username() { return this.form.get("username") as FormControl; }
  get email() { return this.form.get("email") as FormControl; }
  get confirmed() { return this.form.get("confirmed") as FormControl; }
  get blocked() { return this.form.get("blocked") as FormControl; }
  get cellphone() { return this.form.get("cellphone") as FormControl; }
  get licenseNumber() { return this.form.get("licenseNumber") as FormControl; }
  get licenseProvider() { return this.form.get("licenseProvider") as FormControl; }
  get licenseExpires() { return this.form.get("licenseExpires") as FormControl; }
  get firstName() { return this.form.get("firstName") as FormControl; }
  get lastName() { return this.form.get("lastName") as FormControl; }
  get dateOfBirth() { return this.form.get("dateOfBirth") as FormControl; }
  get courses() { return this.form.get("courses") as FormControl }

  public form = this.fb.group({
    role: [null, [Validators.required]],
    username: [null, []],
    email: [null, [Validators.required]],
    confirmed: [false, []],
    blocked: [false, []],
    cellphone: [null, [Validators.required]],
    licenseNumber: [null, []],
    licenseProvider: [null, []],
    licenseExpires: [null, []],
    firstName: [null, [Validators.required]],
    lastName: [null, [Validators.required]],
    dateOfBirth: [null, []],
    courses: [null, [Validators.required]]
  });

  public userId$!: Observable<number>;
  public user$!: Observable<UserDetails>;
  public courses$!: Observable<Array<CourseDetails>>;
  private destroyed$ = new Subject();

  constructor(private fb: FormBuilder, private userService: UserService, private courseService: CourseService, public route: ActivatedRoute, private confirmationModalService: ConfirmationModalService, private router: Router, private toastr: ToastrService, public authService: AuthService) { }

  ngOnInit(): void {
    this.courses$ = this.courseService.getAll()
      .pipe(
        shareReplay(1)
      );

    this.userId$ = this.route.params.pipe(
      map(params => params.staffId)
    );

    this.user$ = this.userId$.pipe(
      switchMap(userId => this.userService.get(userId)),
      tap((user: UserDetails) => this.form.patchValue({
        username: user.username || user.email,
        role: user.role.id,
        email: user.email,
        confirmed: user.confirmed,
        blocked: user.blocked,
        cellphone: user.cellphone,
        licenseNumber: user.licenseNumber,
        licenseProvider: user.licenseProvider,
        licenseExpires: user.licenseExpires,
        firstName: user.firstName,
        lastName: user.lastName,
        dateOfBirth: user.dateOfBirth,
        courses: user.courses.map((c) => c.id)
      })),
      shareReplay(1)
    );
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public submit(): void {
    if (this.form.valid) {
      this.form.patchValue({
        username: this.username.value || this.email.value,
      });

      this.user$.pipe(
        switchMap(user => {
          return this.userService.update(user.id, { ...user, ...this.form.value });
        }),
        takeUntil(this.destroyed$)
      ).subscribe((user: UserDetails) => {
        this.form.setErrors({});

        this.toastr.success("Staff updated successfully!");
        this.router.navigate(["/admin", "staff", user.id, "view"]);
      }, (err: HttpErrorResponse) => {
        err.error.message.forEach((message: any) => {
          if (message.messages.length > 0) {
            message.messages.forEach((mes: any) => {
              const field = mes.field[0];
              const formField = this.form.get(field);
              if (formField) {
                formField.setErrors({
                  error: mes.message
                });
              }
            })
          }
        })
      });
    } else {
      Object.values(this.form.controls).forEach(control => {
        control.markAsTouched();
        control.markAsDirty();
      });
    }
  }

  public openCancelModal(user: UserDetails) {
    this.confirmationModalService.showConfirmationModal({
      title: 'Confirm Cancel',
      message: 'Are you sure you want to discard changes and go back?'
    }).subscribe(confirm => {
      if (confirm === true) {
        this.router.navigate(['/admin', 'staff', user.id, 'view']);
      }
    })
  }

  public openSaveModal() {
    this.confirmationModalService.showConfirmationModal({
      title: 'Confirm Save',
      message: 'Do you want to save these changes?'
    }).subscribe(confirm => {
      if (confirm === true) {
        this.submit();
      }
    })
  }
}
