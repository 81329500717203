import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, forkJoin, merge, Observable, of, Subject } from 'rxjs';
import { map, switchMap, shareReplay, takeUntil, combineAll, take } from 'rxjs/operators';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';

import { environment } from 'projects/admin/src/environments/environment';
import { OrderDetails } from 'projects/shared/src/lib/models/order';
import { OrderService } from 'projects/shared/src/lib/services/order.service';
import { ConfirmationModalService } from 'projects/shared/src/lib/services/confirmation-modal.service';
import { DisplayGolfPassItem, DisplayGolfProducItem, DisplayProductAddOnItem } from '../../../shared/modules/checkout/shared-checkout/shared-checkout.component';
import { CategoryDetails, OrderPaymentDetails } from 'projects/shared/src/public-api';
import { GolfOrderService } from 'projects/shared/src/lib/services/golf-order.service';
import { GolfPassOrderService } from 'projects/shared/src/lib/services/golf-pass-order.service';
import { CategoryService } from 'projects/shared/src/lib/services/category.service';
import { ProductOrderService } from 'projects/shared/src/lib/services/product-order.service';
import { GetReservationTypesDisplayName } from 'projects/shared/src/lib/enumerations/reservation-type';
import { getFirstTeeTime } from '../../../shared/utils/get-first-tee-time';
import { ProductService } from 'projects/shared/src/lib/services/product.service';
import { RefundModalComponent } from 'projects/shared/src/lib/components/refund-modal/refund-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { RefundOptions } from 'projects/shared/src/lib/enumerations/refund-options';
import { GetOrderPaymentTypesDisplayName } from 'projects/shared/src/lib/enumerations/order-payment-types';

@Component({
  selector: 'gcl-admin-order-view',
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.scss']
})
export class OrderViewComponent implements OnInit, OnDestroy {
  GetReservationTypesDisplayName = GetReservationTypesDisplayName;
  GetOrderPaymentTypesDisplayName = GetOrderPaymentTypesDisplayName;

  public apiUrl: string = environment.apiUrl;
  public faChevronLeft = faChevronLeft;

  public order$!: Observable<OrderDetails>;

  private destroyed$ = new Subject();

  private orderChanged$ = new Subject<void>();

  golfOrders$!: Observable<DisplayGolfProducItem[]>;
  golfpassOrders$!: Observable<DisplayGolfPassItem[]>;
  productOrders$!: Observable<DisplayProductAddOnItem[]>;
  categories$!: Observable<CategoryDetails[]>;

  cartCount$!: Observable<number>;

  paidTotal$!: Observable<number>;

  canRefund$!: Observable<boolean>;
  canRefundViaCreditBalance$!: Observable<boolean>;

  constructor(
    private orderService: OrderService, 
    public route: ActivatedRoute, 
    private confirmationModalService: ConfirmationModalService, 
    private router: Router, 
    private toastr: ToastrService,
    private productService: ProductService,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    const orderId$ = this.route.params.pipe(
      map(params => params.orderId)
    );

    let onOrderChanged$ = this.orderChanged$.asObservable();
    let refreshOrder$ = combineLatest(([orderId$, onOrderChanged$])).pipe(
      switchMap(([id]) => this.orderService.get(id)),
      takeUntil(this.destroyed$)
    );

    let getOrder$ = orderId$.pipe(
      switchMap(orderId => this.orderService.get(orderId)),
      takeUntil(this.destroyed$),
      shareReplay(1)
    );

    this.order$ = merge(getOrder$, refreshOrder$).pipe(
      takeUntil(this.destroyed$),
      shareReplay(1)
    );

    this.canRefund$ = this.order$.pipe(map(o => {
      let productOrders = o?.productorders ?? [];
      let golfOrders = o?.golforders ?? [];

      let refundableProductOrders = productOrders.filter(p => p.refunded !== true).length;
      let refundableGolfOrders = golfOrders.filter(g => g.refunded !== true).length;

      return (refundableProductOrders + refundableGolfOrders) > 0;
    }));

    this.canRefundViaCreditBalance$ = this.order$.pipe(map(o => {
      let golfOrders = o?.golforders ?? [];

      let refundableGolfOrders = golfOrders.filter(g => g.refunded !== true).length;

      return refundableGolfOrders > 0;
    }));

    this.cartCount$ = this.order$.pipe(
      switchMap(order => {
        if(order?.productorders && order.productorders.length > 0) {
          let productIds$ = order.productorders.map(p => this.productService.get(<number>p.product).pipe(take(1)));
          return combineLatest(productIds$).pipe(
            take(1),
            map(products => products.filter(p => p.name === 'Golf Cart').length)
          );
        }
        else return of(0);
      })
    );

    this.paidTotal$ = this.order$.pipe(
      map(order => {
        return order.payments.reduce((prev, curr) => prev + curr.amount, 0);
      })
    )
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public openDeleteModal() {
    this.confirmationModalService.showConfirmationModal({
      title: 'Confirm Deletion',
      message: 'You are about to delete this add-on? This action cannot be undone.'
    }).subscribe(accept => {
      if (accept) {
        this.delete();
      }
    });
  }

  private delete(): void {
    this.order$.pipe(
      switchMap((order: OrderDetails) => this.orderService.delete(order.id)),
      shareReplay(1),
      takeUntil(this.destroyed$)
    ).subscribe((order: OrderDetails) => {
      this.toastr.success("Order deleted successfully!");
      this.router.navigate(["/admin", "orders"]);
    });
  }

  public getNine(order: OrderDetails) {
    return order?.tee_times?.[0]?.nine ?? 'N/A'
  }

  getTeeTime(order: OrderDetails) {
    let teeTime = order?.tee_times?.[0];
    
    if(!teeTime || !teeTime?.datestr || !teeTime?.timestr) {
      return new Date();
    }

    return getFirstTeeTime(order);
  }
}
