import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { OrderPaymentTypes } from 'projects/shared/src/lib/enumerations/order-payment-types';
import { SortDirections } from 'projects/shared/src/lib/enumerations/sort-directions';
import { CategoryService } from 'projects/shared/src/lib/services/category.service';
import { GolfOrderService } from 'projects/shared/src/lib/services/golf-order.service';
import { GolfPassOrderService } from 'projects/shared/src/lib/services/golf-pass-order.service';
import { OrderPaymentService } from 'projects/shared/src/lib/services/order-payment.service';
import { ProductOrderService } from 'projects/shared/src/lib/services/product-order.service';
import { CategoryDetails, OrderDetails, OrderPaymentDetails } from 'projects/shared/src/public-api';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { DisplayGolfPassItem, DisplayGolfProducItem, DisplayProductAddOnItem } from '../../modules/checkout/shared-checkout/shared-checkout.component';
import { getOrderDiscountAmount } from '../../utils/get-order-discount-amount';

@Component({
  selector: 'gcl-admin-order-line-items',
  templateUrl: './order-line-items.component.html',
  styleUrls: ['./order-line-items.component.scss']
})
export class OrderLineItemsComponent implements OnInit, OnDestroy {
  @Input('order') order!: OrderDetails

  private destroyed$ = new Subject();

  golfOrders$!: Observable<DisplayGolfProducItem[]>;
  golfpassOrders$!: Observable<DisplayGolfPassItem[]>;
  productOrders$!: Observable<DisplayProductAddOnItem[]>;
  categories$!: Observable<CategoryDetails[]>;

  public paymentsTotal$!: Observable<number>;
  public refundTotal$!: Observable<number>;
  public difference$!: Observable<number>;
  public orderPayments$!: Observable<Array<OrderPaymentDetails>>;


  constructor(
    private golfOrderService: GolfOrderService,
    private golfPassOrderService: GolfPassOrderService,
    private categoryService: CategoryService,
    private productOrderService: ProductOrderService,
    private orderpaymentService: OrderPaymentService
  ) { }

  ngOnInit(): void {
    this.initGolfOrders();
    this.initGolfPassOrders();
    this.initProductOrders();

    
    this.orderPayments$ = of(this.order).pipe(
      switchMap((order) => {
        const pagedResult$ = this.orderpaymentService.query({ order: order.id, sortColumns: [{ column: "created_at", direction: SortDirections.Descending }] });
        return pagedResult$.records$;
      }),
      takeUntil(this.destroyed$)
    );
    this.paymentsTotal$ = this.orderPayments$.pipe(
      map((ops) => {
        const digitalTotal = ops.filter(op => op.type == OrderPaymentTypes.Digital).reduce((total, op) => total + op.amount, 0);
        const cashTotal = ops.filter(op => op.type == OrderPaymentTypes.Cash).reduce((total, op) => total + op.amount, 0);
        return digitalTotal + cashTotal;
      }),
      takeUntil(this.destroyed$)
    );
    this.refundTotal$ = this.orderPayments$.pipe(
      map((ops) => {
        const digitalRefundTotal = ops.filter(op => op.type == OrderPaymentTypes.DigitalRefund).reduce((total, op) => total + op.amount, 0);
        const cashRefundTotal = ops.filter(op => op.type == OrderPaymentTypes.CashRefund).reduce((total, op) => total + op.amount, 0);
        const creditBalanceRefundTotal = ops.filter(op => op.type == OrderPaymentTypes.CreditBalanceRefund).reduce((total, op) => total + op.amount, 0);
        return digitalRefundTotal + cashRefundTotal + creditBalanceRefundTotal;
      }),
      takeUntil(this.destroyed$)
    );
    this.difference$ = combineLatest([of(this.order), this.refundTotal$]).pipe(
      map(([order, refunds]) => {
        return order.finaltotal;
      }),
      takeUntil(this.destroyed$)
    );
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private initGolfOrders(): void {

    this.golfOrders$ = of(this.order)
      .pipe(
        switchMap((order) => combineLatest([of(order), combineLatest(order.golforders.map(go => this.golfOrderService.get(go.id)))])),
        switchMap(([order, golforders]) => of(golforders.map(go => <DisplayGolfProducItem>{
          orderId: order.id,
          golfOrderId: go.id,
          golfproductId: go.golfproduct.id,
          category: "GOLF PRODUCT",
          name: go.golfproduct.name,
          quantity: go.quantity,
          price: go.extprice,
          unitprice: go.unitprice,
          taxrate: ((order.course?.taxrate ?? 0) / 100),
          refunded: go.refunded,
        }))),
        takeUntil(this.destroyed$)
      );

  }
  private initGolfPassOrders(): void {

    this.golfpassOrders$ = of(this.order).pipe(
        switchMap((order) => {
          const pagedResult = this.golfPassOrderService.query({ order: order.id });
          return combineLatest([of(order), pagedResult.records$]);
        }),
        switchMap(([order, golfpassorders]) => of(golfpassorders.map(gpo => <DisplayGolfPassItem>{
          orderId: order.id,
          golfpassOrderId: gpo.id,
          golfpassId: gpo.golfpass.id,
          category: "GOLF PASSES",
          name: gpo.golfpass.name,
          quantity: gpo.quantity,
          unitprice: gpo.unitprice,
          price: gpo.extprice,
          taxrate: ((order.course?.taxrate ?? 0) / 100),
        }))),
        takeUntil(this.destroyed$)
      );
  }

  private initProductOrders(): void {

    this.categories$ = of(this.order)
      .pipe(
        switchMap(order => {
          const pagedResult = this.categoryService.query({ course: order.course?.id ?? undefined});
          return pagedResult.records$;
        }),
        takeUntil(this.destroyed$)
      );

    this.productOrders$ = combineLatest([of(this.order), this.categories$]).pipe(
      switchMap(([order, categories]) => {
        const pagedResult = this.productOrderService.query({ order: order.id });
        return combineLatest([of(order), pagedResult.records$, of(categories)]);
      }),
      switchMap(([order, productOrders, categories]) => of(productOrders.map(po => <DisplayProductAddOnItem>{
        orderId: order.id,
        productOrderId: po.id,
        productId: po.product.id,
        category: categories.find(cat => cat.id == po?.product?.category)?.name || "PRODUCT",
        name: po.product.name,
        quantity: po.quantity,
        price: po.extprice,
        unitprice: po.unitprice,
        taxrate: po.product.taxable ? ((order.course?.taxrate ?? 0) / 100) : 0,
        taxable: po.product.taxable,
        available: po.product.available,
        refunded: po.refunded,
      }))),
      takeUntil(this.destroyed$)
    );
  }

  public discountAmount(order: OrderDetails) {
    return getOrderDiscountAmount(order);
  }

  public showDiscount(order: OrderDetails) {
    return this.discountAmount(order) > 0;
  }
}
