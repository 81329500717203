import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { StaffCreateComponent } from './staff-create/staff-create.component';
import { StaffEditComponent } from './staff-edit/staff-edit.component';
import { StaffListComponent } from './staff-list/staff-list.component';
import { StaffViewComponent } from './staff-view/staff-view.component';

const routes: Routes = [
  {
    path: 'create',
    component: StaffCreateComponent
  },
  {
    path: ':staffId/edit',
    component: StaffEditComponent
  },
  {
    path: ':staffId/view',
    component: StaffViewComponent
  },
  {
    path: '',
    component: StaffListComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StaffRoutingModule { }
