import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OrderDetails } from 'projects/shared/src/public-api';

@Component({
  selector: 'gcl-admin-add-event-reservation-modal',
  templateUrl: './add-event-reservation-modal.component.html',
  styleUrls: ['./add-event-reservation-modal.component.scss']
})
export class AddEventReservationModalComponent implements OnInit {
  get reservationPrice() { return this.form.get('reservationPrice'); }
  get eventSpace() { return this.form.get('eventSpace'); }
  get description() { return this.form.get('description'); }
  get start() { return this.form.get('start'); }
  get end() { return this.form.get('end'); }

  public form = this.fb.group({
    reservationPrice: [null, [Validators.required]],
    eventSpace: [null, [Validators.required]],
    description: ['', []],
    start: ['', [Validators.required]],
    end: ['', []]
  });

  public order!: OrderDetails;

  constructor(private bsModalRef: BsModalRef, private fb: FormBuilder) { }

  ngOnInit(): void {
  }

  addReservation() {
    
  }

  cancel(): void {
    this.bsModalRef.hide();
  }
}
