<div class="modal-header">
    <h4 class="modal-title pull-left">Add Printer</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span class="modal-close" aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="form">
        <div class="form-group">
            <label for="label">Printer Domain Name</label>
            <input type="text" class="form-control" id="label" formControlName="printerDomainName">
        </div>
        <div class="form-group">
            <label for="label">Device Name</label>
            <input type="text" class="form-control" id="label" formControlName="name">
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Cancel</button>
    <button type="button" [disabled]="!form.valid" class="btn btn-primary" (click)="save()">Save</button>
</div>