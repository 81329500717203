import { Inject, Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Router } from "@angular/router";
import { config, Observable } from "rxjs";
import { finalize } from "rxjs/operators";

import { LoadingScreenService } from "../services/loading-screen.service";
import { CONFIG_TOKEN, SharedModuleConfig } from "../shared.config";

@Injectable()
export class LoadingScreenInterceptor implements HttpInterceptor {

  activeRequests: number = 0;

  /**
   * URLs for which the loading screen should not be enabled
   */
  private skippUrls = [
    `${this.config.apiUrl}/orders/[0-9]+/product/[0-9]+/add`
  ];

  /**
   * Pages for which the loading screen should not be enabled
   */
  private skipPages = [
    `/admin/tee-sheet/reservation/[0-9]+/checkout`,
    `/manager/tee-sheet/reservation/[0-9]+/checkout`,
    `/admin/tee-sheet/[0-9]+/register-checkout`,
    `/manager/tee-sheet/[0-9]+/register-checkout`,
  ];

  constructor(private loadingScreenService: LoadingScreenService, @Inject(CONFIG_TOKEN) private config: SharedModuleConfig, private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let displayLoadingScreen = true;

    for (const skippUrl of this.skippUrls) {
      if (new RegExp(skippUrl).test(request.url)) {
        displayLoadingScreen = false;
        break;
      }
    }

    for (const skipPage of this.skipPages) {
      if (new RegExp(skipPage).test(this.router.url)) {
        displayLoadingScreen = false;
        break;
      }
    }

    if (displayLoadingScreen) {
      if (this.activeRequests === 0) {
        this.loadingScreenService.startLoading();
      }

      this.activeRequests++;
      return next.handle(request).pipe(
        finalize(() => {
          this.activeRequests--;
          if (this.activeRequests === 0) {
            this.loadingScreenService.stopLoading();
          }
        })
      );
    } else {
      return next.handle(request);
    }
  };

}