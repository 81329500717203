import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { Schedule, ScheduleDetails } from '../models/schedule';
import { PagedResult } from '../queries/paged-result';
import { ScheduleQuery } from '../queries/schedule-query';
import { CONFIG_TOKEN, SharedModuleConfig } from '../shared.config';
import { StrapiQueryableService } from './strapi-queryable.service';

declare var Qs: any;

@Injectable({
  providedIn: 'root'
})
export class ScheduleService extends StrapiQueryableService<Schedule, ScheduleDetails, ScheduleQuery> {

  constructor(protected httpClient: HttpClient, @Inject(CONFIG_TOKEN) protected config: SharedModuleConfig) {
    super({ path: 'schedules' }, httpClient, config);
  }

  protected buildQuery(query?: ScheduleQuery): string {
    let queryObj = super.buildBaseQueryObj(query);

    // Specific Query filters.
    if (query) {
      if (query.search) {
        queryObj._where.push({
          _or: [{
            id_contains: query.search
          }, {
            firstName: query.search
          }, {
            lastName: query.search
          }]
        });
      }

      if (query.id) {
        queryObj._where.push({ id: query.id });
      }
      if (query.status) {
        queryObj._where.push({ status: query.status });
      }
      if (query.course) {
        queryObj._where.push({ course: query.course });
      }
      if (query.users_permissions_user) {
        queryObj._where.push({ users_permissions_user: query.users_permissions_user });
      }
      if (query.round) {
        queryObj._where.push({ round: query.round });
      }
      if (query.golforders) {
        queryObj._where.push({ golforders: query.golforders });
      }
      if (query.productorders) {
        queryObj._where.push({ productorders: query.productorders });
      }

      if (query.startDate) {
        queryObj._where.push({ startDate_gte: query.startDate });
      }
      if (query.endDate) {
        queryObj._where.push({ endDate_lte: query.endDate });
      }
      if (query.golfPass) {
        queryObj._where.push({ golfPass: query.golfPass });
      }
      if (query.type) {
        queryObj._where.push({ type: query.type });
      }
    }

    return Qs.stringify(queryObj);
  }

  public query(query?: ScheduleQuery): PagedResult<ScheduleDetails> {
    const total$ = this.count();
    const filteredTotal$ = this.count(query);
    const queryStr = this.buildQuery(query);
    const records$ = this.httpClient.get<ScheduleDetails[]>(`${this.config.apiUrl}/${this.pathConfig.path}?${queryStr}`)
      .pipe(
        shareReplay(1)
      );;
    return new PagedResult(records$, filteredTotal$, total$);
  }

  public count(query?: ScheduleQuery): Observable<number> {
    const queryStr = this.buildQuery(query);
    return this.httpClient.get<number>(`${this.config.apiUrl}/${this.pathConfig.path}/count?${queryStr}`)
      .pipe(
        shareReplay(1)
      );
  }

  public generate(scheduleId: number, date: Date): Observable<number> {
    return this.httpClient.post<number>(`${this.config.apiUrl}/${this.pathConfig.path}/generate`, {
      schedule_id: scheduleId,
      date: date
    })
      .pipe(
        shareReplay(1)
      );
  }
}
