import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Terminal, DiscoverResult, Reader, ISdkManagedPaymentIntent, ErrorResponse } from '@stripe/terminal-js';
import { ILineItem, IPaymentIntent } from '@stripe/terminal-js/types/proto';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { OrderDetails } from 'projects/shared/src/lib/models/order';
import { GolfOrderService } from 'projects/shared/src/lib/services/golf-order.service';
import { ProductOrderService } from 'projects/shared/src/lib/services/product-order.service';
import { StripeTerminalService } from 'projects/shared/src/lib/services/stripe-terminal.service';
import { StripeService } from 'projects/shared/src/lib/services/stripe.service';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CheckoutService } from '../../checkout.service';
import { CustomPaymentModalComponent } from '../custom-payment-modal/custom-payment-modal.component';
import { PrinterDetails } from 'projects/shared/src/lib/models/printer';
import { PrinterService } from 'projects/shared/src/lib/services/printer.service';
import { currencyRound } from 'projects/shared/src/lib/utili/currency-round';

enum ReaderState {
  Disconnected = 'disconnected',
  Connecting = 'connecting',
  Connected = 'connected',
  ConnectError = 'connectError',
  Collecting = 'collecting',
  Collected = 'collected',
  CollectError = 'collectError',
  Confirming = 'confirming',
  Confirmed = 'confirmed',
  ConfirmError = 'confirmError',
  Capturing = 'capturing',
  Captured = 'captured',
  CaptureError = 'captureError'
}

@Component({
  selector: 'gcl-admin-checkout-pay-reader',
  templateUrl: './checkout-pay-reader.component.html',
  styleUrls: ['./checkout-pay-reader.component.scss']
})
export class CheckoutPayReaderComponent implements OnInit, OnDestroy {

  public order$!: Observable<OrderDetails>;
  public readers?: Reader[];
  public activeReader?: Reader;

  private courseId$: Observable<number>;
  private terminal?: Terminal;

  ReaderState = ReaderState;
  public currentState: ReaderState = ReaderState.Disconnected;

  private collectResult?: ErrorResponse | { paymentIntent: ISdkManagedPaymentIntent; };
  private confirmResult?: ErrorResponse | { paymentIntent: IPaymentIntent; }

  modalRef!: BsModalRef;
  email: string = '';

  public printers$!: Observable<PrinterDetails[]>;
  public printerArr: number[] = [];
  public disableEmail: boolean = true;

  constructor(
    private checkoutService: CheckoutService, 
    private toastrService: ToastrService, 
    private stripeService: StripeService, 
    private modalService: BsModalService, 
    private stripeTerminalService: StripeTerminalService, 
    private golfOrderService: GolfOrderService, 
    private productOrderService: ProductOrderService,
    private printerService: PrinterService
  ) { 
    this.order$ = this.checkoutService.order$;
    this.courseId$ = this.order$.pipe(map(order => order.course!.id))

    this.order$.subscribe((order) => {
      if (order.course) {
        this.printers$ = this.printerService.query({
          course: order.course.id
        }).records$.pipe();
      }
    });
  }

  async ngOnInit() {
    const courseId = await this.courseId$.pipe(take(1)).toPromise();
    let terminal = await this.stripeTerminalService.getClient(courseId);
    if(terminal) {
      this.terminal = terminal;
      let result = await this.terminal.discoverReaders() as DiscoverResult;
      if(result?.discoveredReaders) {
        this.readers = result.discoveredReaders;
      }
    }
  }

  setState(state: ReaderState) {
    this.currentState = state;
  }

  async connectReader(reader: Reader) {
    if(this.currentState == ReaderState.Disconnected && this.terminal) {
      
      this.setState(ReaderState.Connecting);

      const order = await this.order$.pipe(take(1)).toPromise();
      const golforders = (await combineLatest(order.golforders.map(go => this.golfOrderService.get(go.id))).toPromise()) ?? [];
      const productOrders = (await this.productOrderService.query({ order: order.id }).records$.toPromise()) ?? [];

      let connectResult = await this.terminal.connectReader(reader) as { reader: Reader };
      this.activeReader = connectResult.reader;

      let lineItems: ILineItem[] = [];
      for(const golfOrder of golforders) {
        lineItems.push({
          description: golfOrder.golfproduct.name,
          amount: currencyRound(golfOrder.extprice * 100),
          quantity: golfOrder.quantity
        });
      }

      for(const productOrder of productOrders) {
        lineItems.push({
          description: productOrder.product.name,
          amount: currencyRound(productOrder.extprice * 100),
          quantity: productOrder.quantity
        });
      }

      this.terminal.setReaderDisplay({
        type: 'cart',
        cart: {
          line_items: lineItems,
          tax: currencyRound(order.tax * 100),
          total: currencyRound(order.finaltotal * 100),
          currency: 'USD'
        }
      });

      this.setState(ReaderState.Connected);
    }
  }

  async collectPayment(customAmount: number | undefined = undefined) {
    if(this.terminal) {
      
      this.setState(ReaderState.Collecting);

      const order = await this.order$.pipe(take(1)).toPromise();

      const paymentAmount = customAmount ?? order.finaltotal;

      let paymentIntent = await this.stripeService.createPaymentIntent(order.users_permissions_user?.id, order.id, paymentAmount, 'manual').toPromise();      
      let collectResult = await this.terminal.collectPaymentMethod(paymentIntent.clientSecret);
      if((collectResult as ErrorResponse).error) {
        const { error } = collectResult as ErrorResponse;
        this.toastrService.warning('Reader payment cancelled');
        console.error(error);
        this.setState(ReaderState.CollectError);
      } else {
        this.setState(ReaderState.Collected);
        this.collectResult = collectResult;
      }
    }
  }

  async collectCustomPayment() {
    let order = await this.order$.pipe(take(1)).toPromise();

    let customModalRef = this.modalService.show(CustomPaymentModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        order,
        showEmail: false
      },
      class: "modal-md modal-dialog-centered"
    });

    customModalRef.content?.onCustomAmountSelect.subscribe(async confirmation => {
      if(confirmation.amount && confirmation.amount > 0) {
        customModalRef.hide();
        await this.collectPayment(confirmation.amount);
      }
    })
  }

  async confirmPayment() {
    if(this.terminal) {

        this.setState(ReaderState.Confirming);

        // Process payment after collection
        const { paymentIntent } = this.collectResult as { paymentIntent: ISdkManagedPaymentIntent };
        const confirmResult = await this.terminal.processPayment(paymentIntent);        
  
        if((confirmResult as ErrorResponse).error) {
          const { error } = confirmResult as ErrorResponse;
          this.toastrService.error('Payment error', error.message);
          console.error(error);
          this.setState(ReaderState.ConfirmError);
        } else {
          this.setState(ReaderState.Confirmed);
          this.confirmResult = confirmResult;
        }
    }
  }

  async capturePayment() {
    this.setState(ReaderState.Capturing);

    const order = await this.order$.pipe(take(1)).toPromise();

    const { paymentIntent } = this.confirmResult as { paymentIntent: IPaymentIntent };
    if(paymentIntent.id) {
      await this.stripeService.capturePaymentIntent(paymentIntent.id).toPromise();
      this.setState(ReaderState.Captured);
    }
  }

  async changeReader() {
    await this.terminal?.disconnectReader();

    if(this.currentState == ReaderState.Collected) {
      await this.terminal?.cancelCollectPaymentMethod();
    }

    // cancel payment intent?
    this.activeReader = undefined;
    this.confirmResult = undefined;
    this.collectResult  = undefined;

    this.setState(ReaderState.Disconnected);
  }

  async submit() {
    const order = await this.order$.pipe(take(1)).toPromise();
    await this.confirmPayment();
    await this.capturePayment();
    await this.checkoutService.checkoutSucces("Payment is successful.", order.id, this.printerArr, this.email);
  }

  updatePrinters(event: any) {
    if (event.target.checked) {
      if (this.printerArr.indexOf(event.target.value) < 0) {
        this.printerArr.push(event.target.value);
      }
    } else {
      if (this.printerArr.indexOf(event.target.value) > -1) {
          this.printerArr.splice(this.printerArr.indexOf(event.target.value), 1);
      }
    }
  }

  public async openReceiptModal(template: TemplateRef<any>, order: OrderDetails) {
    let printers = await this.printers$.pipe(take(1)).toPromise();

    this.printerArr = [];

    if (order.users_permissions_user) {
      this.email = order.users_permissions_user.email;
      this.disableEmail = true;
    } else {
      this.email = '';
      this.disableEmail = false;
    }

    if (printers.length > 0) {
      this.modalRef = this.modalService.show(template, { ignoreBackdropClick: true, class: 'modal-dialog-centered' });
    } else {
      if (order.users_permissions_user) {
        this.emailReceipt(order);
      }
      else {
        this.modalRef = this.modalService.show(template, { ignoreBackdropClick: true, class: 'modal-dialog-centered' });
      }
    }
  }

  public emailReceipt(order: OrderDetails) {
    if (this.email || this.printerArr.length !== 0) {
      this.modalRef?.hide();
      this.submit();
    }
    else {
      this.displayError('Please enter a valid email or select a printer.');
    }
  }

  private displayError(message: string) {
    this.toastrService.error(message);
  }

  async ngOnDestroy() {
    if(this.activeReader) {
      await this.changeReader();
    }
  }

}
