import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'gcl-lib-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  get email() { return this.resetForm.get('email') as FormControl; }

  resetForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]]
  });

  @Output()
  onSubmit = new EventEmitter();

  constructor(private fb: FormBuilder, private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
  }

  submit() {
    this.authService.sendForgotPassword(this.email?.value)
      .subscribe(result => {
        this.resetForm.setErrors({});
        this.onSubmit.emit();
      }, err => {
        this.resetForm.setErrors({
          invalidEmail: true
        })
      });
  }

}
