<div class="container-fluid py-2">
    <div class="row" *ngIf="user$ | async as user">
        <div class="col-12">
            <form [formGroup]="form" (ngSubmit)="submit()">
                <div class="d-flex justify-content-end py-2">
                    <button type="button" (click)="openCancelModal(user)" class="btn btn-outline-light-green px-5">Cancel</button>
                    <button type="button" (click)="openSaveModal()" class="btn btn-light-green px-5">Save</button>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label for="firstName" >First Name</label>
                                            <input type="text" class="form-control" formControlName="firstName" name="firstName" [class.is-invalid]="firstName?.invalid && firstName?.touched" />
                                            <div *ngIf="firstName?.invalid && firstName?.touched" class="invalid-feedback">
                                                <div *ngIf="firstName?.errors?.required">
                                                    Please enter a first name.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label for="lastName" >Last Name</label>
                                            <input type="text" class="form-control" formControlName="lastName" name="lastName" [class.is-invalid]="lastName?.invalid && lastName?.touched" />
                                            <div *ngIf="lastName?.invalid && lastName?.touched" class="invalid-feedback">
                                                <div *ngIf="lastName?.errors?.required">
                                                    Please enter a last name.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label for="role">Role</label>
                                            <select  class="form-control" formControlName="role" name="role" [class.is-invalid]="role?.invalid && role?.touched">
                                                <option [value]="Roles.Manager">Manager</option>
                                                <option [value]="Roles.Administrator">Administrator</option>
                                            </select>
                                            <div *ngIf="role?.invalid && role?.touched" class="invalid-feedback">
                                                <div *ngIf="role?.errors?.required">
                                                    Please select a role.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label for="courses">Course</label>
                                            <select  class="form-control" multiple formControlName="courses" name="courses" [class.is-invalid]="courses?.invalid && courses?.touched">
                                                <option *ngFor="let course of courses$ | async" [value]="course.id">{{course.name}}</option>
                                            </select>
                                            <div *ngIf="courses?.invalid && courses?.touched" class="invalid-feedback">
                                                <div *ngIf="courses?.errors?.required">
                                                    Please select a course.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label for="cellphone" >Cell Phone</label>
                                            <input type="text" class="form-control" formControlName="cellphone" name="cellphone" [class.is-invalid]="cellphone?.invalid && cellphone?.touched" />
                                            <div *ngIf="cellphone?.invalid && cellphone?.touched" class="invalid-feedback">
                                                <div *ngIf="cellphone?.errors?.required">
                                                    Please enter a cell phone.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label for="confirmed" >Confirmed</label>
                                            <gcl-lib-toggle-switch formControlName="confirmed"></gcl-lib-toggle-switch>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label for="blocked" >Blocked</label>
                                            <gcl-lib-toggle-switch formControlName="blocked"></gcl-lib-toggle-switch>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="username" >Username</label>
                                            <input type="text" class="form-control" formControlName="username" name="username" [class.is-invalid]="username?.invalid && username?.touched" />
                                            <div *ngIf="username?.invalid && username?.touched" class="invalid-feedback">
                                                <div *ngIf="username?.errors?.required">
                                                    Please enter a user name.
                                                </div>
                                                <div *ngIf="username?.errors?.error">
                                                    {{username?.errors?.error}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="email" >Email</label>
                                            <input type="text" class="form-control" formControlName="email" name="email" [class.is-invalid]="email?.invalid && email?.touched" />
                                            <div *ngIf="email?.invalid && email?.touched" class="invalid-feedback">
                                                <div *ngIf="email?.errors?.required">
                                                    Please enter a email.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label for="licenseProvider" >License Provider</label>
                                            <gcl-lib-state-dropdown formControlName="licenseProvider" [class.is-invalid]="licenseProvider?.invalid && licenseProvider?.touched" ></gcl-lib-state-dropdown>
                                            <div *ngIf="licenseProvider?.invalid && licenseProvider?.touched" class="invalid-feedback">
                                                <div *ngIf="licenseProvider?.errors?.required">
                                                    Please enter a license provider.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label for="licenseNumber" >License Number</label>
                                            <input type="text" class="form-control" formControlName="licenseNumber" name="licenseNumber" [class.is-invalid]="licenseNumber?.invalid && licenseNumber?.touched" />
                                            <div *ngIf="licenseNumber?.invalid && licenseNumber?.touched" class="invalid-feedback">
                                                <div *ngIf="licenseNumber?.errors?.required">
                                                    Please enter a license number.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label for="licenseExpires" >License Expires</label>
                                            <input type="date" class="form-control" formControlName="licenseExpires" name="licenseExpires" [class.is-invalid]="licenseExpires?.invalid && licenseExpires?.touched" />
                                            <div *ngIf="licenseExpires?.invalid && licenseExpires?.touched" class="invalid-feedback">
                                                <div *ngIf="licenseExpires?.errors?.required">
                                                    Please enter a license expires.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label for="dateOfBirth" >Date Of Birth</label>
                                            <input id="dateOfBirth" type="date" formControlName="dateOfBirth" placeholder="Date Of Birth" class="form-control" [class.is-invalid]="dateOfBirth.touched && dateOfBirth.invalid" />
                                            <div *ngIf="dateOfBirth?.invalid && dateOfBirth?.touched" class="invalid-feedback">
                                                <div *ngIf="dateOfBirth?.errors?.required">
                                                    Please enter a date of birth.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
