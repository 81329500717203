<div class="d-flex flex-column h-100">
    <div class="d-flex justify-content-start py-2 bg-light no-flex">
        <a class="text-light-green" [routerLink]="['/manager','schedules']">
            <fa-icon [icon]="faChevronLeft" class="px-3"></fa-icon> <span>Back to Schedules</span>
        </a>
    </div>
    
    <div class="container-fluid py-2 fill-flex overflow-y-auto">
        <div class="row" *ngIf="schedule$ | async as schedule">
            <div class="col-12">
                <h6 class="page-header">Schedule View</h6>
                <div class="card">
                    <div class="card-body">
                       <div class="row">
                            <div class="col-8">
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label >Name</label>
                                            <p>{{schedule.name}}</p>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label >Active</label>
                                            <p>{{schedule.active ? 'Yes' : 'No'}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label >Start Time of Day</label>
                                            <p>{{schedule.starttod}}</p>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label >End Time of Day</label>
                                            <p>{{schedule.endtod}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label >First 9</label>
                                            <p>{{schedule.first9}}</p>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label >Second 9</label>
                                            <p>{{schedule.second9}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label >Monday</label>
                                            <p>{{schedule.monday ? 'Yes' : 'No'}}</p>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label >Tuesday</label>
                                            <p>{{schedule.tuesday ? 'Yes' : 'No'}}</p>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label >Wednesday</label>
                                            <p>{{schedule.wednesday ? 'Yes' : 'No'}}</p>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label >Thursday</label>
                                            <p>{{schedule.thursday ? 'Yes' : 'No'}}</p>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label >Friday</label>
                                            <p>{{schedule.friday ? 'Yes' : 'No'}}</p>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label >Saturday</label>
                                            <p>{{schedule.saturday ? 'Yes' : 'No'}}</p>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label >Sunday</label>
                                            <p>{{schedule.sunday ? 'Yes' : 'No'}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label >Golf Pass Discount Amount</label>
                                            <p>{{schedule.golfPassDiscountAmount || "N/A"}}</p>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label >Golf Pass Discount Percent</label>
                                            <p>{{schedule.golfPassDiscountPercent || "N/A"}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label >Description</label>
                                            <p>{{schedule.description}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label >Golf Products</label>
                                            <ul class="list-group">
                                                <li class="list-group-item">{{schedule.golfproduct?.name || "N/A"}}</li>
                                            </ul>                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                       </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

