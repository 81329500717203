import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { ProductsRoutingModule } from './products-routing.module';
import { ProductListComponent } from './product-list/product-list.component';
import { ProductViewComponent } from './product-view/product-view.component';
import { ProductCreateComponent } from './product-create/product-create.component';
import { ProductEditComponent } from './product-edit/product-edit.component';

import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from 'projects/shared/src/public-api';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AdminSharedModule } from '../../shared/admin-shared.module';

@NgModule({
  declarations: [ProductListComponent, ProductViewComponent, ProductCreateComponent, ProductEditComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    
    SharedModule,
    CarouselModule.forRoot(),
    ToastrModule.forRoot(),
    FontAwesomeModule,
    AdminSharedModule,

    ProductsRoutingModule
  ]
})
export class ProductsModule { }
