<div class="container-fluid py-2">
    <div class="row">
        <div class="col-12" *ngIf="order$ | async as order">
            <gcl-lib-date-picker-slider
                [date]="selectedDate"
                [format]="'MMMM YYYY'"
                [readonly]="true"></gcl-lib-date-picker-slider>
            <h5  class="text-center font-weight-bold">{{selectedTime | date: 'shortTime'}} - {{nineType}}</h5>
            <h6  class="page-header">Tee Sheet</h6>
            <ng-container *ngIf="order$ | async as order">
                <gcl-admin-reservation-form
                [availableSlots]="reservation.maxSlots"
                    [allowSqueezeTime]="false"
                    [disableGroupReservation]="true"
                    [disableBlockReservation]="true"
                    (submit)="submit($event)"
                    (cancel)="cancel()"></gcl-admin-reservation-form>
            </ng-container>
        </div>
    </div>
</div>
