<div style="overflow: auto;" *ngIf="order">
    <!-- Golf Product -->
    <ng-container *ngFor="let item of golfOrders$ | async">
        <gcl-admin-checkout-item
            [category]="item.category"
            [name]="item.name"
            [quantity]="item.quantity"
            [unitprice]="item.unitprice"
            [price]="item.price"
            [taxrate]="item.taxrate"
            [refunded]="item.refunded"
        ></gcl-admin-checkout-item>
    </ng-container>

    <!-- Golf Passes -->
    <ng-container *ngFor="let item of golfpassOrders$ | async">
        <gcl-admin-checkout-item
            [category]="item.category"
            [name]="item.name"
            [quantity]="item.quantity"
            [unitprice]="item.unitprice"
            [price]="item.price"
            [taxrate]="item.taxrate"                                    
        ></gcl-admin-checkout-item>
    </ng-container>

    <!-- Product Add-ons -->
    <ng-container *ngFor="let item of productOrders$ | async">
        <gcl-admin-checkout-item
            [category]="item.category"
            [name]="item.name"
            [quantity]="item.quantity"
            [unitprice]="item.unitprice"                                    
            [price]="item.price"
            [taxrate]="item.taxrate"
            [refunded]="item.refunded"
        ></gcl-admin-checkout-item>
    </ng-container>

    <ng-container *ngIf="order.promo_code">
        <div class="p-4 divider-bt discount">
            <div class="d-flex flex-row justify-content-between">
                <h6 class="font-weight-bold m-0">{{$any(order.promo_code).code | uppercase}}</h6>
                <div class="d-flex flex-row justify-content-between align-items-center">
                    <h6 class="mb-0 font-weight-bold" *ngIf="$any(order.promo_code).discountAmount > 0">({{$any(order.promo_code).discountAmount | currency}})</h6>
                    <h6 class="ml-2 mb-0 font-weight-bold" *ngIf="$any(order.promo_code).discountPercent > 0">({{$any(order.promo_code).discountPercent}}% off)</h6>
                </div>
            </div>
            <div class="d-flex flex-row justify-content-start align-items-center">
                <span class="subtitle mt-1">PROMO CODE</span>
            </div>
        </div>
    </ng-container>

    <div class="p-4">
        <div class="d-flex flex-row justify-content-between">
            <h5 class="mb-0">Subtotal</h5>
            <h5 class="mb-0">{{order.subtotal | currency}}</h5>                                  
        </div>
        <div class="d-flex flex-row justify-content-between mt-3 discount" *ngIf="showDiscount(order)">
            <h5 class="mb-0">Discount</h5>
            <h5 class="mb-0">({{this.discountAmount(order) | currency}})</h5>                                  
        </div>
        <div class="d-flex flex-row justify-content-between mt-3">
            <h5 class="mb-0">Tax</h5>                                            
            <h5 class="mb-0">{{order.tax | currency}}</h5>                          
        </div>
        <ng-container  *ngIf="(difference$ | async) as difference; else finalTotal">
            <div class="d-flex flex-row justify-content-between mt-3" *ngIf="difference < 0">
                <h6 class="mb-0">Previous Total</h6>
                <h6 class="mb-0">{{-1*(difference - order.total) | currency}}</h6>                                  
            </div>
            <div class="d-flex flex-row justify-content-between mt-3" *ngIf="difference < 0">
                <h6 class="mb-0">Difference</h6>
                <h6 class="mb-0 text-danger">{{difference | currency}}</h6>                                  
            </div>
            <div class="d-flex flex-row justify-content-between mt-4">
                <h5 class="font-weight-bold mb-0" *ngIf="difference < 0">Refund</h5>
                <h5 class="font-weight-bold mb-0" *ngIf="difference < 0">{{-1*difference | currency}}</h5>

                <h5 class="font-weight-bold mb-0" *ngIf="difference > 0">Total</h5>
                <h5 class="font-weight-bold mb-0" *ngIf="difference > 0">{{difference | currency}}</h5>
            </div>
        </ng-container>
        <ng-template #finalTotal>
            <div class="d-flex flex-row justify-content-between mt-4">
                <h5 class="font-weight-bold mb-0">Total</h5>
                <h5 class="font-weight-bold mb-0">{{order.finaltotal | currency}}</h5>
            </div>
        </ng-template>
    </div>

</div>