import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Weather, WeatherDetails } from '../models/weather';
import { BaseQuery } from '../queries/base-query';
import { CONFIG_TOKEN, SharedModuleConfig } from '../shared.config';
import { SharedModule } from '../shared.module';
import { StrapiService } from './strapi.service';

@Injectable({
  providedIn: 'root'
})
export class WeatherService extends StrapiService<Weather, WeatherDetails> {

  constructor(protected httpClient: HttpClient, @Inject(CONFIG_TOKEN) protected config: SharedModuleConfig) {
    super({ path: 'weathers' }, httpClient, config);
  }

}
