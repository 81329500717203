import { FormControl, ValidationErrors, Validators } from "@angular/forms";

export class RequiredFieldValidators {
    static RequiredIf(predicate: Function): ValidationErrors | null {
        return (formControl: FormControl) => {
            if (!formControl.parent || !predicate()) {
                return null;
            }
            return Validators.required(formControl);
        }
    }
}