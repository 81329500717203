export enum OrderPaymentTypes {
    Digital = "Digital",
    DigitalRefund = "DigitalRefund",
    Cash = "Cash",
    CashRefund = "CashRefund",
    CreditBalance = "CreditBalance",
    CreditBalanceRefund = "CreditBalanceRefund",
}

export function GetOrderPaymentTypesDisplayName(status: OrderPaymentTypes): string {
    switch (status) {
        case OrderPaymentTypes.Digital:
            return "Digital";
        case OrderPaymentTypes.DigitalRefund:
            return "Digital Refund";
        case OrderPaymentTypes.Cash:
            return "Cash";
        case OrderPaymentTypes.CashRefund:
            return "Cash Refund";
        case OrderPaymentTypes.CreditBalance:
            return "Credit Balance"
        case OrderPaymentTypes.CreditBalanceRefund:
            return "Credit Balance Refund"
        default:
            return status;
    }
}