<div class="h-100 overflow-y-auto d-flex flex-column">
    <!-- Filters -->
    <div class="d-flex justify-content-start px-3  crud-filters no-flex">
        <form [formGroup]="filters">
            <div class="form-row">
                <div class="form-group m-0 pr-4">
                    <select class="form-control" formControlName="status" name="status"  
                        [hasNullOption]="true"
                        enum="CartStatuses">
                    </select>
                </div>
            </div>
        </form>
    </div>

    <div class="container-fluid py-2 fill-flex">
        <div class="row">
            <div class="col-12">
                <h6 class="page-header">Carts List</h6>
                <gcl-lib-table 
                    [totalItems]="total$ | async" 
                    [sortBy]="'id'" 
                    (onPage)="pagination$.next($event)" 
                    [tableClass]="'table table-striped table-hover'"
                    [enableCreate]="false">
                    <thead>
                        <tr>
                            <th columnSort="name">Name</th>
                            <th columnSort="user">Customer</th>
                            <th columnSort="status">Status</th>
                            <ng-container *ngIf="records$ | async as records">
                                <th width="95" class="text-warning cursor-pointer" (click)="clearAllCarts()">Clear All</th>
                            </ng-container>
                            <th width="100" class="text-center">Manage</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let cart of records$ | async">
                            <td>{{cart.name}}</td>
                            <td>{{cart.user?.firstName}} {{cart.user?.lastName}}</td>
                            <td>{{getCartStatusDisplayName(cart.status)}}</td>
                            <td class="text-right cursor-pointer"><span (click)="clearCart(cart)">Clear</span></td>
                            <td class="text-center">
                                <a [routerLink]="['/manager','carts', cart.id, 'edit']" class="text-light-green">Assign</a>
                                <span class="text-secondary px-2">|</span>
                                <a [routerLink]="['/manager','carts', cart.id, 'view']" class="text-light-green">View</a>
                            </td>
                        </tr>
                        <tr *ngIf="(total$ | async) == 0">
                            <td colspan="7">No carts.</td>
                        </tr>
                    </tbody>
                </gcl-lib-table>
            </div>
        </div>
    </div>
</div>
