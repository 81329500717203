<form [formGroup]="form">
    <!-- <div class="card">
        <div class="card-body">
            <div class="row">

                <div class="col-12 col-lg-3">
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label >Golf Passes</label>
                                <gcl-lib-search-golf-passes
                                    [multiselect]="true"
                                    (addItem)="addGolfPass($event)">
                                    <ul class="list-group">
                                        <li class="list-group-item" *ngFor="let value of golfPasses.value">
                                            <span>{{value.price | currency}} - {{value.name}}</span>
                                            <fa-icon class="float-right" [icon]="faTrash" (click)="removeGolfPass(value)"></fa-icon>
                                        </li>
                                    </ul>
                                </gcl-lib-search-golf-passes>
                                <div *ngIf="golfPasses?.invalid && golfPasses?.touched" class="invalid-feedback">
                                    <div *ngIf="golfPasses?.errors?.required">
                                        Please enter a golf pass.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div> -->
    <div class="row border mx-1">
        <div class="col-12 col-lg-7 p-0">
            <div class="row mx-0 pt-3">
                <div class="col-12 p-0">
                    <div class="row mx-0">
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <label for="firstName" >First Name</label>
                                <input type="text" class="form-control" formControlName="firstName" name="firstName" [class.is-invalid]="firstName?.invalid && firstName?.touched" />
                                <div *ngIf="firstName?.invalid && firstName?.touched" class="invalid-feedback">
                                    <div *ngIf="firstName?.errors?.required">
                                        Please enter a first name.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <label for="lastName" >Last Name</label>
                                <input type="text" class="form-control" formControlName="lastName" name="lastName" [class.is-invalid]="lastName?.invalid && lastName?.touched" />
                                <div *ngIf="lastName?.invalid && lastName?.touched" class="invalid-feedback">
                                    <div *ngIf="lastName?.errors?.required">
                                        Please enter a last name.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0 pt-3">
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="email" >Email</label>
                        <input type="text" class="form-control" formControlName="email" name="email" [class.is-invalid]="email?.invalid && email?.touched" />
                        <div *ngIf="email?.invalid && email?.touched" class="invalid-feedback">
                            <div *ngIf="email?.errors?.required">
                                Please enter a email.
                            </div>
                            <div *ngIf="email?.errors?.duplicate">
                                Email is already taken.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="username" >Username</label>
                        <input type="text" class="form-control" formControlName="username" name="username" [class.is-invalid]="username?.invalid && username?.touched" />
                        <div *ngIf="username?.invalid && username?.touched" class="invalid-feedback">
                            <div *ngIf="username?.errors?.required">
                                Please enter a user name.
                            </div>
                            <div *ngIf="username?.errors?.error">
                                {{username?.errors?.error}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>                                      
            <div class="row mx-0 pt-3">
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="cellphone" >Cell Phone</label>
                        <input type="text" class="form-control" formControlName="cellphone" name="cellphone" [class.is-invalid]="cellphone?.invalid && cellphone?.touched" />
                        <div *ngIf="cellphone?.invalid && cellphone?.touched" class="invalid-feedback">
                            <div *ngIf="cellphone?.errors?.required">
                                Please enter a cell phone.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="dateOfBirth" >Date Of Birth</label>
                        <input id="dateOfBirth" type="date" formControlName="dateOfBirth" placeholder="Date Of Birth" class="form-control"
                            [class.is-invalid]="lastName.touched && lastName.invalid" />
                        <div *ngIf="dateOfBirth?.invalid && dateOfBirth?.touched" class="invalid-feedback">
                            <div *ngIf="dateOfBirth?.errors?.required">
                                Please enter a date of birth.
                            </div>
                        </div>
                    </div>
                </div>
            </div>         
            <hr class="my-0 mx-3" />       
            <div *ngIf="showAddPaymentMethodButton || showRedeemGiftCertificateButton" class="mt-3 mx-3 d-flex justify-content-end align-items-center">
                <button class="btn btn-light-green" *ngIf="showAddPaymentMethodButton" (click)="addPaymentClicked()">Add Payment Method</button>
                <button class="btn btn-light-green ml-2" *ngIf="showRedeemGiftCertificateButton" (click)="redeemGiftCertificateClicked()">Redeem Gift Certificate</button>
            </div>  
            <div class="mt-3 mx-3 font-weight-bold">Payment Methods</div>                     
                <gcl-lib-stripe-payment-methods                        
                [userId]="user?.id"
                [showNewCardEntry]="false"
                [showUserCreditBalance]="true"
                ></gcl-lib-stripe-payment-methods>

        </div>
        <div class="bg-light col-12 col-lg-5 p-0">
            <div class="row m-0 pt-3">
                <div class="col-12">
                    <div class="form-group">
                        <label for="licenseNumber" >License Number</label>
                        <input type="text" class="form-control" formControlName="licenseNumber" name="licenseNumber" [class.is-invalid]="licenseNumber?.invalid && licenseNumber?.touched" />
                        <div *ngIf="licenseNumber?.invalid && licenseNumber?.touched" class="invalid-feedback">
                            <div *ngIf="licenseNumber?.errors?.required">
                                Please enter a license number.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row m-0 pt-3">
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="licenseProvider" >State / Provider</label>
                        <gcl-lib-state-dropdown formControlName="licenseProvider" [class.is-invalid]="licenseProvider?.invalid && licenseProvider?.touched" ></gcl-lib-state-dropdown>
                        <div *ngIf="licenseProvider?.invalid && licenseProvider?.touched" class="invalid-feedback">
                            <div *ngIf="licenseProvider?.errors?.required">
                                Please enter a license provider.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="licenseExpires" >License Expires</label>
                        <input type="date" class="form-control" formControlName="licenseExpires" name="licenseExpires" [class.is-invalid]="licenseExpires?.invalid && licenseExpires?.touched" />
                        <div *ngIf="licenseExpires?.invalid && licenseExpires?.touched" class="invalid-feedback">
                            <div *ngIf="licenseExpires?.errors?.required">
                                Please enter a license expires.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row m-0 pt-3">
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="confirmed" >Confirmed</label>
                        <gcl-lib-toggle-switch formControlName="confirmed"></gcl-lib-toggle-switch>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="blocked" >Blocked</label>
                        <gcl-lib-toggle-switch formControlName="blocked"></gcl-lib-toggle-switch>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="isGuest" >Is Guest?</label>
                        <gcl-lib-toggle-switch formControlName="isGuest"></gcl-lib-toggle-switch>
                    </div>
                </div>
                <div class="col-12 col-md-6 d-none">
                    <div class="form-group">
                        <label for="isLeague" >Is League?</label>
                        <gcl-lib-toggle-switch formControlName="isLeague"></gcl-lib-toggle-switch>
                    </div>
                </div>
            </div>
            <hr class="my-0 mx-3" />
        </div>
    </div>

</form>