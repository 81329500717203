<div class="input-group">
    <div class="input-group-prepend round">
      <button class="btn shadow-none" type="button" (click)="decrement()">
          <fa-icon class="text-info" [icon]="faMinus"></fa-icon>
      </button>
    </div>
    <input type="text" class="form-control text-center" *ngIf="!min && !max" [class.border-0]="!bordered" [(ngModel)]="_value" (change)="change($event)">
    <input type="text" class="form-control text-center" [min]="min" *ngIf="min && !max" [class.border-0]="!bordered" [(ngModel)]="_value" (change)="change($event)">
    <input type="text" class="form-control text-center" [max]="max" *ngIf="!min && max" [class.border-0]="!bordered" [(ngModel)]="_value" (change)="change($event)">
    <input type="text" class="form-control text-center" [min]="min" [max]="max" *ngIf="min && max" [class.border-0]="!bordered" [(ngModel)]="_value" (change)="change($event)">
    <div class="input-group-append">
      <button class="btn shadow-none" type="button" (click)="increment()">
        <fa-icon class="text-info" [icon]="faPlus"></fa-icon>
      </button>
    </div>
  </div>