import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LayoutComponent } from './layout/layout.component';
import { AdminRoutingModule } from './admin-routing.module';
import { CartsModule } from './carts/carts.module';
import { CoursesModule } from './courses/courses.module';
import { PlayersModule } from './players/players.module';
import { ProductsModule } from './products/products.module';
import { TeeSheetModule } from './tee-sheet/tee-sheet.module';
import { SchedulesModule } from './schedules/schedules.module';
import { OrdersModule } from './orders/orders.module';
import { StaffModule } from './staff/staff.module';
import { GolfPassesModule } from './golf-passes/golf-passes.module';
import { PromoCodesModule } from './promo-codes/promo-codes.module';
import { EventsModule } from './events/events.module';

@NgModule({
  declarations: [LayoutComponent],
  imports: [
    CommonModule,

    TeeSheetModule,
    CartsModule,
    CoursesModule,
    PlayersModule,
    OrdersModule,
    SchedulesModule,
    ProductsModule,
    StaffModule,
    GolfPassesModule,
    PromoCodesModule,
    EventsModule,
    
    AdminRoutingModule
  ]
})
export class AdminModule { }
