import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PlayerCreateComponent } from './player-create/player-create.component';
import { PlayerEditComponent } from './player-edit/player-edit.component';
import { PlayerListComponent } from './player-list/player-list.component';
import { PlayerViewComponent } from './player-view/player-view.component';

const routes: Routes = [
  {
    path: 'create',
    component: PlayerCreateComponent
  },
  {
    path: ':playerId/edit',
    component: PlayerEditComponent
  },
  {
    path: ':playerId/view',
    component: PlayerViewComponent
  },
  {
    path: '',
    component: PlayerListComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PlayersRoutingModule { }
