import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConsolidatedReportByDate } from 'projects/shared/src/lib/models/consolidated-report-by-date';
import { CashDrawerEntryReportByDateQuery } from 'projects/shared/src/lib/queries/cash-drawer-entry-report-by-date-query';
import { ConsolidatedReportByDateQuery } from 'projects/shared/src/lib/queries/consolidated-report-by-date-query';
import { GratuityReportQuery } from 'projects/shared/src/lib/queries/gratuity-report-query';
import { ReportService } from 'projects/shared/src/lib/services/report.service';
import { UserService } from 'projects/shared/src/lib/services/user.service';
import { OnPageEvent, User } from 'projects/shared/src/public-api';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, shareReplay, takeUntil } from 'rxjs/operators';

enum ReportOutputType {
  Consolidated = 'consolidated',
  Gratuity = 'gratuity',
  EodDrawerCount = 'eod-drawer-count'
}

@Component({
  selector: 'gcl-admin-report-outputs-table',
  templateUrl: './report-outputs-table.component.html',
  styleUrls: ['./report-outputs-table.component.scss']
})
export class ReportOutputsTableComponent implements OnInit {
  @Input() enableCreate: boolean = true;
  _courseId: number = 0;
  @Input() set courseId(courseId: number){
    this._courseId = courseId;
    this.getConsolidatedPageResult();
  }

  consolidatedPagination$ = new BehaviorSubject<OnPageEvent>({
    itemsPerPage: 10,
    page: 1,
    sortBy: 'id',
    sortDescending: true
  });

  gratuityPagination$ = new BehaviorSubject<OnPageEvent>({
    itemsPerPage: 10,
    page: 1,
    sortBy: 'id',
    sortDescending: true
  });

  cashDrawerEntryPagination$ = new BehaviorSubject<OnPageEvent>({
    itemsPerPage: 10,
    page: 1,
    sortBy: 'id',
    sortDescending: true
  });

  beginDate$ = new BehaviorSubject<Date>(new Date().addDays(-7));
  endDate$ = new BehaviorSubject<Date>(new Date());

  employeeId$ = new BehaviorSubject<number | null>(null);

  consolidatedTotal$: BehaviorSubject<number> = new BehaviorSubject(1);
  consolidatedRecords$: ReplaySubject<any[]> = new ReplaySubject(1);

  gratuityTotal$: BehaviorSubject<number> = new BehaviorSubject(1);
  gratuityRecords$: ReplaySubject<any[]> = new ReplaySubject(1);

  cashDrawerEntryTotal$: BehaviorSubject<number> = new BehaviorSubject(1);
  cashDrawerEntryRecords$: ReplaySubject<any[]> = new ReplaySubject(1);

  private destroy$: Subject<boolean> = new Subject();

  public reportOutputType: ReportOutputType = ReportOutputType.Consolidated;
  public nonEmptyResults: boolean = true;
  
  ReportOutputType = ReportOutputType;

  public users$!: Observable<User[]>;

  constructor(private reportService: ReportService, private userService: UserService) { }

  ngOnInit(): void {
    let beginDate = new Date(this.beginDate$.value.getTime());
    let endDate = new Date(this.endDate$.value.getTime());

    beginDate.setHours(0,0,0,0);
    endDate.setHours(23,59,59,999);

    this.beginDate$.next(beginDate);
    this.endDate$.next(endDate);

    combineLatest([this.consolidatedPagination$, this.beginDate$, this.endDate$])
    .pipe(
      shareReplay(1),
      takeUntil(this.destroy$)
    )
    .subscribe(([]) => {
      this.getConsolidatedPageResult();
    });

    combineLatest([this.gratuityPagination$, this.beginDate$, this.endDate$, this.employeeId$])
    .pipe(
      shareReplay(1),
      takeUntil(this.destroy$)
    )
    .subscribe(([]) => {
      this.getGratuityPageResult();
    });

    combineLatest([this.cashDrawerEntryPagination$, this.beginDate$, this.endDate$])
    .pipe(
      shareReplay(1),
      takeUntil(this.destroy$)
    )
    .subscribe(([]) => {
      this.getCashDrawerEntryPageResult();
    });

    this.users$ = this.reportService.getEmployees().pipe(takeUntil(this.destroy$));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  beginDateChanged(beginDate: Date) {
    this.beginDate$.next(beginDate);
  }

  endDateChanged(endDate: Date) {
    this.endDate$.next(endDate);
  }

  private getConsolidatedPageResult() {
    let beginDate = new Date(this.beginDate$.value.getTime());
    let endDate = new Date(this.endDate$.value.getTime());

    beginDate.setHours(0,0,0,0);
    endDate.setHours(23,59,59,999);

    let query: ConsolidatedReportByDateQuery = {
      skip: (this.consolidatedPagination$.value.page - 1) * this.consolidatedPagination$.value.itemsPerPage,
      take: this.consolidatedPagination$.value.itemsPerPage,
      courseId: this.courseId,
      beginDate: beginDate,
      endDate: endDate,
    };

    const pagedResult$ = this.reportService.getConsolidatedReportByDate(query).pipe(shareReplay(1));

    pagedResult$.subscribe((result) => {
      this.consolidatedRecords$.next(result.records);
      this.consolidatedTotal$.next(result.totalCount);
    });
  }

  private getGratuityPageResult() {
    let beginDate = new Date(this.beginDate$.value.getTime());
    let endDate = new Date(this.endDate$.value.getTime());

    beginDate.setHours(0,0,0,0);
    endDate.setHours(23,59,59,999);
    
    let query: GratuityReportQuery = {
      skip: (this.gratuityPagination$.value.page - 1) * this.gratuityPagination$.value.itemsPerPage,
      take: this.gratuityPagination$.value.itemsPerPage,
      courseId: this._courseId,
      beginDate: this.beginDate$.value,
      endDate: this.endDate$.value,
    };

    if(this.employeeId$.value) {
      query.employeeId = this.employeeId$.value;
    }

    const pagedResult$ = this.reportService.getGratuityReport(query).pipe(shareReplay(1));

    pagedResult$.subscribe((result) => {
      this.gratuityRecords$.next(result.records);
      this.gratuityTotal$.next(result.totalCount);
    });
  }

  private getCashDrawerEntryPageResult() {
    let beginDate = new Date(this.beginDate$.value.getTime());
    let endDate = new Date(this.endDate$.value.getTime());

    beginDate.setHours(0,0,0,0);
    endDate.setHours(23,59,59,999);

    let query: CashDrawerEntryReportByDateQuery = {
      skip: (this.cashDrawerEntryPagination$.value.page - 1) * this.cashDrawerEntryPagination$.value.itemsPerPage,
      take: this.cashDrawerEntryPagination$.value.itemsPerPage,
      courseId: this.courseId,
      beginDate: beginDate,
      endDate: endDate,
    };

    const pagedResult$ = this.reportService.getCashDrawerEntryReportByDate(query).pipe(shareReplay(1));

    pagedResult$.subscribe((result) => {
      this.cashDrawerEntryRecords$.next(result.records);
      this.cashDrawerEntryTotal$.next(result.totalCount);
      this.nonEmptyResults = result.records.some(record => record.course != "");
    });
  }

  generateReport() {
      this.reportService.getConsolidatedReportPdf(this._courseId, this.beginDate$.value, this.endDate$.value).subscribe(blob => {
        this.exportPdf(blob, 'consolidated-report.pdf');
      });

    this.generateGratuityReport();
  }

  generateGratuityReport() {
    let skip = (this.gratuityPagination$.value.page - 1) * this.gratuityPagination$.value.itemsPerPage;
    let take = this.gratuityPagination$.value.itemsPerPage;

    this.reportService.getGratuityReportPdf(this._courseId, this.beginDate$.value, this.endDate$.value, skip, take, this.employeeId$.value ?? undefined).subscribe(blob => {
      this.exportPdf(blob, 'gratuity-report.pdf');
    });
  }

  generateReportForRow(row: ConsolidatedReportByDate) {
    let beginDate = new Date(row.date);
    beginDate.setHours(0,0,0,0);

    let endDate = new Date(row.date);
    endDate.setHours(23,59,59,999);

    this.reportService.getConsolidatedReportPdf(this._courseId, beginDate, endDate).subscribe(blob => {
      this.exportPdf(blob, 'consolidated-report.pdf');
    });

    this.reportService.getGratuityReportPdf(this._courseId, beginDate, endDate, 0, 10000).subscribe(blob => {
      this.exportPdf(blob, 'gratuity-report.pdf');
    });
  }

  generateDrawerCountReport() {
    let beginDate = new Date(this.beginDate$.value.getTime());
    let endDate = new Date(this.endDate$.value.getTime());

    beginDate.setHours(0,0,0,0);
    endDate.setHours(23,59,59,999);

    this.reportService.getDrawerCountReportPdf(this.courseId, beginDate, endDate).subscribe(blob => {
      this.exportPdf(blob, 'drawer-count-report.pdf');
    });

    return;
  }

  generateDrawerCountReportForRow(row: any) {
    let beginDate = new Date(row.timestamp);
    beginDate.setHours(0,0,0,0);

    let endDate = new Date(row.timestamp);
    endDate.setHours(23,59,59,999);

    this.reportService.getDrawerCountReportPdf(this.courseId, beginDate, endDate).subscribe(blob => {
      this.exportPdf(blob, 'drawer-count-report.pdf');
    });

    return;
  }

  employeeChanged(event: any) {
    let employeeIdString = event.target.value;

    this.employeeId$.next(employeeIdString === "null" ? null : Number(employeeIdString));
  }

  private exportPdf(blob: Blob, fileName: string) {
    // https://stackoverflow.com/questions/52154874/angular-6-downloading-file-from-rest-api         

    var pdfBlob = new Blob([blob], { type: "application/pdf" });

    const data = window.URL.createObjectURL(pdfBlob);

    let link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }

  getDateString(timestamp: string) {
    let date = new Date(timestamp);
    
    let year = date.getFullYear();
    let month = date.getMonth()+1;
    let dt = date.getDate();

    return `${month < 10 ? '0' : ''}${month}-${dt < 10 ? '0' : ''}${dt}-${year}`
  }
}
