<nav class="navbar navbar-expand-xl navbar-light py-xl-0 px-xl-0">
    <button class="navbar-toggler ml-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" (click)="isCollapsed = !isCollapsed" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-between" id="navbarNavDropdown" [collapse]="isCollapsed">
        
        <div class="navbar-nav d-block d-xl-flex h-100 align-items-center" *ngIf="!(authService.viewManager$ | async)">
            <a (click)="onNavClick()" class="nav-item border-right nav-link text-dark" routerLinkActive="active font-weight-bold" [routerLink]="['/admin', 'tee-sheet']" [class.inactive]="onRegister">Tee Sheet</a>
            <a (click)="onNavClick()" class="nav-item border-right nav-link text-dark" routerLinkActive="active font-weight-bold" [routerLink]="['/admin', 'carts']">Carts</a>
            <a (click)="onNavClick()" class="nav-item border-right nav-link text-dark" routerLinkActive="active font-weight-bold" [routerLink]="['/admin', 'courses']">Courses</a>
            <a (click)="onNavClick()" class="nav-item border-right nav-link text-dark" routerLinkActive="active font-weight-bold" [routerLink]="['/admin', 'players']">Customers</a>
            <a (click)="onNavClick()" class="nav-item border-right nav-link text-dark" routerLinkActive="active font-weight-bold" [routerLink]="['/admin', 'golf-passes']">Golf Passes</a>
            <a (click)="onNavClick()" class="nav-item border-right nav-link text-dark" routerLinkActive="active font-weight-bold" [routerLink]="['/admin', 'promo-codes']">Promo Codes</a>
            <a (click)="onNavClick()" class="nav-item border-right nav-link text-dark" routerLinkActive="active font-weight-bold" [routerLink]="['/admin', 'products']">Products</a>
            <a (click)="onNavClick()" class="nav-item border-right nav-link text-dark" routerLinkActive="active font-weight-bold" [routerLink]="['/admin', 'schedules']">Schedules</a>
            <a (click)="onNavClick()" class="nav-item border-right nav-link text-dark" routerLinkActive="active font-weight-bold" [routerLink]="['/admin', 'add-on']">Add-Ons</a>
            <a (click)="onNavClick()" class="nav-item border-right nav-link text-dark" routerLinkActive="active font-weight-bold" [routerLink]="['/admin', 'categories']">Categories</a>
            <a (click)="onNavClick()" class="nav-item border-right nav-link text-dark" routerLinkActive="active font-weight-bold" [routerLink]="['/admin', 'events']">Events</a>
            <a (click)="onNavClick()" class="nav-item border-right nav-link text-dark" routerLinkActive="active font-weight-bold" [routerLink]="['/admin', 'reports', 'admin-report']">Reports</a>
            <a 
                class="d-block d-xl-none nav-item border-right nav-link text-dark cursor-pointer" 
                [ngClass]="{'active': onRegister, 'font-weight-bold': onRegister}"
                *ngIf="(authService.course$ | async)" 
                (click)="gotoRegister(); onNavClick();"
            >
                Register
            </a>
            <a (click)="onNavClick()" class="d-block d-xl-none nav-item border-right nav-link text-dark cursor-pointer" routerLinkActive="active font-weight-bold" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/admin', 'dashboard']">Dashboard</a>
        </div>
        
        <div class="navbar-nav d-block d-xl-flex h-100 align-items-center" *ngIf="(authService.viewManager$ | async)">
            <a (click)="onNavClick()" class="nav-item border-right nav-link text-dark" routerLinkActive="active font-weight-bold" [routerLink]="['/manager', 'tee-sheet']" [class.inactive]="onRegister">Tee Sheet</a>
            <a (click)="onNavClick()" class="nav-item border-right nav-link text-dark" routerLinkActive="active font-weight-bold" [routerLink]="['/manager', 'carts']">Carts</a>
            <a (click)="onNavClick()" class="nav-item border-right nav-link text-dark" routerLinkActive="active font-weight-bold" [routerLink]="['/admin', 'players']">Customers</a>
            <a (click)="onNavClick()" class="nav-item border-right nav-link text-dark" routerLinkActive="active font-weight-bold" [routerLink]="['/admin', 'courses']">Courses</a>
            <a (click)="onNavClick()" class="nav-item border-right nav-link text-dark" routerLinkActive="active font-weight-bold" [routerLink]="['/admin', 'golf-passes']">Golf Passes</a>
            <a (click)="onNavClick()" class="nav-item border-right nav-link text-dark" routerLinkActive="active font-weight-bold" [routerLink]="['/admin', 'promo-codes']">Promo Codes</a>
            <a (click)="onNavClick()" class="nav-item border-right nav-link text-dark" routerLinkActive="active font-weight-bold" [routerLink]="['/manager', 'products']">Products</a>
            <a (click)="onNavClick()" class="nav-item border-right nav-link text-dark" routerLinkActive="active font-weight-bold" [routerLink]="['/manager', 'schedules']">Schedules</a>
            <a (click)="onNavClick()" class="nav-item border-right nav-link text-dark" routerLinkActive="active font-weight-bold" [routerLink]="['/manager', 'add-on']">Add-Ons</a>
            <a (click)="onNavClick()" class="nav-item border-right nav-link text-dark" routerLinkActive="active font-weight-bold" [routerLink]="['/admin', 'categories']">Categories</a>
            <a (click)="onNavClick()" class="nav-item border-right nav-link text-dark" routerLinkActive="active font-weight-bold" [routerLink]="['/admin', 'events']">Events</a>
            <a (click)="onNavClick()" class="nav-item border-right nav-link text-dark" routerLinkActive="active font-weight-bold" [routerLink]="['/admin', 'reports', 'admin-report']">Reports</a>
            <a (click)="onNavClick()" 
                class="d-block d-xl-none nav-item border-right nav-link text-dark cursor-pointer" 
                [ngClass]="{'active': onRegister, 'font-weight-bold': onRegister}"
                *ngIf="(authService.course$ | async)" 
                (click)="gotoRegister(); onNavClick();"
            >
                Register
            </a>
            <a (click)="onNavClick()" class="d-block d-xl-none nav-item border-right nav-link text-dark cursor-pointer" routerLinkActive="active font-weight-bold" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/manager', 'dashboard']">Dashboard</a>
        </div>

        <div class="justify-content-end d-none d-xl-flex">
            <a 
                class="nav-item d-none d-md-block nav-link text-primary border-left cursor-pointer" 
                [ngClass]="{'active': onRegister, 'font-weight-bold': onRegister}"
                *ngIf="(authService.course$ | async)" 
                (click)="gotoRegister(); onNavClick()"
            >
                <img class="img-fluid" src="assets/register-icon.svg" />
            </a>
            <a (click)="onNavClick()" class="nav-item d-none d-md-block nav-link text-primary border-left" routerLinkActive="active font-weight-bold" [routerLinkActiveOptions]="{ exact: true}" [routerLink]="['/admin', 'dashboard']" *ngIf="!(authService.viewManager$ | async)">
                <img class="img-fluid" src="assets/dashboard-icon.svg" />
            </a>
            <a (click)="onNavClick()" class="nav-item d-none d-md-block nav-link text-primary border-left" routerLinkActive="active font-weight-bold" [routerLinkActiveOptions]="{ exact: true}" [routerLink]="['/manager', 'dashboard']" *ngIf="(authService.viewManager$ | async)">
                <img class="img-fluid" src="assets/dashboard-icon.svg" />
            </a>
        </div>
    </div>
</nav>