<div class="px-4 py-2 divider-bt" style="cursor: pointer;" (click)="clicked()" [class.subtitle]="refunded">
    <div class="d-flex flex-row justify-content-between align-items-center">
        <div>
            <h6 class="font-weight-bold m-0">{{name | uppercase}}</h6>
            <span class="subtitle mt-1">{{category | uppercase}}</span>
        </div>
        <div class="d-flex flex-row justify-content-between align-items-center">
            <h6 class="mr-5 mb-0 quantity font-weight-bold" [class.subtitle]="refunded">x{{quantity}}</h6>
            <h6 class="mr-2 mb-0 discount font-weight-bold" *ngIf="showDiscount()">{{unitprice * quantity | currency}}</h6>
            <h6 class="mb-0 font-weight-bold">{{price | currency}}</h6>
        </div>
    </div>
    <!-- <div class="d-flex flex-row justify-content-start align-items-center">
        <span class="subtitle mt-1">
            {{getTax() | currency}}         
        </span>
    </div> -->
</div>