<div class="input-group mb-3">
    <input type="search" class="form-control" placeholder="Enter User" 
        [(ngModel)]="search"
        [typeaheadOptionsLimit]="7"
        [typeaheadMinLength]="1"
        [typeahead]="records$"

        (typeaheadOnSelect)="setRecord($event)"
        (change)="onChange($event.target)"

        [typeaheadItemTemplate]="customItemTemplate"
        typeaheadOptionField="first"
        [typeaheadAsync]="true">
        <button class="btn btn-outline-secondary" type="button" id="button-addon2" (click)="addRecord()" *ngIf="multiselect">Add</button>
</div>
<ng-content></ng-content>

<ng-template #customItemTemplate let-model="item" let-index="index">
    <span>{{model.firstName}} {{model.lastName}}</span>
</ng-template>