import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, shareReplay, takeUntil } from 'rxjs/operators';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import { environment } from 'projects/admin/src/environments/environment';
import { ScheduleDetails } from 'projects/shared/src/lib/models/schedule';
import { ScheduleService } from 'projects/shared/src/lib/services/schedule.service';

@Component({
  selector: 'gcl-admin-schedule-view',
  templateUrl: './schedule-view.component.html',
  styleUrls: ['./schedule-view.component.scss']
})
export class ScheduleViewComponent implements OnInit, OnDestroy {
  public apiUrl: string = environment.apiUrl;
  public faChevronLeft = faChevronLeft;

  public schedule$!: Observable<ScheduleDetails>;

  private destroyed$ = new Subject();

  constructor(private scheduleService: ScheduleService, public route: ActivatedRoute) { }

  ngOnInit(): void {
    const scheduleId$ = this.route.params.pipe(
      map(params => params.scheduleId)
    );

    this.schedule$ = scheduleId$.pipe(
      switchMap(scheduleId => this.scheduleService.get(scheduleId)),
      shareReplay(1)
    );
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
