<div class="d-flex flex-column h-100">
    <div class="d-flex justify-content-start py-2 bg-light no-flex">
        <a class="text-light-green"  [routerLink]="['/admin','orders']">
            <fa-icon [icon]="faChevronLeft" class="px-3"></fa-icon> <span>Back to Orders List</span>
        </a>
    </div>
    <div class="container-fluid py-2 fill-flex overflow-y-auto">
        <div class="row">
            
            <div class="col-12">
                <form [formGroup]="form" (ngSubmit)="submit()">
                    <div class="d-flex justify-content-end py-2">
                        <button type="submit" class="btn btn-light-green px-5">Save</button>
                    </div>
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-5">
                                            <div class="form-group">
                                                <label >Status</label>
                                                <select class="custom-select" formControlName="status" name="status"  
                                                    [hasNullOption]="true"
                                                    enum="OrderStatuses"
                                                    [value]="status?.value"
                                                    [class.is-invalid]="status?.invalid && status?.touched">
                                                </select>
                                                <div *ngIf="status?.invalid && status?.touched" class="invalid-feedback">
                                                    <div *ngIf="status?.errors?.required">
                                                        Please enter a status.
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="form-group">
                                                <label for="cart">Cart</label>
                                                <gcl-lib-toggle-switch formControlName="cart"></gcl-lib-toggle-switch>
                                                <div *ngIf="cart?.invalid && cart?.touched" class="invalid-feedback">
                                                    <div *ngIf="cart?.errors?.required">
                                                        Cart is required.
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <label >Subtotal</label>
                                                <input type="number" class="form-control" formControlName="subtotal" name="subtotal" [class.is-invalid]="subtotal?.invalid && subtotal?.touched" />
                                                <div *ngIf="subtotal?.invalid && subtotal?.touched" class="invalid-feedback">
                                                    <div *ngIf="subtotal?.errors?.required">
                                                        Please enter a subtotal.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label >Tax</label>
                                                <input type="number" class="form-control" formControlName="tax" name="tax" [class.is-invalid]="tax?.invalid && tax?.touched" />
                                                <div *ngIf="tax?.invalid && tax?.touched" class="invalid-feedback">
                                                    <div *ngIf="tax?.errors?.required">
                                                        Please enter a tax.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label >Tip</label>
                                                <input type="number" class="form-control" formControlName="tip" name="tip" [class.is-invalid]="tip?.invalid && tip?.touched" />
                                                <div *ngIf="tip?.invalid && tip?.touched" class="invalid-feedback">
                                                    <div *ngIf="tip?.errors?.required">
                                                        Please enter a tip.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label >Total</label>
                                                <input type="number" class="form-control" formControlName="total" name="total" [class.is-invalid]="total?.invalid && total?.touched" />
                                                <div *ngIf="total?.invalid && total?.touched" class="invalid-feedback">
                                                    <div *ngIf="total?.errors?.required">
                                                        Please enter a total.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label >Balance</label>
                                                <input type="number" class="form-control" formControlName="finaltotal" name="finaltotal" [class.is-invalid]="finaltotal?.invalid && finaltotal?.touched" />
                                                <div *ngIf="finaltotal?.invalid && finaltotal?.touched" class="invalid-feedback">
                                                    <div *ngIf="finaltotal?.errors?.required">
                                                        Please enter a balance.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col mx-auto text-center">
                                    <div class="row">
                                        <div class="col-6 ml-auto">
                                            <div class="form-group">
                                                <label >Customer</label>
                                                <gcl-lib-search-users formControlName="users_permissions_user"></gcl-lib-search-users>
                                                <div *ngIf="users_permissions_user?.invalid && users_permissions_user?.touched" class="text-danger">
                                                    <div *ngIf="users_permissions_user?.errors?.required">
                                                        Please enter a user.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6 ml-auto">
                                            <div class="form-group">
                                                <label >Round</label>
                                                <gcl-lib-search-rounds formControlName="round"></gcl-lib-search-rounds>
                                                <div *ngIf="round?.invalid && round?.touched" class="text-danger">
                                                    <div *ngIf="round?.errors?.required">
                                                        Please enter a round.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
