import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[gclLibDisableContextMenu]'
})
export class DisableContextMenuDirective {
  @HostListener('contextmenu', ['$event'])
  public onRightClick(event: Event): void {
    event.preventDefault();
  }
}
