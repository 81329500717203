import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';

import { BehaviorSubject, Observable, combineLatest, Subject, ReplaySubject } from 'rxjs';
import { shareReplay, map, takeUntil } from 'rxjs/operators';

import { environment } from 'projects/admin/src/environments/environment';
import { OnPageEvent } from 'projects/shared/src/lib/models/on-page-event';
import { GolfProductDetails } from 'projects/shared/src/lib/models/golfproduct';
import { GolfProductService } from 'projects/shared/src/lib/services/golf-product.service';
import { SortDirections } from 'projects/shared/src/lib/enumerations/sort-directions';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { CategoryService } from 'projects/shared/src/lib/services/category.service';

@Component({
  selector: 'gcl-admin-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit, OnDestroy {
  public apiUrl = environment.apiUrl;

  get isEighteenHoles() { return this.filters.value.isEighteenHoles; }
  get isCart() { return this.filters.value.isCart; }

  filters = this.fb.group({
    isEighteenHoles: [null],
    isCart: [null],
  });

  pagination$ = new BehaviorSubject<OnPageEvent>({
    itemsPerPage: 10,
    page: 1,
    sortBy: 'id',
    sortDescending: true
  });

  total$: BehaviorSubject<number> = new BehaviorSubject(1);
  records$: ReplaySubject<GolfProductDetails[]> = new ReplaySubject(1);

  private destroy$: Subject<any> = new Subject();

  constructor(private fb: FormBuilder, private authService: AuthService, private golfProductService: GolfProductService, private categorieService: CategoryService, private router: Router) { }

  ngOnInit(): void {
    this.pagination$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.getPageResult();
      });

    this.filters.valueChanges
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.getPageResult());
  }

  private getPageResult() {
    this.authService.course$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(course => {
      const pagedResult = this.golfProductService.query({
        skip: (this.pagination$.value.page - 1) * this.pagination$.value.itemsPerPage,
        take: this.pagination$.value.itemsPerPage,
        sortColumns: [{
          column: this.pagination$.value.sortBy,
          direction: this.pagination$.value.sortDescending ? SortDirections.Descending : SortDirections.Ascending,
        }],
        search: this.pagination$.value.search,

        course: course?.id,
        cart: (this.isCart != undefined && this.isCart != "null") ? (this.isCart == "true" ? true : false) : undefined,
        holes: (this.isEighteenHoles != undefined && this.isEighteenHoles != "null") ? (this.isEighteenHoles == "true" ? 18 : 9) : undefined
      });

      pagedResult.filteredCount$.subscribe((total) => this.total$.next(total));
      pagedResult.records$.subscribe((records) => this.records$.next(records));
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  public goToCreate() {
    this.router.navigate(['/manager', 'products', 'create']);
  }
}
