import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { IDirty } from '../models/IDirty';
import { ConfirmationModalService } from '../services/confirmation-modal.service';

@Injectable({
  providedIn: 'root'
})
export class ConfirmModalGuard implements CanDeactivate<IDirty> {

  constructor(private confirmationModalService: ConfirmationModalService, private router: Router) { }

  canDeactivate(component: IDirty, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const navigation = this.router.getCurrentNavigation();

    if (navigation?.extras.state?.skipConfirm === true || !component.isDirty()) {
      return true;
    }

    return this.confirmationModalService.showConfirmationModal({
      message: navigation?.extras.state?.confirmModalMessage || currentRoute.data.confirmModalMessage || 'You are navigating away from this page. Doing so will lose any unsaved changes. Do you wish to continue?',
      title: navigation?.extras.state?.confirmModalTitle || currentRoute.data.confirmModalTitle || 'Warning'
    }).pipe(
      tap(confirmed => {
        if(component.confirmedCallback && confirmed === true) {
          component.confirmedCallback(navigation?.extras?.state?.confirmParams);
        }

        if(component.deniedCallback && confirmed === false) {
          component.deniedCallback(navigation?.extras?.state?.deniedParams);
        }
      })
    );
  }
}