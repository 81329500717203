<div class="container-fluid">
    <div class="row">
        <div class="col-12 col-md-4 py-3" *ngIf="showUserCreditBalance">
            <button type="button" class="btn btn-sm btn-default btn-block payment-option text-uppercase h-100 w-100">
                <span class="float-left font-weight-bold">
                    CREDIT BALANCE
                </span>
                <span class="float-right text-right">
                    <div class="font-weight-bold">{{userCreditBalance$ | async | currency}}</div>
                </span>
            </button>
        </div>
        <div class="col-12 col-md-4 py-3" *ngFor="let method of paymentMethods$ | async">
            <gcl-lib-stripe-card
                [paymentMethod]="method" 
                [selected]="selectedPaymentMethodId == method.id"
                (select)="onSelect(method)">
            </gcl-lib-stripe-card>
        </div>
        <div class="col-12 col-md-4 py-3" *ngIf="showNewCardEntry">
            <button type="button" class="btn btn-sm btn-default btn-block payment-option text-uppercase h-100 w-100"
                (click)="onSelectNewPayment()">
                <small class="font-bold">New Card Entry</small>
            </button>
        </div>
        <div class="col-12 col-md-4 py-3" *ngIf="!showNewCardEntry && !showUserCreditBalance && !((paymentMethods$ | async)?.length)">
            No payment methods configured
        </div>
    </div>
    <ng-content></ng-content>
</div>