import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { shareReplay, takeUntil } from 'rxjs/operators';

import { CreditBalanceTransactionService } from '../../services/credit-balance-transaction.service';

@Component({
  selector: 'gcl-lib-user-credit-balance',
  templateUrl: './user-credit-balance.component.html',
  styleUrls: ['./user-credit-balance.component.css']
})
export class UserCreditBalanceComponent implements OnInit, OnDestroy {

  @Input()
  userId!: number;

  @Input()
  reload$!: Observable<void>;

  public balance$!: Observable<number>;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(private creditBalanceService: CreditBalanceTransactionService) { }

  ngOnInit(): void {
    this.getBalance();

    this.reload$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.getBalance());
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  public getBalance(): void {
    this.balance$ = this.creditBalanceService.getBalance(this.userId).pipe(
      shareReplay(1)
    );
  }

}
