<ng-container *ngIf="state$ | async as state">
    <div class="d-flex flex-column h-100">
        <div class="d-flex justify-content-start py-2 bg-light" *ngIf="(state == CheckoutState.PayCash || state == CheckoutState.PayDigital || state == CheckoutState.PayReader)">
            <button type="button" class="btn btn-link text-light-green" *ngIf="order$ | async as order" (click)="onState(CheckoutState.ViewCategory)">
                <fa-icon [icon]="faChevronLeft" class="px-3"></fa-icon> <span>Back to Edit Order</span>
            </button>
        </div>
        
        <div class="container-fluid py-2 h-100" style="flex: 1; min-height: 0;">
            <div class="row px-3 h-100">
                <div class="col-12 col-xl-5 p-0 mh-100">
                    <div slot="left" class="d-flex flex-column bg-light border h-100" *ngIf="order$ | async as order">
                        <div class="dark">
                            <div class="d-flex flex-row justify-content-between" *ngIf="user$ | async as user">
                                <ng-container>
                                    <div style="padding-top: 0.75rem; padding-left: 1.5rem;">
                                        <h6 *ngIf="user.firstName || user.lastName else noName" class="font-weight-bold m-0">
                                            {{user.firstName | uppercase}} {{user.lastName | uppercase}}
                                        </h6>
                                        <span class="subtitle mt-1">Member</span>
                                    </div>
                                    <ng-template #noName>
                                        <h6 class="font-weight-bold m-0">
                                            {{user.username | uppercase}}
                                        </h6>
                                    </ng-template>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <a class="font-weight-bold" >
                                            <img src="assets/kebab.svg" [popover]="promoPopover" placement="bottom right" [outsideClick]="true" style="cursor: pointer;" />
                                        </a>
                                    </div>

                                </ng-container>
                            </div>
                        </div>
                        <div style="overflow: auto;">
                            <div class="dark d-flex justify-content-between">
                                <div style="padding-bottom: 0.75rem; padding-left: 1.5rem;">
                                    <h6 *ngIf="order.tee_times.length > 0; else noTeeTime" class="font-weight-bold mt-3 mb-0">{{ getFirstTeeTime(order) | date: 'fullDate' }} - {{ getFirstTeeTime(order) | date:'shortTime' }}</h6>
                                    <ng-template #noTeeTime><h6 class="font-weight-bold mt-3 mb-0">{{ today | date: 'fullDate' }} - {{ today | date: 'shortTime'}}</h6></ng-template>
                                    <span>{{order?.course?.full_name}}</span>
                                </div>
                                <div class="d-flex justify-content-between align-items-center pr-2" [style.visibility]="(user$ | async) ? 'hidden' : 'visible'">
                                    <a class="font-weight-bold" >
                                        <img src="assets/kebab.svg" [popover]="promoPopover" placement="bottom right" [outsideClick]="true" style="cursor: pointer;" />
                                    </a>
                                </div>
                            </div>
                            <!-- Golf Product -->
                            <ng-container *ngFor="let item of golfOrders$ | async">
                                <gcl-admin-checkout-item
                                    [category]="item.category"
                                    [name]="item.name"
                                    [quantity]="item.quantity"
                                    [unitprice]="item.unitprice"
                                    [price]="item.price"
                                    [taxrate]="item.taxrate"
                                    [refunded]="item.refunded"
                                ></gcl-admin-checkout-item>
                            </ng-container>

                            <!-- Golf Passes -->
                            <ng-container *ngFor="let item of golfpassOrders$ | async">
                                <gcl-admin-checkout-item
                                    [category]="item.category"
                                    [name]="item.name"
                                    [quantity]="item.quantity"
                                    [unitprice]="item.unitprice"
                                    [price]="item.price"
                                    [taxrate]="item.taxrate"
                                    (selected)="openViewGolfPassModal(item.golfpassOrderId)"
                                ></gcl-admin-checkout-item>
                            </ng-container>

                            <!-- Product Add-ons -->
                            <ng-container *ngFor="let item of productOrders$ | async">
                                <gcl-admin-checkout-item
                                    [category]="item.category"
                                    [name]="item.name"
                                    [quantity]="item.quantity"
                                    [unitprice]="item.unitprice"                                    
                                    [price]="item.price"
                                    [taxrate]="item.taxrate"
                                    [refunded]="item.refunded"
                                    (selected)="openViewProductModal(item.productOrderId)"
                                ></gcl-admin-checkout-item>
                            </ng-container>

                            <ng-container *ngIf="order.promo_code">
                                <div class="px-4 py-2 divider-bt discount">
                                    <div class="d-flex flex-row justify-content-between">
                                        <h6 class="font-weight-bold m-0">{{$any(order.promo_code).code | uppercase}}</h6>
                                        <div class="d-flex flex-row justify-content-between align-items-center">
                                            <h6 class="mb-0 font-weight-bold" *ngIf="$any(order.promo_code).discountAmount > 0">({{$any(order.promo_code).discountAmount | currency}})</h6>
                                            <h6 class="ml-2 mb-0 font-weight-bold" *ngIf="$any(order.promo_code).discountPercent > 0">({{$any(order.promo_code).discountPercent}}% off)</h6>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-row justify-content-start align-items-center">
                                        <span class="subtitle mt-1">PROMO CODE</span>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div class="px-4 py-2 divider-bt d-none d-xl-block" style="flex: 1;">
                            <div *ngIf="(hasOrders$ | async) === false">
                                Please select a product to continue.
                            </div>
                        </div>
                        <ng-container *ngIf="creditBalance$ | async as creditBalance">
                            <div class="px-4 py-2 divider-bt d-flex justify-content-between align-items-center" *ngIf="creditBalance > 0">
                                <!-- <div class="form-check">
                                    <input id="mobileActive" type="checkbox" class="form-check-input" />
                                    <label class="form-check-label" for="mobileActive">
                                        Apply Credit Balance
                                    </label>
                                </div> -->
                                <div class="d-flex flex-1 align-items-center">
                                    <gcl-lib-toggle-switch [(ngModel)]="applyCreditBalance" (toggle)="toggledCreditBalance($event)" [class.disabled]="togglingCreditBalance"></gcl-lib-toggle-switch>
                                    <div class="credit-balance-label ml-2">Apply Credit Balance</div>
                                </div>
                                
                                <div class="credit-balance font-weight-bold">{{creditBalance | currency}}</div>
                            </div>
                        </ng-container>
                        <!-- Order Totals -->
                        <div class="px-4 py-2">
                            <div class="d-flex flex-row justify-content-between">
                                <h5 class="mb-0">Subtotal</h5>
                                <h5 class="mb-0">{{order.subtotal | currency}}</h5>
                            </div>
                            <div class="d-flex flex-row justify-content-between" *ngIf="(tip$ | async) as tip">
                                <h5 class="mb-0">Tip</h5>
                                <h5 class="mb-0">{{tip.extprice | currency}}</h5>
                            </div>
                            <div class="d-flex flex-row justify-content-between discount" *ngIf="showDiscount(order)">
                                <h5 class="mb-0">Discount</h5>
                                <h5 class="mb-0">({{this.discountAmount(order) | currency}})</h5>
                            </div>
                            <div class="d-flex flex-row justify-content-between">
                                <h5 class="mb-0">Tax</h5>
                                <h5 class="mb-0">{{order.tax | currency}}</h5>
                            </div>
                            <ng-container  *ngIf="(difference$ | async) as difference; else finalTotal">
                                <div class="d-flex flex-row justify-content-between" *ngIf="difference < 0">
                                    <h6 class="mb-0">Previous Total</h6>
                                    <h6 class="mb-0">{{-1*(difference - order.total) | currency}}</h6>
                                </div>
                                <div class="d-flex flex-row justify-content-between" *ngIf="difference < 0">
                                    <h6 class="mb-0">Difference</h6>
                                    <h6 class="mb-0 text-danger">{{difference | currency}}</h6>
                                </div>
                                <div class="d-flex flex-row justify-content-between">
                                    <h5 class="font-weight-bold mb-0 mt-3" *ngIf="difference < 0">Refund</h5>
                                    <h5 class="font-weight-bold mb-0 mt-3" *ngIf="difference < 0">{{-1*difference | currency}}</h5>
        
                                    <h5 class="font-weight-bold mb-0 mt-3" *ngIf="difference > 0">Total</h5>
                                    <h5 class="font-weight-bold mb-0 mt-3" *ngIf="difference > 0">{{difference | currency}}</h5>
                                </div>

                                <!-- Payment Selection -->
                                <div class="d-flex flex-row justify-content-between mt-4" *ngIf="!((state == CheckoutState.PayCash || state == CheckoutState.PayDigital || state == CheckoutState.PayReader))">
                                    <button type="button" class="mx-3 flex-fill btn btn-outline-light-green" (click)="onCancel()">Cancel</button>
                                    <button *ngIf="(order.golforders.length > 0) && (difference > 0)" type="button" class="mx-3 flex-fill btn btn-light-green" (click)="onPayLater(order.id)">Save Order</button>
                                    <ng-container *ngIf="difference < 0">
                                        <button type="button" class="mx-3 flex-fill btn btn-light-green" (click)="onState(CheckoutState.RefundCash)">Refund Cash</button>
                                        <button type="button" class="mx-3 flex-fill btn btn-light-green" (click)="confirmDigitalPaymentRefund()">Refund Digital</button>
                                    </ng-container>
                                    <ng-container *ngIf="difference > 0">
                                        <!-- <button type="button" class="mx-3 flex-fill btn btn-light-green" (click)="onState(CheckoutState.PayCash)">Pay Cash</button> -->
                                        <button type="button" class="mx-3 flex-fill btn btn-light-green" (click)="payClick()">Pay</button>
                                        <!-- <button type="button" class="mx-3 flex-fill btn btn-light-green" (click)="onState(CheckoutState.PayReader)">Pay Reader</button> -->
                                    </ng-container>
                                </div>
                            </ng-container>
                            <ng-template #finalTotal>
                                <div class="d-flex flex-row justify-content-between mt-4">
                                    <h5 class="font-weight-bold mb-0">Total</h5>
                                    <h5 class="font-weight-bold mb-0">{{order.finaltotal | currency}}</h5>
                                </div>
                            </ng-template>
                            <div class="d-flex flex-row justify-content-between mt-3" *ngIf="!((state == CheckoutState.PayCash || state == CheckoutState.PayDigital)) && !(difference$ | async)">
                                <button class="mx-3 flex-fill btn btn-outline-light-green" (click)="onCancel()" type="button">Cancel</button>
                                <button class="mx-3 flex-fill btn btn-light-green" type="button" (click)="onPayLater(order.id)">Continue</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-xl-7 p-0 mh-100">
                    <div slot="right" class="border h-100">
                        <!-- Product Add-on Categories -->
                        <gcl-admin-checkout-category-list *ngIf="state == CheckoutState.ViewCategory"></gcl-admin-checkout-category-list>

                        <ng-container *ngIf="(state == CheckoutState.PayCash || state == CheckoutState.PayDigital || state == CheckoutState.RefundCash || state == CheckoutState.PayReader)">
                            <div class="d-flex flex-column h-100">
                                <nav class="navbar navbar-expand-md navbar-light py-md-0 px-md-0 mb-4" style="flex: 0 0 auto;">
                                    <button class="navbar-toggler ml-auto" type="button" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" (click)="isCollapsed = !isCollapsed">
                                        <span class="navbar-toggler-icon"></span>
                                    </button>
                                    <div class="collapse navbar-collapse" id="navbarNavDropdown" [collapse]="isCollapsed">
                                        <div class="navbar-nav mr-auto h-100">
                                            <button type="button" class="btn btn-link nav-item border-right nav-link text-dark m-0"
                                                [class.active]="state == CheckoutState.PayDigital" 
                                                [class.font-weight-bold]="state == CheckoutState.PayDigital"
                                                (click)="onState(CheckoutState.PayDigital)">Pay Digital</button>
                                            <button type="button" class="btn btn-link nav-item border-right nav-link text-dark m-0"
                                                [class.active]="state == CheckoutState.PayReader" 
                                                [class.font-weight-bold]="state == CheckoutState.PayReader"
                                                (click)="onState(CheckoutState.PayReader)">Pay Reader</button>
                                            <button type="button" class="btn btn-link nav-item border-right nav-link text-dark m-0"
                                                [class.active]="state == CheckoutState.PayCash" 
                                                [class.font-weight-bold]="state == CheckoutState.PayCash"
                                                (click)="onState(CheckoutState.PayCash)">Pay Cash</button>
                                        </div>
                                    </div>
                                </nav>
                                <gcl-admin-checkout-pay-reader class="fill-flex"  *ngIf="state == CheckoutState.PayReader"></gcl-admin-checkout-pay-reader>
                                <gcl-admin-checkout-pay-cash class="fill-flex" *ngIf="state == CheckoutState.PayCash"></gcl-admin-checkout-pay-cash>
                                <gcl-admin-checkout-pay-digital class="fill-flex" *ngIf="state == CheckoutState.PayDigital"></gcl-admin-checkout-pay-digital>
                                <gcl-admin-checkout-refund-cash class="fill-flex" *ngIf="state == CheckoutState.RefundCash"></gcl-admin-checkout-refund-cash>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        
    </div>

</ng-container>

<ng-template #promoPopover>
    <ul class="promo-popover" *ngIf="order$ | async as order;">
        <ng-container *ngIf="order.tee_times.length > 0; else noTeeTime">
            <li role="menuitem" *ngIf="user$ | async as user;"><a class="dropdown-item" (click)="hidePopover(); openEditUserModal(user)">EDIT CUSTOMER</a></li>
        </ng-container>
        <ng-template #noTeeTime>
            <li role="menuitem"><a class="dropdown-item" (click)="hidePopover(); openSelectUserModal(order)">
                <ng-container *ngIf="user$ | async; else chooseCustomer">CHANGE CUSTOMER</ng-container>
                <ng-template #chooseCustomer>
                    <ng-container>SELECT CUSTOMER</ng-container>
                </ng-template>
            </a></li>
        </ng-template>
        <li role="menuitem" *ngIf="!order.promo_code; else removePromoItem"><a class="dropdown-item" (click)="hidePopover(); openPromoModal(order)">APPLY PROMO</a></li>
        <ng-template #removePromoItem>
            <li role="menuitem"><a class="dropdown-item" (click)="hidePopover(); removePromo(order)">REMOVE PROMO</a></li>
        </ng-template>
    </ul>
    <!-- <div class="d-flex align-items-center mb-2" *ngFor="let promo of promoCodes$ | async">
        {{promo.code}}
    </div> -->
</ng-template>
