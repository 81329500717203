import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { EventSpaceType } from 'projects/shared/src/lib/enumerations/event-space-type';
import { GolfProductReservationTypes } from 'projects/shared/src/lib/enumerations/golf-product-reservation-type';
import { ReservationTypes } from 'projects/shared/src/lib/enumerations/reservation-type';
import { EventSpaceDetails } from 'projects/shared/src/lib/models/event-space';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { EventSpaceService } from 'projects/shared/src/lib/services/event-space.service';
import { EventService } from 'projects/shared/src/lib/services/event.service';
import { GolfProductService } from 'projects/shared/src/lib/services/golf-product.service';
import { ReservationTypeService } from 'projects/shared/src/lib/services/reservation-type.service';
import { GolfProductDetails, UserDetails } from 'projects/shared/src/public-api';
import { BehaviorSubject, combineLatest, merge, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, map, share, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { PlayerCreateModalComponent } from '../../../shared/components/player-create-modal/player-create-modal.component';
import { PlayerEditModalComponent } from '../../../shared/components/player-edit-modal/player-edit-modal.component';
import { PlayerSelectModalComponent } from '../../../shared/components/player-select-modal/player-select-modal.component';

@Component({
  selector: 'gcl-admin-event-create',
  templateUrl: './event-create.component.html',
  styleUrls: ['./event-create.component.scss']
})
export class EventCreateComponent implements OnInit, OnDestroy {
  faChevronLeft = faChevronLeft

  get name() { return this.form.get('name') }
  get description() { return this.form.get('description') }
  get event_space() { return this.form.get('event_space') }
  get type() { return this.form.get('type') }
  get start() { return this.form.get('start') }
  get startTime() { return this.form.get('startTime')}
  get end() { return this.form.get('end') }
  get endTime() { return this.form.get('endTime') }
  get participants() { return this.form.get('participants')}
  get carts() { return this.form.get('carts')}
  get courseLength() { return this.form.get('courseLength') }
  // get depositAmount() { return this.form.get('depositAmount') }
  get eventAmount() { return this.form.get('eventAmount') }
  get user() { return this.form.get('user') }
  get golfproduct() { return this.form.get('golfproduct') }

  public form = this.fb.group({
    name: ['', [Validators.required]],
    description: ['', []],
    event_space: ['', [Validators.required]],
    type: [null, []],
    start: [null, [Validators.required]],
    startTime: [null, [Validators.required]],
    end: [null, []],
    endTime: [null, []],
    participants: [1, []],
    carts: [0, []],
    courseLength: [9, []],
    // depositAmount: [0, []],
    eventAmount: [0, [Validators.required]],
    user: [null, [Validators.required]],
    golfproduct: [null, []]
  });

  public eventSpaces$!: Observable<EventSpaceDetails[]>;
  public eventSpace$: BehaviorSubject<EventSpaceDetails | null> = new BehaviorSubject<EventSpaceDetails | null>(null);
  public eventSpaceRequiresGolfProduct$!: Observable<boolean>;

  private golfProductParamsChanged$ = new Subject();
  public golfProducts$!: Observable<GolfProductDetails[]>;

  private destroyed$ = new Subject<void>();

  constructor(
    private fb: FormBuilder, 
    private authService: AuthService,
    private eventSpaceService: EventSpaceService,
    private eventService: EventService,
    private router: Router,
    private bsModalService: BsModalService,
    private toastrService: ToastrService,
    private golfProductService: GolfProductService,
    private reservationTypeService: ReservationTypeService
  ) { }

  ngOnInit(): void {
    this.eventSpaces$ = this.authService.course$.pipe(
      switchMap(course => this.eventSpaceService.query({course: course.id}).records$),
      shareReplay(1),
    );

    let reservationType$ = this.reservationTypeService.getAll().pipe(
      map(types => types.find(t => t.slug === GolfProductReservationTypes.Event)),
    );

    let refreshGolfProducts$ = combineLatest([this.golfProductParamsChanged$, reservationType$]).pipe(
      switchMap(([_, type]) => {
        let holes = this.courseLength?.value;
        let cart = this.carts?.value > 0;
        return this.golfProductService.query({reservationType: type?.id, holes, cart}).records$;
      }),
      takeUntil(this.destroyed$),
    );

    let initialGolfProducts$ = reservationType$.pipe(
      switchMap(type => {
        return this.golfProductService.query({
          holes: this.courseLength?.value,
          cart: (this.carts?.value > 0),
          reservationType: type?.id
        }).records$.pipe(
          takeUntil(this.destroyed$),
        );
      })
    )

    this.golfProducts$ = merge(initialGolfProducts$, refreshGolfProducts$);

    // Golf Product fields should only be required if event space is of type Course
    this.event_space?.valueChanges.subscribe(async id => {
      try {
        let eventSpace = await this.eventSpaceService.get(id).toPromise();
        this.eventSpace$.next(eventSpace);

        if(eventSpace.type === EventSpaceType.Course) {
          this.golfproduct?.setValidators([Validators.required]);
          this.carts?.setValidators([Validators.required]);
          this.carts?.setValue(0);
          this.courseLength?.setValidators([Validators.required]);
          this.courseLength?.setValue(9);
        }
        else {
          this.golfproduct?.clearValidators();
          this.carts?.clearValidators();
          this.carts?.setValue(null);
          this.courseLength?.clearValidators();
          this.courseLength?.setValue(null);
        }

        this.golfproduct?.setValue(null);
        this.golfproduct?.updateValueAndValidity();

        this.carts?.updateValueAndValidity();
        this.courseLength?.updateValueAndValidity();
      }
      catch(e: any) {
        console.error(e?.message);
        this.eventSpace$.next(null);
      }
    });

    this.courseLength?.valueChanges.subscribe(courseLength => {
      this.golfproduct?.reset();
      this.golfProductParamsChanged$.next();
    });
    
    this.carts?.valueChanges.subscribe(carts => {
      this.golfproduct?.reset();
      this.golfProductParamsChanged$.next();
    });

    this.eventSpaceRequiresGolfProduct$ = this.eventSpace$.pipe(
      map(space => {
        if(!space) {
          return false;
        }
        return space.type === EventSpaceType.Course}
      )
    );
      
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  async openSelectCustomerModal() {
    const selectModalRef = this.bsModalService.show(PlayerSelectModalComponent, {
      class: "modal-lg modal-dialog-centered customer-modal",
      ignoreBackdropClick: true,
      initialState: {
        selectedUser: this.user?.value ?? null,
        previousUser: this.user?.value ?? null,
      }
    });

    if (selectModalRef.content) {
      selectModalRef.content.select$
        .pipe(
          tap(((user: UserDetails | null) => {
            this.user?.patchValue(user);
            selectModalRef.hide();
          })),
          takeUntil(this.destroyed$)
        ).subscribe();

        selectModalRef.content.openCreateModal$
        .pipe(
          takeUntil(this.destroyed$)
        )
        .subscribe(() => {
          this.openCreateModal();
          selectModalRef.hide();
        });

      selectModalRef.content.openEditModal$
        .pipe(
          takeUntil(this.destroyed$)
        )
        .subscribe((_user: UserDetails) => {
          this.openEditModal(_user);
          selectModalRef.hide();
        });
    }
  }

  public openEditModal(user: UserDetails): void {
    const editModalRef = this.bsModalService.show(PlayerEditModalComponent, {
      class: "modal-lg modal-dialog-centered",
      ignoreBackdropClick: true,
      initialState: {
        userId: user.id
      }
    });

    if (editModalRef.content) {
      editModalRef.content.submit$
        .pipe(
          tap((user: UserDetails) => {
            this.user?.patchValue(user);
            editModalRef.hide();
          }),
          takeUntil(this.destroyed$)
        ).subscribe();

      editModalRef.content.cancel$
        .pipe(
          takeUntil(this.destroyed$)
        )
        .subscribe(() => editModalRef.hide());
    }
  }

  public openCreateModal(): void {
    const createModalRef = this.bsModalService.show(PlayerCreateModalComponent, {
      class: "modal-lg modal-dialog-centered",
      ignoreBackdropClick: true
    });

    if (createModalRef.content) {
      createModalRef.content.submit$
        .pipe(
          tap((user: UserDetails) => {
            this.user?.patchValue(user);
            createModalRef.hide();
          }),
          takeUntil(this.destroyed$)
        ).subscribe();

      createModalRef.content.cancel$
        .pipe(
          takeUntil(this.destroyed$)
        )
        .subscribe(() => createModalRef.hide());
    }
  }

  submit() {
    let startDay = this.start?.value;
    let startTime = this.startTime?.value;

    let start = this.combineDates(startDay, startTime);

    let record = {
      ...(this.form.value),
      start
    };

    delete record.startTime;
    delete record.endTime;

    if(this.end?.value && this.endTime?.value) {
      record['end'] = this.combineDates(this.end.value, this.endTime.value);
    }

    let eventAmount = record.eventAmount ?? null;
    delete record.eventAmount;

    if(!eventAmount || eventAmount <= 0) {
      this.toastrService.error('Event Total Price must be greater than 0.');
      return;
    }

    // let depositAmount = record.depositAmount ?? null;
    // delete record.depositAmount;

    let golfproduct = record.golfproduct ?? null;
    delete record.golfproduct;

    let userId = record.user.id;
    delete record.user;

    this.authService.course$.pipe(
      take(1),
      switchMap(course => this.eventService.createWithOrder(record, course.id, eventAmount, userId, golfproduct).pipe(take(1)))
    ).subscribe(
      (event) => {
        this.router.navigate(['/admin', 'events']);
      },
      (err) => {
        console.error(err?.message);
      }
    )
  }

  private combineDates(day: Date, time: Date): Date {
    return new Date(day.getFullYear(), day.getMonth(), day.getDate(), time.getHours(), time.getMinutes(), time.getSeconds());
  }
}
