import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';

import { environment } from 'projects/admin/src/environments/environment';
import { GolfPassDetails } from 'projects/shared/src/lib/models/golfpass';
import { GolfPassService } from 'projects/shared/src/lib/services/golf-pass.service';
import { ConfirmationModalService } from 'projects/shared/src/lib/services/confirmation-modal.service';

@Component({
  selector: 'gcl-admin-golf-pass-edit',
  templateUrl: './golf-pass-edit.component.html',
  styleUrls: ['./golf-pass-edit.component.scss']
})
export class GolfPassEditComponent implements OnInit, OnDestroy {
  public apiUrl = environment.apiUrl;
  public faChevronLeft = faChevronLeft;

  get name() { return this.form.get("name") as FormControl }
  get price() { return this.form.get("price") as FormControl }
  get description() { return this.form.get("description") as FormControl }
  get available() { return this.form.get("available") as FormControl }
  get taxable() { return this.form.get("taxable") as FormControl }

  public form = this.fb.group({
    name: [null, [Validators.required]],
    price: [0, [Validators.required]],
    description: [null, []],
    available: [false, [Validators.required]],
    taxable: [true, [Validators.required]],
  });

  public guidelines = this.fb.control(null);

  public golfpassId$!: Observable<number>;
  public golfpass$!: Observable<GolfPassDetails>;

  private destroyed$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private golfpassService: GolfPassService,
    public route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private confirmationService: ConfirmationModalService
  ) { }

  ngOnInit(): void {
    this.golfpassId$ = this.route.params.pipe(
      map(params => params.golfpassid)
    );

    this.golfpass$ = this.golfpassId$.pipe(
      switchMap(golfpassId => this.golfpassService.get(golfpassId)),
      tap(golfpass => {
        this.form.patchValue({
          name: golfpass.name,
          price: golfpass.price,
          description: golfpass.description,
          available: golfpass.available,
          taxable: golfpass.taxable,
        });

        if(golfpass.guidelines) {
          this.guidelines.patchValue({name: golfpass.guidelines.name});
        }
      }),
      shareReplay(1),
      takeUntil(this.destroyed$)
    );
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public openCancelModal() {
    this.confirmationService.showConfirmationModal({
      title: 'Confirm Cancel',
      message: 'Are you sure you want to discard changes and go back?'
    }).subscribe(confirm => {
      if (confirm === true) {
        this.router.navigate(['/admin', 'golf-passes']);
      }
    })
  }

  public openSaveModal() {
    this.confirmationService.showConfirmationModal({
      title: 'Confirm Save',
      message: 'Do you want to save these changes?'
    }).subscribe(confirm => {
      if (confirm === true) {
        this.form.markAllAsTouched();
        if (this.form.invalid) {
          return;
        }

        this.golfpass$
          .pipe(
            switchMap(golfpass => this.golfpassService.updateWithGuidelines(golfpass.id, { ...golfpass, ...this.form.value }, this.guidelines.value)),
            takeUntil(this.destroyed$)
          )
          .subscribe((golfpass: GolfPassDetails) => {
            this.form.setErrors({});

            this.toastr.success("Golf pass updated successfully!");
            this.router.navigate(["/admin", "golf-passes", golfpass.id, "view"]);
          }, err => {
            this.toastr.error("An error has occurred. Please try again.")
          });
      }
    })
  }

  public onFileChanged(event: any) {
    this.guidelines.patchValue(event.target.files[0]);
  }

}
