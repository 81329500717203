import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { v4 as uuidv4 } from 'uuid';
import { ToastrService } from 'ngx-toastr';

import { UserService } from 'projects/shared/src/lib/services/user.service';
import { UserDetails } from 'projects/shared/src/public-api';
import { PlayerFormComponent } from 'projects/shared/src/lib/components/player-form/player-form.component';

@Component({
  selector: 'gcl-admin-player-create-modal',
  templateUrl: './player-create-modal.component.html',
  styleUrls: ['./player-create-modal.component.scss']
})
export class PlayerCreateModalComponent implements OnInit, OnDestroy {

  @ViewChild(PlayerFormComponent) playerFormComponent!: PlayerFormComponent;

  public faChevronLeft = faChevronLeft;

  private destory$: Subject<boolean> = new Subject();

  public submit$: EventEmitter<UserDetails> = new EventEmitter<UserDetails>();
  public cancel$: EventEmitter<void> = new EventEmitter<void>();

  previousUser!: UserDetails | null;

  constructor(public bsModalRef: BsModalRef, private toastr: ToastrService, private userService: UserService) { }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.bsModalRef.hide();
    
    this.destory$.next(true);
    this.destory$.complete();
  }

  public submit(): void {
    if (this.playerFormComponent.form.valid) {
      this.userService.create({
        ...this.playerFormComponent.form.value, ...{
          role: 3,
          password: uuidv4()
        }
      })
        .pipe(
          takeUntil(this.destory$)
        )
        .subscribe(
          (user: UserDetails) => {
            this.toastr.success("User created successfully!");
            
            this.userService.generateAndSendTempPasswordEmail(user.id);
            this.submit$.emit(user);
          },
          (err: HttpErrorResponse) => {
            err.error.message.forEach((message: any) => {
              if (message.messages.length > 0) {
                message.messages.forEach((mes: any) => {
                  const field = mes.field[0];
                  const formField = this.playerFormComponent.form.get(field);
                  if (formField) {
                    formField.setErrors({
                      error: mes.message
                    });
                  }
                })
              }
            })
          });
    } else {
      Object.values(this.playerFormComponent.form.controls).forEach(control => {
        control.markAsTouched();
        control.markAsDirty();
      });
    }
  }

  public onCancel(): void {
    this.cancel$.emit();
    this.bsModalRef.hide();
  }

}
