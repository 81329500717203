<div class="d-flex justify-content-start py-2 bg-light no-flex">
    <a class="text-light-green cursor-pointer" (click)="goBack()">
        <fa-icon [icon]="faChevronLeft" class="px-3"></fa-icon> <span>Back to Tee Sheet</span>
    </a>
</div>
<div class="container-fluid py-2" *ngIf="order$ | async as order">
    <div class="row">
        <ng-container *ngIf="order.status !== OrderStatuses.Paid; else orderDetails">
            <div class="col-12" >
                <gcl-lib-date-picker-slider
                    [date]="selectedDate"
                    [format]="'MMMM YYYY'"
                    [readonly]="true"></gcl-lib-date-picker-slider>
                <h5  class="text-center font-weight-bold">{{selectedTime | date: 'shortTime'}} - {{nineType}}</h5>
                <h6  class="page-header">Tee Sheet</h6>
                <gcl-admin-reservation-form
                    [canEdit]="canEdit"
                    [order]="order"
                    [allowSqueezeTime]="reservation.canSqueezeTime && canEdit"
                    [availableSlots]="reservation.availableSlots"
                    [disableGroupReservation]="reservation.singleBookingReservationOnly"
                    [disableSingleBookingReservation]="reservation.groupReservationOnly"
                    (submit)="submit($event)"
                    (cancel)="cancel()"
                    (addSqueezeTime)="addSqueezeTime($event)"></gcl-admin-reservation-form>                    
            </div>
        </ng-container>
        <ng-template #orderDetails>
            <div class="col-12">
                <h6 class="page-header">Reservation Details</h6>
                <gcl-admin-order-details [order]="order"></gcl-admin-order-details>
            </div>
            <div class="container-fluid py-2 mx-1 fill-flex overflow-y-auto" *ngIf="order.payments && order.payments.length > 0">
                <div class="row">
                    <div class="col-12">
                        <gcl-admin-order-payment-table [order]="order"></gcl-admin-order-payment-table>
                    </div>
                </div>
            </div>
        </ng-template>     
    </div>
</div>
