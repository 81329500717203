import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, combineLatest } from 'rxjs';
import { map, switchMap, takeUntil, take } from 'rxjs/operators';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';

import { environment } from 'projects/admin/src/environments/environment';
import { GolfProductDetails } from 'projects/shared/src/lib/models/golfproduct';
import { GolfProductService } from 'projects/shared/src/lib/services/golf-product.service';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { GolfProductReservationTypes } from 'projects/shared/src/lib/enumerations/golf-product-reservation-type';
import { ProductFormComponent } from '../../../shared/components/product-form/product-form.component';

@Component({
  selector: 'gcl-admin-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.scss']
})
export class ProductEditComponent implements OnInit, OnDestroy {
  @ViewChild(ProductFormComponent) productFormComponent!: ProductFormComponent;

  public apiUrl = environment.apiUrl;
  public faChevronLeft = faChevronLeft;
  public GolfProductReservationTypes = GolfProductReservationTypes;

  public productId$!: Observable<number>;
  public product$!: Observable<GolfProductDetails>;

  private destroyed$ = new Subject();

  constructor(
    private golfProductService: GolfProductService,
    private authService: AuthService,
    public route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.productId$ = this.route.params.pipe(
      map(params => params.productId)
    );

    this.product$ = this.productId$.pipe(
      switchMap((productId) => this.golfProductService.get(productId)),
      takeUntil(this.destroyed$)
    );

    combineLatest([this.product$, this.authService.course$])
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(([product, course]) => {
        this.productFormComponent.form.patchValue({
          course: course.id,
          name: product.name,
          isEightteenHoles: product.holes == 18 ? true : false,
          cart: product.cart,
          price: product.price,
          notes: product.notes,
          accountCode: product.accountCode,
          cost: product.cost,
          reservation_types: product.reservation_types ? product.reservation_types.map(r => r.id) : []
        });
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public submit(): void {
    this.productFormComponent.form.markAllAsTouched();
    if (this.productFormComponent.form.invalid) {
      return;
    }

    this.product$.pipe(
      switchMap(product => this.golfProductService.update(product.id, {
        course: this.productFormComponent.course?.value,
        name: this.productFormComponent.name?.value,
        holes: (this.productFormComponent.isEightteenHoles?.value ? 18 : 9),
        cart: this.productFormComponent.cart?.value,
        price: this.productFormComponent.price?.value,
        notes: this.productFormComponent.notes?.value,
        accountCode: this.productFormComponent.accountCode?.value,
        cost: this.productFormComponent.cost?.value,
        reservation_types: this.productFormComponent.reservation_types?.value,
      })),
      take(1),
      takeUntil(this.destroyed$)
    ).subscribe((product: GolfProductDetails) => {
      this.productFormComponent.form.setErrors({});

      this.toastr.success("Product updated successfully!");
      this.router.navigate(["/manager", "products", product.id, "view"]);
    }, err => {
      this.toastr.error("An error has occurred. Please try again.")
    });
  }

  public cancel(): void {
    this.product$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(product => this.router.navigate(['/manager', 'products', product.id, 'view']));
  }

}
