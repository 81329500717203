import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { BsModalService } from 'ngx-bootstrap/modal';
import { RefundModalComponent } from 'projects/shared/src/lib/components/refund-modal/refund-modal.component';
import { GetReservationTypesDisplayName } from 'projects/shared/src/lib/enumerations/reservation-type';
import { GolfOrderService } from 'projects/shared/src/lib/services/golf-order.service';
import { OrderService } from 'projects/shared/src/lib/services/order.service';
import { GolfOrderDetails, OrderDetails } from 'projects/shared/src/public-api';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'gcl-admin-order-refund-credit-balance',
  templateUrl: './order-refund-credit-balance.component.html',
  styleUrls: ['./order-refund-credit-balance.component.scss']
})
export class OrderRefundCreditBalanceComponent implements OnInit, OnDestroy {
  GetReservationTypeDisplayName = GetReservationTypesDisplayName;
  public faChevronLeft = faChevronLeft;

  public order$!: Observable<OrderDetails>;
  public golfOrders$!: Observable<GolfOrderDetails[]>;

  private destroyed$ = new Subject();

  public form = this.fb.group(
    {
      golfOrders: this.fb.array([])
    }
  );

  get golfOrders() { return this.form.get('golfOrders') as FormArray }

  constructor(
    private route: ActivatedRoute, 
    private orderService: OrderService,
    private golfOrderService: GolfOrderService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    const orderId$ = this.route.params.pipe(
      map(params => params.orderId),
    );

    this.order$ = orderId$.pipe(
      switchMap(id => this.orderService.get(id)),
      takeUntil(this.destroyed$)
    );
    this.golfOrders$ = orderId$.pipe(
      switchMap(id => this.golfOrderService.query({order: id, refunded: false}).records$),
      tap(golfOrders => this.buildFormArray(golfOrders)),
      takeUntil(this.destroyed$)
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  buildFormArray(golfOrders: GolfOrderDetails[]): void {
    for(let i = 0; i < golfOrders.length; i++) {
      if(!this.golfOrders.at(i)) {
        this.golfOrders.insert(i, new FormControl(false));
      }
    }
  }

  getSelectedGolfOrders(golfOrders: GolfOrderDetails[]) {
    let selectedGolfOrders = [];

    for(let i = 0; i < this.golfOrders.value.length; i++) {
      if(this.golfOrders.value[i] === true) {
        selectedGolfOrders.push(golfOrders[i]);
      }
    }

    return selectedGolfOrders;
  }

  async openRefundModal() {
    let golfOrders = await this.golfOrders$.pipe(take(1)).toPromise();

    let golfOrdersToRefund = this.getSelectedGolfOrders(golfOrders);

    let order = await this.order$.pipe(take(1)).toPromise();

    const modalRef = this.modalService.show(RefundModalComponent, {
      initialState: {order, golfOrdersToRefund},
      class: "modal-md modal-dialog-centered",
      ignoreBackdropClick: true,
      focus: true
    });

    if (!modalRef.content) {
      return;
    }

    modalRef.content.accept$.pipe(
      take(1)
    ).subscribe(async choice => {
      if(choice === true) {
        await this.orderService.refundRainCheck(order, golfOrdersToRefund.map(go => go.id)).toPromise();
        this.router.navigate(['/admin', 'orders', order.id, 'view']);
      }
    });
  }

  toggleAllChanged(evt: any) {
    for(let control of this.golfOrders.controls) {
      control.setValue(evt.target.checked);
    }
  }

  modalDisabled(): boolean {
    if(!this.golfOrders || !this.golfOrders?.value || !this.golfOrders?.value?.length) {
      return true;
    }

    return !(<boolean[]>this.golfOrders.value).some(go => go === true);
  }
}
