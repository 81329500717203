import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, shareReplay, skip, takeUntil } from 'rxjs/operators';

import { OnPageEvent } from 'projects/shared/src/lib/models/on-page-event';
import { UserDetails } from 'projects/shared/src/public-api';
import { UserService } from 'projects/shared/src/lib/services/user.service';
import { SortDirections } from 'projects/shared/src/lib/enumerations/sort-directions';

@Component({
  selector: 'gcl-admin-player-list',
  templateUrl: './player-list.component.html',
  styleUrls: ['./player-list.component.scss']
})
export class PlayerListComponent implements OnInit, OnDestroy {
  initialPagination = {
    itemsPerPage: 10,
    page: 1,
    sortBy: 'firstName',
    sortDescending: false,
    search: ''
  };
  pagination$ = new BehaviorSubject<OnPageEvent>(this.initialPagination);

  total$: BehaviorSubject<number> = new BehaviorSubject(0);
  records$: ReplaySubject<UserDetails[]> = new ReplaySubject(0);

  private destory$: Subject<boolean> = new Subject();

  constructor(private userService: UserService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.pagination$
      .pipe(
        takeUntil(this.destory$),
        skip(1)
      )
      .subscribe(() => {
        this.getPageResult();
      });

    this.initFunc();
  }

  ngOnDestroy() {
    this.destory$.next(true);
    this.destory$.complete();
  }

  private getPageResult() {
    const pagedResult = this.userService.query({
      skip: (this.pagination$.value.page - 1) * this.pagination$.value.itemsPerPage,
      take: this.pagination$.value.itemsPerPage,
      sortColumns: [{
        column: this.pagination$.value.sortBy,
        direction: this.pagination$.value.sortDescending ? SortDirections.Descending : SortDirections.Ascending,
      }],
      search: this.pagination$.value.search,
      roles: ["player"]
    });

    pagedResult.filteredCount$.subscribe((total) => this.total$.next(total));
    pagedResult.records$.subscribe((records) => this.records$.next(records));
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.pagination$.value,
      queryParamsHandling: 'merge',
    });
  }

  public goToCreate() {
    this.router.navigate(['/admin', 'players', 'create']);
  }

  public getQueryParams() {
    return this.route.snapshot.queryParams;
  }

  private initFunc() {
    const params = this.route.snapshot.queryParams;
    this.initialPagination = {
      itemsPerPage: !isNaN(parseInt(params['itemsPerPage'])) ? parseInt(params['itemsPerPage']) : this.initialPagination.itemsPerPage,
      page: !isNaN(parseInt(params['page'])) ? parseInt(params['page']) : this.initialPagination.page,
      sortBy: params['sortBy'] ? params['sortBy'] : this.initialPagination.sortBy,
      sortDescending: params['sortDescending'] ? params['sortDescending'] : this.initialPagination.sortDescending,
      search: params['search'] ? params['search'] : this.initialPagination.search
    };
    this.pagination$.next(this.initialPagination);
  }
}
