<h6 class="page-header mb-0 pb-0">Payment Method(s)</h6>
<gcl-lib-table 
    [totalItems]="order.payments.length"                                                          
    [tableClass]="'table table-striped table-hover'" 
    [showSearch]="false"
    [enableCreate]="false"
    [showPageOptions]="false"
    [showPagination]="false"
>
    <thead>
        <tr>
            <th columnSort="type">Tender</th>
            <th columnSort="created_at">Timestamp</th>
            <th columnSort="amount">Total</th>
            <!-- <th width="100" class="text-center">Manage</th> -->
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let payment of order.payments">
            <td>{{GetOrderPaymentTypesDisplayName(payment.type)}}</td>
            <td>{{payment.created_at | date: 'medium'}}</td>
            <td>{{payment.amount | currency}}</td>
            <!-- <td class="text-center">
                <div class="btn-group btn-group-sm">
                    <a [routerLink]="['/admin','add-on', product.id, 'view']" class="btn btn-link btn-primary-light">View</a>
                </div>
            </td> -->
        </tr>
    </tbody>
</gcl-lib-table>