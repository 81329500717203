<div class="d-flex flex-column h-100">
    <div class="d-flex justify-content-start py-2 bg-light no-flex">
        <a class="text-light-green"  [routerLink]="['/admin','carts']">
            <fa-icon [icon]="faChevronLeft" class="px-3"></fa-icon> <span>Back to Cart View</span>
        </a>
    </div>
    <div class="container-fluid py-2 fill-flex overflow-y-auto">
        <div class="row">
            <div class="col-12">
                <form [formGroup]="form" (ngSubmit)="submit()">
                    <div class="d-flex justify-content-end py-2">
                        <button type="submit" class="btn btn-light-green px-5">Save</button>
                    </div>
                    <div class="card">
                        <div class="card-body">                                                        
                            <div class="row">
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="name" >Name</label>
                                                <input type="text" class="form-control" formControlName="name" name="name" [class.is-invalid]="name?.invalid && name?.touched" UniqueCartNameValidator/>
                                                <div *ngIf="name?.invalid && name?.touched" class="invalid-feedback">
                                                    <div *ngIf="name?.errors?.required">
                                                        Please enter a name.
                                                    </div>
                                                    <div *ngIf="name?.errors?.maxlength">
                                                        Cart name cannot exceed 10 characters.
                                                    </div>
                                                    <div *ngIf="name?.errors?.duplicateCartName">
                                                        Cart name already exists.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="status" >Status</label>
                                                <select class="custom-select" formControlName="status" name="status"  
                                                    [hasNullOption]="true"
                                                    enum="CartStatuses"
                                                    [value]="status?.value"
                                                    [class.is-invalid]="status?.invalid && status?.touched">
                                                </select>
                                                <div *ngIf="status?.invalid && status?.touched" class="invalid-feedback">
                                                    <div *ngIf="status?.errors?.required">
                                                        Please select a status.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="user" >Customer</label>
                                                <select class="form-control" name="user" formControlName="user">
                                                    <option [value]="null">Select Customer</option>
                                                    <option *ngFor="let user of users$ | async" [value]="user.id">{{user.firstName}} {{user.lastName}} ({{user.email}})</option>
                                                </select>
                                                <div *ngIf="user?.invalid && user?.touched" class="invalid-feedback">
                                                    <div *ngIf="user?.errors?.required">
                                                        Please select a customer.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="device" >Device</label>
                                                <select class="form-control" name="device" formControlName="device" [class.is-invalid]="device?.invalid && device?.touched">
                                                    <option [value]="null">Select Device</option>
                                                    <option *ngFor="let device of devices$ | async" [value]="device.id">{{device.name}}</option>
                                                </select>
                                                <div *ngIf="device?.invalid && device?.touched" class="invalid-feedback">
                                                    <div *ngIf="device?.errors?.required">
                                                        Please select a device.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
