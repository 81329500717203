<gcl-lib-table 
[totalItems]="total$ | async" 
[itemsPerPage]="initialPagination.itemsPerPage" 
[sortBy]="initialPagination.sortBy" 
[sortDescending]="initialPagination.sortDescending"
[page]="initialPagination.page"
[searchTxt]="initialPagination.search"
(onPage)="pagination$.next($event)" 
[tableClass]="'table table-striped table-hover'" 
[enableCreate]="enableCreate"
(onCreate)="onCreateClick()"
>
<thead>
    <tr>
        <th columnSort="name">NAME</th>
        <th columnSort="start">START DATE/TIME</th>
        <th columnSort="end">END DATE/TIME</th>
        <th columnSort="type">TYPE</th>
        <th columnSort="event_space.name">EVENT SPACE</th>
        <th columnSort="order.status">PAYMENT STATUS</th>
        <th>CUSTOMER</th>
        <th columnSort="description">DESCRIPTION</th>
        <th width="100" class="text-center">MANAGE</th>
    </tr>
</thead>
<tbody>
    <tr *ngFor="let event of records$ | async">
        <td>{{event.name}}</td>
        <td>{{event.start | date: 'medium'}}</td>
        <td>{{event.end | date: 'medium'}}</td>
        <td>{{event.type}}</td>
        <td>{{event.event_space?.name}}</td>
        <td>{{event.order?.status}}</td>
        <td>{{getEventUser($any(event?.order?.users_permissions_user)) | async}}</td>
        <td>{{event.description}}</td>
        <td class="text-center">
            <div class="btn-group btn-group-sm">
                <a [routerLink]="['/admin','events', event.id, 'view']" [queryParams]="getQueryParams()" class="btn btn-link btn-primary-light">View</a>
            </div>
        </td>
    </tr>
    <tr *ngIf="(total$ | async) == 0">
        <td colspan="10">No events.</td>
    </tr>
</tbody>
</gcl-lib-table>
