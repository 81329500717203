import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, shareReplay, takeUntil } from 'rxjs/operators';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';

import { environment } from 'projects/admin/src/environments/environment';
import { PromoCodeDetails } from 'projects/shared/src/lib/models/promo-code';
import { ConfirmationModalService } from 'projects/shared/src/lib/services/confirmation-modal.service';
import { PromoCodeService } from 'projects/shared/src/lib/services/promo-code.service';

@Component({
  selector: 'gcl-admin-promo-code-view',
  templateUrl: './promo-code-view.component.html',
  styleUrls: ['./promo-code-view.component.scss']
})
export class PromoCodeViewComponent implements OnInit, OnDestroy {
  public apiUrl: string = environment.apiUrl;
  public faChevronLeft = faChevronLeft;

  public promocode$!: Observable<PromoCodeDetails>;

  private destroyed$ = new Subject();

  constructor(
    private promocodeService: PromoCodeService,
    public route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private confirmationModalService: ConfirmationModalService
  ) { }

  ngOnInit(): void {
    const promocodeId$ = this.route.params.pipe(
      map(params => params.promocodeId)
    );

    this.promocode$ = promocodeId$.pipe(
      switchMap(promocodeId => this.promocodeService.get(promocodeId)),
      shareReplay(1)
    );
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public openDeleteModal() {
    this.confirmationModalService.showConfirmationModal({
      title: 'Confirm Deletion',
      message: 'You are about to delete this promo code? This action cannot be undone.'
    }).subscribe(accept => {
      if (accept) {
        this.delete();
      }
    });
  }

  private delete(): void {
    this.promocode$
      .pipe(
        switchMap((promocode: PromoCodeDetails) => this.promocodeService.delete(promocode.id)),
        shareReplay(1),
        takeUntil(this.destroyed$)
      )
      .subscribe((promocode: PromoCodeDetails) => {
        this.toastr.success("Promo code deleted successfully!");
        this.router.navigate(["/admin", "promo-codes"]);
      });
  }

  public getQueryParams() {
    return this.route.snapshot.queryParams;
  }
}
