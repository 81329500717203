<div class="d-flex flex-column h-100">
    <div class="d-flex justify-content-start py-2 bg-light no-flex">
        <a class="text-light-green" [routerLink]="['/manager','carts']">
            <fa-icon [icon]="faChevronLeft" class="px-3"></fa-icon> <span>Back to Cart List</span>
        </a>
    </div>
    
    
    <div class="container-fluid py-2 h-100 fill-flex overflow-y-auto">
        <div class="row h-100" *ngIf="cart$ | async as cart">
            <div class="col-12 d-flex flex-column">
                <h6 class="page-header">Cart View</h6>
                <div class="d-flex justify-content-end py-2">
                    <a [routerLink]="['/manager','carts', cart.id, 'edit']" class="btn btn-light-green mx-1">Edit</a>
                </div>
                <div class="card h-100">
                    <div class="card-body">
                        <div class="row h-100">
                            <div class="col-4">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label >Name</label>
                                            <p>{{cart.name}}</p>    
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label >Status</label>
                                            <p>{{getCartStatusDisplayName(cart.status)}}</p>    
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label >Device</label>
                                            <p *ngIf="cart.device">{{cart.device?.name}}</p>    
                                            <p *ngIf="!cart.device">N/A</p>    
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label >Customer</label>
                                            <p *ngIf="cart.user">{{cart.user?.firstName}} {{cart.user?.lastName}}</p>
                                            <p *ngIf="!cart.user">N/A</p>
                                        </div>
                                        <p>{{cart.latitude}}</p>
                                        <p>{{cart.longitude}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col mx-auto text-center" *ngIf="course$ | async as course">
                                <div class="h-100">
                                    <gcl-lib-maps
                                        [centerAddress]="course.address"
                                        [coordinates]="[{
                                            coordinate:{
                                                lat: cart.latitude,
                                                lng: cart.longitude
                                            }
                                        }]"></gcl-lib-maps>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>