import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { switchMap, map, shareReplay, takeUntil } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import { GetCartStatusesDisplayName } from 'projects/shared/src/lib/enumerations/cart-status';
import { environment } from 'projects/admin/src/environments/environment';
import { UserService } from 'projects/shared/src/lib/services/user.service';
import { ConfirmationModalService } from 'projects/shared/src/lib/services/confirmation-modal.service';
import { UserDetails } from 'projects/shared/src/public-api';

@Component({
  selector: 'gcl-admin-staff-view',
  templateUrl: './staff-view.component.html',
  styleUrls: ['./staff-view.component.scss']
})
export class StaffViewComponent implements OnInit, OnDestroy {
  public apiUrl: string = environment.apiUrl;
  public faChevronLeft = faChevronLeft;
  public getCartStatusDisplayName = GetCartStatusesDisplayName;

  public user$!: Observable<UserDetails>;

  private destroyed$ = new Subject();

  constructor(private userService: UserService, public route: ActivatedRoute, private router: Router, private toastr: ToastrService, private confirmationModalService: ConfirmationModalService) { }

  ngOnInit(): void {
    const userId$ = this.route.params.pipe(
      map(params => params.staffId)
    );

    this.user$ = userId$.pipe(
      switchMap(userId => this.userService.get(userId)),
      shareReplay(1)
    );
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public openDeleteModal() {
    this.confirmationModalService.showConfirmationModal({
      title: 'Confirm Deletion',
      message: 'You are about to delete this staff? This action cannot be undone.'
    }).subscribe(accept => {
      if (accept) {
        this.delete();
      }
    });
  }

  private delete(): void {
    this.user$.pipe(
      switchMap((user: UserDetails) => this.userService.delete(user.id)),
      shareReplay(1),
      takeUntil(this.destroyed$)
    ).subscribe((user: UserDetails) => {
      this.toastr.success("staff deleted successfully!");
      this.router.navigate(["/admin", "staff"]);
    });
  }
}
