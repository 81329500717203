<div class="container-fluid py-2">
    <div class="row">
        <div class="col-12">
            <h6  class="page-header">Staff List</h6>
            <gcl-lib-table 
                [totalItems]="total$ | async" 
                [sortBy]="'firstName'" 
                (onPage)="pagination$.next($event)" 
                [tableClass]="'table table-striped table-hover'" 
                (onCreate)="goToCreate()">
                <thead>
                    <tr>
                        <th columnSort="firstName">Name</th>
                        <th columnSort="email">Email</th>
                        <th columnSort="cellphone">Cellphone</th>
                        <th width="100" class="text-center">Manage</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let staff of records$ | async">
                        <td>{{staff.firstName}} {{staff.lastName}}</td>
                        <td>{{staff.email}}</td>
                        <td>{{staff.cellphone}}</td>
                        <td class="text-center">
                            <a [routerLink]="['/admin','staff', staff.id, 'view']" class="text-light-green">View</a>
                        </td>
                    </tr>
                    <tr *ngIf="(total$ | async) == 0">
                        <td colspan="5">No staff.</td>
                    </tr>
                </tbody>
            </gcl-lib-table>
        </div>
    </div>
</div>