<div class="input-group mb-3">
    <select class="form-control" [(ngModel)]="id" (ngModelChange)="setRecord($event)" [attr.disabled]="disabled">
        <option [value]="undefined">Select Golf Product {{selectedItem?.name}}</option>
        <option *ngFor="let option of records$ | async" 
            [value]="option.id">{{option.price | currency | golfProductPrice}} - {{option.name}} ({{option.holes}} Holes/Cart {{option.cart ? "Y" : "N"}})</option>
    </select>
    <button class="btn btn-outline-secondary" type="button" id="button-addon2" (click)="addRecord()" *ngIf="multiselect && !disabled">Add</button>
</div>
<ng-content></ng-content>

<ng-template #itemTemplate let-model="item">
    <span>{{model.name}} ({{model.holes}} Holes/Cart {{model.cart ? "Y" : "N"}})</span>
</ng-template>