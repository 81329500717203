import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PaymentMethod } from '@stripe/stripe-js';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ProductType } from 'projects/shared/src/lib/enumerations/product-type';
import { StripePaymentToken } from 'projects/shared/src/lib/models/stripe-payment-token';
import { OrderDetails } from 'projects/shared/src/public-api';
import { PrinterDetails } from 'projects/shared/src/lib/models/printer';
import { PrinterService } from 'projects/shared/src/lib/services/printer.service';

export interface CustomAmountConfirmation {
  amount: number;
  email?: string;
  printerArr: number[];
}

@Component({
  selector: 'gcl-admin-custom-payment-modal',
  templateUrl: './custom-payment-modal.component.html',
  styleUrls: ['./custom-payment-modal.component.scss']
})
export class CustomPaymentModalComponent implements OnInit {
  order!: OrderDetails;
  showEmail: boolean = true;
  last4?: string;
  submitButtonText: string = 'Pay';

  printers$!: Observable<PrinterDetails[]>;
  printerArr: number[] = [];
  disableEmail: boolean = true;

  @Output() onCustomAmountSelect = new EventEmitter<CustomAmountConfirmation>();

  get amount() { return this.form.get('amount'); }
  get email() { return this.form.get('email'); }

  public form = this.fb.group({
    amount: [null, [Validators.required, Validators.min(0)]],
    email: ['', []]
  });

  constructor(private fb: FormBuilder, private bsModalRef: BsModalRef, private printerService: PrinterService) {
  }


  ngOnInit(): void {
    this.amount?.patchValue(this.order?.finaltotal ?? 0);

    if (this.order.course) {
      this.printers$ = this.printerService.query({
        course: this.order.course.id
      }).records$.pipe();
    }
  }

  assignCertificate() {
    if(this.form.valid) {
      this.onCustomAmountSelect.emit({
        amount: this.amount?.value,
        email: this.email?.value,
        printerArr: this.printerArr
      });
    }
  }

  cancel() {
    this.bsModalRef.hide();
  }

  updatePrinters(event: any) {
    if (event.target.checked) {
      if (this.printerArr.indexOf(event.target.value) < 0) {
        this.printerArr.push(event.target.value);
      }
    } else {
      if (this.printerArr.indexOf(event.target.value) > -1) {
          this.printerArr.splice(this.printerArr.indexOf(event.target.value), 1);
      }
    }
  }
}
