<div class="d-flex flex-column h-100">
    <div class="d-flex justify-content-start py-2 bg-light no-flex">
        <a class="text-light-green" [routerLink]="['/admin','promo-codes']" *ngIf="!(promocodeId$ | async)">
            <fa-icon [icon]="faChevronLeft" class="px-3"></fa-icon> <span>Back to Promo Code List</span>
        </a>
        <a class="text-light-green" [routerLink]="['/admin','promo-codes', promocodeId,'view']" *ngIf="promocodeId$ | async as promocodeId">
            <fa-icon [icon]="faChevronLeft" class="px-3"></fa-icon> <span>Back to Promo Code View</span>
        </a>
    </div>
    
    <div class="container-fluid py-2 fill-flex overflow-y-auto">
        <div class="row" *ngIf="((promocode$ | async) && (promocodeId$ | async)) || !(promocodeId$ | async)">
            <div class="col-12">
                <form [formGroup]="form" (ngSubmit)="submit()">
                    <div class="d-flex justify-content-end py-2">
                        <button type="submit" class="btn btn-light-green px-5">Save</button>
                    </div>
                    <div class="card">
                        <div class="card-body">
                            <div class="form-group">
                                <label >Code</label>
                                <input type="text" class="form-control" formControlName="code" name="code" [class.is-invalid]="code?.invalid && code?.touched" />
                                <div *ngIf="code?.invalid && code?.touched" class="invalid-feedback">
                                    <div *ngIf="code?.errors?.required">
                                        Please enter a code.
                                    </div>
                                </div>
                            </div>
                    
                            <div class="form-group">
                                <label >Active</label>
                                <gcl-lib-toggle-switch formControlName="active"></gcl-lib-toggle-switch>
                            </div>
                    
                            <div class="form-group">
                                <label >Active Date From</label>
                                <input type="date" class="form-control" formControlName="activeDateFrom" name="activeDateFrom" [class.is-invalid]="activeDateFrom?.invalid && activeDateFrom?.touched" />
                                <div *ngIf="activeDateFrom?.invalid && activeDateFrom?.touched" class="invalid-feedback">
                                    <div *ngIf="activeDateFrom?.errors?.required">
                                        Please enter a Active Date From.
                                    </div>
                                </div>
                            </div>
                    
                            <div class="form-group">
                                <label >Active Date To</label>
                                <input type="date" class="form-control" formControlName="activeDateTo" name="activeDateTo" [class.is-invalid]="activeDateTo?.invalid && activeDateTo?.touched" />
                                <div *ngIf="activeDateTo?.invalid && activeDateTo?.touched" class="invalid-feedback">
                                    <div *ngIf="activeDateTo?.errors?.required">
                                        Please enter a Active Date To.
                                    </div>
                                </div>
                            </div>
                    
                            <div class="form-group">
                                <label >Discount Amount</label>
                                <input type="text" class="form-control" formControlName="discountAmount" name="discountAmount" [class.is-invalid]="discountAmount?.invalid && discountAmount?.touched" />
                                <div *ngIf="discountAmount?.invalid && discountAmount?.touched" class="invalid-feedback">
                                    <div *ngIf="discountAmount?.errors?.required">
                                        Please enter a Discount Amount.
                                    </div>
                                </div>
                            </div>
                    
                            <div class="form-group">
                                <label >Discount Percent</label>
                                <input type="text" class="form-control" formControlName="discountPercent" name="discountPercent" [class.is-invalid]="discountPercent?.invalid && discountPercent?.touched" />
                                <div *ngIf="discountPercent?.invalid && discountPercent?.touched" class="invalid-feedback">
                                    <div *ngIf="discountPercent?.errors?.required">
                                        Please enter a Discount Percent.
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
