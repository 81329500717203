import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import { UserService } from 'projects/shared/src/lib/services/user.service';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { CartService } from 'projects/shared/src/lib/services/cart.service';
import { CourseService } from 'projects/shared/src/lib/services/course.service';
import { DeviceService } from 'projects/shared/src/lib/services/device.service';
import { CartDetails, DeviceDetails, UserDetails } from 'projects/shared/src/public-api';
import { ConfirmationModalService } from 'projects/shared/src/lib/services/confirmation-modal.service';


@Component({
  selector: 'gcl-admin-cart-edit',
  templateUrl: './cart-edit.component.html',
  styleUrls: ['./cart-edit.component.scss']
})
export class CartEditComponent implements OnInit, OnDestroy {
  public faChevronLeft = faChevronLeft;

  get course() { return this.form.get("course") as FormControl }
  get name() { return this.form.get("name") as FormControl }
  get status() { return this.form.get("status") as FormControl }
  get device() { return this.form.get("device") as FormControl }
  get user() { return this.form.get("user") as FormControl }

  public form = this.fb.group({
    name: [null, [Validators.required, Validators.maxLength(10)]],
    status: [null, [Validators.required]],
    device: [null, [Validators.required]],
    user: [null, []],
  });

  public cartId$!: Observable<number>;
  public cart$!: Observable<CartDetails>;

  public devices$!: Observable<Array<DeviceDetails>>;
  public users$!: Observable<Array<UserDetails>>;
  public carts$!: Observable<Array<CartDetails>>;

  private destroyed$ = new Subject();

  constructor(private fb: FormBuilder, public authService: AuthService, private courseService: CourseService, private cartService: CartService, private userService: UserService, private deviceService: DeviceService, public route: ActivatedRoute, private confirmationModalService: ConfirmationModalService, private router: Router, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.devices$ = this.deviceService.getAll()
      .pipe(
        shareReplay(1),
        takeUntil(this.destroyed$)
      );
    this.users$ = this.userService.getAll()
      .pipe(
        shareReplay(1),
        takeUntil(this.destroyed$)
      );

    this.cartId$ = this.route.params.pipe(
      map(params => params.cartId)
    );

    this.cart$ = this.cartId$.pipe(
      switchMap(cartId => this.cartService.get(cartId)),
      tap((cart: CartDetails) => this.form.patchValue({
        course: cart.course?.id,
        name: cart.name,
        status: cart.status,
        device: cart.device?.id || null,
        user: cart.user?.id || null,
      })),
      shareReplay(1),
      takeUntil(this.destroyed$)
    );

    this.carts$ = this.cartService.query().records$.pipe(      
      shareReplay(1)
    );
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public submit(): void {
    this.form.markAllAsTouched();
    
    if(this.user.value === "null") {
      this.user.setValue(null);
    }
    if(this.status.value === "null") {
      this.status.setValue(null);
      this.status.updateValueAndValidity();
    }
    if(this.device.value === "null") {
      this.device.setValue(null);
      this.status.updateValueAndValidity();
    }

    combineLatest([this.cart$,this.carts$]).subscribe(
      ([cart, carts]) => {
        let nameErrors: any = {};
        if(this.duplicateCartName(cart.name, carts)) {
          nameErrors['duplicateCartName'] = true;
        }

        if(this.name.getError('maxLength')) {
          nameErrors['maxlength'] = true;
        }

        if(this.name.getError('required')) {
          nameErrors['required'] = true;
        }

        if(Object.keys(nameErrors).length === 0) {
          nameErrors = null;
        }

        this.name.setErrors(nameErrors);

        if(this.form.invalid) {
          return;
        }

        this.cart$.pipe(
          switchMap(cart => this.cartService.update(cart.id, { ...cart, ...this.form.value })),
          takeUntil(this.destroyed$)
        ).subscribe((cart: CartDetails) => {
          this.form.setErrors({});
    
          this.toastr.success("Cart updated successfully!");
          this.router.navigate(["/admin","carts", cart.id,"view"]);
        }, err => {
          this.toastr.error("An error has occurred. Please try again.")
        });
      }
    );
  }

  public openCancelModal(cart: CartDetails) {
    this.confirmationModalService.showConfirmationModal({
      title: 'Confirm Cancel',
      message: 'Are you sure you want to discard changes and go back?'
    }).subscribe((confirm) => {
      if(confirm === true) {
        this.router.navigate(['/admin','carts', cart.id, 'view'])
      }
    })
  }

  public openSaveModal() {
    this.confirmationModalService.showConfirmationModal({
      title: 'Confirm Save',
      message: 'Do you want to save these changes?'
    }).subscribe((confirm) => {
      if(confirm === true) {
        this.submit();
      }
    })
  }

  private duplicateCartName(currentCartName: string, carts: CartDetails[]): boolean {
    return carts.filter(c => c.name !== currentCartName).map(c => c.name).includes(this.name.value);
  }
}
