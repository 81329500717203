import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { OrderDetails } from 'projects/shared/src/lib/models/order';
import { CategoryService } from 'projects/shared/src/lib/services/category.service';
import { ProductService } from 'projects/shared/src/lib/services/product.service';
import { CategoryDetails, ProductDetails } from 'projects/shared/src/public-api';
import { CheckoutService } from '../../checkout.service';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { GolfPassDetails } from 'projects/shared/src/lib/models/golfpass';
import { GolfPassService } from 'projects/shared/src/lib/services/golf-pass.service';
import { ProductType } from 'projects/shared/src/lib/enumerations/product-type';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddPhysicalGiftCertificateModalComponent } from 'projects/admin/src/app/shared/components/add-physical-gift-certificate-modal/add-physical-gift-certificate-modal.component';
import { AddDigitalGiftCertificateModalComponent } from 'projects/admin/src/app/shared/components/add-digital-gift-certificate-modal/add-digital-gift-certificate-modal.component';
import { CategoryQuery } from 'projects/shared/src/lib/queries/category-query';
import { AddEventReservationModalComponent } from '../../../../components/add-event-reservation-modal/add-event-reservation-modal.component';
import { GolfPassQuery } from 'projects/shared/src/lib/queries/golfpass-query';
import { UserService } from 'projects/shared/src/lib/services/user.service';

interface CategoryTab { index: number, label: string, categoryId: number };

@Component({
  selector: 'gcl-admin-checkout-category-list',
  templateUrl: './checkout-category-list.component.html',
  styleUrls: ['./checkout-category-list.component.scss']
})
export class CheckoutCategoryListComponent implements OnInit, OnDestroy {

  parentCategoryTabs: Array<CategoryTab> = [];
  childCategoryTabs: Array<CategoryTab> = [];

  golfpasses$!: Observable<GolfPassDetails[]>;
  categories$!: Observable<CategoryDetails[]>;
  categoryProducts$!: Observable<ProductDetails[][]>;
  categoryProductsById$!: Observable<Record<number, ProductDetails[]>>;

  selectedParentTab: number = 0;
  selectedChildCategory: number = 0;

  order$!: Observable<OrderDetails>;

  isCollapsed: boolean = false;
  isChildCollapsed: boolean = false;

  readonly hiddenCategories = ['Events', 'Tips'];

  private destroy$: Subject<any> = new Subject();

  constructor(
    private readonly categoryService: CategoryService,
    private readonly authService: AuthService,
    private readonly checkoutService: CheckoutService,
    private readonly golfpassService: GolfPassService,
    private readonly productService: ProductService,
    private readonly bsModalService: BsModalService,
    private readonly userService: UserService,
  ) { }

  ngOnInit(): void {
    this.order$ = this.checkoutService.order$;

    let orderUser$ = this.order$.pipe(
      switchMap(order => order.users_permissions_user ? this.userService.get(order.users_permissions_user.id) : of(null))
    );

    this.golfpasses$ = combineLatest([this.authService.course$, orderUser$]).pipe(
      switchMap(([course, orderUser]) => {
        let query: GolfPassQuery = { course: course?.id };

        if(orderUser && orderUser.golfPasses?.length > 0) {
          query['id_nin'] = (orderUser.golfPasses.map(gp => gp.id));
        }

        return this.golfpassService.query(query).records$
      }),
      shareReplay(1),
      takeUntil(this.destroy$)
    );

    this.categories$ = combineLatest([this.authService.course$, this.checkoutService.order$]).pipe(
      switchMap(([course, order]) => {
        let query: CategoryQuery = { course: course?.id, name_nin: this.hiddenCategories };
        if(!order.users_permissions_user) {
          query['name_ne'] = 'Gift Certificates';
        }
        return this.categoryService.query(query).records$
      }),
      shareReplay(1),
      takeUntil(this.destroy$)
    );

    this.categoryProducts$ = combineLatest([this.categories$, this.authService.course$]).pipe(
      switchMap(([categories, course]) => combineLatest(categories.map(c => this.productService.query({ category: c.id, course: course?.id, available: true }).records$))),
      shareReplay(1),
      takeUntil(this.destroy$)
    );

    this.categoryProductsById$ = this.categoryProducts$.pipe(
      map(productArrays => {
        if(!productArrays || productArrays?.length <= 0) {
          return {};
        }
        let record: Record<number, ProductDetails[]> = {};
        for(let productArray of productArrays) {
          for(let product of productArray) {
            if(!product.category) {
              continue;
            }
            if(!record[product.category.id]) {
              record[product.category.id] = [];
              
            }
            record[product.category.id].push(product);
          }            
        };
        return record;        
      })
    )

    combineLatest([this.golfpasses$, this.categories$, this.checkoutService.order$])
      .pipe(
        shareReplay(1),
        takeUntil(this.destroy$)
      )
      .subscribe(([golfpasses, categories, order]) => {
        this.parentCategoryTabs = [];
        this.childCategoryTabs = [];

        this.parentCategoryTabs.push({
          index: -1,
          label: "Golf Passes",
          categoryId: categories.find(c => c.name === "Golf Passes")?.id ?? -1,
        });

        let shownParentCategories = categories.filter(c => !c.parent);
        let selectedTab = this.selectedParentTab ?? 0;
        let selectedCategory = shownParentCategories?.[selectedTab]?.id ?? 0;
    
        let subcategories = categories.filter(c => !!c.parent && c.parent.id === selectedCategory);

        if(!order?.users_permissions_user) {
          shownParentCategories = shownParentCategories.filter(c => c.name !== 'Gift Certificates');
        }

        shownParentCategories.forEach((cat, index) => this.parentCategoryTabs.push({
          index: index,
          label: cat.name,
          categoryId: cat.id,
        }));

        subcategories.forEach((cat, index) => this.childCategoryTabs.push({
          index: index,
          label: cat.name,
          categoryId: cat.id,
        }));

        this.selectedChildCategory = subcategories[0]?.id ?? 0;
      });

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public addGolfPass(order: OrderDetails, golfpass: GolfPassDetails): void {
    if(!order.users_permissions_user) {
      return;
    }

    this.checkoutService.addGolfPass(order.id, golfpass.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.checkoutService.onOrderChanged$.next());
  }

  public addProduct(order: OrderDetails, product: ProductDetails): void {
    if((product?.type) === ProductType.PhysicalGiftCertificate) {
      const giftCardModalRef = this.bsModalService.show(AddPhysicalGiftCertificateModalComponent, {
        class: "modal-lg modal-dialog-centered",
        ignoreBackdropClick: true,
        initialState: {
          order
        }
      });

      giftCardModalRef?.content?.onGiftCertificateAssign.subscribe(async assignment => {
        this.checkoutService.addPhysicalGiftCertificateProduct(order.id, product.id, assignment.amount, assignment.code).pipe(takeUntil(this.destroy$)).subscribe(
          () => {
            this.checkoutService.onOrderChanged$.next();
            giftCardModalRef.hide();
          },
          (err) => {
            console.error(err);
          }
        )
      });
    }
    else if((product?.type) === ProductType.DigitalGiftCertificate) {
      const giftCardModalRef = this.bsModalService.show(AddDigitalGiftCertificateModalComponent, {
        class: "modal-lg modal-dialog-centered",
        ignoreBackdropClick: true,
        initialState: {
          order
        }
      });

      giftCardModalRef?.content?.onGiftCertificateAssign.subscribe(async assignment => {
        this.checkoutService.addDigitalGiftCertificateProduct(order.id, product.id, assignment.amount, assignment.email).pipe(takeUntil(this.destroy$)).subscribe(
          () => {
            this.checkoutService.onOrderChanged$.next();
            giftCardModalRef.hide();
          },
          (err) => {
            console.error(err);
          }
        )
      });
    }
    else if ((product?.type) === ProductType.Event) {
      const eventReservationModalRef = this.bsModalService.show(AddEventReservationModalComponent, {
        class: "modal-lg modal-dialog-centered",
        ignoreBackdropClick: true,
      })
    }
    else {
      this.checkoutService.addProduct(order.id, product.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.checkoutService.onOrderChanged$.next());
    }
  }

  async parentCategoryClicked(tab: CategoryTab) {
    let previousSelectedTab = {previous: this.selectedParentTab};

    this.selectedParentTab = tab.index;

    this.childCategoryTabs = [];

    let categories = await this.categories$.pipe(take(1)).toPromise();

    let subcategories = categories.filter(c => !!c.parent && c.parent.id === tab.categoryId);

    subcategories.forEach((cat, index) => this.childCategoryTabs.push({
      index: index,
      label: cat.name,
      categoryId: cat.id,
    }));

    if(previousSelectedTab.previous !== tab.index) {
      this.selectedChildCategory = subcategories[0]?.id ?? 0;
    }
  }

  async childCategoryClicked(tab: CategoryTab) {
    if(this.selectedChildCategory === tab.categoryId) {
      this.selectedChildCategory = 0;
    }
    else {
      this.selectedChildCategory = tab.categoryId;
    }    
  }

}
