<ng-container *ngIf="order$ | async as order">
    <div class="d-flex justify-content-start py-2 bg-light no-flex">
        <a class="text-light-green" [routerLink]="['/admin','orders', order.id, 'view']">
            <fa-icon [icon]="faChevronLeft" class="px-3"></fa-icon> <span>Back to Order Details</span>
        </a>
    </div>
    
    <form [formGroup]="form" (ngSubmit)="openRefundModal()">
        <div class="container-fluid py-2 fill-flex overflow-y-auto">
            <h6 class="page-header">Select Items to Refund</h6>
            <gcl-lib-table
                [tableClass]="'table table-striped table-hover'" 
                [enableCreate]="false"
                [showSearch]="false"
                [showPageOptions]="false"
                [showPagination]="false"
            >
                <thead>
                    <tr>
                        <th>
                            <div class="custom-control custom-checkbox">
                                <input class="custom-control-input" type="checkbox" id="check-all" (change)="toggleAllChanged($event)">
                                <label class="custom-control-label" for="check-all"></label>
                            </div>
                        </th>
                        <th>GOLF PRODUCT NAME</th>
                        <th>RESERVATION TYPE</th>
                        <th>QTY</th>
                        <th>EXT PRICE</th>
                        <th>UNIT PRICE</th>
                        <th>TAX</th>
                    </tr>
                </thead>
                <tbody formArrayName="golfOrders">
                    <tr *ngFor="let go of golfOrders$ | async; let i = index;">
                        <td>
                            <div class="custom-control custom-checkbox">
                                <input class="custom-control-input" type="checkbox" [id]="'check'+i" [formControlName]="i">
                                <label class="custom-control-label" [for]="'check'+i"></label>
                            </div>
                        </td>
                        <td>{{go?.golfproduct?.name}}</td>
                        <td>{{go?.golfproduct?.reservationType ? GetReservationTypeDisplayName($any(go?.golfproduct?.reservationType)) : 'N/A'}}</td>
                        <td>{{go?.quantity}}</td>
                        <td>{{go?.extprice | currency}}</td>
                        <td>{{go?.unitprice | currency}}</td>
                        <td>{{go?.tax | currency}}</td>
                    </tr>
                </tbody>
            </gcl-lib-table>
            <div class="d-flex justify-content-end">
                <button type="submit" class="btn btn-light-green" [disabled]="modalDisabled()">Refund to Credit Balance</button>
            </div>
        </div>
    </form>
</ng-container>
