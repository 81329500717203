import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'gcl-admin-checkout-item',
  templateUrl: './checkout-item.component.html',
  styleUrls: ['./checkout-item.component.scss']
})
export class CheckoutItemComponent implements OnInit {
  @Input() category!: string;
  @Input() name!: string;
  @Input() quantity!: number;
  @Input() unitprice!: number;
  @Input() price!: number;
  @Input() taxrate: number = 0;
  @Input() refunded: boolean = false;

  @Output() selected: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  public getTax(): number {
    return (this.taxrate * this.price);
  }

  public clicked(): void {
    this.selected.emit();
  }

  public showDiscount(): boolean {
    return (this.unitprice * this.quantity) > this.price;
  }
}
