import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CourseCreateComponent } from './course-create/course-create.component';
import { CourseEditComponent } from './course-edit/course-edit.component';
import { CourseListComponent } from './course-list/course-list.component';
import { CourseViewComponent } from './course-view/course-view.component';
import { EventSpaceCreateComponent } from './event-space-create/event-space-create.component';
import { EventSpaceEditComponent } from './event-space-edit/event-space-edit.component';
import { EventSpaceViewComponent } from './event-space-view/event-space-view.component';

const routes: Routes = [
  {
    path: 'create',
    component: CourseCreateComponent
  },
  {
    path: ':courseId/edit',
    component: CourseEditComponent
  },
  {
    path: ':courseId/view',
    component: CourseViewComponent
  },
  {
    path: ':courseId/spaces/create',
    component: EventSpaceCreateComponent
  },
  {
    path: ':courseId/spaces/:eventSpaceId/edit',
    component: EventSpaceEditComponent
  },
  {
    path: ':courseId/spaces/:eventSpaceId/view',
    component: EventSpaceViewComponent
  },
  {
    path: '',
    component: CourseListComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoursesRoutingModule { }
