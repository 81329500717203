<div class="d-flex flex-column h-100">
    <div class="d-flex justify-content-start py-2 bg-light no-flex">
        <a class="text-light-green"  [routerLink]="['/admin','players']">
            <fa-icon [icon]="faChevronLeft" class="px-3"></fa-icon> <span>Back to Player List</span>
        </a>
    </div>
    <div class="container-fluid py-2 fill-flex overflow-y-auto">
        <div class="row" *ngIf="user$ | async as user">
            <div class="col-12">
                <div class="row py-2">
                    <div class="col">
                        <div class="float-right">
                            <button type="button" (click)="openCancelModal(user)" class="btn btn-outline-light-green">Cancel</button>
                            <button type="button" (click)="openSaveModal()" class="btn btn-light-green">Save</button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <gcl-lib-player-form 
                            [user]="user"
                            (onAddPaymentClick)="onAddPaymentClicked()"
                            (onRedeemGiftCertificateClick)="onRedeemGiftCertificateClicked()">
                        </gcl-lib-player-form>
                    </div>
                </div>
                <div class="mt-3 mx-1">
                    <div class="font-weight-bold mb-2">Customer Pass(es)</div>
                    <gcl-admin-user-golf-pass-table 
                        [userId]="user.id"
                        [enableAdd]="true"
                        [enableRemove]="true"
                        (onRemove)="onRemoveGolfPass($event)"
                        (onAdd)="openSelectGolfPassModal()">
                    </gcl-admin-user-golf-pass-table>
                </div>
                <div class="mt-3 mx-1">
                    <div class="font-weight-bold mb-2">Customer Orders</div>
                    <gcl-admin-order-table [userId]="user.id"></gcl-admin-order-table>
                </div>
                <div class="mt-3 mx-1">
                    <div class="font-weight-bold mb-2">Credit Balance History</div>
                    <gcl-admin-credit-balance-transaction-table [userId]="user.id"></gcl-admin-credit-balance-transaction-table>
                </div>
            </div>
        </div>
    </div>
</div>