import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { EventSpaceType } from 'projects/shared/src/lib/enumerations/event-space-type';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { EventSpaceService } from 'projects/shared/src/lib/services/event-space.service';
import { Observable, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'gcl-admin-event-space-create',
  templateUrl: './event-space-create.component.html',
  styleUrls: ['./event-space-create.component.scss']
})
export class EventSpaceCreateComponent implements OnInit, OnDestroy {
  faChevronLeft = faChevronLeft;

  get name() { return this.form.get('name') }
  get type() { return this.form.get('type') }

  public form = this.fb.group({
    name: ['', [Validators.required]],
    type: [EventSpaceType.General, [Validators.required]],
    course: [null, [Validators.required]]
  })

  routerLink$!: Observable<any[]>;

  private destroyed$ = new Subject();

  constructor(
    private fb: FormBuilder, 
    private activatedRoute: ActivatedRoute,
    private eventSpaceService: EventSpaceService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.pipe(takeUntil(this.destroyed$)).subscribe(params => {
      if(params['courseId']) {
        this.form.patchValue({course: params['courseId']});
      }
    });

    this.routerLink$ = this.activatedRoute.params.pipe(
      map(p => ['/admin', 'courses', p['courseId'], 'view'])
    )
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  async submit() {
    let courseId = await this.activatedRoute.params.pipe(take(1), map(p => p['courseId'])).toPromise();

    this.eventSpaceService.create(this.form.value).subscribe(
      space => {
        this.router.navigate(['/admin', 'courses', courseId, 'view']);
      },
      err => {
        console.error(err?.message);
      }
    )
  }
}
