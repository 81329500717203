import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';

import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';

import { environment } from 'projects/admin/src/environments/environment';
import { OnPageEvent } from 'projects/shared/src/lib/models/on-page-event';
import { ScheduleService } from 'projects/shared/src/lib/services/schedule.service';
import { ScheduleDetails } from 'projects/shared/src/lib/models/schedule';
import { SortDirections } from 'projects/shared/src/lib/enumerations/sort-directions';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { CategoryDetails } from 'projects/shared/src/public-api';
import { GetScheduleTypeDisplayName } from 'projects/shared/src/lib/enumerations/schedule-types';
import { PagedResult } from 'projects/shared/src/lib/queries/paged-result';

@Component({
  selector: 'gcl-admin-schedule-list',
  templateUrl: './schedule-list.component.html',
  styleUrls: ['./schedule-list.component.scss']
})
export class ScheduleListComponent implements OnInit, OnDestroy {
  public apiUrl = environment.apiUrl;
  public GetScheduleTypeDisplayName = GetScheduleTypeDisplayName;

  get scheduletype() { return this.filters.value.scheduletype; }

  filters = this.fb.group({
    scheduletype: [null]
  });

  initialPagination = {
    itemsPerPage: 10,
    page: 1,
    sortBy: 'id',
    sortDescending: true,
    search: ''
  };
  pagination$ = new BehaviorSubject<OnPageEvent>(this.initialPagination);

  pagedResult$: ReplaySubject<PagedResult<ScheduleDetails>> = new ReplaySubject(0);

  categories$!: Observable<CategoryDetails[]>;

  private destroy$: Subject<any> = new Subject();

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private scheduleService: ScheduleService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getPageResult();

    this.initFunc();

    this.filters.valueChanges
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.getPageResult());
  }

  private getPageResult() {
    combineLatest([this.authService.course$, this.pagination$])
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(([course, pagination]) => {
        const pagedResult = this.scheduleService.query({
          skip: (pagination.page - 1) * pagination.itemsPerPage,
          take: pagination.itemsPerPage,
          sortColumns: [{
            column: pagination.sortBy,
            direction: pagination.sortDescending ? SortDirections.Descending : SortDirections.Ascending,
          }],
          search: pagination.search,

          course: course?.id,
          type: this.scheduletype
        });
        this.pagedResult$.next(pagedResult);
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { ...this.pagination$.value, ...this.filters.value },
          queryParamsHandling: 'merge',
        });
      });
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }

  public goToCreate() {
    this.router.navigate(['/admin', 'schedules', 'create']);
  }

  public getQueryParams() {
    return this.route.snapshot.queryParams;
  }

  private initFunc() {
    const params = this.route.snapshot.queryParams;
    if (params['scheduletype']) {
      this.filters.patchValue({
        scheduletype: params['scheduletype']
      });
    }
    this.initialPagination = {
      itemsPerPage: !isNaN(parseInt(params['itemsPerPage'])) ? parseInt(params['itemsPerPage']) : this.initialPagination.itemsPerPage,
      page: !isNaN(parseInt(params['page'])) ? parseInt(params['page']) : this.initialPagination.page,
      sortBy: params['sortBy'] ? params['sortBy'] : this.initialPagination.sortBy,
      sortDescending: params['sortDescending'] ? params['sortDescending'] : this.initialPagination.sortDescending,
      search: params['search'] ? params['search'] : this.initialPagination.search
    };
    this.pagination$.next(this.initialPagination);
  }
}
