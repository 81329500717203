import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ManagerRoutingModule } from './manager-routing.module';
import { LayoutComponent } from './layout/layout.component';
import { CartsModule } from './carts/carts.module';
import { PlayersModule } from './players/players.module';
import { ProductsModule } from './products/products.module';
import { SchedulesModule } from './schedules/schedules.module';
import { TeeSheetModule } from './tee-sheet/tee-sheet.module';


@NgModule({
  declarations: [LayoutComponent],
  imports: [
    CommonModule,
    
    TeeSheetModule,
    CartsModule,
    PlayersModule,
    SchedulesModule,
    ProductsModule,

    ManagerRoutingModule
  ]
})
export class ManagerModule { }
