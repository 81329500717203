<div class="py-4">
    <div class="container py-4">
        <div class="row">
            <div class="col-6 mx-auto">
                <div class="card my-2">
                    <div class="card-body">       
                        <h3 class="card-title text-center text-primary">Account</h3>
                        <div>
                            <ng-container *ngIf="user$ | async as user">
                                <h4>Personal Info</h4>
                                <div>
                                    <p>Particulars:</p>
                                    <p>Username: {{user.username}}</p>
                                    <p>Email: {{user.email}}</p>
                                    <p>Name: {{user.firstName}} {{user.lastName}}</p>
                                    <p>Date of Birth: {{user?.dateOfBirth | date: 'mediumDate'}}</p>
                                </div>
                                <a class="btn btn-link pr-2" [routerLink]="['/account','edit']">Edit Personal Info</a>
                                <a class="btn btn-link" [routerLink]="['/account','change-password']">Change Password</a>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>