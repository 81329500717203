export enum NineTypes {
    Front = "Front",
    Back = "Back",
    Tertiary = "Tertiary",
    NotApplicable = "NotApplicable"
}

export function GetNineTypesDisplayName(status: NineTypes): string {
    switch (status) {
        case NineTypes.Front:
            return "Front";
        case NineTypes.Back:
            return "Back";
        case NineTypes.Tertiary:
            return "Tertiary";
        case NineTypes.NotApplicable: 
            return "N/A";
        default:
            return "N/A";
    }
}