<div class="d-flex justify-content-start py-2 bg-light">
    <a class="text-light-green"  [routerLink]="['/admin','courses']">
        <fa-icon [icon]="faChevronLeft" class="px-3"></fa-icon> <span>Back to Course List</span>
    </a>
</div>
<div class="container-fluid py-2">
    <div class="row">
        <div class="col-12">
            <form [formGroup]="form">
                <div class="d-flex justify-content-end py-2">
                    <button type="button" (click)="openCancelModal()" class="btn btn-outline-light-green px-5 mx-1">Cancel</button>
                    <button type="button" (click)="openSaveModal()" class="btn btn-light-green px-5 mx-1">Save</button>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label >Name</label>
                                            <input type="text" class="form-control" formControlName="name" name="name" [class.is-invalid]="name?.invalid && name?.touched" />
                                            <div *ngIf="name?.invalid && name?.touched" class="invalid-feedback">
                                                <div *ngIf="name?.errors?.required">
                                                    Please enter a name.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label >Full Name</label>
                                            <input type="text" class="form-control" formControlName="full_name" name="full_name" [class.is-invalid]="full_name?.invalid && full_name?.touched" />
                                            <div *ngIf="full_name?.invalid && full_name?.touched" class="invalid-feedback">
                                                <div *ngIf="full_name?.errors?.required">
                                                    Please enter a full price.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label >Address</label>
                                            <input type="text" class="form-control" formControlName="address" name="address" [class.is-invalid]="address?.invalid && address?.touched" />
                                            <div *ngIf="address?.invalid && address?.touched" class="invalid-feedback">
                                                <div *ngIf="address?.errors?.required">
                                                    Please enter an address.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>Email</label>
                                            <input type="text" class="form-control" formControlName="email" name="email" [class.is-invalid]="email?.invalid && email?.touched" />
                                            <div *ngIf="email?.invalid && description?.touched" class="invalid-feedback">
                                                <div *ngIf="email?.errors?.required">
                                                    Please enter a email.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label>Holes</label>
                                            <input type="number" class="form-control" formControlName="holes" name="holes" [class.is-invalid]="holes?.invalid && holes?.touched" />
                                            <div *ngIf="holes?.invalid && holes?.touched" class="invalid-feedback">
                                                <div *ngIf="holes?.errors?.required">
                                                    Please enter number of holes.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label>Par</label>
                                            <input type="number" class="form-control" formControlName="par" name="par" [class.is-invalid]="par?.invalid && par?.touched" />
                                            <div *ngIf="par?.invalid && par?.touched" class="invalid-feedback">
                                                <div *ngIf="par?.errors?.required">
                                                    Please enter par number.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label>Course Length</label>
                                            <input type="number" class="form-control" formControlName="courselength" name="courselength" [class.is-invalid]="courselength?.invalid && courselength?.touched" />
                                            <div *ngIf="courselength?.invalid && courselength?.touched" class="invalid-feedback">
                                                <div *ngIf="courselength?.errors?.required">
                                                    Please enter a course length.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label>Slope</label>
                                            <input type="number" class="form-control" formControlName="slope" name="slope" [class.is-invalid]="slope?.invalid && slope?.touched" />
                                            <div *ngIf="slope?.invalid && slope?.touched" class="invalid-feedback">
                                                <div *ngIf="slope?.errors?.required">
                                                    Please enter slope number.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label>Rating</label>
                                            <input type="number" class="form-control" formControlName="rating" name="rating" [class.is-invalid]="rating?.invalid && rating?.touched" />
                                            <div *ngIf="rating?.invalid && rating?.touched" class="invalid-feedback">
                                                <div *ngIf="rating?.errors?.required">
                                                    Please enter rating number.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label>Taxrate</label>
                                            <div class="input-group">
                                                <input type="number" class="form-control" formControlName="taxrate" name="taxrate" [class.is-invalid]="taxrate?.invalid && taxrate?.touched" />
                                                <span class="input-group-text">%</span>
                                                <div *ngIf="taxrate?.invalid && taxrate?.touched" class="invalid-feedback">
                                                    <div *ngIf="taxrate?.errors?.required">
                                                        Please enter a taxrate.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label>Dwell</label>
                                            <div class="input-group">
                                                <input type="number" class="form-control" formControlName="dwell" name="dwell" [class.is-invalid]="dwell?.invalid && dwell?.touched" />
                                                <div *ngIf="dwell?.invalid && dwell?.touched" class="invalid-feedback">
                                                    <div *ngIf="dwell?.errors?.required">
                                                        Please enter a dwell.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label>Turn</label>
                                            <div class="input-group">
                                                <input type="number" class="form-control" formControlName="turn" name="turn" [class.is-invalid]="turn?.invalid && turn?.touched" />
                                                <div *ngIf="turn?.invalid && turn?.touched" class="invalid-feedback">
                                                    <div *ngIf="turn?.errors?.required">
                                                        Please enter turns.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label>Starting Drawer Count</label>
                                            <div class="input-group">
                                                <input type="number" class="form-control" formControlName="startingDrawerCount" name="startingDrawerCount" [class.is-invalid]="startingDrawerCount?.invalid && startingDrawerCount?.touched" />
                                                <div *ngIf="startingDrawerCount?.invalid && startingDrawerCount?.touched" class="invalid-feedback">
                                                    <div *ngIf="startingDrawerCount?.errors?.required">
                                                        Please enter a starting drawer count.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label>Opening Time</label>
                                            <div class="input-group">
                                                <input type="time" class="form-control" formControlName="open_time" name="open_time" [class.is-invalid]="open_time?.invalid && open_time?.touched" />
                                                <div *ngIf="open_time?.invalid && open_time?.touched" class="invalid-feedback">
                                                    <div *ngIf="open_time?.errors?.required">
                                                        Please enter opening time.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label>Closing Time</label>
                                            <div class="input-group">
                                                <input type="time" class="form-control" formControlName="close_time" name="close_time" [class.is-invalid]="close_time?.invalid && close_time?.touched" />
                                                <div *ngIf="close_time?.invalid && close_time?.touched" class="invalid-feedback">
                                                    <div *ngIf="close_time?.errors?.required">
                                                        Please enter closing time.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>Address 1</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control" formControlName="address1" name="address1" [class.is-invalid]="address1?.invalid && address1?.touched" />
                                                <div *ngIf="address1?.invalid && address1?.touched" class="invalid-feedback">
                                                    <div *ngIf="address1?.errors?.required">
                                                        Please enter address 1.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>Address 2</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control" formControlName="address2" name="address2" [class.is-invalid]="address2?.invalid && address2?.touched" />
                                                <div *ngIf="address2?.invalid && address2?.touched" class="invalid-feedback">
                                                    <div *ngIf="address2?.errors?.required">
                                                        Please enter address 2.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label>City</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control" formControlName="city" name="city" [class.is-invalid]="city?.invalid && city?.touched" />
                                                <div *ngIf="city?.invalid && city?.touched" class="invalid-feedback">
                                                    <div *ngIf="city?.errors?.required">
                                                        Please enter city.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label>State</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control" formControlName="state" name="state" [class.is-invalid]="state?.invalid && state?.touched" />
                                                <div *ngIf="state?.invalid && state?.touched" class="invalid-feedback">
                                                    <div *ngIf="state?.errors?.required">
                                                        Please enter state.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label>Postal Code</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control" formControlName="postalCode" name="postalCode" [class.is-invalid]="postalCode?.invalid && postalCode?.touched" />
                                                <div *ngIf="postalCode?.invalid && postalCode?.touched" class="invalid-feedback">
                                                    <div *ngIf="postalCode?.errors?.required">
                                                        Please enter postal code.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label>Time Zone</label>
                                            <select formControlName="timezone" class="form-control" formControlName="timezone" name="timezone" [class.is-invalid]="timezone?.invalid && timezone?.touched">
                                                <option *ngFor="let tz of timezones" [value]="tz">{{tz}}</option>
                                            </select>
                                            <div *ngIf="timezone?.invalid && timezone?.touched" class="invalid-feedback">
                                                <div *ngIf="timezone?.errors?.required">
                                                    Please select a time zone.
                                                </div>
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label >Description</label>
                                            <textarea type="text" class="form-control" formControlName="description" name="description" [class.is-invalid]="description?.invalid && description?.touched" rows="5"></textarea>
                                            <div *ngIf="description?.invalid && description?.touched" class="invalid-feedback">
                                                <div *ngIf="description?.errors?.required">
                                                    Please enter a description.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label>From Email</label>
                                            <input type="text" class="form-control" formControlName="fromEmail" name="fromEmail" [class.is-invalid]="fromEmail?.invalid && fromEmail?.touched" />
                                            <div *ngIf="fromEmail?.invalid && description?.touched" class="invalid-feedback">
                                                <div *ngIf="fromEmail?.errors?.required">
                                                    Please enter a from email.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-check">
                                            <input id="mobileActive" type="checkbox" formControlName="mobileActive" class="form-check-input" />
                                            <label class="form-check-label" for="mobileActive">
                                                Mobile Active
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col mx-auto text-center">
                                    <img *ngIf="logoImageUrl" [src]="logoImageUrl" class="img-fluid"/>
        
                                    <div class="form-group">
                                        <input type="file" accept="image/*" (change)="onFileChange($event)" #imageInput class="d-none">
                                        <button *ngIf="logoImageUrl == null" type="button" class="btn btn-link" (click)="imageInput.click()">Add Logo</button>
                                        <button *ngIf="logoImageUrl != null" type="button" class="btn btn-link" (click)="imageInput.click()">Change Logo</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
