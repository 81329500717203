import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ScheduleCreateComponent } from './schedule-create/schedule-create.component';
import { ScheduleEditComponent } from './schedule-edit/schedule-edit.component';
import { ScheduleListComponent } from './schedule-list/schedule-list.component';
import { ScheduleViewComponent } from './schedule-view/schedule-view.component';

const routes: Routes = [
  {
    path: 'create',
    component: ScheduleCreateComponent
  },
  {
    path: ':scheduleId/view',
    component: ScheduleViewComponent
  },
  {
    path: ':scheduleId/clone',
    component: ScheduleCreateComponent
  },
  {
    path: ':scheduleId/edit',
    component: ScheduleEditComponent
  },
  {
    path: '',
    component: ScheduleListComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SchedulesRoutingModule { }
