import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';

import { ToastrService } from 'ngx-toastr';

import { InputModalService } from 'projects/shared/src/lib/services/input-modal.service';
import { OrderPaymentService } from 'projects/shared/src/lib/services/order-payment.service';
import { OrderDetails, OrderPayment } from 'projects/shared/src/public-api';
import { OrderPaymentTypes } from 'projects/shared/src/lib/enumerations/order-payment-types';
import { OrderService } from 'projects/shared/src/lib/services/order.service';
import { CheckoutService } from '../../checkout.service';

interface PaymentAmount {
  amount: number;
  selected: boolean;
  isCustom: boolean;
}

@Component({
  selector: 'gcl-admin-checkout-refund-cash',
  templateUrl: './checkout-refund-cash.component.html',
  styleUrls: ['./checkout-refund-cash.component.scss']
})
export class CheckoutRefundCashComponent implements OnInit {
  public isCollapsed: boolean = false;

  public order$!: Observable<OrderDetails>;
  public paymentAmounts: PaymentAmount[] = [];

  constructor(private orderService: OrderService, private checkoutService: CheckoutService, private orderPaymentService: OrderPaymentService, private toastr: ToastrService, private inputModalService: InputModalService) { }

  ngOnInit(): void {
    this.order$ = this.checkoutService.order$;

    this.checkoutService.difference$.subscribe((difference: number) => {
      const postDifference = Math.abs(difference);
      this.paymentAmounts.push({
        amount: postDifference,
        selected: false,
        isCustom: false
      });

      let rounded = Math.ceil(postDifference);
      rounded = rounded == postDifference ? rounded + 10 : rounded;
      for (let i = 0; i < 4; i++) {
        this.paymentAmounts.push({
          amount: rounded + (i * 10),
          selected: false,
          isCustom: false
        });
      }

      this.paymentAmounts.push({
        amount: 0,
        selected: false,
        isCustom: true
      });
    })
  }

  public setPaymentAmount(amount: PaymentAmount): void {
    this.paymentAmounts.forEach((pa) => pa.selected = false);
    amount.selected = true;
  }

  public openCustomAmountModal(): void {
    this.inputModalService.showConfirmationModal({
      title: 'Enter Custom Amount'
    }).subscribe((amount: number) => {
      this.order$.subscribe((order: OrderDetails) => {
        let custom = this.paymentAmounts.find(pa => pa.isCustom) as PaymentAmount;
        custom.amount = amount;
        this.setPaymentAmount(custom);
      });
    });
  }

  private getSelectedPayment(): PaymentAmount | undefined {
    return this.paymentAmounts.find(pa => pa.selected);
  }

  public anyPaymentSelected(): boolean {
    return this.paymentAmounts.find(pa => pa.selected) != undefined;
  }

  public refund(order: OrderDetails) {
    const payment = this.getSelectedPayment();

    if (payment) {
      let submissions = [];

      submissions.push(this.orderPaymentService.create({
        amount: -1 * payment.amount,
        order: order.id,
        success: true,
        type: OrderPaymentTypes.CashRefund
      }));

      combineLatest(submissions)
        .subscribe(
          async () => {
            await this.orderService.recalculate(order.id);
            await this.checkoutService.checkoutSucces("Refund is successful.", order.id, []);
          },
          (err) => {
            this.toastr.error("An error occurred while processing this refund.");
          }
        );
    } else {
      this.toastr.error("A refund needs to be selected.");
    }
  }
}
