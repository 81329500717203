<form [formGroup]="form">
    <div class="row">
        <div class="col-6">
            <div class="form-group">
                <label >Name</label>
                <input type="text" class="form-control" formControlName="name" name="name" [class.is-invalid]="name?.invalid && name?.touched" />
                <div *ngIf="name?.invalid && name?.touched" class="invalid-feedback">
                    <div *ngIf="name?.errors?.required">
                        Please enter a name.
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="form-group">
                <label >Price</label>
                <input type="number" class="form-control" formControlName="price" name="price" [class.is-invalid]="price?.invalid && price?.touched" />
                <div *ngIf="price?.invalid && price?.touched" class="invalid-feedback">
                    <div *ngIf="price?.errors?.required">
                        Please enter a price.
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="form-group">
                <div class="row">
                    <div class="col-8">
                        <label for="isEightteenHoles">Course Length</label>
                    </div>
                    <div class="col mx-auto">
                        <div>
                            <gcl-lib-text-toggle-switch formControlName="isEightteenHoles"
                                [leftText]="'9 Holes'"
                                [rightText]="'18 Holes'"></gcl-lib-text-toggle-switch>
                            <div *ngIf="isEightteenHoles?.invalid && isEightteenHoles?.touched" class="invalid-feedback">
                                <div *ngIf="isEightteenHoles?.errors?.required">
                                    Course Length is required.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="form-group">
                <label for="cart">Cart can be added</label>
                <gcl-lib-toggle-switch formControlName="cart"></gcl-lib-toggle-switch>
                <div *ngIf="cart?.invalid && cart?.touched" class="invalid-feedback">
                    <div *ngIf="cart?.errors?.required">
                        Cart is required.
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="form-group">
                <label>Reservation Types</label>
                <ng-select 
                    formControlName="reservation_types"
                    [multiple]="true"
                    bindLabel="name"
                    bindValue="id"
                    [items]="$any(reservationTypes$ | async)"
                    [class.is-invalid]="reservation_types?.invalid && reservation_types?.touched"
                ></ng-select>
                <div *ngIf="reservation_types?.invalid && reservation_types?.touched" class="invalid-feedback">
                    <div *ngIf="reservation_types?.errors?.required">
                        A Reservation Type is required.
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="form-group">
                <label >Notes</label>
                <textarea type="text" class="form-control" formControlName="notes" name="notes" [class.is-invalid]="notes?.invalid && notes?.touched" rows="5"></textarea>
                <div *ngIf="notes?.invalid && notes?.touched" class="invalid-feedback">
                    <div *ngIf="notes?.errors?.required">
                        Please enter a notes.
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="form-group">
                <label >Account Code</label>
                <input type="text" class="form-control" formControlName="accountCode" name="accountCode" [class.is-invalid]="accountCode?.invalid && accountCode?.touched" />
                <div *ngIf="accountCode?.invalid && accountCode?.touched" class="invalid-feedback">
                    <div *ngIf="accountCode?.errors?.required">
                        Please enter an account code.
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="form-group">
                <label >Cost</label>
                <input type="number" class="form-control" formControlName="cost" name="cost" [class.is-invalid]="cost?.invalid && cost?.touched" />
                <div *ngIf="cost?.invalid && cost?.touched" class="invalid-feedback">
                    <div *ngIf="cost?.errors?.required">
                        Please enter a cost.
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>