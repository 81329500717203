<div class="h-100">
    <div class="d-flex justify-content-start py-2 bg-light no-flex">
        <a class="text-light-green" [routerLink]="['/admin','schedules']" [queryParams]="getQueryParams()">
            <fa-icon [icon]="faChevronLeft" class="px-3"></fa-icon> <span>Back to Schedules</span>
        </a>
    </div>
    
    <ng-container *ngIf="schedule$ | async as schedule">
    
        <div class="container-fluid py-2 no-flex overflow-y-auto">
            <div class="row">
                <div class="col-12">
                    <h6 class="page-header">Schedule View</h6>
                    <div class="d-flex justify-content-end py-2">
                        <a [routerLink]="['/admin','schedules', schedule.id, 'edit']" class="btn btn-light-green mx-1">Edit</a>
                        <a [routerLink]="['/admin','schedules', schedule.id, 'clone']" class="btn btn-outline-light-green mx-1">Clone</a>
                        <button (click)="openDeleteModal()" type="button" class="btn btn-outline-light-green mx-1">Delete</button>
                    </div>
                    <div class="card">
                        <div class="card-body">
                           <div class="row">
                                <div class="col-8">
                                    <div class="row">
                                        <div class="col">
                                            <div class="form-group">
                                                <label >Name</label>
                                                <p>{{schedule.name}}</p>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label >Active</label>
                                                <p>{{schedule.active ? 'Yes' : 'No'}}</p>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label >Schedule Type</label>
                                                <p>{{GetScheduleTypeDisplayName(schedule.type)}}</p>
                                            </div>
                                        </div>
                                        <div class="col" *ngIf="schedule.type == ScheduleTypes.GolfPass">
                                            <div class="form-group">
                                                <label >Golf Pass</label>
                                                <p>{{schedule.golfPass?.name}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="form-group">
                                                <label >Start Time of Day</label>
                                                <p>{{schedule.starttod}}</p>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label >End Time of Day</label>
                                                <p>{{schedule.endtod}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="form-group">
                                                <label >First 9</label>
                                                <p>{{schedule.first9}}</p>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label >Second 9</label>
                                                <p>{{schedule.second9}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="form-group">
                                                <label >Monday</label>
                                                <p>{{schedule.monday ? 'Yes' : 'No'}}</p>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label >Tuesday</label>
                                                <p>{{schedule.tuesday ? 'Yes' : 'No'}}</p>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label >Wednesday</label>
                                                <p>{{schedule.wednesday ? 'Yes' : 'No'}}</p>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label >Thursday</label>
                                                <p>{{schedule.thursday ? 'Yes' : 'No'}}</p>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label >Friday</label>
                                                <p>{{schedule.friday ? 'Yes' : 'No'}}</p>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label >Saturday</label>
                                                <p>{{schedule.saturday ? 'Yes' : 'No'}}</p>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label >Sunday</label>
                                                <p>{{schedule.sunday ? 'Yes' : 'No'}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="form-group">
                                                <label >Golf Pass Discount Amount</label>
                                                <p>{{schedule.golfPassDiscountAmount || "N/A"}}</p>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label >Golf Pass Discount Percent</label>
                                                <p>{{schedule.golfPassDiscountPercent || "N/A"}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="form-group">
                                                <label >Description</label>
                                                <p>{{schedule.description}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label >Golf Products</label>
                                        <ul class="list-group">
                                            <li class="list-group-item">{{schedule.golfproduct?.name || "N/A"}}</li>
                                        </ul>
                                    </div>
                                </div>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="container-fluid py-2 overflow-y-auto">
            <div class="row">
                <div class="col-12" *ngIf="roundsPagedResult$ | async as pagedResult">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between pb-4">
                                <h6 class="page-header">Rounds</h6>
                                <button class="btn btn-primary" type="button" (click)="openGenerateModal(generateModalTemplate)">Generate</button>
                            </div>
                            <gcl-lib-table 
                                [totalItems]="pagedResult.filteredCount$ | async" 
                                [sortBy]="'id'" 
                                [enableCreate]="false"
                                (onPage)="roundsPagination$.next($event)"
                                [tableClass]="'table table-striped table-hover'">
                                <thead>
                                    <tr>
                                        <th columnSort="status">Status</th>
                                        <th columnSort="starttod">Start Time of Day</th>
                                        <th columnSort="turntod">Turn Time of Day</th>
                                        <th columnSort="datestr">Date str</th>
                                        <th columnSort="back">Back</th>
                                        <th columnSort="holes">Holes</th>
                                        <th width="150" class="text-center">Manage</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let round of pagedResult.records$ | async">
                                        <td>{{round.status}}</td>
                                        <td>{{round.starttod}}</td>
                                        <td>{{round.turntod}}</td>
                                        <td>{{round.datestr}}</td>
                                        <td>{{round.back}}</td>
                                        <td>{{round.holes}}</td>
                                        <td class="text-center">
                                            <div class="btn-group btn-group-sm">
                                                <select [enum]="'RoundStatuses'" class="custom-select" [value]="round.status"(change)="updateRound(round, $event)"></select>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="(pagedResult.filteredCount$ | async) == 0">
                                        <td colspan="7">No rounds.</td>
                                    </tr>
                                </tbody>
                            </gcl-lib-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </ng-container>
    
    <ng-template #generateModalTemplate>
        <div class="modal-header">
          <h4 class="modal-title float-left">Generate Rounds</h4>
          <button type="button" class="btn btn-default" aria-label="Close" (click)="generateModalRef.hide()">
            <span class="modal-close" aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <gcl-lib-date-picker (selectDate)="onSelectgenerateDate($event)"></gcl-lib-date-picker>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" (click)="generateModalRef.hide()">Cancel</button>
            <button type="button" class="btn btn-outline-primary" (click)="generateRounds()">Generate</button>
        </div>
    </ng-template>
</div>
