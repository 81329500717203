import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { tap, switchMap, shareReplay, takeUntil } from 'rxjs/operators';

import { faTrash } from '@fortawesome/free-solid-svg-icons';

import { UserDetails } from 'projects/shared/src/public-api';
import { GolfPass, GolfPassDetails } from '../../models/golfpass';
import { AuthService } from '../../services/auth.service';
import { GolfPassService } from '../../services/golf-pass.service';

@Component({
  selector: 'gcl-lib-player-form',
  templateUrl: './player-form.component.html',
  styleUrls: ['./player-form.component.css']
})
export class PlayerFormComponent implements OnInit {
  public faTrash = faTrash;

  @Input()
  user?: UserDetails;

  @Input()
  showAddPaymentMethodButton?: boolean = true;

  @Input()
  showRedeemGiftCertificateButton?: boolean = true;

  @Output()
  onAddPaymentClick = new EventEmitter<void>();

  @Output()
  onRedeemGiftCertificateClick = new EventEmitter<void>();


  public golfPasses$!: Observable<GolfPassDetails[]>;

  get username() { return this.form.get("username") as FormControl; }
  get email() { return this.form.get("email") as FormControl; }
  get confirmed() { return this.form.get("confirmed") as FormControl; }
  get blocked() { return this.form.get("blocked") as FormControl; }
  get cellphone() { return this.form.get("cellphone") as FormControl; }
  get licenseNumber() { return this.form.get("licenseNumber") as FormControl; }
  get licenseProvider() { return this.form.get("licenseProvider") as FormControl; }
  get licenseExpires() { return this.form.get("licenseExpires") as FormControl; }
  get firstName() { return this.form.get("firstName") as FormControl; }
  get lastName() { return this.form.get("lastName") as FormControl; }
  get dateOfBirth() { return this.form.get("dateOfBirth") as FormControl; }
  get isGuest() { return this.form.get("isGuest") as FormControl; }
  get isLeague() { return this.form.get("isLeague") as FormControl; }
  //get golfPasses() { return this.form.get("golfPasses") as FormArray; }

  public form = this.fb.group({
    username: [null, []],
    email: [null, Validators.required],
    confirmed: [false, []],
    blocked: [false, []],
    cellphone: [null, [Validators.required]],
    licenseNumber: [null, []],
    licenseProvider: [null, []],
    licenseExpires: [null, []],
    firstName: [null, [Validators.required]],
    lastName: [null, [Validators.required]],
    dateOfBirth: [null, []],
    isGuest: [false, []],
    isLeague: [false, []],
    //golfPasses: this.fb.array([]),
  });

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(private fb: FormBuilder, private authService: AuthService, private golfpassService: GolfPassService) { }

  ngOnInit(): void {
    this.golfPasses$ = this.authService.course$
      .pipe(
        tap(course => this.form.patchValue({
          course: course.id,
        })),
        switchMap(course => this.golfpassService.query({
          course: course.id
        }).records$),
        shareReplay(1),
        takeUntil(this.destroyed$)
      );

    if (this.user) {
      this.form.patchValue({
        username: this.user.username,
        email: this.user.email,
        confirmed: this.user.confirmed,
        blocked: this.user.blocked,
        cellphone: this.user.cellphone,
        licenseNumber: this.user.licenseNumber,
        licenseProvider: this.user.licenseProvider,
        licenseExpires: this.user.licenseExpires,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        dateOfBirth: this.user.dateOfBirth,
        isGuest: this.user.isGuest,
        isLeague: this.user.isLeague,
        golfPasses: this.user.golfPasses
      });

      //this.initGolfPasses(this.user.golfPasses);
    }
  }

  // private initGolfPasses(golfPasses: Array<GolfPass> | undefined): void {
  //   let controls: FormArray = this.fb.array([]);

  //   if (golfPasses) {
  //     golfPasses.forEach(golfpass => controls.push(this.fb.control(golfpass)));
  //   }

  //   this.form.setControl("golfPasses", controls);
  // }

  // public addGolfPass(golfpass: GolfPass): void {
  //   const index = this.golfPasses.value.findIndex((t: GolfPass) => t.id == golfpass.id);
  //   if (index == -1) {
  //     this.golfPasses.push(this.fb.control(golfpass));
  //   }
  // }

  // public removeGolfPass(golfpass: GolfPass): void {
  //   const index = this.golfPasses.value.findIndex((t: GolfPass) => t.id == golfpass.id);
  //   if (index != -1) {
  //     this.golfPasses.removeAt(index);
  //   }
  // }

  addPaymentClicked() {
    this.onAddPaymentClick.emit();
  }

  redeemGiftCertificateClicked() {
    this.onRedeemGiftCertificateClick.emit();
  }
}
