import { Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'gcl-lib-state-dropdown',
  templateUrl: './state-dropdown.component.html',
  styleUrls: ['./state-dropdown.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => StateDropdownComponent),
    multi: true
  }],
})
export class StateDropdownComponent implements OnInit {

  private propagateChange = (_: any) => { };
  private propagateTouched = () => { };

  public value: string = "";

  constructor() { }

  ngOnInit(): void {
  }

  onChange(val: string): void {
    this.value = val;

    this.propagateChange(this.value);
    this.propagateTouched();
  }

  writeValue(val: string): void {
    this.value = val;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

}
