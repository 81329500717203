import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IDirty } from 'projects/shared/src/lib/models/IDirty';

@Component({
  selector: 'gcl-admin-register-checkout',
  templateUrl: './register-checkout.component.html',
  styleUrls: ['./register-checkout.component.scss']
})
export class RegisterCheckoutComponent implements OnInit, IDirty {

  isDirty(): boolean {
    return !this.success;
  };

  private success: boolean = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  public cancel(): void {
    this.router.navigate(['/admin', 'tee-sheet']);
  }

  public onSuccess(success: boolean): void {
    this.success = success;
  }

}
