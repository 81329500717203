<form [formGroup]="resetForm" (ngSubmit)="submit()">
    <small class="text-danger d-block mb-2" *ngIf="resetForm.errors?.invalidPassword">
        Invalid email address. Please try again.
    </small>
    <div class="form-group">
        <label for="email">Email</label>
        <input id="email" type="email" formControlName="email" placeholder="Email Address" class="form-control"
            [class.is-invalid]="email.touched && email.invalid" />
        <div *ngIf="email?.invalid && email?.touched" class="invalid-feedback">
            <div *ngIf="email?.errors?.required">
                Please enter an email address.
            </div>
        </div>
    </div>
    <br />
    <button type="submit" class="btn btn-primary btn-block" [disabled]="resetForm.invalid">Send</button>
</form>