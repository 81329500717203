<div class="modal-header">
    <h4 class="modal-title pull-left">Search Player</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="cancel()">
        <span class="modal-close" aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <h6  class="page-header">Player List</h6>
    <div class="table-responsive">
        <gcl-lib-table
            [totalItems]="total$ | async" 
            [sortBy]="'firstName'" 
            [enableCreate]="false"
            [tableClass]="'table table-striped table-hover'"
            (onPage)="pagination$.next($event)" >
            <thead>
                <tr>
                    <th columnSort="firstName">Name</th>
                    <th columnSort="email">Email</th>
                    <th columnSort="cellphone">Cellphone</th>
                    <th width="100" class="text-center">Manage</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let player of records$ | async">
                    <td>{{player.firstName}} {{player.lastName}}</td>
                    <td>{{player.email}}</td>
                    <td>{{player.cellphone}}</td>
                    <td class="text-center">
                        <button type="button" class="btn btn-primary" (click)="onSelect(player)">Select</button>
                    </td>
                </tr>
                <tr *ngIf="(total$ | async) == 0">
                    <td colspan="5">No players.</td>
                </tr>
            </tbody>
        </gcl-lib-table>
</div>