import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { from, Observable, Subject } from 'rxjs';
import { map, switchMap, shareReplay, takeUntil, take } from 'rxjs/operators';

import { faChevronLeft, faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { environment } from 'projects/admin/src/environments/environment';
import { CourseDetails } from 'projects/shared/src/lib/models/course';
import { CourseService } from 'projects/shared/src/lib/services/course.service';
import { MediaService } from 'projects/shared/src/lib/services/media.service';
import { Media, MediaDetails } from 'projects/shared/src/public-api';
import { ConfirmationModalService } from 'projects/shared/src/lib/services/confirmation-modal.service';
import { StripeService } from 'projects/shared/src/lib/services/stripe.service';
import { StripeTerminalService } from 'projects/shared/src/lib/services/stripe-terminal.service';
import { DiscoverResult, Reader, Terminal } from '@stripe/terminal-js';
import { AddReaderComponent } from 'projects/shared/src/lib/components/add-reader/add-reader.component';
import { AddPrinterComponent } from 'projects/shared/src/lib/components/add-printer/add-printer.component';
import { PrinterService } from 'projects/shared/src/lib/services/printer.service';
import { PrinterDetails } from 'projects/shared/src/lib/models/printer';

@Component({
  selector: 'gcl-admin-course-view',
  templateUrl: './course-view.component.html',
  styleUrls: ['./course-view.component.scss']
})
export class CourseViewComponent implements OnInit, OnDestroy {
  public apiUrl: string = environment.apiUrl;
  public faChevronLeft = faChevronLeft;
  public deleteIcon = faTrash;
  public editIcon = faEdit;
  public addIcon = faPlus;

  public courseId$!: Observable<number>;
  public course$!: Observable<CourseDetails>;
  public banners$!: Observable<Array<Media>>;
  public readers$!: Observable<Reader[]>;
  public printers$!: Observable<PrinterDetails[]>;

  private destroyed$ = new Subject();
  private terminal$!: Observable<Terminal>;

  constructor(private courseService: CourseService, private printerService: PrinterService, private mediaService: MediaService, public route: ActivatedRoute, private confirmationModalService: ConfirmationModalService, private router: Router, private toastr: ToastrService, private stripeService: StripeService, private modalService: BsModalService, private stripeTerminalService: StripeTerminalService) { }

  refreshReaders() {
    this.readers$ = this.terminal$.pipe(
      switchMap(terminal => from(terminal!.discoverReaders())),
      map(result => <DiscoverResult>result),
      map(result => result.discoveredReaders),
      shareReplay(1)
    );
  }

  async refreshPrinters() {
    const courseId = await this.courseId$.pipe(take(1)).toPromise();
    this.printers$ = this.printerService.query({
      course: courseId
    }).records$.pipe();
  }

  async ngOnInit() {
    this.courseId$ = this.route.params.pipe(
      map(params => params.courseId)
    );

    this.terminal$ = this.courseId$.pipe(
      switchMap(courseId => from(this.stripeTerminalService.getClient(courseId))),
      map(terminal => <Terminal>terminal)
    );

    this.refreshReaders();
    this.refreshPrinters();

    this.course$ = this.courseId$.pipe(
      switchMap(courseId => this.courseService.get(courseId)),
      shareReplay(1),
      takeUntil(this.destroyed$)
    );

    this.banners$ = this.course$
      .pipe(
        map(course => course.banners),
        takeUntil(this.destroyed$)
      );
  }

  async addReader() {
    const courseId = await this.courseId$.pipe(take(1)).toPromise();
    let modal = this.modalService.show(AddReaderComponent, {
      ignoreBackdropClick: true,
      initialState: {
        courseId
      }
    });

    modal.onHidden.subscribe(() => this.refreshReaders());
  }

  async renameTerminal(reader: Reader) {
    const newName = prompt('Enter name:', reader.label);
    if(newName && reader.label != newName) {
      await this.stripeTerminalService.renameTerminal(reader.id, newName);
      this.toastr.success('Terminal successfully renamed');
      this.refreshReaders();
    }
  }

  deleteTerminal(terminaId: string) {
    this.confirmationModalService.showConfirmationModal({
      title: 'Delete Reader?',
      message: 'Are you sure you want to delete the card reader? This action cannot be undone.'
    }).subscribe(async result => {
      if(result) {
        await this.stripeTerminalService.deleteTerminal(terminaId);
        this.toastr.success('Terminal successfully deleted');
        this.refreshReaders();
      }
    });
  }

  async addPrinter() {
    const courseId = await this.courseId$.pipe(take(1)).toPromise();
    let modal = this.modalService.show(AddPrinterComponent, {
      ignoreBackdropClick: true,
      initialState: {
        courseId
      }
    });

    modal.onHidden.subscribe(() => this.refreshPrinters());
  }

  // async renamePrinter(PrinterDetails: PrinterDetails) {
  //   const newName = prompt('Enter name:', reader.label);
  //   if(newName && reader.label != newName) {
  //     await this.stripeTerminalService.renameTerminal(reader.id, newName);
  //     this.toastr.success('Terminal successfully renamed');
  //     this.refreshPrinters();
  //   }
  // }

  deletePrinter(printerId: number) {
    this.confirmationModalService.showConfirmationModal({
      title: 'Delete Printer?',
      message: 'Are you sure you want to delete the printer? This action cannot be undone.'
    }).subscribe(async result => {
      if (result) {
        this.printerService.delete(printerId).subscribe(() => {
          this.toastr.success('Printer successfully deleted');
          this.refreshPrinters();
        });
      }
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public openDeleteModal() {
    this.confirmationModalService.showConfirmationModal({
      title: 'Confirm Deletion',
      message: 'You are about to delete this course. This action cannot be undone.'
    }).subscribe(accept => {
      if (accept) {
        this.course$.pipe(
          switchMap((course: CourseDetails) => this.courseService.delete(course.id)),
          shareReplay(1),
          takeUntil(this.destroyed$)
        ).subscribe((course: CourseDetails) => {
          this.toastr.success("Course deleted successfully!");
          this.router.navigate(["/admin", "courses"]);
        });
      }
    });
  }

  public onUploadSuccess(): void {
    this.refreshBanners();
  }

  private refreshBanners(): void {
    const course$ = this.courseId$.pipe(
      switchMap(courseId => this.courseService.get(courseId)),
      takeUntil(this.destroyed$)
    );

    this.banners$ = course$
      .pipe(
        map(course => course.banners),
        takeUntil(this.destroyed$)
      );
  }

  public openRemoveBannerModal(mediaId: number) {
    this.confirmationModalService.showConfirmationModal({
      title: 'Confirm Deletion',
      message: 'You are about to delete this banner. This action cannot be undone.'
    }).subscribe(accept => {
      if (accept) {
        this.mediaService.removeMediaFile(mediaId).pipe(takeUntil(this.destroyed$)).subscribe(() => this.refreshBanners());
      }
    });
  }

  private addFilesModal?: BsModalRef;
  openUploadModal(template: TemplateRef<any>) {
    this.addFilesModal = this.modalService.show(template, {ignoreBackdropClick: true});
  }

  closeUploadModal() {
    this.addFilesModal?.hide();
  }

  connectStripe() {
    this.course$.pipe(take(1)).subscribe(course => {
      window.location.href = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${environment.stripeConnectClientId}&scope=read_write&state=${course.id}&redirect_uri=${environment.apiUrl}/stripe/connect-account`;
    });
  }

  disconnectStripe() {
    this.course$.pipe(
      take(1),
      switchMap(course => this.stripeService.disconnectAccount(course.id))
    ).subscribe(course => {
      this.toastr.success("Successfully disconnected stripe account");
      this.router.navigate(["/admin", "courses"]);
    });
  }

  onCreateSpace(course: CourseDetails) {
    this.router.navigate(['/admin', 'courses', course.id, 'spaces', 'create']);
  }

  public getQueryParams() {
    return this.route.snapshot.queryParams;
  }
}
