import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { OnPageEvent, OrderDetails } from 'projects/shared/src/public-api';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { SortDirections } from 'projects/shared/src/lib/enumerations/sort-directions';
import { CreditBalanceTransactionService } from 'projects/shared/src/lib/services/credit-balance-transaction.service';
import { CreditBalanceTransactionQuery } from 'projects/shared/src/lib/queries/credit-balance-transaction-query';
import { CreditBalanceTransactionDetails } from 'projects/shared/src/lib/models/credit-balance-transaction';
import { GetCreditBalanceTransactionTypesDisplayName } from 'projects/shared/src/lib/enumerations/credit-balance-type';

@Component({
  selector: 'gcl-admin-credit-balance-transaction-table',
  templateUrl: './credit-balance-transaction-table.component.html',
  styleUrls: ['./credit-balance-transaction-table.component.scss']
})
export class CreditBalanceTransactionTableComponent implements OnInit, OnDestroy {
  GetCreditBalanceTransactionTypesDisplayName = GetCreditBalanceTransactionTypesDisplayName;
  
  @Input('userId') userId?: number;

  pagination$ = new BehaviorSubject<OnPageEvent>({
    itemsPerPage: 10,
    page: 1,
    sortBy: 'id',
    sortDescending: true
  });

  total$: BehaviorSubject<number> = new BehaviorSubject(1);
  records$: ReplaySubject<CreditBalanceTransactionDetails[]> = new ReplaySubject(1);

  private destory$: Subject<boolean> = new Subject();

  constructor(private router: Router, private creditBalanceTransactionService: CreditBalanceTransactionService) { }

  ngOnInit(): void {
    this.pagination$
    .pipe(
      takeUntil(this.destory$)
    )
    .subscribe(() => {
      this.getPageResult();
    });
  }

  ngOnDestroy() {
    this.destory$.next(true);
    this.destory$.complete();
  }

  private getPageResult() {
    let query: CreditBalanceTransactionQuery = {
      skip: (this.pagination$.value.page - 1) * this.pagination$.value.itemsPerPage,
      take: this.pagination$.value.itemsPerPage,
      sortColumns: [{
        column: this.pagination$.value.sortBy,
        direction: this.pagination$.value.sortDescending ? SortDirections.Descending : SortDirections.Ascending,
      }],
      search: this.pagination$.value.search,
    };

    if(this.userId) {
      query.user = this.userId
    }

    const pagedResult = this.creditBalanceTransactionService.query(query);

    pagedResult.filteredCount$.subscribe((total) => this.total$.next(total));
    pagedResult.records$.subscribe((records) => this.records$.next(records));
  }
}
