import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { Event, EventDetails } from '../models/event';
import { EventQuery } from '../queries/event-query';
import { PagedResult } from '../queries/paged-result';
import { CONFIG_TOKEN, SharedModuleConfig } from '../shared.config';
import { StrapiQueryableService } from './strapi-queryable.service';

declare var Qs: any;

@Injectable({
  providedIn: 'root'
})
export class EventService extends StrapiQueryableService<Event, EventDetails, EventQuery> {

  constructor(protected httpClient: HttpClient, @Inject(CONFIG_TOKEN) protected config: SharedModuleConfig) {
    super({ path: 'events' }, httpClient, config);
  }

  protected buildQuery(query?: EventQuery): string {
    let queryObj = super.buildBaseQueryObj(query);

    // Specific Query filters.
    if (query) {
      if (query.search) {
        queryObj._where.push({
          _or: [{
            id_contains: query.search
          }, {
            name_contains: query.search
          }]
        });
      }

      if (query.id) {
        queryObj._where.push({ id: query.id });
      }
      if (query.order) {
        queryObj._where.push({ order: query.order });
      }
      if (query.name) {
        queryObj._where.push({ name: query.name });
      }
      if (query.event_space) {
        queryObj._where.push({ event_space: query.event_space });
      }
      if (query.type) {
        queryObj._where.push({ type: query.type });
      }
      if (query.start) {
        queryObj._where.push({ start: query.start });
      }
      if (query.start_gte) {
        queryObj._where.push({ start_gte: query.start_gte });
      }
      if (query.start_lte) {
        queryObj._where.push({ start_lte: query.start_lte });
      }
      if (query.end) {
        queryObj._where.push({ end: query.end });
      }
      if(query.course) {
        queryObj._where.push({ 'event_space.course': query.course });
      }
    }

    return Qs.stringify(queryObj);
  }

  public query(query?: EventQuery): PagedResult<EventDetails> {
    const total$ = this.count();
    const filteredTotal$ = this.count(query);
    const queryStr = this.buildQuery(query);
    const records$ = this.httpClient.get<EventDetails[]>(`${this.config.apiUrl}/${this.pathConfig.path}?${queryStr}`)
      .pipe(
        shareReplay(1)
      );
    return new PagedResult(records$, filteredTotal$, total$);
  }

  public count(query?: EventQuery): Observable<number> {
    const queryStr = this.buildQuery(query);
    return this.httpClient.get<number>(`${this.config.apiUrl}/${this.pathConfig.path}/count?${queryStr}`)
      .pipe(
        shareReplay(1)
      );
  }

  public createWithOrder(record: Partial<Event>, courseId: number, eventAmount: number, userId: number, golfproduct?: number) {
    return this.httpClient.post<EventDetails>(`${this.config.apiUrl}/${this.pathConfig.path}/create-with-order`, {record, courseId, eventAmount, userId, golfproduct});
  }
}
