<div class="modal-header">
    <h4 class="modal-title pull-left">Search Promotions</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="cancel()">
        <span class="modal-close" aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <h6  class="page-header">Promotions List</h6>
    <div class="table-responsive">
        <gcl-lib-table
            [totalItems]="total$ | async" 
            [sortBy]="'code'" 
            [enableCreate]="false"
            [showPageOptions]="false"
            [tableClass]="'table table-striped table-hover table-sm'"
            (onCreate)="openCreateModal()"
            (onPage)="pagination$.next($event)" >
            <thead>
                <tr>
                    <th columnSort="code">Promotion Code</th>
                    <th columnSort="status">Active</th>
                    <th>Active Date From</th>
                    <th>Active Date To</th>
                    <!-- <th width="100" class="text-center">Manage</th> -->
                </tr>
            </thead>
            <tbody>
                <tr [class.selected-code]="code.id === selectedCode?.id" *ngFor="let code of records$ | async" (click)="onSelect(code)">
                    <td>{{code.code}}</td>
                    <td>{{code.active ? 'Active' : 'Inactive'}}</td>
                    <td>{{code.activeDateFrom | date: 'M/d/yyyy h:mm a'}}</td>
                    <td>{{code.activeDateTo | date: 'M/d/yyyy h:mm a'}}</td>
                    <!-- <td class="text-center">
                        <a class="select-button" type="button" (click)="openEditModal(code)">VIEW</a>
                    </td> -->
                </tr>
                <tr *ngIf="(total$ | async) == 0">
                    <td colspan="5">No promotions.</td>
                </tr>
            </tbody>
        </gcl-lib-table>
    </div>
    <div class="d-flex justify-content-end align-items-center">
        <button class="btn btn-outline-light-green" (click)="cancel()">Cancel</button>
        <button class="btn btn-light-green ml-2" [disabled]="selectedCode === null || selectedCode?.active === false" (click)="selectCode()">Select Promotion</button>
    </div>
</div>
