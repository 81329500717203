import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, shareReplay, takeUntil } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { environment } from 'projects/admin/src/environments/environment';
import { GolfProductDetails } from 'projects/shared/src/lib/models/golfproduct';
import { GolfProductService } from 'projects/shared/src/lib/services/golf-product.service';
import { ConfirmationModalService } from 'projects/shared/src/lib/services/confirmation-modal.service';
import { GetGolfProductReservationTypesDisplayName } from 'projects/shared/src/lib/enumerations/golf-product-reservation-type';
import { ReservationType } from 'projects/shared/src/lib/models/reservation-type';

@Component({
  selector: 'gcl-admin-product-view',
  templateUrl: './product-view.component.html',
  styleUrls: ['./product-view.component.scss']
})
export class ProductViewComponent implements OnInit, OnDestroy {
  public apiUrl: string = environment.apiUrl;
  public faChevronLeft = faChevronLeft;
  public GetGolfProductReservationTypesDisplayName = GetGolfProductReservationTypesDisplayName;

  public product$!: Observable<GolfProductDetails>;

  private destroyed$ = new Subject();

  constructor(private golfProductService: GolfProductService, private route: ActivatedRoute, private router: Router, private toastr: ToastrService, private confirmationModalService: ConfirmationModalService) { }

  ngOnInit(): void {
    const productId$ = this.route.params.pipe(
      map(params => params.productId)
    );

    this.product$ = productId$.pipe(
      switchMap(productId => this.golfProductService.get(productId)),
      shareReplay(1)
    );
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public openDeleteModal() {
    this.confirmationModalService.showConfirmationModal({
      title: 'Confirm Deletion',
      message: 'You are about to delete this add-on? This action cannot be undone.'
    }).subscribe(accept => {
      if (accept) {
        this.delete();
      }
    });
  }

  private delete(): void {
    this.product$.pipe(
      switchMap((product: GolfProductDetails) => this.golfProductService.delete(product.id)),
      shareReplay(1),
      takeUntil(this.destroyed$)
    ).subscribe((product: GolfProductDetails) => {
      this.toastr.success("Product deleted successfully!");
      this.router.navigate(["/admin", "products", "list"]);
    });
  }

  public getReservationTypesDisplayName(reservationTypes: ReservationType[]) {
    if(!reservationTypes || reservationTypes?.length <= 0) {
      return 'N/A';
    }
    else {
      return reservationTypes.map(r => r.name).join(', ');
    }
  }

  public getQueryParams() {
    return this.route.snapshot.queryParams;
  }
}
