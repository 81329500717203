import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import { GolfPassDetails, OnPageEvent, UserDetails } from 'projects/shared/src/public-api';
import { SortDirections } from 'projects/shared/src/lib/enumerations/sort-directions';
import { UserService } from 'projects/shared/src/lib/services/user.service';
import { GolfPassService } from 'projects/shared/src/lib/services/golf-pass.service';

@Component({
  selector: 'gcl-admin-golf-pass-select-modal',
  templateUrl: './golf-pass-select-modal.component.html',
  styleUrls: ['./golf-pass-select-modal.component.scss']
})
export class GolfPassSelectModalComponent implements OnInit, OnDestroy {
  public faChevronLeft = faChevronLeft;

  @Input() id_nin?: number[] | null;

  @Output()
  select$: EventEmitter<GolfPassDetails | null> = new EventEmitter<GolfPassDetails | null>();

  selectedPass!: GolfPassDetails | null;

  private destory$: Subject<boolean> = new Subject();

  constructor(private bsModalRef: BsModalRef, private golfpassService: GolfPassService) { }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.destory$.next(true);
    this.destory$.complete();
  }


  public onPassClicked(pass: GolfPassDetails | null): void {
    this.selectedPass = pass;
  }

  public selectPass(): void {
    this.select$.emit(this.selectedPass);
  }

  public cancel(): void {
    this.bsModalRef.hide()
  }
}
