import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, combineLatest, ReplaySubject } from 'rxjs';
import { map, switchMap, shareReplay, takeUntil } from 'rxjs/operators';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { environment } from 'projects/admin/src/environments/environment';
import { ScheduleDetails } from 'projects/shared/src/lib/models/schedule';
import { ScheduleService } from 'projects/shared/src/lib/services/schedule.service';
import { RoundService } from 'projects/shared/src/lib/services/round.service';
import { RoundDetails } from 'projects/shared/src/lib/models/round';
import { OnPageEvent } from 'projects/shared/src/lib/models/on-page-event';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { SortDirections } from 'projects/shared/src/lib/enumerations/sort-directions';
import { RoundStatuses } from 'projects/shared/src/lib/enumerations/round-status';
import { ConfirmationModalService } from 'projects/shared/src/lib/services/confirmation-modal.service';
import { GetScheduleTypeDisplayName, ScheduleTypes } from 'projects/shared/src/lib/enumerations/schedule-types';
import { PagedResult } from 'projects/shared/src/lib/queries/paged-result';
import { GolfPassDetails } from 'projects/shared/src/lib/models/golfpass';
import { GolfPassService } from 'projects/shared/src/lib/services/golf-pass.service';

@Component({
  selector: 'gcl-admin-schedule-view',
  templateUrl: './schedule-view.component.html',
  styleUrls: ['./schedule-view.component.scss']
})
export class ScheduleViewComponent implements OnInit, OnDestroy {
  public apiUrl: string = environment.apiUrl;
  public faChevronLeft = faChevronLeft;
  public ScheduleTypes = ScheduleTypes;
  public GetScheduleTypeDisplayName = GetScheduleTypeDisplayName;

  public scheduleId$!: Observable<number>;
  public schedule$!: Observable<ScheduleDetails>;

  golfPassesPagination$: ReplaySubject<OnPageEvent> = new ReplaySubject<OnPageEvent>(1);
  golfPassesPagedResult$: ReplaySubject<PagedResult<GolfPassDetails>> = new ReplaySubject(1);

  roundsPagination$: ReplaySubject<OnPageEvent> = new ReplaySubject<OnPageEvent>(1);
  roundsPagedResult$: ReplaySubject<PagedResult<RoundDetails>> = new ReplaySubject(1);

  public generateModalRef!: BsModalRef;

  private destroyed$ = new Subject();

  constructor(
    private scheduleService: ScheduleService,
    private roundService: RoundService,
    private authService: AuthService,
    public route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private confirmationModalService: ConfirmationModalService,
    private golfpassService: GolfPassService
  ) { }

  ngOnInit(): void {
    this.scheduleId$ = this.route.params.pipe(
      map(params => params.scheduleId)
    );

    this.schedule$ = this.scheduleId$.pipe(
      switchMap(scheduleId => this.scheduleService.get(scheduleId)),
      shareReplay(1)
    );

    this.initRounds();
    this.initGolfPasses();
  }

  private initRounds(): void {
    this.getRoundsPageResult();

    this.roundsPagination$.next({
      itemsPerPage: 10,
      page: 1,
      sortBy: 'id',
      sortDescending: true
    });
  }

  private getRoundsPageResult() {
    combineLatest([this.scheduleId$, this.authService.course$, this.roundsPagination$])
      .pipe(
        takeUntil(this.destroyed$)
      ).subscribe(([scheduleId, course, pagination]) => {
        const pagedResult = this.roundService.query({
          skip: (pagination.page - 1) * pagination.itemsPerPage,
          take: pagination.itemsPerPage,
          sortColumns: [{
            column: pagination.sortBy,
            direction: pagination.sortDescending ? SortDirections.Descending : SortDirections.Ascending,
          }],
          search: pagination.search,

          schedule: scheduleId,
          course: course?.id
        });
        this.roundsPagedResult$.next(pagedResult);
      });
  }

  private initGolfPasses(): void {
    this.getGolfPasssPageResult();

    this.golfPassesPagination$.next({
      itemsPerPage: 10,
      page: 1,
      sortBy: 'id',
      sortDescending: true
    });
  }

  private getGolfPasssPageResult() {
    combineLatest([this.scheduleId$, this.authService.course$, this.golfPassesPagination$])
      .pipe(
        takeUntil(this.destroyed$)
      ).subscribe(([scheduleId, course, pagination]) => {
        const pagedResult = this.golfpassService.query({
          skip: (pagination.page - 1) * pagination.itemsPerPage,
          take: pagination.itemsPerPage,
          sortColumns: [{
            column: pagination.sortBy,
            direction: pagination.sortDescending ? SortDirections.Descending : SortDirections.Ascending,
          }],
          search: pagination.search,

          // TODO: wire up golf pass schedule filtering
          // schedule: scheduleId,
          course: course?.id
        });
        this.golfPassesPagedResult$.next(pagedResult);
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public openGenerateModal(template: TemplateRef<any>) {
    this.generateModalRef = this.modalService.show(template, { ignoreBackdropClick: true });
  }

  public updateRound(round: RoundDetails, event: Event): void {
    const target = event.currentTarget as HTMLInputElement;
    if (target) {
      const value = target.value as RoundStatuses;
      round.status = value;
      this.roundService.update(round.id, round)
        .subscribe(() => this.toastr.success("Round has been updated."));
    }
  }

  private generateDate?: Date;
  public onSelectgenerateDate(date: Date): void {
    this.generateDate = date;
  }

  public generateRounds() {
    this.scheduleId$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe((scheduleId) => {
        if (this.generateDate) {
          this.scheduleService.generate(scheduleId, this.generateDate)
            .subscribe((count: number) => {
              this.toastr.success(`${count} rounds generated!`);
              this.generateModalRef.hide();
            });
        } else {
          this.toastr.error(`A date is required.`);
        }
      });
  }

  public openDeleteModal() {
    this.confirmationModalService.showConfirmationModal({
      title: 'Confirm Deletion',
      message: 'You are about to delete this schedule? This action cannot be undone.'
    }).subscribe(accept => {
      if (accept) {
        this.delete();
      }
    });
  }

  private delete(): void {
    this.schedule$.pipe(
      switchMap((schedule: ScheduleDetails) => this.scheduleService.delete(schedule.id)),
      shareReplay(1),
      takeUntil(this.destroyed$)
    ).subscribe((schedule: ScheduleDetails) => {
      this.toastr.success("Schedule deleted successfully!");
      this.router.navigate(["/admin", "schedules"]);
    });
  }

  public getQueryParams() {
    return this.route.snapshot.queryParams;
  }
}
