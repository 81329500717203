<div class="container-fluid py-2 h-100 overflow-y-auto">
    <div class="row">
        <div class="col-12">
            <h6  class="page-header">Orders List</h6>
            <gcl-lib-table 
                [totalItems]="total$ | async" 
                [sortBy]="'id'" 
                (onPage)="pagination$.next($event)" 
                [tableClass]="'table table-striped table-hover'" 
                (onCreate)="goToCreate()">
                <thead>
                    <tr>
                        <th columnSort="course.full_name">Course</th>
                        <th columnSort="users_permissions_user.firstName">Customer</th>
                        <th columnSort="created_at">Created</th>
                        <th columnSort="status">Status</th>
                        <th columnSort="subtotal">Subtotal</th>
                        <th columnSort="tip">Tip</th>
                        <th columnSort="tax">Tax</th>
                        <th columnSort="total">Total</th>
                        <th columnSort="finaltotal">Balance</th>
                        <th>Amount Refunded</th>
                        <th width="140" class="text-center">Payment Type</th>
                        <th width="100" class="text-center">Manage</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let order of records$ | async">
                        <td>{{order.course?.full_name}}</td>
                        <td>{{order.users_permissions_user?.firstName}} {{order.users_permissions_user?.lastName}}</td>
                        <td>{{order.created_at | date: 'M/dd/yyyy h:mm a'}}</td>
                        <td>{{order.status | orderStatus}}</td>
                        <td>{{order.subtotal | currency}}</td>
                        <td>{{order.tip | currency}}</td>
                        <td>{{order.tax | currency}}</td>
                        <td>{{order.total | currency}}</td>
                        <td>{{order.finaltotal | currency}}</td>
                        <td>{{order.amountRefunded | currency}}</td>
                        <td class="text-center">{{order.paymentTypes}}</td>
                        <td class="text-center">
                            <div class="btn-group btn-group-sm">
                                <a [routerLink]="['/admin','orders', order.id, 'view']" class="btn btn-link btn-primary-light">View</a>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="(total$ | async) == 0">
                        <td colspan="9">No orders.</td>
                    </tr>
                </tbody>
            </gcl-lib-table>
        </div>
    </div>
</div>