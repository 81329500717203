<div class="d-flex flex-column h-100 mx-4" *ngIf="order$ | async as order">
    <h5 class="font-weight-bold">Reader</h5>
    <ng-container *ngIf="readers && currentState == ReaderState.Disconnected">
        <p>Choose a reader to connect to:</p>
        <div class="row">
            <div class="col-4" *ngFor="let reader of readers">
                <button type="button" class="btn btn-default btn-block payment-option fixed-amount w-100 h-100 d-flex justify-content-center align-items-center text-uppercase" 
                (click)="connectReader(reader)"
                [disabled]="reader.status == 'offline'"
                [ngClass]="{'active-amount': false }">
                {{reader.label}}
                </button>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="activeReader">
        <div>Current Reader: {{activeReader.label}} <button class="btn btn-primary btn-sm d-inline" (click)="changeReader()">Change</button></div>
    </ng-container>
    <ng-container *ngIf="currentState == ReaderState.Connected">
        <div class="d-flex flex-row justify-content-end m-4">
            <button (click)="collectCustomPayment()" class="btn btn-primary">Collect Payment (Custom Amount)</button>
            <button (click)="collectPayment()" class="btn btn-primary">Collect Payment</button>
        </div>
    </ng-container>
    <!-- <ng-container *ngIf="currentState == ReaderState.Collected">
        <button (click)="confirmPayment()" class="btn btn-primary">Confirm Payment</button>
    </ng-container>
    <ng-container *ngIf="currentState == ReaderState.Confirmed">
        <button (click)="capturePayment()" class="btn btn-primary">Capture Payment</button>
    </ng-container> -->
    <ng-container *ngIf="currentState == ReaderState.Collected">
        <div class="d-flex flex-row justify-content-end m-4">
            <button class="btn btn-light-green mr-2" (click)="submit()">Submit</button>
            <button class="btn btn-light-green" (click)="openReceiptModal(receiptEmail, order)">Submit and Send Receipt</button>
        </div>
        <ng-template #receiptEmail>
            <div class="modal-header">
              <h4 class="modal-title pull-left">Email Receipt</h4>
              <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span class="modal-close" aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label for="email">Email</label>
                    <input class="form-control" id="email" [(ngModel)]="email" />
                </div>
                <div class="form-group" *ngIf="printers$ | async as printers">
                    <label>Printers</label>
                    <div class="custom-control custom-checkbox" *ngFor="let printer of printers">
                        <input class="custom-control-input" type="checkbox" [id]="'printer-'+printer.id.toString()" value="{{printer.id}}" (change) ="updatePrinters($event)">
                        <label class="custom-control-label" [for]="'printer-'+printer.id.toString()">{{printer.name}}</label>
                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <button class="btn btn-outline-light-green mr-2" (click)="modalRef.hide()">Cancel</button>
                    <button class="btn btn-light-green" (click)="emailReceipt(order)">Continue</button>
                </div>
            </div>
          </ng-template>
    </ng-container>
</div>