import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, Subject, timer } from 'rxjs';
import { switchMap, map, shareReplay, takeUntil } from 'rxjs/operators';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import { GetCartStatusesDisplayName } from 'projects/shared/src/lib/enumerations/cart-status';
import { environment } from 'projects/admin/src/environments/environment';
import { CartService } from 'projects/shared/src/lib/services/cart.service';
import { CartDetails, UserDetails } from 'projects/shared/src/public-api';
import { Course } from 'projects/shared/src/lib/models/course';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';

@Component({
  selector: 'gcl-admin-cart-view',
  templateUrl: './cart-view.component.html',
  styleUrls: ['./cart-view.component.scss']
})
export class CartViewComponent implements OnInit, OnDestroy {
  public apiUrl: string = environment.apiUrl;
  public faChevronLeft = faChevronLeft;
  public getCartStatusDisplayName = GetCartStatusesDisplayName;

  public course$!: Observable<Course>;
  public cart$!: Observable<CartDetails>;
  public user$!: Observable<UserDetails>;

  private destroyed$ = new Subject();

  constructor(private cartService: CartService, public route: ActivatedRoute, private authService: AuthService) { }

  ngOnInit(): void {
    this.course$ = this.authService.course$;

    const cartId$ = this.route.params.pipe(
      map(params => params.cartId)
    );

    const interval$ = timer(0, 30 * 1000)
      .pipe(takeUntil(this.destroyed$));

    this.cart$ = combineLatest([interval$, cartId$])
      .pipe(
        switchMap(([time, cartId]) => this.cartService.get(cartId)),
        shareReplay(1),
        takeUntil(this.destroyed$)
      );
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
