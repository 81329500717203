<form [formGroup]="changeForm" (ngSubmit)="submit()">
    <small class="text-danger d-block mb-2" *ngIf="changeForm.errors?.invalidPassword">
        Invalid password. Please try again.
    </small>
    <small class="text-danger d-block mb-2" *ngIf="(password?.touched && confirmPassword?.touched) && changeForm.errors?.invalidPasswordMatch">
        Password do not match. Please try again.
    </small>
    <div class="form-group">
        <label for="password">Password</label>
        <input id="password" type="password" formControlName="password" placeholder="Password" class="form-control"
            [class.is-invalid]="password.touched && password.invalid" />
        <div *ngIf="password?.invalid && password?.touched" class="invalid-feedback">
            <div *ngIf="password?.errors?.required">
                Please enter a password.
            </div>
        </div>
    </div>
    <div class="form-group">
        <label for="confirmPassword">Confirm Password</label>
        <input id="confirmPassword" type="password" formControlName="confirmPassword" placeholder="Confirm Password" class="form-control"
            [class.is-invalid]="confirmPassword.touched && confirmPassword.invalid" />
        <div *ngIf="confirmPassword?.invalid && confirmPassword?.touched" class="invalid-feedback">
            <div *ngIf="confirmPassword?.errors?.required">
                Please enter a confirmation password.
            </div>
        </div>
    </div>
    <br />
    <button type="submit" class="btn btn-primary btn-block" [disabled]="changeForm.invalid">Submit</button>
</form>