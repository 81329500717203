<div class="modal-header">
    <h4 class="modal-title pull-left">Redeem Gift Certificate</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="cancel()">
        <span class="modal-close" aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="form" (ngSubmit)="redeemCode()">
        <div class="row mt-2">
            <div class="col-12">
                <div class="form-group">
                    <label for="code">Enter Gift Certificate Code</label>
                    <input formControlName="code" id="code" class="form-control font-weight-bold" placeholder="A1B2C3D4E5" oninput="this.value = this.value.toUpperCase()"/>
                    <div *ngIf="code?.invalid && code?.touched" class="text-danger mt-2">
                        <div *ngIf="code?.errors?.required">
                            Code required.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end align-items-center">
            <button class="btn btn-outline-light-green" (click)="cancel()">Cancel</button>
            <button class="btn btn-light-green ml-2" type="submit" [disabled]="form.invalid">Redeem</button>
        </div>
    </form>
</div>
