import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { ReservationType, ReservationTypeDetails } from '../models/reservation-type';
import { PagedResult } from '../queries/paged-result';
import { CONFIG_TOKEN, SharedModuleConfig } from '../shared.config';
import { StrapiQueryableService } from './strapi-queryable.service';
import { StrapiService } from './strapi.service';

declare var Qs: any;

@Injectable({
  providedIn: 'root'
})
export class ReservationTypeService extends StrapiService<ReservationType, ReservationTypeDetails> {

  constructor(protected httpClient: HttpClient, @Inject(CONFIG_TOKEN) protected config: SharedModuleConfig) {
    super({ path: 'reservation-types' }, httpClient, config);
  }
}
