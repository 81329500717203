<div class="container-fluid py-2">
  <div class="row">
      <div class="col-12">
        <gcl-admin-tee-sheet-table
          [initDate]="selectedDate"
          [teeTimeChange$]="teeTimeChange$"
          (removeBlockTime)="removeBlockTime($event)"
          (cancelReservation)="cancelReservation($event)"
          (newReservation)="newReservation($event)"
          (selectOrder)="selectOrder($event)"></gcl-admin-tee-sheet-table>
      </div>
  </div>
</div>