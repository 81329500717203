import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { PaymentMethod } from '@stripe/stripe-js';
import { LazyStripeAPILoader, StripeFactoryService, WindowRef } from 'ngx-stripe';

import { StrapiPaymentIntent } from '../models/strapi-payment-intent';
import { StrapiSetupIntent } from '../models/strapi-setup-intent';
import { CONFIG_TOKEN, SharedModuleConfig } from '../shared.config';
import { CourseDetails } from '../models/course';
import { StripeConstants } from 'projects/admin/src/app/shared/constants/stripe';

@Injectable({
  providedIn: 'root'
})
export class StripeService extends StripeFactoryService {

  public reloadPaymentList$: Subject<number> = new Subject<number>();

  constructor(loader: LazyStripeAPILoader, window: WindowRef, private http: HttpClient, @Inject(CONFIG_TOKEN) protected config: SharedModuleConfig) {
    super(config.stripe.publicKey, {
      apiVersion: config.stripe.version
    }, loader, window);
  }

  public createInstance() {
    return this.create(this.config.stripe.publicKey);
  }

  /* Returns payment Client Secret. */
  public createPaymentIntent(userId: number | undefined, orderId: number, amount: number, captureMethod? : 'automatic' | 'manual'): Observable<StrapiPaymentIntent> {
    // Initialize Params Object
    let params = new HttpParams();

    userId = userId ?? StripeConstants.EmptyUserId;

    params = params.append('userId', userId.toString());
    

    if(captureMethod) {
      params = params.append('captureMethod', captureMethod);
    }
    
    return this.http.post<StrapiPaymentIntent>(`${this.config.apiUrl}/stripe/orders/${orderId}/payment-intent`, { amount }, { params: params });
  }

  /* Capture payment intent. */
  public capturePaymentIntent(paymentIntentId: string): Observable<StrapiPaymentIntent> {
    // Initialize Params Object
    let params = new HttpParams();

    // Begin assigning parameters
    params = params.append('paymentIntentId', paymentIntentId);
    
    return this.http.post<StrapiPaymentIntent>(`${this.config.apiUrl}/stripe/capture-payment-intent`, {}, { params: params });
  }

  /* Returns setup Client Secret. */
  public createSetupIntent(userId: number): Observable<StrapiSetupIntent> {
    // Initialize Params Object
    let params = new HttpParams();

    // Begin assigning parameters
    params = params.append('userId', userId.toString());

    return this.http.post<StrapiSetupIntent>(`${this.config.apiUrl}/stripe/setup-intent`, {}, { params: params });
  }

  /* Payment Methods */
  public queryPaymentMethods(userId?: number): Observable<Array<PaymentMethod>> {
    let params: any = {};
    if (userId) {
      params['userId'] = userId;
    }

    return this.http.get<Array<PaymentMethod>>(`${this.config.apiUrl}/stripe/payment-methods`, { params });
  }

  public getPaymentMethod(paymentMethodId: string) {
    return this.http.get<PaymentMethod>(`${this.config.apiUrl}/stripe/payment-method/${paymentMethodId}`);
  }

  public deletePaymentMethod(paymentMethodId: string): Observable<PaymentMethod> {
    return this.http.delete<PaymentMethod>(`${this.config.apiUrl}/stripe/payment-methods/${paymentMethodId}`);
  }

  public disconnectAccount(courseId: number): Observable<CourseDetails> {
    return this.http.put<CourseDetails>(`${this.config.apiUrl}/stripe/disconnect-account/${courseId}`, {});
  }

}
