<div class="d-flex flex-column h-100" *ngIf="order$ | async as order">
    <nav class="navbar navbar-expand-md navbar-light py-md-0 px-md-0 mb-4" style="flex: 0 0 auto;">
        <button 
            class="navbar-toggler ml-auto" 
            type="button" 
            data-bs-toggle="collapse" 
            data-bs-target="#navbarNavDropdown" 
            (click)="isCollapsed = !isCollapsed" 
            aria-controls="navbarNavDropdown" 
            aria-expanded="false" 
            aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavDropdown" [collapse]="isCollapsed">
            <div class="navbar-nav mr-auto h-100">
                <a class="nav-item border-right nav-link text-dark" routerLinkActive="active font-weight-bold"
                    [routerLink]="['/admin', 'tee-sheet', 'order', 'checkout', order.id, 'refund-cash']">Refund Cash</a>
            </div>
        </div>
    </nav>
    <h5 class="font-weight-bold ml-4">Cash</h5>
    <div class="container-fluid">
        <div class="row">
            <div class="col-4 mb-4 px-4 payment-select-container text-center" *ngFor="let payment of paymentAmounts" >
                <ng-container *ngIf="!payment.isCustom">
                    <button type="button" class="btn btn-default btn-block payment-option fixed-amount w-100 h-100 d-flex justify-content-center align-items-center text-uppercase" 
                        (click)="setPaymentAmount(payment)"
                        [ngClass]="{'active-amount': payment.selected }">
                        {{payment.amount | currency}}
                    </button>
                </ng-container>
                <ng-container *ngIf="payment.isCustom">
                    <button type="button" class="btn btn-default btn-block payment-option fixed-amount w-100 h-100 d-flex justify-content-center align-items-center text-uppercase" 
                        (click)="openCustomAmountModal()"
                        [ngClass]="{'active-amount': payment.selected }">
                        Custom Amount
                    </button>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="d-flex flex-row justify-content-center m-4" *ngIf="anyPaymentSelected()">
        <button class="btn btn-light-green btn-block" (click)="refund(order)">Refund</button>
    </div>
</div>