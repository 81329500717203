<div class="p-4" *ngIf="order$ | async as order">
    <div class="d-flex flex-row justify-content-between">
        <h5 class="font-weight-bold m-0">{{productOrder.product.name | uppercase}}</h5>
        <div class="d-flex flex-row justify-content-between">
            <h5 class="mb-0 font-weight-bold">{{productOrder.extprice | currency}}</h5>
        </div>
    </div>
    <div class="d-flex flex-row justify-content-between">
        <span class="subtitle mt-1" *ngIf="category$ | async as category">{{category.name}}</span>
        <span class="subtitle mt-1">
            <ng-container *ngIf="taxrate && productOrder.product.taxable else noTax">
                {{ (taxrate * (productOrder.unitprice * productOrder.quantity)) | currency }}
            </ng-container>
            <ng-template #noTax>
                {{0 | currency}}
            </ng-template>                                                        
        </span>
    </div>

    <div class="container-fluid py-3">
        <div class="row">
            <div class="col-12 col-sm-4">
                <img [src]="apiUrl + productOrder?.product?.image?.url" [alt]="productOrder?.product?.image?.alternativeText" class="img-fluid" *ngIf="productOrder?.product?.image"/>
                <img src="https://via.placeholder.com/150?text=Product" [alt]="productOrder?.product?.name" class="img-fluid" *ngIf="!productOrder?.product?.image"/>
            </div>
            <div class="col-12 col-sm-8 position-relative">
                <div class="position-absolute" style="bottom: 10px;">
                    <div class="mx-auto p-2">
                        <gcl-lib-counter
                            [min]="1"
                            [bordered]="false"
                            [value]="quantity"
                            (changed)="updateQuantity($event)"></gcl-lib-counter>
                    </div>
                    <div class="d-flex flex-row justify-content-end">
                        <button class="btn btn-sm btn-outline-light-green mr-2" (click)="onRemoveProduct(order.id)">Remove Item</button>
                        <button class="btn btn-sm btn-light-green flex-fill" (click)="onSetQuantity(order.id)">Ok</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
