import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { shareReplay, switchMap, takeUntil } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { UserService } from 'projects/shared/src/lib/services/user.service';
import { UserDetails } from 'projects/shared/src/public-api';
import { PlayerFormComponent } from 'projects/shared/src/lib/components/player-form/player-form.component';

@Component({
  selector: 'gcl-admin-player-edit-modal',
  templateUrl: './player-edit-modal.component.html',
  styleUrls: ['./player-edit-modal.component.scss']
})
export class PlayerEditModalComponent implements OnInit, OnDestroy {

  @ViewChild(PlayerFormComponent) playerFormComponent!: PlayerFormComponent;

  public userId!: number;

  public faChevronLeft = faChevronLeft;

  public submit$: EventEmitter<UserDetails> = new EventEmitter<UserDetails>();
  public cancel$: EventEmitter<void> = new EventEmitter<void>();

  public user$!: Observable<UserDetails>;

  private destory$: Subject<boolean> = new Subject();

  constructor(public bsModalRef: BsModalRef, private toastr: ToastrService, private userService: UserService) { }

  ngOnInit(): void {
    this.user$ = this.userService.get(this.userId)
      .pipe(
        shareReplay(1),
        takeUntil(this.destory$)
      );
  }

  ngOnDestroy() {
    this.bsModalRef.hide();
    
    this.destory$.next(true);
    this.destory$.complete();
  }

  public submit(): void {
    if (this.playerFormComponent.form.valid) {
      this.user$
        .pipe(
          switchMap((user) => this.userService.update(user.id, { ...user, ...this.playerFormComponent.form.value }))
        )
        .subscribe((user: UserDetails) => {
          this.playerFormComponent.form.setErrors({});
          
          this.toastr.success("User updated successfully!");
          this.submit$.emit(user);
          this.bsModalRef.hide();
        }, (err: HttpErrorResponse) => {
          err.error.message.forEach((message: any) => {
            if (message.messages.length > 0) {
              message.messages.forEach((mes: any) => {
                const field = mes.field[0];
                const formField = this.playerFormComponent.form.get(field);
                if (formField) {
                  formField.setErrors({
                    error: mes.message
                  });
                }
              })
            }
          })
        });
    } else {
      Object.values(this.playerFormComponent.form.controls).forEach(control => {
        control.markAsTouched();
        control.markAsDirty();
      });
    }
  }

  public onCancel(): void {
    this.cancel$.emit();
    this.bsModalRef.hide();
  }

}
