import { Component, EventEmitter, OnInit, Output, Input, forwardRef, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subject, Observer, combineLatest } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators';

import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

import { UserService } from '../../services/user.service';
import { UserDetails } from '../../models/user';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'gcl-lib-search-users',
  templateUrl: './search-users.component.html',
  styleUrls: ['./search-users.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SearchUsersComponent),
    multi: true
  }],
})
export class SearchUsersComponent implements OnInit, OnDestroy, ControlValueAccessor {
  public records$!: Observable<Array<UserDetails>>;
  public search: string = "";

  private selectedItem?: UserDetails;
  private propagateChange = (_: any) => { };
  private propagateTouched = (_: any) => { };

  private destroy$: Subject<boolean> = new Subject();

  @Input()
  multiselect: boolean = false;

  @Output()
  addItem: EventEmitter<UserDetails> = new EventEmitter<UserDetails>();

  constructor(private authService: AuthService, private userService: UserService) { }

  ngOnInit(): void {
    this.getPagedResults();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  getPagedResults() {
    const search$ = new Observable((observer: Observer<string>) => {
      observer.next(this.search);
    }).pipe(
      takeUntil(this.destroy$)
    );

    const course$ = this.authService.course$
      .pipe(
        takeUntil(this.destroy$)
      );

    this.records$ = combineLatest([search$, course$]).pipe(
      switchMap(([search, course]) => {
        const pagedResult = this.userService.query({
          take: 10,
          course: course?.id,
          search: search
        });
        return pagedResult.records$;
      }),
      takeUntil(this.destroy$)
    );
  }

  public setRecord(event: TypeaheadMatch) {
    this.selectedItem = event.item;
  }

  public addRecord() {
    if (this.selectedItem && this.multiselect) {
      this.addItem.emit(this.selectedItem);

      // Clears search box
      this.selectedItem = undefined;
      this.search = "";
    }
  }

  public onChange(val: EventTarget | null) {
    if (val) {
      const input = val as HTMLInputElement;
      // On Clear, ngx-bootstrap doesn't handle on clear events.
      if (input && !input.value) {
        this.propagateChange(null);
      }
    }
  }

  writeValue(val?: UserDetails): void {
    this.selectedItem = val;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }
}
