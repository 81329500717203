import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IconDefinition, IconLookup, IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { faCcAmex, faCcDiscover, faCcMastercard, faCcVisa  } from '@fortawesome/free-brands-svg-icons';
import { faCreditCard  } from '@fortawesome/free-solid-svg-icons';
import { PaymentMethod } from '@stripe/stripe-js';

@Component({
  selector: 'gcl-lib-stripe-card',
  templateUrl: './stripe-card.component.html',
  styleUrls: ['./stripe-card.component.css']
})
export class StripeCardComponent implements OnInit {

  @Input()
  paymentMethod!: PaymentMethod;

  @Input()
  selected: boolean = false;

  @Output()
  select: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  public onClick(): void {
    this.select.emit();
  }

  public truncateName(name: string | null): string | null {
    if (name && name.length > 20) {
      return name.substring(0, 20) + "...";
    }
    return name;
  }

  public getBrandIcon(brand: string | undefined): IconDefinition | [IconPrefix, IconName] {
    switch (brand) {
      case "visa":
        return faCcVisa;
      case "mastercard":
        return faCcMastercard;
      case "amex":
        return faCcAmex;
      case "discover":
        return faCcDiscover;
      default:
        return faCreditCard;
    }
  }
}
