import { OnInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CourseDetails } from '../../models/course';
import { DropzoneComponent } from '../dropzone/dropzone.component';

@Component({
  selector: 'gcl-lib-add-files-modal',
  templateUrl: './add-files-modal.component.html',
  styleUrls: ['./add-files-modal.component.css']
})
export class AddFilesModalComponent implements OnInit {
  @Input() course?: CourseDetails;

  @Output() success: EventEmitter<void> = new EventEmitter<void>();
  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

  public uploadFileForm = this.fb.group({
    file: ['', [Validators.required]]
  });

  @ViewChild(DropzoneComponent) dropzone?: DropzoneComponent;

  constructor(private toastr: ToastrService, private fb: FormBuilder) { }

  ngOnInit(): void {
  }

  async uploadAsync() {
    await this.dropzone?.uploadFilesAsync();
  }

  closeModal() {
    this.uploadFileForm.reset();
    this.close.emit();
  }

  uploadDone() {
    this.success.emit();
  }

  uploadError(error: string) {
    this.toastr.error(`${error}`, 'Error');
    this.close.emit();
  }

}

