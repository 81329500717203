import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import { OnPageEvent, UserDetails } from 'projects/shared/src/public-api';
import { SortDirections } from 'projects/shared/src/lib/enumerations/sort-directions';
import { UserService } from 'projects/shared/src/lib/services/user.service';

@Component({
  selector: 'gcl-admin-player-select-modal',
  templateUrl: './player-select-modal.component.html',
  styleUrls: ['./player-select-modal.component.scss']
})
export class PlayerSelectModalComponent implements OnInit, OnDestroy {
  public faChevronLeft = faChevronLeft;

  @Output()
  select$: EventEmitter<UserDetails | null> = new EventEmitter<UserDetails | null>();
  @Output()
  openCreateModal$: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  openEditModal$: EventEmitter<UserDetails> = new EventEmitter<UserDetails>();

  pagination$ = new BehaviorSubject<OnPageEvent>({
    itemsPerPage: 10,
    page: 1,
    sortBy: 'firstName',
    sortDescending: false
  });
  total$: BehaviorSubject<number> = new BehaviorSubject(1);
  records$: ReplaySubject<UserDetails[]> = new ReplaySubject(1);

  selectedUser!: UserDetails | null;
  previousUser!: UserDetails | null;

  private destory$: Subject<boolean> = new Subject();

  constructor(private userService: UserService) { }

  ngOnInit(): void {
    this.pagination$
      .pipe(
        takeUntil(this.destory$)
      )
      .subscribe(() => {
        this.getPageResult();
      });
  }

  ngOnDestroy() {
    this.destory$.next(true);
    this.destory$.complete();
  }

  private getPageResult() {
    const pagedResult = this.userService.query({
      skip: (this.pagination$.value.page - 1) * this.pagination$.value.itemsPerPage,
      take: this.pagination$.value.itemsPerPage,
      sortColumns: [{
        column: this.pagination$.value.sortBy,
        direction: this.pagination$.value.sortDescending ? SortDirections.Descending : SortDirections.Ascending,
      }],
      search: this.pagination$.value.search,
      roles: ["player"]
    });

    pagedResult.filteredCount$.subscribe((total) => this.total$.next(total));
    pagedResult.records$.subscribe((records) => this.records$.next(records));
  }

  public onSelect(user: UserDetails): void {
    this.selectedUser = (this.selectedUser?.id !== user.id) ? user : null;
  }

  public selectUser(): void {
    this.select$.emit(this.selectedUser);
  }

  public cancel(): void {
    this.select$.emit(this.previousUser);
  }

  public openEditModal(user: UserDetails): void {
    this.openEditModal$.emit(user);
  }

  public openCreateModal(): void {
    this.openCreateModal$.emit();
  }

}
