

export function calculateSalesTotals(groupedByCategoryName: any) {
    const aggregatePropertyNames = ["quantity", "unitprice", "tax", "taxrate", "ntsq", "ntsr", "totalRevenue"];
    const categoryNameTotalInfoMap: any = {};
    Object.keys(groupedByCategoryName).forEach(key => {
        const records = groupedByCategoryName[key];
        const aggregateRecord: any = {};
        aggregatePropertyNames.forEach(name => {
            let total = 0;
            if (name == "unitprice") {
                total = records.reduce((accumulator: number, record: any) => {
                    return accumulator + calculateRevenue(record.unitprice, record.cost, record.quantity);
                }, 0);
            } else if (name == "totalRevenue") {
                total = records.reduce((accumulator: number, record: any) => {
                    return accumulator + calculateTotalRevenue(record.unitprice, record.cost, record.quantity, record.tax);
                }, 0);
            } else {
                total = records.reduce((accumulator: number, object: any) => {
                    return object[name] ? object[name] + accumulator : accumulator;
                }, 0);
            }
            aggregateRecord[name] = total ? total : 0;
        });
        categoryNameTotalInfoMap[key] = aggregateRecord;
    });
    return categoryNameTotalInfoMap;
}

function calculateTotalRevenue(unitprice: number, cost: number, quantity: number, tax: number) {
    let qty = (((unitprice - cost) * quantity) - tax)
    return qty ? qty : 0;
}

function calculateRevenue(unitprice: number, cost: number, quantity: number) {
    let qty = (unitprice - cost) * quantity;
    return qty ? qty : 0;
}


export function calculateInventoryTotals(groupedByCategoryName: any) {
    const aggregatePropertyNames = ['cost', 'onHand', 'cost', 'price', 'price'];
    const categoryNameTotalInfoMap: any = {};
    Object.keys(groupedByCategoryName).forEach(key => {
        const records = groupedByCategoryName[key];
        const aggregateRecord: any = {};
        aggregatePropertyNames.forEach(name => {
            let total = records.reduce((accumulator: number, object: any) => {
                return accumulator + object[name];
            }, 0);
            aggregateRecord[name] = total ? total : 0;
        });
        categoryNameTotalInfoMap[key] = aggregateRecord;
    });
    return categoryNameTotalInfoMap;
}


export function calculateEventAgingTotals(records: any) {
    let finalTotalAmount = records.reduce((accumulator: number, object: any) => {
        return accumulator + object.finalTotalAmount;
    }, 0);
    return finalTotalAmount;
}

export function calculateRoundsPlayedTotals(records: any) {
    let totalAmount = records.reduce((accumulator: number, object: any) => {
        return accumulator + (object.totalAmount || 0);
    }, 0);
    let totalCost = records.reduce((accumulator: number, object: any) => {
        return accumulator + (object.totalCost || 0);
    }, 0);
    let totalRoundsPlayed = records.reduce((accumulator: number, object: any) => {
        return accumulator + (object.totalRoundsPlayed ? Number(object.totalRoundsPlayed) : 0);
    }, 0);
    return { totalRoundsPlayed, totalAmount, totalCost };
}

export function calculateMemberAgingTotals(records: any) {
    let currentAmount = records.reduce((accumulator: number, object: any) => {
        return accumulator + (object.currentAmount || 0);
    }, 0);
    let amount = records.reduce((accumulator: number, object: any) => {
        return accumulator + (object.amount || 0);
    }, 0);
    return { currentAmount, amount };
}