import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormBuilder, Validators, FormArray, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, of } from 'rxjs';
import { map, takeUntil, tap, shareReplay, switchMap } from 'rxjs/operators';

import { faChevronLeft, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';

import { environment } from 'projects/admin/src/environments/environment';
import { ScheduleDetails } from 'projects/shared/src/lib/models/schedule';
import { ScheduleService } from 'projects/shared/src/lib/services/schedule.service';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { GolfProduct, GolfProductDetails } from 'projects/shared/src/lib/models/golfproduct';
import { NineTypes } from 'projects/shared/src/lib/enumerations/nine-type';
import { ScheduleTypes } from 'projects/shared/src/lib/enumerations/schedule-types';
import { GolfPassDetails } from 'projects/shared/src/lib/models/golfpass';
import { GolfPassService } from 'projects/shared/src/lib/services/golf-pass.service';
import { RequiredFieldValidators } from 'projects/shared/src/lib/validators/required-field-validators';
import { GolfProductService } from 'projects/shared/src/lib/services/golf-product.service';

@Component({
  selector: 'gcl-admin-schedule-create',
  templateUrl: './schedule-create.component.html',
  styleUrls: ['./schedule-create.component.scss']
})
export class ScheduleCreateComponent implements OnInit, OnDestroy {
  public apiUrl = environment.apiUrl;
  public faTrash = faTrash;
  public faChevronLeft = faChevronLeft;
  public nineTypes = NineTypes;
  public ScheduleTypes = ScheduleTypes;

  get course() { return this.form.get("course") as FormControl }
  get name() { return this.form.get("name") as FormControl }
  get active() { return this.form.get("active") as FormControl }
  get starttod() { return this.form.get("starttod") as FormControl }
  get endtod() { return this.form.get("endtod") as FormControl }
  get first9() { return this.form.get("first9") as FormControl }
  get second9() { return this.form.get("second9") as FormControl }
  get monday() { return this.form.get("monday") as FormControl }
  get tuesday() { return this.form.get("tuesday") as FormControl }
  get wednesday() { return this.form.get("wednesday") as FormControl }
  get thursday() { return this.form.get("thursday") as FormControl }
  get friday() { return this.form.get("friday") as FormControl }
  get saturday() { return this.form.get("saturday") as FormControl }
  get sunday() { return this.form.get("sunday") as FormControl }
  get description() { return this.form.get("description") as FormControl }
  get golfproduct() { return this.form.get("golfproduct") as FormControl }
  get type() { return this.form.get("type") as FormControl }
  get golfPass() { return this.form.get("golfPass") as FormControl }
  get golfPassDiscountAmount() { return this.form.get("golfPassDiscountAmount") as FormControl }
  get golfPassDiscountPercent() { return this.form.get("golfPassDiscountPercent") as FormControl }

  public form!: FormGroup;

  public scheduleId$!: Observable<number | undefined>;
  public schedule$!: Observable<ScheduleDetails | undefined>;
  public golfpasses$!: Observable<GolfPassDetails[]>;
  public golfproducts$!: Observable<GolfProductDetails[]>;

  private destroyed$ = new Subject<boolean>();

  constructor(
    private fb: FormBuilder,
    private scheduleService: ScheduleService,
    private authService: AuthService,
    public route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private golfpassService: GolfPassService,
    private golfproductService: GolfProductService
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      course: [null, [Validators.required]],
      name: [null, [Validators.required]],
      active: [false, [Validators.required]],
      starttod: [null, [Validators.required]],
      endtod: [null, [Validators.required]],
      first9: [NineTypes.Front, [Validators.required]],
      second9: [NineTypes.Back, []],
      monday: [false, [Validators.required]],
      tuesday: [false, [Validators.required]],
      wednesday: [false, [Validators.required]],
      thursday: [false, [Validators.required]],
      friday: [false, [Validators.required]],
      saturday: [false, [Validators.required]],
      sunday: [false, [Validators.required]],
      description: [null, []],
      golfproduct: [null, [Validators.required]],
      type: [ScheduleTypes.General, [Validators.required]],
      golfPass: [null, [RequiredFieldValidators.RequiredIf(() => (this.type?.value == ScheduleTypes.GolfPass))]],
      golfPassDiscountAmount: [null, [RequiredFieldValidators.RequiredIf(() => (this.type?.value == ScheduleTypes.GolfPass && this.golfPassDiscountPercent == undefined))]],
      golfPassDiscountPercent: [null, [RequiredFieldValidators.RequiredIf(() => (this.type?.value == ScheduleTypes.GolfPass && this.golfPassDiscountAmount == undefined))]]
    });

    this.scheduleId$ = this.route.params.pipe(
      map(params => params.scheduleId)
    );

    this.golfpasses$ = this.authService.course$
      .pipe(
        tap(course => this.form.patchValue({
          course: course.id,
        })),
        switchMap(course => this.golfpassService.query({
          //course: course.id
        }).records$),
        shareReplay(1),
        takeUntil(this.destroyed$)
      );

    this.golfproducts$ = this.authService.course$
      .pipe(
        switchMap(course => this.golfproductService.query({
          course: course.id
        }).records$),
        shareReplay(1),
        takeUntil(this.destroyed$)
      );

    this.schedule$ = this.scheduleId$
      .pipe(
        switchMap(scheduleId => (scheduleId != undefined) ? this.scheduleService.get(scheduleId) : of(undefined)),
        shareReplay(1),
        takeUntil(this.destroyed$)
      );

    this.schedule$
      .subscribe(schedule => {
        // Cloning Schedule.
        if (schedule != undefined) {
          this.form.patchValue({
            name: schedule.name,
            active: schedule.active,
            starttod: schedule.starttod,
            endtod: schedule.endtod,
            first9: schedule.first9,
            second9: schedule.second9,
            monday: schedule.monday,
            tuesday: schedule.tuesday,
            wednesday: schedule.wednesday,
            thursday: schedule.thursday,
            friday: schedule.friday,
            saturday: schedule.saturday,
            sunday: schedule.sunday,
            description: schedule.description,
            type: schedule.type || ScheduleTypes.General,
            golfPass: schedule.golfPass?.id || null,
            golfPassDiscountAmount: schedule.golfPassDiscountAmount,
            golfPassDiscountPercent: schedule.golfPassDiscountPercent
          });
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public submit(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.scheduleService.create(this.form.value)
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe((schedule: ScheduleDetails) => {
        this.form.setErrors({});

        this.toastr.success("Schedule created successfully!");
        this.router.navigate(["/admin", "schedules", schedule.id, "view"]);
      }, err => {
        this.toastr.error("An error has occurred. Please try again.")
      });
  }
}
