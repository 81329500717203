import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { OnPageEvent } from 'projects/shared/src/public-api';
import { BehaviorSubject, merge, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { GolfPassService } from 'projects/shared/src/lib/services/golf-pass.service';
import { SortDirections } from 'projects/shared/src/lib/enumerations/sort-directions';
import { GolfPassQuery } from 'projects/shared/src/lib/queries/golfpass-query';
import { GolfPassDetails } from 'projects/shared/src/lib/models/golfpass';
import { UserService } from 'projects/shared/src/lib/services/user.service';
import { ConfirmationModalService } from 'projects/shared/src/lib/services/confirmation-modal.service';

@Component({
  selector: 'gcl-admin-user-golf-pass-table',
  templateUrl: './user-golf-pass-table.component.html',
  styleUrls: ['./user-golf-pass-table.component.scss']
})
export class UserGolfPassTableComponent implements OnInit, OnDestroy {
  @Input('userId') userId?: number;
  @Input('enableAdd') enableAdd?: boolean = true;
  @Input('enableRemove') enableRemove?: boolean = true;

  total$: BehaviorSubject<number> = new BehaviorSubject(1);
  records$!: Observable<GolfPassDetails[]>;

  private _userUpdated = new Subject<void>();
  
  @Output()
  onAdd = new EventEmitter<void>();

  @Output()
  onRemove = new EventEmitter<GolfPassDetails>();

  private destory$: Subject<boolean> = new Subject();

  constructor(
    private router: Router, 
    private userService: UserService, 
    private golfPassService: GolfPassService,
    private confirmationModalService: ConfirmationModalService
  ) { }

  ngOnInit(): void {
    if(this.userId) {
      let refreshUser$ = this._userUpdated.asObservable().pipe(switchMap(() => this.userService.get(this.userId ?? 0).pipe(
        takeUntil(this.destory$),
        switchMap(user => {
          if(user && user?.golfPasses && user?.golfPasses.length > 0) {
            return this.golfPassService.query({id_in: user.golfPasses.map(g => g.id)}).records$.pipe(takeUntil(this.destory$));
          }
          else {
            return of([]);
          }
        })
      )))
      
      let getUser$ = this.userService.get(this.userId).pipe(
        takeUntil(this.destory$),
        switchMap(user => {
          if(user && user?.golfPasses && user?.golfPasses.length > 0) {
            return this.golfPassService.query({id_in: user.golfPasses.map(g => g.id)}).records$.pipe(takeUntil(this.destory$));
          }
          else {
            return of([]);
          }
        })
      );

      this.records$ = merge(getUser$, refreshUser$);
    }
    else {
      this.records$ = of([]);
    }
  }

  ngOnDestroy() {
    this.destory$.next(true);
    this.destory$.complete();
  }

  onAddClick() {
    this.onAdd.emit();
  }

  async onRemoveClick(pass: GolfPassDetails) {
    this.onRemove.emit(pass);
  }

  userUpdated() {
    this._userUpdated.next();
  }

}
