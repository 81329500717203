import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import { combineLatest, merge, Observable, of, Subject } from 'rxjs';
import { map, shareReplay, switchMap, take, takeUntil } from 'rxjs/operators';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';

import { UserService } from 'projects/shared/src/lib/services/user.service';
import { GolfPassDetails, UserDetails } from 'projects/shared/src/public-api';
import { ConfirmationModalService } from 'projects/shared/src/lib/services/confirmation-modal.service';
import { PlayerFormComponent } from 'projects/shared/src/lib/components/player-form/player-form.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { GolfPassSelectModalComponent } from '../../../shared/components/golf-pass-select-modal/golf-pass-select-modal.component';
import { UserGolfPassTableComponent } from '../../../shared/components/user-golf-pass-table/user-golf-pass-table.component';
import { StripeCreateCardComponent } from 'projects/shared/src/lib/stripe/stripe-create-card/stripe-create-card.component';
import { StripeInstance } from 'ngx-stripe';
import { StripeService } from 'projects/shared/src/lib/services/stripe.service';
import { StripePaymentToken } from 'projects/shared/src/lib/models/stripe-payment-token';
import { RedeemGiftCardModalComponent } from '../../../shared/components/redeem-gift-card-modal/redeem-gift-card-modal.component';
import { GiftCertificateService } from 'projects/shared/src/lib/services/gift-certificate.service';

@Component({
  selector: 'gcl-admin-player-edit',
  templateUrl: './player-edit.component.html',
  styleUrls: ['./player-edit.component.scss']
})
export class PlayerEditComponent implements OnInit, OnDestroy {

  @ViewChild(PlayerFormComponent) playerFormComponent!: PlayerFormComponent;
  @ViewChild(UserGolfPassTableComponent) userGolfPassTableComponent!: UserGolfPassTableComponent;

  public stripe!: StripeInstance;

  public faChevronLeft = faChevronLeft;
  public userId$!: Observable<number>;
  public user$!: Observable<UserDetails>;

  private _userUpdated$ = new Subject<void>();

  private destroyed$ = new Subject();

  constructor(
    private userService: UserService, 
    public route: ActivatedRoute, 
    private confirmationModalService: ConfirmationModalService, 
    private router: Router, 
    private toastr: ToastrService,
    private modalService: BsModalService,
    private stripeService: StripeService,
    private toastrService: ToastrService,
    private giftCertificateService: GiftCertificateService
  ) { }

  ngOnInit(): void {
    this.stripe = this.stripeService.createInstance();

    this.userId$ = this.route.params.pipe(
      map(params => params.playerId)
    );

    let userUpdated$ = combineLatest([this.userId$, this._userUpdated$.asObservable()]).pipe(
      switchMap(([id]) => this.userService.get(id)),
      takeUntil(this.destroyed$)
    );

    let getUser$ = this.userId$.pipe(
      switchMap(userId => this.userService.get(userId)),
      takeUntil(this.destroyed$),
    );

    this.user$ = merge(userUpdated$, getUser$);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public openCancelModal(user: UserDetails): void {
    this.confirmationModalService.showConfirmationModal({
      title: 'Confirm Cancel',
      message: 'Are you sure you want to discard changes and go back?'
    }).subscribe(confirm => {
      if (confirm === true) {
        this.router.navigate(['/admin', 'players', user.id, 'view']);
      }
    })
  }

  public openSaveModal(): void {
    this.confirmationModalService.showConfirmationModal({
      title: 'Confirm Save',
      message: 'Do you want to save these changes?'
    }).subscribe(confirm => {
      if (confirm === true) {
        this.submit();
      }
    })
  }

  private submit(): void {
    if (this.playerFormComponent.form.valid) {
      this.playerFormComponent.form.patchValue({
        username: this.playerFormComponent.username.value || this.playerFormComponent.email.value,
      });

      this.user$.pipe(
        switchMap(user => this.userService.update(user.id, { ...user, ...this.playerFormComponent.form.value })),
        takeUntil(this.destroyed$)
      ).subscribe((user: UserDetails) => {
        this.playerFormComponent.form.setErrors({});

        this.toastr.success("User updated successfully!");
        this.router.navigate(["/admin", "players", user.id, "view"]);
      }, (err: HttpErrorResponse) => {
        err.error.message.forEach((message: any) => {
          if (message.messages.length > 0) {
            message.messages.forEach((mes: any) => {
              const field = mes.field[0];
              const formField = this.playerFormComponent.form.get(field);
              if (formField) {
                formField.setErrors({
                  error: mes.message
                });
              }
            })
          }
        })
      });
    } else {
      Object.values(this.playerFormComponent.form.controls).forEach(control => {
        control.markAsTouched();
        control.markAsDirty();
      });
    }
  }

  async openSelectGolfPassModal() {
    let user = await this.user$.pipe(take(1)).toPromise();

    let selectModalRef = this.modalService.show(GolfPassSelectModalComponent,{
      ignoreBackdropClick: true,
      class: 'modal-lg modal-dialog-centered',
      initialState: {
        id_nin: user.golfPasses.map(g => g.id)
      }
    });

    if(selectModalRef.content?.select$) {
      let selectSubsription = selectModalRef.content.select$.subscribe(selected => {
        if(selected) {
          this.user$.pipe(
            take(1),
            switchMap(user => { 
              if(!user?.golfPasses?.some(g => g.id === selected.id)) {
                return this.userService.update(user.id, {golfPasses: [...user.golfPasses.map(g => g.id), selected?.id]}).pipe(take(1))
              }
              else {
                return of(user);
              }
          })).subscribe(
            user => {
              this.userGolfPassTableComponent?.userUpdated();
              this._userUpdated$.next();
              selectModalRef.hide();
            },
            err => {
              console.error(err);
              selectModalRef.hide();
            }
          )
        }
      })
    }
  }

  async onRemoveGolfPass(pass: GolfPassDetails) {
    try {
      let user = await this.user$.pipe(take(1)).toPromise();

      let deletePass = await this.confirmationModalService.showConfirmationModal({
        message: `Are you sure you want to remove ${pass.name} from ${user.email}'s golf passes?`,
        title: 'Remove Golf Pass?'
      }).toPromise();

      if(deletePass === true) {
        await this.userService.update(user.id, {golfPasses: user.golfPasses.filter(gp => gp.id !== pass.id)}).pipe(take(1)).toPromise();
        this._userUpdated$.next();
        this.userGolfPassTableComponent?.userUpdated();
      }
    }
    catch(e) {
      console.error(e);
    }
  }

  async onAddPaymentClicked() {
    let user = await this.user$.pipe(take(1)).toPromise();

    let paymentModalRef = this.modalService.show(StripeCreateCardComponent, {
      class: 'modal-lg modal-dialog-centered',
      ignoreBackdropClick: true,
      initialState: {
        stripe: this.stripe,
        userId: user.id,
        forceSave: true
      }
    });

    paymentModalRef?.content?.cancel
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(() => paymentModalRef.hide());

    paymentModalRef?.content?.submit
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe((payment: StripePaymentToken) => {
        if (!payment.error) {
          if (payment.save) {
            this.toastrService.success("Payment Added");
            this.stripeService.reloadPaymentList$.next(user.id as number);
          }
          paymentModalRef.hide();

        } else {
          this.toastrService.error(payment.error);
        }
      });
  }

  async onRedeemGiftCertificateClicked() {
    let user = await this.user$.pipe(take(1)).toPromise();

    let redeemModalRef = this.modalService.show(RedeemGiftCardModalComponent, {
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered'
    });

    redeemModalRef?.content?.redemptionCode.subscribe(code => {
      if(code) {
        this.giftCertificateService.adminDebit(user.id, code).pipe(take(1)).subscribe(
          (giftCertificate) => {
            if(giftCertificate) {
              this._userUpdated$.next();
              this.stripeService.reloadPaymentList$.next(user.id as number);
              redeemModalRef.hide();
            }
          },
          (e) => {
            console.error(e);
            this.toastrService.error('An error ocurred while redeeming the gift certificate');
          }
        );
      }
    })
  }
}
