export enum TeeTimeStatus {
    Booked = "Booked",
    Reserved = "Reserved",
    Blocked = "Blocked"
}

export function GetTeeTimeDisplayName(status: TeeTimeStatus): string {
    switch (status) {
        case TeeTimeStatus.Booked:
            return "Booked";
        case TeeTimeStatus.Reserved:
            return "Reserved";
        case TeeTimeStatus.Blocked:
            return "Blocked";
        default:
            return "N/A";
    }
}