<div class="position-relative">
    <div class="sticky-header" *ngIf="selectedDate$ | async as selectedDate">
        <gcl-lib-date-picker-slider
            [date]="selectedDate"
            [format]="'MMMM YYYY'"
            [incrementBy]="'month'"
            (dateSelect)="onSelectDate($event)"></gcl-lib-date-picker-slider>
        <h5 class="text-center font-weight-bold">{{selectedDate | date: "fullDate"}}</h5>
    </div>

    <h6  class="page-header position-sticky">Tee Sheet</h6>
    <div class="d-flex justify-content-end align-items-center mb-1">
        <div class="input-group w-50">
            <input class="form-control bg-white" placeholder="Search golfer name and/or golf product" disabled [(ngModel)]="displayedUserName">
        </div>
    </div>
    <ng-container *ngIf="events$ | async as events">
        <table class="table table-bordered table-sm tee-sheet-table mb-0" *ngIf="events.length > 0">
            <tbody>
                <tr class="event-row cursor-pointer" *ngFor="let event of events" [routerLink]="['/admin', 'events', event.id, 'view']">
                    <th class="time-cell">
                        <span 
                            class="time-column" 
                            [class.unpaid-row]="event.order.status !== 'Paid'"
                        >
                        </span>
                        <span>{{event.start | date: 'shortTime'}}</span>
                    </th>
                    <td>
                        <span>{{event?.event_space?.name}}</span>
                    </td>
                    <td class="event-wide-cell" 
                        [class.user-reserved]="event?.order?.status === 'Paid'"
                        [class.unpaid]="event?.order?.status !== 'Paid'"
                    >
                        <div class="row align-items-center mx-0">
                            <div class="col-3 pl-0">
                                <ng-container *ngIf="event?.order?.users_permissions_user">{{getEventUser($any(event?.order?.users_permissions_user)) | async}}</ng-container>
                                <ng-container *ngIf="event.type"> | {{event?.type}}</ng-container>
                            </div>
                            <div class="col-9" class="event-description">{{event?.description}}</div>
    
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </ng-container>
    <div class="">
        <table class="table table-bordered table-sm tee-sheet-table" *ngIf="course$ | async as course" gclLibDisableContextMenu>
            <thead class="thead-light border">
                <tr class="text-center">
                    <th width="100" colspan="1" scope="col">Round</th>
                    <th [attr.colspan]="slots" scope="col">{{course.Front9}}</th>
                    <th [attr.colspan]="slots" scope="col">{{course.Back9}}</th>
                    <th [attr.colspan]="slots" scope="col" *ngIf="course.Third9">{{course.Third9}}</th>
                </tr>
            </thead>
            <tbody class="text-center" 
                (mouseup)="onRowMouseUp()"
                (mouseleave)="onMouseUp()">
                <ng-container *ngFor="let time of rows | keyvalue; let timeIndex = index;">
                    <tr>
                        <th scope="row" 
                            (mousedown)="onRowMouseDown(time.key)"
                            (mousemove)="onRowMouseMove(time.key)"
                            (mouseup)="onRowMouseUp()">
                            <span class="time-column" [class.unpaid-row]="timeHasUnpaid(time.key)"></span>
                            <span>{{time.key | date: "shortTime"}}</span>
                        </th>
    
                        <!-- Front Row -->
                        <ng-container [ngTemplateOutlet]="nineColumnTemplate"
                            [ngTemplateOutletContext]="{
                                nine: NineTypes.Front,
                                time: time,
                                timeIndex: timeIndex
                            }"></ng-container>
                    
                        <ng-container *ngIf="course$ | async as course">
                            <!-- Back Row -->
                            <ng-container *ngIf="course.Back9">
                                <ng-container [ngTemplateOutlet]="nineColumnTemplate"
                                    [ngTemplateOutletContext]="{
                                        nine: NineTypes.Back,
                                        time: time,
                                        timeIndex: timeIndex
                                    }"></ng-container>
                            </ng-container>
    
                            <!-- Tertiary Row -->
                            <ng-container *ngIf="course.Third9">
                                <ng-container [ngTemplateOutlet]="nineColumnTemplate"
                                    [ngTemplateOutletContext]="{
                                        nine: NineTypes.Tertiary,
                                        time: time,
                                        timeIndex: timeIndex
                                    }"></ng-container>
                            </ng-container>
                        </ng-container>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>

<!-- Templates -->
<ng-template #nineColumnTemplate 
    let-time="time"
    let-nine="nine"
    let-timeIndex="timeIndex">

    <!-- Squeeze time template -->
    <ng-container *ngIf="getRowCount(time.value[nine]) > 1">
        <td class="not-selectable p-0 m-0 border-right" [attr.colspan]="slots">
            <table class="table table-sm m-0 table table-bordered table-sm">
                <ng-container *ngFor="let r of [].constructor(getRowCount(time.value[nine])); let row = index">
                    <tr>
                        <ng-container [ngTemplateOutlet]="columnRowTemplate"
                            [ngTemplateOutletContext]="{
                                nine: nine,
                                time: time,
                                row: row,
                                timeIndex: timeIndex
                            }"></ng-container>
                    </tr>
                </ng-container>
            </table>
        </td>
    </ng-container>

    <ng-container *ngIf="getRowCount(time.value[nine]) == 1">
        <ng-container [ngTemplateOutlet]="columnRowTemplate"
            [ngTemplateOutletContext]="{
                nine: nine,
                time: time,
                row: 0,
                timeIndex: timeIndex
            }"></ng-container>
    </ng-container>
</ng-template>

<ng-template #columnRowTemplate 
    let-nine="nine" 
    let-time="time" 
    let-row="row"
    let-timeIndex="timeIndex">
    <ng-container *ngFor="let c of [].constructor(slots); let slot = index">
        <ng-container *ngIf="time.value[nine][row][slot] as cell">
            <ng-container [ngTemplateOutlet]="cellTemplate"
                [ngTemplateOutletContext]="{
                    nine: nine,
                    cell: cell,
                    slot: slot,
                    row: row,
                    time: time,
                    timeIndex: timeIndex
                }"></ng-container>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #cellTemplate 
    let-nine="nine" 
    let-time="time" 
    let-row="row" 
    let-slot="slot" 
    let-cell="cell"
    let-timeIndex="timeIndex">
    <td class="selectable" 
        [class.next-reservation]="cell?.userId && cell?.nextReservation"
        [class.user-reserved]="cell?.orderId && !cell?.isGuest"
        [class.selected]="cell?.selected"
        [class.unpaid]="cell?.userId && !cell?.paid && !cell?.isGuest"
        [class.bg-light]="cell?.userId && !cell?.turnTime"
        [class.turn-time]="cell?.turnTime"
        [class.blocked]="cell?.blocked"
        (mousedown)="onCellMouseDown($event, nine, time.key, slot, cell?.selected, row, timeIndex)"
        (mouseenter)="onCellMouseEnter(nine, time.key, slot, row, timeIndex)"
        (mouseup)="onCellMouseUp()">
        <ng-container *ngIf="cell?.orderId">
            <small *ngIf="cell?.turnTime">Potential Turn Time | </small>
            <small *ngIf="!cell?.isGuest">{{cell?.userName}}</small>
            <small *ngIf="!cell?.isGuest && !cell?.turnTime"> | {{cell?.golfProductName}}</small>
            <small *ngIf="cell?.isGuest">Guest</small>
        </ng-container>
        <ng-container *ngIf="cell?.blocked">
            <small>{{cell?.displayBlockedReason}}</small>
        </ng-container>
    </td>
</ng-template>