<div class="p-4 bg-grey">
    <div class="d-flex">
        <div class="mr-4">
            <div class="mb-1 font-weight-bold">Report Type</div>
            <select [(ngModel)]="reportOutputType"  name="reportOutputType" class="report-type-select form-control">
                <option [value]="ReportOutputType.Consolidated">Consolidated</option>
                <option [value]="ReportOutputType.Gratuity">Gratuity</option>
                <option [value]="ReportOutputType.EodDrawerCount">EOD Drawer Count</option>
            </select>
        </div>
        <div class="mr-4" *ngIf="beginDate$ | async as beginDate">
            <div class="mb-1 font-weight-bold">Start Date</div>
            <gcl-lib-date-picker class="mr-4" [placeholder]="'Start Date'" [date]="beginDate" (selectDate)="beginDateChanged($event)"></gcl-lib-date-picker>
        </div>
        <div *ngIf="endDate$ | async as endDate">
            <div class="mb-1 font-weight-bold">End Date</div>
            <gcl-lib-date-picker [placeholder]="'End Date'" [date]="endDate" (selectDate)="endDateChanged($event)"></gcl-lib-date-picker>
        </div>
    </div>

    <ng-container *ngIf="reportOutputType === ReportOutputType.Gratuity">
        <hr/>
        <div class="mr-4">
            <div class="mb-1 font-weight-bold">Employee</div>
            <select class="report-type-select form-control" (change)="employeeChanged($event)">
                <option [value]="null">Select...</option>
                <option [value]="user.id" *ngFor="let user of users$ | async">{{user?.firstName}} {{user?.lastName}}</option>
            </select>
        </div>
    </ng-container>
</div>
<div class="container-fluid py-2 fill-flex">
    <div class="row">
        <div class="col-12">       
            <h6 class="page-header">Report Outputs</h6>
            <gcl-lib-table 
                [totalItems]="consolidatedTotal$ | async" 
                [sortBy]="'id'" 
                (onPage)="consolidatedPagination$.next($event)" 
                [tableClass]="'table table-striped table-hover'" 
                [enableCreate]="enableCreate"
                [createLabel]="'generate pdf'"
                (onCreate)="generateReport()"
                [showSearch]="false"
                [style.display]="reportOutputType === ReportOutputType.Consolidated ? 'block' : 'none'"
            >
            <thead>
                <tr>
                    <th columnSort="date">TIMESTAMP</th>
                    <th columnSort="debitsTotal">DEBITS TOTAL</th>
                    <th columnSort="creditsTotal">CREDITS TOTAL</th>
                    <th columnSort="cashDepositsTotal">CASH DEPOSITS</th>
                    <th columnSort="creditCardDepositsTotal">CREDIT CARD DEPOSITS</th>
                    <th columnSort="refundsTotal">REFUNDS</th>
                    <!-- <th columnSort="prepaids">PREPAIDS</th> -->
                    <!-- <th columnSort="voids">VOIDS</th> -->
                    <!-- <th columnSort="returns">RETURNS</th> -->

                    <th columnSort="discountsTotal">DISCOUNTS</th>
                    <!-- <th columnSort="comps">COMPS</th> -->
                    <th width="200" class="text-center">MANAGE</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let record of consolidatedRecords$ | async">
                    <td>{{record.date | date: 'mediumDate'}}</td>
                    <td>{{record.debitsTotal | currency}}</td>
                    <td>{{record.creditsTotal | currency}}</td>
                    <td>{{record.cashDepositsTotal | currency}}</td>
                    <td>{{record.creditCardDepositsTotal | currency}}</td>
                    <td>{{record.refundsTotal | currency}}</td>
                    <td>{{record.discountsTotal | currency}}</td>
                    <!-- <td>voids</td>
                    <td>returns</td>
                    <td>discounts</td>
                    <td>comps</td> -->
                    <td class="text-center">
                        <div class="btn-group btn-group-sm">
                            <a class="btn btn-link btn-primary-light" (click)="generateReportForRow(record)">GENERATE PDF</a>
                        </div>
                    </td>
                </tr>
                <tr *ngIf="(consolidatedTotal$ | async) == 0">
                    <td colspan="10">No consolidated report data found.</td>
                </tr>
            </tbody>
            </gcl-lib-table>
            <gcl-lib-table 
                [totalItems]="gratuityTotal$ | async" 
                [sortBy]="'id'" 
                (onPage)="gratuityPagination$.next($event)" 
                [tableClass]="'table table-striped table-hover'" 
                [enableCreate]="enableCreate"
                [createLabel]="'generate pdf'"
                (onCreate)="generateGratuityReport()"
                [showSearch]="false"
                [style.display]="reportOutputType === ReportOutputType.Gratuity ? 'block' : 'none'"
            >
            <thead>
                <tr>
                    <th columnSort="date">TIMESTAMP</th>
                    <th columnSort="firstName">FIRST NAME</th>
                    <th columnSort="lastName">LAST NAME</th>
                    <th columnSort="amount">TIP AMOUNT</th>
                    <th columnSort="orderId">ORDER NUMBER</th>
                    <th width="200" class="text-center">MANAGE</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let record of gratuityRecords$ | async">
                    <td>{{record.date | date: 'mediumDate'}}</td>
                    <td>{{record.firstName ? record.firstName : 'N/A'}}</td>
                    <td>{{record.lastName ? record.lastName : 'N/A'}}</td>
                    <td>{{record.amount | currency}}</td>
                    <td>{{record.orderId}}</td>
                    <td class="text-center">
                        <div class="btn-group btn-group-sm">
                            <a class="btn btn-link btn-primary-light">VIEW</a>
                        </div>
                    </td>
                </tr>
                <tr *ngIf="(gratuityTotal$ | async) == 0">
                    <td colspan="10">No gratuity report data found.</td>
                </tr>
            </tbody>
            </gcl-lib-table>
            <gcl-lib-table
                [sortBy]="'id'"
                [totalItems]="cashDrawerEntryTotal$ | async" 
                (onPage)="cashDrawerEntryPagination$.next($event)" 
                [tableClass]="'table table-striped table-hover'" 
                [enableCreate]="enableCreate && nonEmptyResults"
                [createLabel]="'generate pdf'"
                (onCreate)="generateDrawerCountReport()"
                [showSearch]="false"
                searchPlaceholder="Search Report Outputs"
                [style.display]="reportOutputType === ReportOutputType.EodDrawerCount ? 'block' : 'none'"
            >
            <thead>
                <tr>
                    <th columnSort="timestamp">TIMESTAMP</th>
                    <th columnSort="course">COURSE</th>
                    <th columnSort="cashTotal">CASH TOTAL</th>
                    <th columnSort="startingCashAmount">STARTING CASH AMOUNT</th>
                    <th columnSort="depositTotal">DEPOSIT TOTAL</th>
                    <th columnSort="modifiedBy">MODIFIED BY</th>
                    <th width="100" class="text-center">MANAGE</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let drawerCount of cashDrawerEntryRecords$ | async">
                    <td>{{drawerCount.timestamp | date: 'mediumDate'}}</td>
                    <td>{{drawerCount.course}}</td>
                    <td>{{drawerCount.cashTotal | currency}}</td>
                    <td>{{drawerCount.startingCashAmount | currency}}</td>
                    <td>{{drawerCount.depositTotal | currency}}</td>
                    <td>{{drawerCount.modifiedBy}}</td>
                    <td class="text-center">
                        <div class="btn-group btn-group-sm text-nowrap" *ngIf="!drawerCount.course; else exists">
                            <a class="btn btn-link btn-primary-light" [routerLink]="['/admin', 'reports', 'drawer-counts', getDateString(drawerCount.timestamp)]">CREATE</a>
                        </div>
                        <ng-template #exists>
                            <div class="btn-group btn-group-sm text-nowrap">
                                <a class="btn btn-link btn-primary-light" [routerLink]="['/admin', 'reports', 'drawer-counts', getDateString(drawerCount.timestamp)]">VIEW</a>
                                <span class="btn px-0">|</span>
                                <a class="btn btn-link btn-primary-light" (click)="generateDrawerCountReportForRow(drawerCount)">GENERATE PDF</a>
                            </div>
                        </ng-template>
                    </td>
                </tr>
                <tr *ngIf="(cashDrawerEntryTotal$ | async) == 0">
                    <td colspan="10">No drawer counts.</td>
                </tr>
            </tbody>
            </gcl-lib-table>              
        </div>
    </div>
</div>
