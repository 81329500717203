import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { shareReplay, switchMap, takeUntil } from 'rxjs/operators';

import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { ReportService } from 'projects/shared/src/lib/services/report.service';
import { GolfProductUtilization } from 'projects/shared/src/lib/models/golf-product-utilization';
import { CourseService } from 'projects/shared/src/lib/services/course.service';
import { CourseDetails } from 'projects/shared/src/lib/models/course';

interface GolfProductBar {
  name: string;
  total: number;
  percentage: number;
  backgroundColor: string;
}

interface PieChartValue {
  name: string;
  value: number;
}

@Component({
  selector: 'gcl-admin-golf-product-utilization',
  templateUrl: './golf-product-utilization.component.html',
  styleUrls: ['./golf-product-utilization.component.scss']
})
export class GolfProductUtilizationComponent implements OnInit, OnDestroy {

  @Input()
  courseId?: number;

  public course$!: Observable<CourseDetails>;
  public records$!: Observable<Array<GolfProductUtilization>>;

  public golfproducts$!: Observable<Array<GolfProductBar>>;
  public doughnutchartdata: Array<PieChartValue> = [];
  public doughnutchartcolors: any = {
    domain: [
      '#056839',
      '#079954',
      '#69D5A2',
      '#0ACC70',
      '#034023'
    ]
  };

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(private authService: AuthService, private courseService: CourseService, private reportService: ReportService) { }

  ngOnInit(): void {
    if (this.courseId) {
      this.course$ = this.courseService.get(this.courseId as number)
        .pipe(
          shareReplay(1),
          takeUntil(this.destroyed$)
        );
    } else {
      this.course$ = this.authService.course$
        .pipe(
          switchMap(course => this.courseService.get(course.id)),
          shareReplay(1),
          takeUntil(this.destroyed$)
        );
    }

    this.records$ = this.course$
      .pipe(
        switchMap(course => this.getUtilitization(course.id)),
        shareReplay(1),
        takeUntil(this.destroyed$)
      );

    this.records$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe((records) => {
        const total = records.reduce((total, r) => total + parseInt(r.total.toString()), 0);
        const sorted = records.sort((rr) => rr.quantity);

        if (sorted.length > 0) {
          const max = sorted.length > 4 ? 4 : sorted.length;
          let golfproducts = [];

          for (let i = 0; i < max; i++) {
            golfproducts[i] = {
              name: sorted[i].golfproduct_name,
              percentage: parseFloat(((sorted[i].total / total) * 100).toFixed(2)),
              total: sorted[i].total,
              backgroundColor: this.getBgColor(i)
            };
          }

          if (max >= 4) {
            const otherTotal = sorted.reduce((total, ss) => total + ss.total, 0) - golfproducts.reduce((total, ss) => total + ss.total, 0);
            golfproducts[4] = {
              name: "Other",
              percentage: parseFloat(((otherTotal / total) * 100).toFixed(2)),
              total: otherTotal,
              backgroundColor: this.getBgColor(4)
            }
          }

          this.golfproducts$ = of(golfproducts);

          golfproducts.forEach((record) => this.doughnutchartdata.push({
            name: record.name,
            value: record.total
          }))
        }
      });
  }

  private getBgColor(index: number): string {
    const i = index % this.doughnutchartcolors.domain.length;
    return "color-" + this.doughnutchartcolors.domain[i].split("#")[1];
  }

  private getUtilitization(courseId?: number) {
    return this.reportService.getGolfProductUtilization(courseId)
      .pipe(
        takeUntil(this.destroyed$)
      );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
