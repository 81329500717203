import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';

import { faChevronLeft, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';

import { environment } from 'projects/admin/src/environments/environment';
import { OrderDetails } from 'projects/shared/src/lib/models/order';
import { OrderService } from 'projects/shared/src/lib/services/order.service';
import { UserService } from 'projects/shared/src/lib/services/user.service';
import { GolfOrder } from 'projects/shared/src/lib/models/golforder';
import { ProductOrder } from 'projects/shared/src/lib/models/productorder';
import { OrderStatuses } from 'projects/shared/src/lib/enumerations/order-status';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { UserDetails } from 'projects/shared/src/public-api';

@Component({
  selector: 'gcl-admin-order-create',
  templateUrl: './order-create.component.html',
  styleUrls: ['./order-create.component.scss']
})
export class OrderCreateComponent implements OnInit, OnDestroy {
  public apiUrl = environment.apiUrl;
  public faTrash = faTrash;
  public faChevronLeft = faChevronLeft;

  get course() { return this.form.get("course") as FormControl }
  get round() { return this.form.get("round") as FormControl }
  get users_permissions_user() { return this.form.get("users_permissions_user") as FormControl }
  get golfOrders() { return this.form.get("golfOrders") as FormControl }
  get productOrders() { return this.form.get("productOrders") as FormControl }
  get status() { return this.form.get("status") as FormControl }
  get subtotal() { return this.form.get("subtotal") as FormControl }
  get tax() { return this.form.get("tax") as FormControl }
  get total() { return this.form.get("total") as FormControl }
  get tip() { return this.form.get("tip") as FormControl }
  get finaltotal() { return this.form.get("finaltotal") as FormControl }
  get cart() { return this.form.get("cart") as FormControl }

  get golforders() { return this.form.get("golforders") as FormArray }
  get productorders() { return this.form.get("productorders") as FormArray }

  public form = this.fb.group({
    course: [null, [Validators.required]],
    round: [null, [Validators.required]],
    users_permissions_user: [null, [Validators.required]],
    golforders: [null, [Validators.required]],
    productorders: [null, [Validators.required]],
    status: [OrderStatuses.Open, [Validators.required]],
    subtotal: [0, [Validators.required]],
    tax: [0, [Validators.required]],
    total: [0, [Validators.required]],
    tip: [0, [Validators.required]],
    finaltotal: [0, [Validators.required]],
    cart: [false, [Validators.required]],
  });

  public users$!: Observable<UserDetails[]>;

  private destroyed$ = new Subject();

  constructor(private fb: FormBuilder, private userService: UserService, private authService: AuthService, private orderService: OrderService, public route: ActivatedRoute, private router: Router, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.users$ = this.userService.getAll().pipe(
      takeUntil(this.destroyed$),
      shareReplay(1)
    );

    this.authService.course$
      .pipe(
        takeUntil(this.destroyed$)
      ).subscribe((course) => {
        this.form.patchValue({
          course: course.id
        });

        this.initGolfOrders(undefined);
        this.initProductOrder(undefined);
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  initGolfOrders(golforders: Array<GolfOrder> | undefined): void {
    let controls: FormArray = this.fb.array([]);

    if (golforders) {
      golforders.forEach(golforder => controls.push(this.fb.control(golforder)));
    }

    this.form.setControl("golforders", controls);
  }

  addGolfOrder(golforder: GolfOrder): void {
    const index = this.golforders.value.findIndex((t: GolfOrder) => t.id == golforder.id);
    if (index == -1) {
      this.golforders.push(this.fb.control(golforder));
    }
  }

  removeGolfOrder(golforder: GolfOrder): void {
    const index = this.golforders.value.findIndex((t: GolfOrder) => t.id == golforder.id);
    if (index != -1) {
      this.golforders.removeAt(index);
    }
  }

  initProductOrder(productorders: Array<ProductOrder> | undefined): void {
    let controls: FormArray = this.fb.array([]);

    if (productorders) {
      productorders.forEach(productorder => controls.push(this.fb.control(productorder)));
    }

    this.form.setControl("productorders", controls);
  }

  addProductOrdert(productorder: ProductOrder): void {
    const index = this.productorders.value.findIndex((t: GolfOrder) => t.id == productorder.id);
    if (index == -1) {
      this.productorders.push(this.fb.control(productorder));
    }
  }

  removeProductOrder(productorder: ProductOrder): void {
    const index = this.productorders.value.findIndex((t: ProductOrder) => t.id == productorder.id);
    if (index != -1) {
      this.productorders.removeAt(index);
    }
  }

  public submit(): void {
    this.form.markAllAsTouched();
    if(this.form.invalid) {
      return;
    }
    
    this.orderService.create(this.form.value)
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe((order: OrderDetails) => {
        this.form.setErrors({});

        this.toastr.success("Order created successfully!");
        this.router.navigate(["/admin","orders", order.id,"view"]);
      }, err => {
        this.toastr.error("An error has occurred. Please try again.")
      });
  }
}
