<div class="row border mx-1">
    <div class="col-12 col-lg-7 p-0">
        <div class="row mx-0 pt-3">
            <div class="col-6 col-lg-4">
                <div class="form-group">
                    <label>Order Number</label>
                    <p>{{order?.id}}</p>
                </div>
            </div>
            <div class="col-6 col-lg-4">
                <div class="form-group">
                    <label>Status</label>
                    <p *ngIf="order?.created_at; else notApplicable">{{order?.status}}</p>
                </div>
            </div>
            <div class="col-6 col-lg-4">
                <div class="form-group">
                    <label>Timestamp</label>
                    <p *ngIf="order?.created_at; else notApplicable">{{order?.created_at | date: 'medium'}}</p>
                </div>
            </div>
        </div>
        <div class="row mx-0 pt-3">
            <div class="col-6 col-lg-4">
                <div class="form-group">
                    <label>First Name</label>
                    <p *ngIf="order?.users_permissions_user?.firstName; else notApplicable">{{order?.users_permissions_user?.firstName}}</p>    
                </div>
            </div>
            <div class="col-6 col-lg-4">
                <div class="form-group">
                    <label>Last Name</label>
                    <p *ngIf="order?.users_permissions_user?.lastName; else notApplicable">{{order?.users_permissions_user?.lastName}}</p>    
                </div>
            </div>
            <div class="col-6 col-lg-4">
                <div class="form-group">
                    <label>Email</label>
                    <p *ngIf="order?.users_permissions_user?.email; else notApplicable">{{order?.users_permissions_user?.email}}</p>
                </div>
            </div>
        </div>                        
        <hr class="my-0 mx-3" />     
        <div class="row mx-0 pt-3">
            <div class="col-6">
                <label>Reservation Type</label>
                <p>{{GetReservationTypesDisplayName($any(order?.reservationType))}}</p>    
            </div>
            <div class="col-6">
                <label>Tee Time</label>
                <p *ngIf="order.tee_times && order.tee_times[0]; else notApplicable">{{ getTeeTime(order) | date: 'fullDate' }} - {{ getTeeTime(order) | date:'shortTime' }}</p>
            </div>
        </div>       
        <div class="row mx-0 pt-3">
            <div class="col-6">
                <label>Nine</label>
                <p>{{getNine(order)}}</p>    
            </div>
            <div class="col-6">
                <label>Course Length</label>
                <p *ngIf="order?.course?.holes; else notApplicable">{{order?.course?.holes}} Holes</p>                                
            </div>
        </div>    
        <div class="row mx-0 pt-3">
            <div class="col-6">
                <label># in Party</label>
                <p>{{order.golforders ? order.golforders.length : 1}}</p>    
            </div>
            <div class="col-6">
                <label>Number of Carts</label>
                <p *ngIf="cartCount$ | async as cartCount;">{{cartCount}}</p>
                <p *ngIf="!(cartCount$ | async)">0</p>
            </div>
        </div>  
        <div class="row mx-0 pt-3">
            <div class="col-12">
                <label>Description</label>
                <p>{{order.description ? order.description : 'N/A'}}</p>    
            </div>
        </div>      
        <ng-template #notApplicable>
            <p>N/A</p>
        </ng-template>
    </div>
    <div class="bg-light col-12 col-lg-5 p-0">
        <div class="px-4 pt-4 font-weight-bold">
            Itemized List
        </div>
        <gcl-admin-order-line-items [order]="order"></gcl-admin-order-line-items>
    </div>
</div>