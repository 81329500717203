import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { TeeSheetRoutingModule } from './tee-sheet-routing.module';
import { LayoutComponent } from './layout/layout.component';
import { SharedModule } from 'projects/shared/src/public-api';
import { ReservationDetailsComponent } from './reservation-details/reservation-details.component';
import { AddReservationComponent } from './add-reservation/add-reservation.component';
import { AddSqueezeTimeComponent } from './add-squeeze-time/add-squeeze-time.component';
import { AdminSharedModule } from '../../shared/admin-shared.module';
import { CheckoutComponent } from './checkout/checkout.component';
import { CheckoutModule } from '../../shared/modules/checkout/checkout.module';
import { RegisterCheckoutComponent } from './register-checkout/register-checkout.component';

@NgModule({
  declarations: [LayoutComponent, ReservationDetailsComponent, AddReservationComponent, AddSqueezeTimeComponent, CheckoutComponent, RegisterCheckoutComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    CheckoutModule,
    SharedModule,
    AdminSharedModule,
    FontAwesomeModule,

    TeeSheetRoutingModule,
  ]
})
export class TeeSheetModule { }
