<div class="d-flex flex-column h-100">
    <div class="d-flex justify-content-start py-2 bg-light no-flex">
        <a class="text-light-green" [routerLink]="['/admin','orders']">
            <fa-icon [icon]="faChevronLeft" class="px-3"></fa-icon> <span>Back to Order List</span>
        </a>
    </div>
    
    <div class="container-fluid py-2 fill-flex overflow-y-auto">
        <div class="row" *ngIf="order$ | async as order">
            <div class="col-12">
                <h6 class="page-header">Order View</h6>
                <div class="d-flex justify-content-end py-2">
                    <!-- <a [routerLink]="['/admin','orders', order.id, 'edit']" class="btn btn-light-green mx-1">Edit</a> -->
                    <ng-container *ngIf="(paidTotal$ | async) as paidTotal">
                        <ng-container *ngIf="paidTotal > 0 && order.users_permissions_user">
                            <a [routerLink]="['/admin', 'orders', order.id, 'refund-credit-balance']" class="btn btn-light-green mx-1" *ngIf="canRefundViaCreditBalance$ | async">Refund to Credit Balance</a>
                            <a [routerLink]="['/admin', 'orders', order.id, 'refund']" class="btn btn-light-green mx-1" *ngIf="canRefund$ | async">Refund</a>    
                        </ng-container>
                    </ng-container>                    
                    <button (click)="openDeleteModal()" type="button" class="btn btn-outline-light-green mx-1">Delete</button>
                </div>
                <gcl-admin-order-details [order]="order"></gcl-admin-order-details>
            </div>
            <div class="container-fluid py-2 mx-1 fill-flex overflow-y-auto" *ngIf="order.payments && order.payments.length > 0">
                <div class="row">
                    <div class="col-12">
                        <gcl-admin-order-payment-table [order]="order"></gcl-admin-order-payment-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

