<div class="container-fluid py-2 h-100 overflow-y-auto">
    <div class="row">
        <div class="col-12">
            <h6  class="page-header">Course List</h6>
            <gcl-lib-table 
                [totalItems]="total$ | async" 
                [itemsPerPage]="initialPagination.itemsPerPage" 
                [sortBy]="initialPagination.sortBy" 
                [sortDescending]="initialPagination.sortDescending"
                [page]="initialPagination.page"
                [searchTxt]="initialPagination.search"
                (onPage)="pagination$.next($event)" 
                [tableClass]="'table table-striped table-hover'" 
                (onCreate)="goToCreate()">
                <thead>
                    <tr>
                        <th columnSort="name">Name</th>
                        <th width="200" class="text-center">Holes</th>
                        <th width="200" class="text-center">Par</th>
                        <th width="200" class="text-center">Course Length</th>
                        <th width="200" class="text-center">Slope</th>
                        <th width="200" class="text-center">Rating</th>
                        <th width="200" class="text-center">Dwell Time</th>
                        <th width="200" class="text-center">Tax Rate</th>
                        <th width="200" class="text-center">Manage</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let course of records$ | async">
                        <td>{{course.name}}</td>
                        <td class="text-center">{{course.holes}}</td>
                        <td class="text-center">{{course.par}}</td>
                        <td class="text-center">{{course.courselength}}</td>
                        <td class="text-center">{{course.slope}}</td>
                        <td class="text-center">{{course.rating}}</td>
                        <td class="text-center">{{course.dwell}}</td>
                        <td class="text-center">{{course.taxrate}}%</td>
                        <td class="text-center">
                            <div class="btn-group btn-group-sm">
                                <a [routerLink]="['/admin','courses', course.id, 'view']" [queryParams]="getQueryParams()" class="btn btn-link btn-primary-light">View</a>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="(total$ | async) == 0">
                        <td colspan="2">No courses.</td>
                    </tr>
                </tbody>
            </gcl-lib-table>
        </div>
    </div>
</div>