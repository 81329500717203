import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { EventSpaceDetails } from 'projects/shared/src/lib/models/event-space';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { EventSpaceService } from 'projects/shared/src/lib/services/event-space.service';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'gcl-admin-event-space-view',
  templateUrl: './event-space-view.component.html',
  styleUrls: ['./event-space-view.component.scss']
})
export class EventSpaceViewComponent implements OnInit, OnDestroy {
  faChevronLeft = faChevronLeft;

  eventSpace$!: Observable<EventSpaceDetails>;

  routerLink$!: Observable<any[]>;
  editLink$!: Observable<any[]>;

  private destroyed$ = new Subject();

  constructor(
    private fb: FormBuilder, 
    private activatedRoute: ActivatedRoute,
    private eventSpaceService: EventSpaceService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.eventSpace$ = this.activatedRoute.params.pipe(
      switchMap(p => this.eventSpaceService.get(p['eventSpaceId'] as number))
    );

    this.routerLink$ = this.activatedRoute.params.pipe(
      map(p => ['/admin', 'courses', p['courseId'], 'view'])
    )

    this.editLink$ = this.activatedRoute.params.pipe(
      map(p => ['/admin', 'courses', p['courseId'], 'spaces', p['eventSpaceId'], 'edit'])
    )
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
