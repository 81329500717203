import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { shareReplay, takeUntil } from 'rxjs/operators';

import { MarkdownService } from 'ngx-markdown';

import { GolfPassService } from 'projects/shared/src/lib/services/golf-pass.service';
import { GolfPass } from 'projects/shared/src/lib/models/golfpass';

@Component({
  selector: 'gcl-user-golf-pass-guidlines',
  templateUrl: './golf-pass-guidlines.component.html',
  styleUrls: ['./golf-pass-guidlines.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GolfPassGuidlinesComponent implements OnInit, OnDestroy {
  @Input() golfpassId!: number

  private destroy$ = new Subject<boolean>();

  public golfpass$!: Observable<GolfPass>;

  constructor(
    private route: ActivatedRoute,
    private golfpassService: GolfPassService,
    private markdownService: MarkdownService
  ) { }

  ngOnInit(): void {
    this.golfpass$ = this.golfpassService.get(this.golfpassId)
      .pipe(
        shareReplay(1),
        takeUntil(this.destroy$)
      );
  }

  public compileMarkdown(markdown: string): string {
    return this.markdownService.compile(markdown);
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

}
