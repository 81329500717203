
<div class="container-fluid py-2 h-100 overflow-y-auto">
    <div class="row">
        <div class="col-12">
            <h6  class="page-header">Schedules</h6>
            <gcl-lib-table 
                [totalItems]="total$ | async" 
                [sortBy]="'id'" 
                (onPage)="pagination$.next($event)" 
                [tableClass]="'table table-striped table-hover'" 
                [enableCreate]="false">
                <thead>
                    <tr>
                        <th columnSort="name">Name</th>
                        <th columnSort="starttod">Start Time of Day</th>
                        <th columnSort="endtod">End Time of Day</th>
                        <th columnSort="turn">Turn</th>
                        <th columnSort="active">Active</th>
                        <th width="100" class="text-center">Manage</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let schedule of records$ | async">
                        <td>{{schedule.name}}</td>
                        <td>{{schedule.starttod}}</td>
                        <td>{{schedule.endtod}}</td>
                        <td>{{schedule.turn}}</td>
                        <td>{{schedule.active ? 'Yes' : 'No'}}</td>
                        <td class="text-center">
                            <div class="btn-group btn-group-sm">
                                <a [routerLink]="['/manager','schedules', schedule.id, 'view']" class="btn btn-link btn-primary-light">View</a>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="(total$ | async) == 0">
                        <td colspan="9">No schedules.</td>
                    </tr>
                </tbody>
            </gcl-lib-table>
        </div>
    </div>
</div>