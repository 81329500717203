import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'creditCard'
})
export class CreditCardPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): string {
    if(typeof value !== 'string') {
      return value;
    }

    if(value.length !== 4) {
      return value;
    }

    return `XXXX-XXXX-XXXX-${value}`;
  }

}
