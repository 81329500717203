import { Inject, Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { CONFIG_TOKEN, SharedModuleConfig } from '../shared.config';

@Injectable({
  providedIn: 'root'
})
export class ManagerAuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router, @Inject(CONFIG_TOKEN) private config: SharedModuleConfig) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.viewManager$.pipe(
    );
  }
}
