import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';

import { faChevronLeft, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';

import { environment } from 'projects/admin/src/environments/environment';
import { OrderDetails } from 'projects/shared/src/lib/models/order';
import { OrderService } from 'projects/shared/src/lib/services/order.service';
import { GolfOrder } from 'projects/shared/src/lib/models/golforder';
import { ProductOrder } from 'projects/shared/src/lib/models/productorder';

import { ConfirmationModalService } from 'projects/shared/src/lib/services/confirmation-modal.service';

@Component({
  selector: 'gcl-admin-order-edit',
  templateUrl: './order-edit.component.html',
  styleUrls: ['./order-edit.component.scss']
})
export class OrderEditComponent implements OnInit, OnDestroy {
  public apiUrl = environment.apiUrl;
  public faTrash = faTrash;
  public faChevronLeft = faChevronLeft;

  get course() { return this.form.get("course") as FormControl }
  get round() { return this.form.get("round") as FormControl }
  get users_permissions_user() { return this.form.get("users_permissions_user") as FormControl }
  get status() { return this.form.get("status") as FormControl }
  get subtotal() { return this.form.get("subtotal") as FormControl }
  get tax() { return this.form.get("tax") as FormControl }
  get total() { return this.form.get("total") as FormControl }
  get tip() { return this.form.get("tip") as FormControl }
  get finaltotal() { return this.form.get("finaltotal") as FormControl }
  get cart() { return this.form.get("cart") as FormControl }

  get golforders() { return this.form.get("golforders") as FormArray }
  get productorders() { return this.form.get("productorders") as FormArray }

  public form = this.fb.group({
    course: [null, [Validators.required]],
    round: [null, [Validators.required]],
    users_permissions_user: [null, [Validators.required]],
    golforders: [null, [Validators.required]],
    productorders: [null, [Validators.required]],
    status: [0, [Validators.required]],
    subtotal: [0, [Validators.required]],
    tax: [0, [Validators.required]],
    total: [0, [Validators.required]],
    tip: [0, [Validators.required]],
    finaltotal: [0, [Validators.required]],
    cart: [false, [Validators.required]],
  });

  public orderId$!: Observable<number>;
  public order$!: Observable<OrderDetails>;
  private destroyed$ = new Subject();

  constructor(private fb: FormBuilder, private orderService: OrderService, public route: ActivatedRoute, private confirmationModalService: ConfirmationModalService, private router: Router, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.orderId$ = this.route.params.pipe(
      map(params => params.orderId)
    );

    this.order$ = this.orderId$.pipe(
      switchMap(((orderId) => this.orderService.get(orderId))),
      tap((order: OrderDetails) => {
        this.form.patchValue({
          course: order.course,
          round: order.round,
          users_permissions_user: order.users_permissions_user?.id,
          status: order.status,
          subtotal: order.subtotal,
          tax: order.tax,
          total: order.total,
          tip: order.tip,
          finaltotal: order.finaltotal,
          cart: order.cart
        });

        this.initGolfOrders(order.golforders);
        this.initProductOrder(order.productorders);
      }),
      takeUntil(this.destroyed$)
    );
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  initGolfOrders(golforders: Array<GolfOrder> | undefined): void {
    let controls: FormArray = this.fb.array([]);

    if (golforders) {
      golforders.forEach(golforder => controls.push(this.fb.control(golforder)));
    }

    this.form.setControl("golforders", controls);
  }

  addGolfOrder(golforder: GolfOrder): void {
    const index = this.golforders.value.findIndex((t: GolfOrder) => t.id == golforder.id);
    if (index == -1) {
      this.golforders.push(this.fb.control(golforder));
    }
  }

  removeGolfOrder(golforder: GolfOrder): void {
    const index = this.golforders.value.findIndex((t: GolfOrder) => t.id == golforder.id);
    if (index != -1) {
      this.golforders.removeAt(index);
    }
  }

  initProductOrder(productorders: Array<ProductOrder> | undefined): void {
    let controls: FormArray = this.fb.array([]);

    if (productorders) {
      productorders.forEach(productorder => controls.push(this.fb.control(productorder)));
    }

    this.form.setControl("productorders", controls);
  }

  addProductOrdert(productorder: ProductOrder): void {
    const index = this.productorders.value.findIndex((t: GolfOrder) => t.id == productorder.id);
    if (index == -1) {
      this.productorders.push(this.fb.control(productorder));
    }
  }

  removeProductOrder(productorder: ProductOrder): void {
    const index = this.productorders.value.findIndex((t: ProductOrder) => t.id == productorder.id);
    if (index != -1) {
      this.productorders.removeAt(index);
    }
  }

  public submit(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.order$.pipe(
      switchMap(order => this.orderService.update(order.id, { ...order, ...this.form.value })),
      takeUntil(this.destroyed$)
    ).subscribe((order: OrderDetails) => {
      this.form.setErrors({});

      this.toastr.success("Order updated successfully!");
      this.router.navigate(["/admin", "orders", order.id, "view"]);
    }, err => {
      this.toastr.error("An error has occurred. Please try again.")
    });
  }

  public openCancelModal(order: OrderDetails) {
    this.confirmationModalService.showConfirmationModal({
      title: 'Confirm Cancel',
      message: 'Are you sure you want to discard changes and go back?'
    }).subscribe(confirm => {
      if (confirm === true) {
        this.router.navigate(['/admin', 'orders', order.id, 'view']);
      }
    })
  }
  public openSaveModal() {
    this.confirmationModalService.showConfirmationModal({
      title: 'Confirm Save',
      message: 'Do you want to save these changes?'
    }).subscribe(confirm => {
      if (confirm === true) {
        this.submit();
      }
    })
  }

}
