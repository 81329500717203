<!-- <div class="modal-header">    
    <button type="button" class="close pull-right" aria-label="Close">
        <span class="modal-close" aria-hidden="true">&times;</span>
    </button>
</div> -->
<div class="modal-body">
    <form [formGroup]="form" (ngSubmit)="assignCertificate()">
        <div class="d-flex justify-content-between">
            <div>
                <h6  class="page-header mb-0">ASSIGN GIFT CERTIFICATE VALUE</h6>
                <div class="subtitle">INPUT THE VALUE OF THIS GIFT CERTIFICATE</div>    
            </div>
            <div class="form-group">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text font-weight-bold">$</div>
                        <input formControlName="amount" id="amount" class="form-control font-weight-bold" type="number" min="0" placeholder="0" step="0.01"/>
                      </div>
                </div>
                <div *ngIf="amount?.invalid && amount?.touched" class="text-danger mt-2 ml-3">
                    <div *ngIf="amount?.errors?.required">
                        Dollar amount required.
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label >Order Number</label>
                    <p>{{order?.id}}</p>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label>Timestamp</label>
                    <p>{{order?.created_at | date: 'medium'}}</p>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12">
                <div class="form-group">
                    <label for="email">Enter Recipient</label>
                    <input formControlName="email" id="email" class="form-control font-weight-bold" placeholder="Enter email here"/>
                    <div *ngIf="email?.invalid && email?.touched" class="text-danger mt-2">
                        <div *ngIf="email?.errors?.required">
                            Email required.
                        </div>
                        <div *ngIf="email?.errors?.email">
                            Invalid email entered.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end align-items-center">
            <button class="btn btn-outline-light-green" (click)="cancel()">Cancel</button>
            <button class="btn btn-wide btn-light-green ml-2 px-5" type="submit" [disabled]="form.invalid">Assign value</button>
        </div>
    </form>
</div>