import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { faPlus, faSearch, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { OnPageEvent } from '../../models/on-page-event';

@Component({
  selector: 'gcl-lib-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit, OnDestroy {
  public faSearch = faSearch;

  get search() { return this.form.get("search") as FormControl }

  @Input()
  enableCreate?: boolean = true;
  @Input()
  faCreateIcon?: IconDefinition | undefined = faPlus;
  @Input()
  createIcon?: string | undefined = undefined;

  @Input()
  tableClass = 'table table-bordered table-striped table-hover table-sm';

  @Input()
  showSearch = true;

  @Input()
  totalItems?: number | null;

  @Input()
  page: number = 1;

  @Input()
  sortBy?: string = '';

  @Input()
  sortDescending?: boolean = true;

  @Input()
  itemsPerPage: number = 10;

  @Input()
  searchTxt: string = '';

  @Input()
  itemsPerPageOptions: number[] = [10, 25, 50, 100];

  @Input()
  showPageOptions: boolean = true;

  @Input()
  showPagination: boolean = true;

  @Input()
  createLabel: string = 'create';

  @Output()
  onCreate = new EventEmitter<void>();

  @Output()
  onPage = new EventEmitter<OnPageEvent>();

  public form = this.fb.group({
    page: [this.page],
    itemsPerPage: [this.itemsPerPage],
    search: [this.searchTxt],
    sortBy: [this.sortBy],
    sortDescending: [this.sortDescending]
  });

  get recordStart() { return (this.form.value.page - 1) * this.form.value.itemsPerPage + 1; }
  get recordEnd() { return Math.min((this.form.value.page - 1) * this.form.value.itemsPerPage + this.form.value.itemsPerPage, this.totalItems!); }

  destroyed$ = new Subject();

  constructor(private fb: FormBuilder, private el: ElementRef) { }

  toggleSortBy(columnName: string) {
    let descending = true;

    // If the same column, toggle the descending value
    if (this.form.value.sortBy == columnName) {
      descending = !this.form.value.sortDescending;
    }

    this.form.patchValue({
      sortBy: columnName,
      sortDescending: descending,
      page: 1
    });
  }

  create(): void {
    this.onCreate.emit();
  }

  ngOnInit(): void {
    this.form.patchValue({
      page: this.page,
      itemsPerPage: this.itemsPerPage,
      sortBy: this.sortBy,
      sortDescending: this.sortDescending,
      search: this.searchTxt
    });

    this.form.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        takeUntil(this.destroyed$)
      )
      .subscribe(value => {
        this.onPage.emit(value)
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}
