import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from 'projects/shared/src/public-api';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { CoursesRoutingModule } from './courses-routing.module';
import { CourseListComponent } from './course-list/course-list.component';
import { CourseViewComponent } from './course-view/course-view.component';
import { CourseEditComponent } from './course-edit/course-edit.component';
import { CourseCreateComponent } from './course-create/course-create.component';
import { EventSpaceCreateComponent } from './event-space-create/event-space-create.component';
import { EventSpaceEditComponent } from './event-space-edit/event-space-edit.component';
import { EventSpaceViewComponent } from './event-space-view/event-space-view.component';
import { AdminSharedModule } from '../../shared/admin-shared.module';

@NgModule({
  declarations: [
    CourseListComponent, 
    CourseViewComponent, 
    CourseEditComponent, 
    CourseCreateComponent,
    EventSpaceCreateComponent,
    EventSpaceEditComponent,
    EventSpaceViewComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,

    SharedModule,
    CarouselModule.forRoot(),
    TabsModule.forRoot(),
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    FontAwesomeModule,
    AdminSharedModule,

    CoursesRoutingModule
  ]
})
export class CoursesModule { }
