<div class="modal-header">
    <div>
        <h5 class="modal-title pull-left">WOULD YOU LIKE TO LEAVE A TIP?</h5>
        <p style="color: lightgray;">Edit the desired tip value</p>
    </div>
    
    <div class="form-group">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text font-weight-bold">$</div>
                <input #tipAmount type="number" min="0" size="4" class="tip-amount form-control font-weight-bold" value="{{tip}}" (change)="setCustomTip(tipAmount.value)" />
            </div>
        </div>
    </div>
    <!-- <div class="large-text d-flex align-items-center justify-content-end">
        $ <input #tipAmount type="number" min="0" size="4" class="tip-amount form-control font-weight-bold" value="{{tip}}" (change)="setCustomTip(tipAmount.value)" />
    </div> -->
</div>
<div class="modal-body">
    <div class="fill-flex overflow-y-auto container-fluid">
        <div class="gratuity-grid">
            <button type="button" class="p-3 gratuity-select btn btn-default text-uppercase" 
                [class.gratuity-select-active]="tipPercentage === 5" (click)="setTipPercentage(5)">
                5%
            </button>
            <button type="button" class="p-3 gratuity-select btn btn-default text-uppercase" 
                [class.gratuity-select-active]="tipPercentage === 10" (click)="setTipPercentage(10)">
                10%
            </button>
            <button type="button" class="p-3 gratuity-select btn btn-default text-uppercase" 
                [class.gratuity-select-active]="tipPercentage === 15" (click)="setTipPercentage(15)">
                15%
            </button>
            <button type="button" class="p-3 gratuity-select btn btn-default text-uppercase" 
                [class.gratuity-select-active]="tipPercentage === 20" (click)="setTipPercentage(20)">
                20%
            </button>
            <button type="button" class="p-3 gratuity-select btn btn-default text-uppercase" 
                [class.gratuity-select-active]="tipPercentage === 0" (click)="setTipPercentage(0)">
                No Tip
            </button>
        </div>
    </div>
    <div class="d-flex justify-content-end align-items-center">
        <button class="btn btn-outline-light-green ml-2 px-5" type="button" (click)="cancel()">Cancel</button>
        <button class="btn btn-light-green ml-2 px-5" type="button" (click)="confirm()">Confirm</button>
    </div>
</div>
