import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { StripeTerminalService } from '../../services/stripe-terminal.service';
import { StripeService } from '../../services/stripe.service';

@Component({
  selector: 'gcl-lib-add-reader',
  templateUrl: './add-reader.component.html',
  styleUrls: ['./add-reader.component.css']
})
export class AddReaderComponent implements OnInit {

  courseId?: number;
  form: FormGroup;

  constructor(public bsModalRef: BsModalRef, private fb: FormBuilder, private stripeService: StripeTerminalService, private toastr: ToastrService) {
    this.form = fb.group({
      registrationCode: ['', [Validators.required]],
      label: ['']
    });
  }

  ngOnInit(): void {
  }

  async save() {
    if(this.courseId) {
      try {
        await this.stripeService.createTerminal(this.courseId, this.form.value.registrationCode, this.form.value.label);
        this.bsModalRef.hide();
      } catch(err) {
        this.toastr.error('Unable to add reader. Verify registration code is correct.')
      }
    }
  }

}
