import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProductCreateComponent } from './product-create/product-create.component';
import { ProductEditComponent } from './product-edit/product-edit.component';
import { ProductListComponent } from './product-list/product-list.component';
import { ProductViewComponent } from './product-view/product-view.component';

const routes: Routes = [
  {
    path: 'create',
    component: ProductCreateComponent
  },
  {
    path: ':productId/view',
    component: ProductViewComponent
  },
  {
    path: ':productId/clone',
    component: ProductCreateComponent
  },
  {
    path: ':productId/edit',
    component: ProductEditComponent
  },
  {
    path: '',
    component: ProductListComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductsRoutingModule { }
