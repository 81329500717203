import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { Subject } from 'rxjs';
import { shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { UserDetails } from '../../models/user';

import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'gcl-lib-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent implements OnInit {

  get username() { return this.editForm.get('username') as FormControl; }
  get email() { return this.editForm.get('email') as FormControl; }
  get firstName() { return this.editForm.get('firstName') as FormControl; }
  get lastName() { return this.editForm.get('lastName') as FormControl; }
  get dateOfBirth() { return this.editForm.get('dateOfBirth') as FormControl; }

  editForm = this.fb.group({
    username: ['', [Validators.required]],
    email: ['', [Validators.required]],
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    dateOfBirth: ['', [Validators.required]],
  });

  @Output()
  onSubmit = new EventEmitter<UserDetails>();

  private destroyed$ = new Subject();

  constructor(private fb: FormBuilder, private authService: AuthService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.authService.user$
      .pipe(
        shareReplay(1),
        takeUntil(this.destroyed$)
      )
      .subscribe((user: UserDetails) => this.editForm.setValue({
        username: user.username,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        dateOfBirth: new Date(user.dateOfBirth),
      }));
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  submit() {
    this.authService.user$
      .pipe(
        switchMap(user => this.authService.updateMe({
          username: this.username?.value,
          email: this.email?.value,
          firstName: this.firstName?.value,
          lastName: this.lastName?.value,
          dateOfBirth: this.dateOfBirth?.value,
        })),
        takeUntil(this.destroyed$)
      )
      .subscribe(
        (user: UserDetails) => {
          this.editForm.setErrors({});
          this.onSubmit.emit(user);
        },
        err => {
          this.editForm.setErrors({
            invalidEmail: true
          })
        });
  }
}
