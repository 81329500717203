<div class="container-fluid py-2 h-100 overflow-y-auto">
    <div class="row">
        <div class="col-12">
            <h6  class="page-header">Customer List</h6>
            <gcl-lib-table 
                [totalItems]="total$ | async" 
                [itemsPerPage]="initialPagination.itemsPerPage" 
                [sortBy]="initialPagination.sortBy" 
                [sortDescending]="initialPagination.sortDescending"
                [page]="initialPagination.page"
                [searchTxt]="initialPagination.search"
                (onPage)="pagination$.next($event)" 
                [tableClass]="'table table-striped table-hover'" 
                (onCreate)="goToCreate()">
                <thead>
                    <tr>
                        <th columnSort="firstName">Name</th>
                        <th columnSort="email">Email</th>
                        <th columnSort="cellphone">Cellphone</th>
                        <th>Next Tee Time</th>
                        <th columnSort="checkIn">Check-In Status</th>
                        <th columnSort="purchasedGolfPass">Golf Pass Status</th>
                        <th width="100" class="text-center">Manage</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let player of records$ | async">
                        <td>{{player.firstName}} {{player.lastName}}</td>
                        <td>{{player.email}}</td>
                        <td>{{player.cellphone}}</td>
                        <td>{{player.teetime}}</td>
                        <td>{{player.checkIn}}</td>
                        <td>{{player.purchasedGolfPass ? "Active" : ""}}</td>
                        <td class="text-center">
                            <a [routerLink]="['/admin','tee-sheet']" class="text-light-green">Reserve</a>
                            <span class="text-secondary px-2">|</span>
                            <a [routerLink]="['/admin','players', player.id, 'view']" [queryParams]="getQueryParams()" class="text-light-green">View</a>
                        </td>
                    </tr>
                    <tr *ngIf="(total$ | async) == 0">
                        <td colspan="5">No customers.</td>
                    </tr>
                </tbody>
            </gcl-lib-table>
        </div>
    </div>
</div>