import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GetNineTypesDisplayName, NineTypes } from 'projects/shared/src/lib/enumerations/nine-type';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { ConfirmationModalService } from 'projects/shared/src/lib/services/confirmation-modal.service';
import { OrderService } from 'projects/shared/src/lib/services/order.service';
import { Subject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'gcl-admin-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {

  public teeTimeChange$: Subject<void> = new Subject<void>();
  public selectedDate?: Date;
  private destroy$: Subject<boolean> = new Subject();

  constructor(private router: Router, public route: ActivatedRoute, private authService: AuthService, private toastr: ToastrService, private confirmationModalService: ConfirmationModalService, private orderService: OrderService) { }

  ngOnInit(): void {
    this.route.params.pipe(
      map(params => params.queryParam),
      takeUntil(this.destroy$)
    ).subscribe((params) => {
      if (params) {
        const dateParam = JSON.parse(params) as {
          selectedDate: string
        };
        this.selectedDate = new Date(dateParam.selectedDate);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public removeBlockTime(blockTime: {
    nineType: NineTypes,
    startTime: Date,
    endTime?: Date,
    blockedReason: string
  }): void {
    const endTimeStr = blockTime.endTime != undefined ? ` to ${blockTime.endTime.toLocaleTimeString()}` : "";
    let message = `<p>You are about to remove the blocked tee time(s) on ${blockTime.startTime.toDateString()} at ${blockTime.startTime.toLocaleTimeString()}${endTimeStr} for ${GetNineTypesDisplayName(blockTime.nineType)}.</p>`;
    message += `
      <div class="mb-3">
        <label for="reason">Blocked Reason</label>
        <p id="reason">${blockTime.blockedReason}</p>
      </div>`;
    
    this.confirmationModalService.showConfirmationModal({
      title: "Remove blocked time?",
      message: message,
      isHTML: true
    }).subscribe((accept) => {
      if (accept) {
        this.authService.course$
          .pipe(
            take(1),
            switchMap(course => this.orderService.adminUnblock({
              course: course.id,
              nine: blockTime.nineType,
              datestr: blockTime.startTime.toISODate(),
              timestr: blockTime.startTime.getTimeString(false),
              endtimestr: blockTime?.endTime?.getTimeString(false),
            })),
            takeUntil(this.destroy$)
          )
          .subscribe(() => {
            this.toastr.success("Successfully unblocked time.");
            this.teeTimeChange$.next();
          });
      }
    });
  }

  public cancelReservation(reservation: { orderId: number, time: Date }): void {
    let message = `<p>You are about to cancel the reservation on ${reservation.time.toDateString()}.</p>`;
    this.confirmationModalService.showConfirmationModal({
      title: "Cancel Reservation?",
      message: message,
      isHTML: true
    }).subscribe((accept) => {
      if (accept) {
        this.orderService.cancel(reservation.orderId)
          .pipe(
            take(1),
            takeUntil(this.destroy$)
          )
          .subscribe(() => {
            this.toastr.success("Successfully canceled reservation.");
            this.teeTimeChange$.next();
          });
      }
    });
  }

  public newReservation(queryParam: string): void {
    this.router.navigate(["/manager", "tee-sheet", "add-reservation", {
      queryParam: queryParam
    }]);
  }

  public selectOrder(order: { orderId: number, queryParam: string }): void {
    this.router.navigate(["/manager", "tee-sheet", order.orderId, "reservation-details", {
      queryParam: order.queryParam
    }]);
  }
}
