import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LayoutComponent } from './layout/layout.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'carts',
        loadChildren: () => import('./carts/carts.module').then(m => m.CartsModule)
      },
      {
        path: 'courses',
        loadChildren: () => import('./courses/courses.module').then(m => m.CoursesModule)
      },
      {
        path: 'players',
        loadChildren: () => import('./players/players.module').then(m => m.PlayersModule)
      },
      {
        path: 'promo-codes',
        loadChildren: () => import('./promo-codes/promo-codes.module').then(m => m.PromoCodesModule)
      },
      {
        path: 'golf-passes',
        loadChildren: () => import('./golf-passes/golf-passes.module').then(m => m.GolfPassesModule)
      },
      {
        path: 'products',
        loadChildren: () => import('./products/products.module').then(m => m.ProductsModule)
      },
      {
        path: 'schedules',
        loadChildren: () => import('./schedules/schedules.module').then(m => m.SchedulesModule)
      },
      {
        path: 'add-on',
        loadChildren: () => import('./add-on/add-on.module').then(m => m.AddOnModule)
      },
      {
        path: 'orders',
        loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule)
      },
      {
        path: 'tee-sheet',
        loadChildren: () => import('./tee-sheet/tee-sheet.module').then(m => m.TeeSheetModule)
      },
      {
        path: 'staff',
        loadChildren: () => import('./staff/staff.module').then(m => m.StaffModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'categories',
        loadChildren: () => import('./categories/categories.module').then(m => m.CategoriesModule)
      },
      {
        path: 'gift-certificates',
        loadChildren: () => import('./gift-certificate/gift-certificate.module').then(m => m.GiftCertificateModule)
      },
      {
        path: 'banners',
        loadChildren: () => import('./banners/banners.module').then(m => m.BannersModule)
      },
      {
        path: 'events',
        loadChildren: () => import('./events/events.module').then(m => m.EventsModule)
      },
      {
        path: 'reports',
        loadChildren: () => import ('./reports/reports.module').then(m => m.ReportsModule)
      }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
