import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, combineLatest, ReplaySubject, forkJoin, of, merge } from 'rxjs';
import { map, mergeMap, shareReplay, switchMap, take, takeUntil } from 'rxjs/operators';

import { faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';

import { Order, OrderDetails } from 'projects/shared/src/lib/models/order';
import { Course } from 'projects/shared/src/lib/models/course';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { CourseService } from 'projects/shared/src/lib/services/course.service';
import { GolfProductDetails, TeetimeDetails, GolfOrder, UserDetails } from 'projects/shared/src/public-api';
import { GolfProductService } from 'projects/shared/src/lib/services/golf-product.service';
import { NineTypes } from 'projects/shared/src/lib/enumerations/nine-type';
import { TeetimeRow } from 'projects/shared/src/lib/models/teetime-row';
import { TeeSheetTableRow, TeeSheetTableCells, TeeSheetTableColumns, Cell, TeeSheetTableColumnRows } from '../../../shared/models/tee-sheet-table';
import { getDateTime } from 'projects/shared/src/lib/utili/get-datetime';

import * as moment from 'moment';
import { ISODateStringToDate } from 'projects/shared/src/lib/utili/iso-date-string-to-date';
import { OrderStatuses } from 'projects/shared/src/lib/enumerations/order-status';
import { TeeTimeStatus } from 'projects/shared/src/lib/enumerations/tee-time-status';
import { EventService } from 'projects/shared/src/lib/services/event.service';
import { EventDetails } from 'projects/shared/src/lib/models/event';
import { SortDirections } from 'projects/shared/src/lib/enumerations/sort-directions';
import { UserService } from 'projects/shared/src/lib/services/user.service';
import { getStartAndEndOfDay } from 'projects/shared/src/lib/utili/get-start-and-end-of-day';

interface TeetimeOrder {
  teetime: TeetimeDetails;
  order: OrderDetails;
  slots: number;
  isNineHole: boolean;
  golfproducts: { [slot: number]: GolfProductDetails }
}

export interface Reservation {
  selectedDate: Date;
  selectedTimes: string; // [time:string]:number, # of slots selected per time.
  singleBookingReservationOnly: boolean;
  groupReservationOnly: boolean;
  slotsTaken: number;
  slots: number;
  blockedSlots: number;
  availableSlots: number;
  nineType: NineTypes;
  canSqueezeTime: boolean;
  maxSlots: number;
}

enum MouseEvents {
  Left = 0,
  Middle = 1,
  Right = 2,
  Unknown = 3
}

@Component({
  selector: 'gcl-admin-tee-sheet-table',
  templateUrl: './tee-sheet-table.component.html',
  styleUrls: ['./tee-sheet-table.component.scss']
})
export class TeeSheetTableComponent implements OnInit, OnDestroy, OnChanges {

  public faSearch = faSearch;
  public faPlus = faPlus;
  public NineTypes = NineTypes;
  public slots = 4;      // Slots per column

  public rows: TeeSheetTableRow = {};
  public selectedUserId?: number;
  public displayedUserName?: string;
  public selectedDate$: ReplaySubject<Date> = new ReplaySubject<Date>();

  public course$!: Observable<Course>;
  public teetimes$!: Observable<Array<TeetimeRow>>;

  public acceptableRangeIndicies: number[] = [];

  public events$!: Observable<EventDetails[]>;
  public eventUsers$!: Observable<UserDetails[]>;

  @Input()
  teeTimeChange$!: Subject<void>;

  @Input()
  initDate?: Date;

  @Output()
  removeBlockTime: EventEmitter<{
    nineType: NineTypes,
    startTime: Date,
    endTime?: Date,
    blockedReason: string
  }> = new EventEmitter();

  @Output()
  cancelReservation: EventEmitter<{
    orderId: number,
    time: Date,
  }> = new EventEmitter();

  @Output()
  newReservation: EventEmitter<string> = new EventEmitter();

  @Output()
  selectOrder: EventEmitter<{ orderId: number, queryParam: string }> = new EventEmitter();

  private golfproducts: { [id: number]: GolfProductDetails } = {};
  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private authService: AuthService, 
    private courseService: CourseService, 
    private router: Router, 
    private golfproductService: GolfProductService,
    private eventService: EventService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.course$ = this.authService.course$
      .pipe(
        shareReplay(1),
        takeUntil(this.destroy$)
      );

    const dateCourse$ = combineLatest([this.course$, this.selectedDate$, this.teeTimeChange$]).pipe(
      takeUntil(this.destroy$)
    );
    dateCourse$.subscribe(([course, date]) => {
      this.initTable(date);

      const teetimeChanged$ = this.teeTimeChange$.pipe(
        switchMap(() => this.courseService.getTeeTimes(course.id, date.toISODate()))
      );

      const teeTimes$ = this.courseService.getTeeTimes(course.id, date.toISODate()).pipe(
        takeUntil(this.destroy$)
      );

      this.teetimes$ = merge(teeTimes$, teetimeChanged$).pipe(
        takeUntil(this.destroy$)
      );

      this.events$ = combineLatest([this.selectedDate$, this.course$]).pipe(
        switchMap(([date, course]) => {
          let { startOfDay, endOfDay } = getStartAndEndOfDay(date);

          return this.eventService.query({
            course: course.id, 
            start_gte: startOfDay, 
            start_lte: endOfDay,
            take: 1000,
            sortColumns: [{
              column: 'start',
              direction: SortDirections.Ascending,
            }],
          }).records$;
        }),
        takeUntil(this.destroy$),
        shareReplay(1)
      );

      this.eventUsers$ = this.events$.pipe(
        switchMap(events => { 
          let eventUsers = events.filter(e => !!(e?.order?.users_permissions_user)).map(e => e.order.users_permissions_user) as number[];
          return this.userService.query({id_in: eventUsers}).records$;
        }),        
        takeUntil(this.destroy$),
        shareReplay(1)
      );

      this.initTimeRows(course, date);
    });

    if (this.initDate) {
      this.selectedDate$.next(this.initDate);
    } else {
      this.selectedDate$.next(new Date());
    }

    this.teeTimeChange$.next();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["initDate"].currentValue != undefined) {
      this.selectedDate$.next(changes["initDate"].currentValue);
    }
  }

  private iterateSlots(callback: Function, maxSlots: number = this.slots, start: number = 0) {
    for (let slot = start; slot < maxSlots; slot++) {
      callback(slot);
    }
  }

  private iterateTime(date: Date, callback: Function) {
    this.course$
      .pipe(takeUntil(this.destroy$))
      .subscribe((course: Course) => {
        const start = ISODateStringToDate(date.toISODate(), course.open_time);
        const end = ISODateStringToDate(date.toISODate(), course.close_time);

        const startTimeMinutes = start.getMinutes();
        const closeTimeMinutes = end.getMinutes();

        for (let h = start.getHours(); h <= end.getHours(); h++) {
          let startMinutes = (h == start.getHours()) ? startTimeMinutes : 0;
          let endMinutes = (h == end.getHours()) ? closeTimeMinutes : 59;
          
          for (let m = startMinutes; m <= endMinutes; m += course.dwell) {
            let time = new Date(date.getFullYear(), date.getMonth(), date.getDate());
            time.setHours(h, m, 0, 0);
            callback(time);
          }
        }
      });
  }

  private initTable(date: Date): void {
    this.rows = {};

    this.iterateTime(date, (time: Date) => {
      let front: TeeSheetTableCells = {};
      let back: TeeSheetTableCells = {};
      let tertiary: TeeSheetTableCells = {};

      this.iterateSlots((slot: number) => {
        front[slot] = this.getEmptyCell(time.toString(), NineTypes.Front, 0, slot);
        back[slot] = this.getEmptyCell(time.toString(), NineTypes.Back, 0, slot);
        tertiary[slot] = this.getEmptyCell(time.toString(), NineTypes.Tertiary, 0, slot);
      });

      let columns: TeeSheetTableColumns = {};
      columns[NineTypes.Front] = {}; columns[NineTypes.Front][0] = front;
      columns[NineTypes.Back] = {}; columns[NineTypes.Back][0] = back;
      columns[NineTypes.Tertiary] = {}; columns[NineTypes.Tertiary][0] = tertiary;
      this.rows[time.toString()] = columns;
    });
  }

  private getEmptyCell(time?: string, nineType?: NineTypes, row?: number, slot?: number): Cell {
    return {
      blocked: false,
      time: time,
      nineType: nineType,
      row: row,
      slot: slot,

      selected: false,
      nextReservation: false,
      isGuest: false,
      lastGuest: false,
      numOfGuests: 0,
      paid: false
    };
  }

  private initTimeRows(course: Course, selectedDate: Date): void {
    this.teetimes$
      .pipe(
        switchMap((teetimes) => {
          const golfproducts = this.getGolfProducts(teetimes);
          return combineLatest([of(teetimes), (golfproducts.length > 0 ? forkJoin(golfproducts) : of([]))]);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(([rows, golfproducts]) => {
        this.initGolfProductDictionary(golfproducts);
        this.iterateOrders(course, selectedDate, rows);
        this.iterateTurnTimes(course, selectedDate, rows);
      });
  }

  private iterateOrders(course: Course, selectedDate: Date, rows: TeetimeRow[]): void {
    this.iterateTime(selectedDate, (dateTime: Date) => {
      rows
        .filter((ttr: TeetimeRow) => {
          const ttDateTime = ISODateStringToDate(dateTime.toISODate(), ttr.time);
          const isSameDateTime = (ttDateTime.compareDate(dateTime)) == 0;
          return isSameDateTime;
        })
        .forEach((row: TeetimeRow) => {
          if (course.Front9) {
            this.iterateColumn(course, dateTime, NineTypes.Front, row, false);
          }
          if (course.Back9) {
            this.iterateColumn(course, dateTime, NineTypes.Back, row, false);
          }
          if (course.Third9) {
            this.iterateColumn(course, dateTime, NineTypes.Tertiary, row, false);
          }
        });
    });
  }

  private iterateTurnTimes(course: Course, selectedDate: Date, rows: TeetimeRow[]): void {
    this.iterateTime(selectedDate, (dateTime: Date) => {
      rows
        .filter((ttr: TeetimeRow) => {
          const ttDateTime = ISODateStringToDate(dateTime.toISODate(), ttr.time);
          const isSameDateTime = (ttDateTime.compareDate(dateTime)) == 0;
          return isSameDateTime;
        })
        .forEach((row: TeetimeRow) => {
          if (course.Front9) {
            this.iterateColumn(course, dateTime, NineTypes.Front, row, true);
          }
          if (course.Back9) {
            this.iterateColumn(course, dateTime, NineTypes.Back, row, true);
          }
          if (course.Third9) {
            this.iterateColumn(course, dateTime, NineTypes.Tertiary, row, true);
          }
        });
    });
  }

  private initGolfProductDictionary(golfproducts: GolfProductDetails[]): void {
    golfproducts.forEach(golfproduct => this.golfproducts[golfproduct.id] = golfproduct);
  }

  private getGolfProducts(teetimes: TeetimeRow[]): Array<Observable<GolfProductDetails>> {
    let golfproducts: Array<Observable<GolfProductDetails>> = [];
    let golfproductIds: Array<number> = [];

    Object.values(teetimes).forEach(teetime => {
      teetime.orders.forEach(order => {
        order.golforders.forEach((go) => {
          const golfproductId = go.golfproduct as number;
          if (!golfproductIds.find(gpid => gpid == golfproductId)) {
            golfproducts.push(this.golfproductService.get(golfproductId)
              .pipe(
                shareReplay(1),
                takeUntil(this.destroy$)
              ));

            golfproductIds.push(golfproductId);
          }
        });
      });
    });

    return golfproducts;
  }

  private iterateColumn(course: Course, dateTime: Date, type: NineTypes, row: TeetimeRow, setTurnTimeOrders: boolean): void {
    const time = dateTime.toString();
    const column: TeeSheetTableColumnRows = this.rows[time][type];

    row.teeTimes
      .filter((tt: TeetimeDetails) => tt.nine == type)
      .forEach((tt: TeetimeDetails) => {
        const blockedPlayerCount = tt?.blockPlayerCount || 0;

        // Blocked Tee Time
        if (tt.status == TeeTimeStatus.Blocked) {
          this.iterateSlots((row: number) => {
            this.iterateSlots((slot: number) => {
              column[row] = (column[row] == undefined) ? {} : column[row];
              column[row][slot] = (column[row][slot] == undefined) ? this.getEmptyCell() : column[row][slot];
              column[row][slot].blocked = true;
              column[row][slot].blockedReason = tt.blockReason;
              column[row][slot].event = tt.event;
              
              if(tt.event?.name) {
                column[row][slot].displayBlockedReason = (tt.event.name.length > 30) ? tt.event.name.substring(0, 30) + "..." : tt.event.name;
              }
              else {
                column[row][slot].displayBlockedReason = (tt.blockReason.length > 30) ? tt.blockReason.substring(0, 30) + "..." : tt.blockReason;
              }
            }, blockedPlayerCount);
          }, 1);
        }

        // Orders/Reservations
        if (tt.orders && tt.orders.length > 0) {
          const orders = this.getOrderDetails(type, tt, row);

          if (setTurnTimeOrders) {
            // Turn Times
            // for each order with a turn time, calculate turn time and fill in cells.
            const turnOrders = Object.values(orders).filter(o => !o.isNineHole);
            if (turnOrders.length > 0) {
              const turnRows = Math.ceil(turnOrders.reduce((total, o) => total + o.slots, 0) / this.slots);
              this.setOrderTurnTimeCells(course, time, turnRows, type, turnOrders);
            }
          } else {
            const orderCount = Object.values(orders).reduce((total, o) => total + o.slots, 0);
            const rows = Math.ceil((orderCount + blockedPlayerCount) / this.slots);
            this.setOrderCells(time, type, column, rows, orders, false, blockedPlayerCount);
          }
        }
      });
  }

  private getOrderDetails(nineType: NineTypes, tt: TeetimeDetails, row: TeetimeRow): { [orderId: number]: TeetimeOrder } {
    let orderSlots: { [nineType: string]: { [orderId: number]: number } } = {};

    // Filters out POS orders.
    const orders = row.orders.filter((order: Order) => (order.golforders.length > 0) && tt.orders?.find(o => o.id == order.id) != undefined);
    return orders.reduce((orderDict, order) => {
      let golfproducts: { [slot: number]: GolfProductDetails } = order.golforders.reduce((goDict, go, goIndex) => ({ ...goDict, [goIndex]: this.golfproducts[go.golfproduct as number] }), {});
      const isNineHole = golfproducts[0].holes == 9;  // All golf products have the same # of holes for a given order.

      const totalOrderSlots = order.golforders.reduce((total, go) => go.quantity + total, 0);

      orderSlots[nineType] = (orderSlots[nineType] || {});
      orderSlots[nineType][order.id] = (orderSlots[nineType][order.id] || 0);
      const difference = totalOrderSlots - (orderSlots[nineType][order.id] || 0);
      const slots = Math.min(difference, this.slots)
      orderSlots[nineType][order.id] += slots;

      // Mobile apps don't create multiple golf products, qty is on the one golf order, players all share golf product.
      if (slots > Object.keys(golfproducts).length) {
        for (let i = 0; i < slots; i++) {
          golfproducts[i] = this.golfproducts[golfproducts[0].id]
        }
      }

      return {
        ...orderDict, [order.id]: {
          teetime: tt,
          order: order,
          slots: slots,
          golfproducts: golfproducts,
          isNineHole: isNineHole,
        }
      };
    }, {});
  }

  private setOrderCells(time: string, nineType: NineTypes, column: TeeSheetTableColumnRows, rows: number, orders: { [orderId: number]: TeetimeOrder }, turnTime: boolean = false, blockedSlots: number = 0): void {
    let orderCells: Array<Cell> = [];

    // Generate all cells with orders.
    Object.values(orders).forEach(oo => {
      let previousOrder = orderCells.length > 0 ? orderCells[orderCells.length - 1] : undefined;
      orderCells = orderCells.concat(this.getOrderCells(oo, previousOrder));
    });

    // Fill the rest with empty cells for the max # of cells.
    // Max should always be 4, up to 8 if there is an order squeeze time.
    const max = (rows * this.slots);
    this.iterateSlots((slot: number) => {
      orderCells.push(this.getEmptyCell(time, nineType, 0, slot));
    }, max - orderCells.length, blockedSlots);

    // Updates the corresponding cells.
    this.iterateSlots((row: number) => {
      this.iterateSlots((slot: number) => {
        column[row] = (column[row] == undefined) ? {} : column[row];
        column[row][slot] = (column[row][slot] == undefined) ? this.getEmptyCell() : column[row][slot];

        const cell = orderCells.shift() as Cell;
        column[row][slot].blocked = false;
        column[row][slot].turnTime = turnTime && (cell?.orderId != undefined);
        column[row][slot].selected = cell?.selected;
        column[row][slot].nextReservation = cell?.nextReservation;
        column[row][slot].isGuest = cell?.isGuest;
        column[row][slot].lastGuest = cell?.lastGuest;
        column[row][slot].orderId = cell?.orderId;
        column[row][slot].userId = cell?.userId;
        column[row][slot].userName = cell?.userName;
        column[row][slot].numOfGuests = cell?.numOfGuests;
        column[row][slot].golfOrderId = cell?.golfOrderId;
        column[row][slot].golfProductId = cell?.golfProductId;
        column[row][slot].golfProductName = cell?.golfProductName;

        column[row][slot].time = time;
        column[row][slot].nineType = nineType;
        column[row][slot].row = row;
        column[row][slot].slot = slot;
        column[row][slot].paid = cell?.paid;
        column[row][slot].cancelled = cell?.cancelled;
      }, this.slots, blockedSlots);
    }, rows);
  }

  private getOrderCells(teetimeOrder: TeetimeOrder, previousOrder?: Cell): Array<Cell> {
    let orderCells: Array<Cell> = [];

    if (teetimeOrder.order.status != OrderStatuses.Cancelled) {
      this.iterateSlots((slot: number) => {
        const nextReservation = (previousOrder && slot == 0) ? true : false;
        orderCells.push(this.getOrderCell(teetimeOrder, teetimeOrder.golfproducts[slot], slot, nextReservation));
      }, teetimeOrder.slots);
    }

    return orderCells;
  }

  private getOrderCell(tto: TeetimeOrder, golfproduct: GolfProductDetails, slot: number, nexReservation: boolean): Cell {
    const user = tto.order.users_permissions_user;
    const userId = user?.id as number;
    const userName = (user?.firstName && user?.lastName) ? `${user?.firstName} ${user?.lastName}` : user?.email;

    return {
      blocked: false,

      selected: false,
      nextReservation: nexReservation,
      isGuest: (slot != 0),
      lastGuest: (slot == 4),
      orderId: tto.order?.id,
      userId: userId,
      userName: userName,
      numOfGuests: tto.slots,
      golfProductId: golfproduct.id,
      golfProductName: golfproduct.name,
      paid: tto.order.status === OrderStatuses.Paid,
      cancelled: tto.order.status == OrderStatuses.Cancelled,
    };
  }

  private setOrderTurnTimeCells(course: Course, time: string, rows: number, type: NineTypes, orders: Array<TeetimeOrder>): void {
    const turnMinTime = course.turn;

    if (type == NineTypes.Front && course.Back9) {
      orders.forEach((order) => {
        this.setTurnTimeCell(turnMinTime, rows, time, order, NineTypes.Back);
      });
    }

    if (type == NineTypes.Back && course.Third9) {
      orders.forEach((order) => {
        this.setTurnTimeCell(turnMinTime, rows, time, order, NineTypes.Tertiary);
      });
    } else if (type == NineTypes.Back && course.Back9) {
      orders.forEach((order) => {
        this.setTurnTimeCell(turnMinTime, rows, time, order, NineTypes.Front);
      });
    }

    if (type == NineTypes.Tertiary && course.Third9) {
      orders.forEach((order) => {
        this.setTurnTimeCell(turnMinTime, rows, time, order, NineTypes.Front);
      });
    }
  }

  private setTurnTimeCell(turnMinTime: number, rows: number, time: string, order: TeetimeOrder, type: NineTypes): void {
    const teeTime = getDateTime(order.teetime.datestr, order.teetime.timestr);
    const turnTime = teeTime.addMinutes(turnMinTime);
    if (this.rows[turnTime.toString()]) {
      const column = this.rows[turnTime.toString()][type];
      const blockedPlayerCount = Object.values(column).reduce((total: number, row: TeeSheetTableCells) => total + Object.values(row).filter((cc: Cell) => cc.blocked).length, 0);
      this.setOrderCells(time, type, column, rows, {
        [order.order.id]: order
      }, true, blockedPlayerCount);
    }
  }

  public getRowCount(teesheetColumnRows: TeeSheetTableColumnRows): number {
    return Object.keys(teesheetColumnRows).length;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public onSelectDate(date: Date): void {
    this.selectedDate$.next(date);
  }

  private getMouseEvent(event: number): MouseEvents {
    switch (event) {
      case 0:
        return MouseEvents.Left;
      case 1:
        return MouseEvents.Middle;
      case 2:
        return MouseEvents.Right;
      default:
        return MouseEvents.Unknown;
    }
  }

  private cellMouseDown: boolean = false;
  private disableSelection: boolean = false;
  private selectedTime?: string;
  private previousCellMouseDown?: Cell;
  private previousTimeIndexMouseDown?: number;
  private previousMouseDownSlotsFilled?: boolean;
  public onCellMouseDown(event: any, type: NineTypes, time: string, slot: number, selected: boolean, row: number = 0, timeIndex: number = 0): void {
    this.cellMouseDown = true;

    const cell = this.rows[time][type][row][slot];
    if (cell.cancelled == undefined || !cell.cancelled) {
      const mouseEvent = this.getMouseEvent(event.button);
      switch (mouseEvent) {
        case MouseEvents.Left:
          this.leftMouseClick(type, time, slot, selected, row, timeIndex);
          break;
        case MouseEvents.Right:
          this.rightMouseClick(type, time, slot, row);
          break;
      }
    }
  }

  private rightMouseClick(type: NineTypes, time: string, slot: number, row: number = 0): void {
    setTimeout(() => {
      const cell = this.rows[time][type][row][slot];
      if (cell.orderId) {
        this.cancelReservation.emit({
          orderId: cell.orderId,
          time: new Date(time)
        });
      }
      this.onMouseUp();
    }, 250);
  }

  private leftMouseClick(type: NineTypes, time: string, slot: number, selected: boolean, row: number = 0, timeIndex: number = 0): void {
    const curRow = this.rows[time][type][row];

    const cell = this.rows[time][type][row][slot];

    this.previousCellMouseDown = cell;
    this.previousTimeIndexMouseDown = timeIndex;
    this.previousMouseDownSlotsFilled = Object.values(curRow).some(c => !!c.userId);

    if (this.cellMouseDown && !cell.selected) {
      this.clearAllSelection();

      cell.selected = (new Date(time) > new Date());

      // Selects entire time row.
      for (let i = 0; i < slot; i++) {
        const target = this.rows[time][type][row][i];
        if (!target.userId && target.time && !target.blocked) {
          target.selected = (new Date(target.time) > new Date());
        }
      }

      // Highlights other cells in any other course columns that are the same order.
      this.onSelectCell(cell);
      this.selectedTime = time;

      let indexCount = 0;
      let indexOfLastReserved = 0;
      let previousReservedIndex = 0;
      let nextReservedIndex = 0;
      Object.keys(this.rows).forEach((time: string) => {
        const column = this.rows[time][type];
        this.iterateTableRowColumn(column, (_row: number, slot: number) => {
          // Keep track of the last time that had reserved slots.
          if (indexCount < timeIndex && column?.[_row]?.[slot]?.userId) {
            indexOfLastReserved = indexCount;
          }

          // Once we reach the currently selected row, save the index of the last
          // row with reserved slots so we know where to start the beginning of
          // our acceptable selection range.
          if (indexCount === timeIndex) {
            previousReservedIndex = indexOfLastReserved;
          }

          // Once we find a row past the currently selected row that has reserved
          // slots, save it so we know where to set the end of our acceptable 
          // selection range.
          if (indexCount > timeIndex && column?.[_row]?.[slot]?.userId && nextReservedIndex === 0) {
            nextReservedIndex = indexCount;
            return;
          }
        });
        indexCount++;
      });

      // Use the indicies found previously to set our acceptable selection range.
      if (previousReservedIndex === 0 && nextReservedIndex === 0) {
        this.acceptableRangeIndicies[0] = 0;
        this.acceptableRangeIndicies[1] = Object.keys(this.rows).length - 1;
      }
      else if (nextReservedIndex === 0) {
        this.acceptableRangeIndicies[0] = previousReservedIndex + 1;
        this.acceptableRangeIndicies[1] = Object.keys(this.rows).length - 1;
      }
      else if (previousReservedIndex === 0) {
        this.acceptableRangeIndicies[0] = 0;
        this.acceptableRangeIndicies[1] = nextReservedIndex - 1;
      }
      else {
        this.acceptableRangeIndicies[0] = previousReservedIndex + 1;
        this.acceptableRangeIndicies[1] = nextReservedIndex - 1;
      }
    }

    // Double click
    if (selected) {
      const front = this.getAllSelected(NineTypes.Front);
      const back = this.getAllSelected(NineTypes.Back);
      const tertiary = this.getAllSelected(NineTypes.Tertiary);
      // Note: Only a range of tee times per nine type can be selected at any one time.
      const selections = (Object.values(front.time).length > 0) ? front : ((Object.values(back.time).length > 0) ? back : tertiary);

      const times = Object.keys(selections.time);
      const slotsSelected = Object.values(selections.time).reduce((total, slots) => total + slots, 0);

      const slotsTaken = Object.values(this.rows[time][type])
        .filter((rtt: TeeSheetTableColumnRows) => Object.values(rtt).filter(_rt => _rt.orderId != undefined))
        .reduce((total, rtt: TeeSheetTableColumnRows) => total + Object.values(rtt).filter(rtt => rtt?.orderId != undefined).length, 0);
      const blockedCells = Object.values(this.rows[time][type])
        .filter((rtt: TeeSheetTableColumnRows) => Object.values(rtt).filter(_rt => _rt.blocked))
        .reduce((total, rtt: TeeSheetTableColumnRows) => total + Object.values(rtt).filter(rtt => rtt?.blocked).length, 0);


      const selectedDateTime = new Date(times[0]);
      const qty = cell.numOfGuests;
      const singleBookingOnly = (slotsTaken > 0 && slotsTaken <= this.slots && qty <= this.slots);
      const groupReservationOnly = (slotsSelected > this.slots || qty > this.slots);
      const canSqueezeTime = ((slotsTaken % 4) == 0) && (slotsTaken < (2 * this.slots)) && !groupReservationOnly;

      // Accounts for squeeze times & group reservations
      const maxSlots = (times.length > 1) ? (times.length * this.slots) : ((slotsTaken > this.slots) ? 2 : 1) * this.slots;
      const availableSlots = Math.max(Math.min((maxSlots - (slotsTaken + blockedCells) + cell.numOfGuests), this.slots), cell.numOfGuests);

      if (cell.blocked && (selectedDateTime > new Date())) {
        const endTime = times.length > 1 ? new Date(times[times.length - 1]) : undefined;
        let blockedReason = "";
        if(cell.event?.name) {
          blockedReason = `Event Reservation: ${cell.event.name}`;
        }
        else if(cell.blockedReason) {
          blockedReason = cell.blockedReason;
        }

        this.removeBlockTime.emit({
          nineType: selections.nineType,
          startTime: selectedDateTime,
          endTime: endTime,
          blockedReason
        });
        this.onMouseUp();
      } else {
        if (cell.orderId) {
          this.selectOrder.emit({
            orderId: cell.orderId,
            queryParam: JSON.stringify({
              selectedDate: selectedDateTime,
              slotsTaken: slotsTaken,
              singleBookingReservationOnly: singleBookingOnly,
              groupReservationOnly: groupReservationOnly,
              availableSlots: availableSlots,
              canSqueezeTime: canSqueezeTime,
              maxSlots: this.slots
            })
          });
        } else if (selectedDateTime > new Date()) {
          this.newReservation.emit(JSON.stringify({
            selectedDate: selectedDateTime,
            selectedTimes: selections.time,
            singleBookingReservationOnly: singleBookingOnly,
            groupReservationOnly: groupReservationOnly,
            slots: slotsSelected,
            blockedSlots: blockedCells,
            availableSlots: availableSlots,
            nineType: selections.nineType
          }));
        }
      }
    }
  }

  private getAllSelected(nineType: NineTypes): { time: { [time: string]: number }, nineType: NineTypes } {
    let selection: { time: { [time: string]: number }, nineType: NineTypes } = {
      time: {},
      nineType: nineType
    };

    Object.keys(this.rows).forEach((time: string) => {
      const row = this.rows[time];
      this.iterateTableRowColumn(row[nineType], (_row: number, slot: number) => {
        if (row?.[nineType]?.[_row]?.[slot]?.selected) {
          selection.time[time] = 1 + (selection.time[time] || 0);
        }
      });
    });

    return selection;
  }

  public onCellMouseEnter(type: NineTypes, time: string, slot: number, row: number = 0, timeIndex: number = 0): void {
    if (this.cellMouseDown && type === this.previousCellMouseDown?.nineType) {
      var lastDownSlot = this.previousCellMouseDown?.slot ?? 0;
      var minSlot = Math.min(lastDownSlot, slot);
      var maxSlot = Math.max(lastDownSlot, slot);

      var minTimeIndex = Math.min(timeIndex, this.previousTimeIndexMouseDown ?? 0);
      var maxTimeIndex = Math.max(timeIndex, this.previousTimeIndexMouseDown ?? 0);

      const grid = this.rows;
      let index: number = 0;
      for (let time in grid) {
        const rows = grid[time][type];
        for (let rowIndex in rows) {
          const row = rows[rowIndex];
          let anySlotsFilled = Object.values(row).some(c => !!c.userId);

          for (let slotIndex in row) {
            let cell = row[slotIndex];
            if (cell.userId) {
              continue;
            }

            if(cell.blocked && !this.previousCellMouseDown?.blocked) {
              continue;
            }

            if(!cell.blocked && this.previousCellMouseDown?.blocked) {
              continue;
            }

            // Don't allow the selection box to be dragged into or away from a time that is already reserved.
            if (index !== this.previousTimeIndexMouseDown && (this.previousMouseDownSlotsFilled === true || anySlotsFilled === true)) {
              continue;
            }

            let cellSlot = cell.slot ?? 0;
            // Highlight cells that are between the cell first selected and the cell most recently entered.
            // Ensure cells being highlighted don't go past a reserved time.
            if (index >= minTimeIndex && index <= maxTimeIndex && ((index === maxTimeIndex && cellSlot <= maxSlot) || index < maxTimeIndex) && index >= this.acceptableRangeIndicies[0] && index <= this.acceptableRangeIndicies[1]) {
              cell.selected = (new Date(time) > new Date());
            }
            else {
              cell.selected = false;
            }
          }
        }
        index++;
      }
    }
  }

  public onCellMouseUp(): void {
    this.cellMouseDown = false;
    this.disableSelection = false;
    this.selectedTime = undefined;
  }

  public onSelectCell(cell: Cell): void {
    if (cell.orderId || cell.blocked) {

      if (cell.orderId) {
        this.selectedUserId = cell.userId;
        this.displayedUserName = cell.numOfGuests > 1 ? `${cell.userName}, Guest | (x${cell.numOfGuests - 1})` : cell.userName;
      }

      const cellNineType = cell.nineType;
      Object.values(this.rows).forEach((row: TeeSheetTableColumns) => {
        this.iterateTableRowColumn(row[NineTypes.Front], (_row: number, slot: number) => {
          const front = row?.[NineTypes.Front]?.[_row]?.[slot];

          if(front) {
            front.selected = ((front.orderId != undefined) && (front.orderId == cell.orderId)) || ((cellNineType == NineTypes.Front) && (front.time == cell.time) && ((front.blocked == true) && (cell.blocked == true)));
          }
        });
        this.iterateTableRowColumn(row[NineTypes.Back], (_row: number, slot: number) => {
          const back = row?.[NineTypes.Back]?.[_row]?.[slot];
          if(back) {
            back.selected = ((back.orderId != undefined) && (back.orderId == cell.orderId)) || ((cellNineType == NineTypes.Back) && (back.time == cell.time) && ((back.blocked == true) && (cell.blocked == true)));
          }
        });
        this.iterateTableRowColumn(row[NineTypes.Tertiary], (_row: number, slot: number) => {
          const tertiary = row?.[NineTypes.Tertiary]?.[_row]?.[slot];
          if(tertiary) {
            tertiary.selected = ((tertiary.orderId != undefined) && (tertiary.orderId == cell.orderId)) || ((cellNineType == NineTypes.Tertiary) && (tertiary.time == cell.time) && ((tertiary.blocked == true) && (cell.blocked == true)));
          }
        });
      });
    }
  }

  private rowMouseDown: boolean = false;
  public onRowMouseDown(time: string): void {
    this.clearAllSelection();

    this.rowMouseDown = true;
    if (this.rowMouseDown && (new Date(time) > new Date())) {
      this.selectRow(time, NineTypes.Front);
    }
  }

  public onRowMouseMove(time: string): void {
    if (this.rowMouseDown && !this.disableSelection && (new Date(time) > new Date())) {
      // Prevents selecting pass a row with an order.
      this.disableSelection = this.anyOrderOnTime(NineTypes.Front, time);
      if (!this.disableSelection) {
        this.selectRow(time, NineTypes.Front);
      }
    }
  }

  public onRowMouseUp(): void {
    this.rowMouseDown = false;
    this.disableSelection = false;
  }

  public onMouseUp(): void {
    this.onRowMouseUp();
    this.onCellMouseUp();
  }

  public selectRow(time: string, type: NineTypes) {
    if (!this.anySlotTaken(this.rows[time][type]) && this.canSelect(type)) {
      const row = this.rows[time];
      this.iterateTableRowColumn(row[type], (_row: number, slot: number) => {
        row[type][_row][slot].selected = true;
      });
    }
  }

  private clearAllSelection(): void {
    this.selectedUserId = undefined;
    this.displayedUserName = undefined;

    Object.values(this.rows).forEach((row: TeeSheetTableColumns) => {
      this.iterateTableRowColumn(row[NineTypes.Front], (_row: number, slot: number) => {
        if(row?.[NineTypes.Front]?.[_row]?.[slot]?.selected) {
          row[NineTypes.Front][_row][slot].selected = false;
        }        
      });
      this.iterateTableRowColumn(row[NineTypes.Back], (_row: number, slot: number) => {
        if(row?.[NineTypes.Back]?.[_row]?.[slot]?.selected) {
          row[NineTypes.Back][_row][slot].selected = false;
        }
      });
      this.iterateTableRowColumn(row[NineTypes.Tertiary], (_row: number, slot: number) => {
        if(row?.[NineTypes.Tertiary]?.[_row]?.[slot]?.selected) {
          row[NineTypes.Tertiary][_row][slot].selected = false;
        }
      });
    });
  }

  private iterateTableRowColumn(row: TeeSheetTableColumnRows, callback: Function): void {
    this.iterateSlots((_row: number) => {
      this.iterateSlots((slot: number) => {
        callback(_row, slot);
      });
    }, Object.keys(row).length);
  }

  public anySlotTaken(row: TeeSheetTableColumnRows): boolean {
    let anySelected = false;

    this.iterateTableRowColumn(row, (_row: number, slot: number) => {
      anySelected = anySelected || (row[_row][slot].userId != undefined);
    });

    return anySelected;
  }

  public anySelected(): boolean {
    const front = this.anyInNineColumnSelected(NineTypes.Front);
    const back = this.anyInNineColumnSelected(NineTypes.Back);
    const tertiary = this.anyInNineColumnSelected(NineTypes.Tertiary);

    return (front || back || tertiary);
  }

  private anyInNineColumnSelected(columnType: NineTypes): boolean {
    let anySelected = false;

    Object.values(this.rows).forEach((row: TeeSheetTableColumns) => {
      this.iterateTableRowColumn(row[columnType], (_row: number, slot: number) => {
        const selected = row[columnType][_row][slot].selected;
        anySelected = anySelected || selected;
      });
    });

    return anySelected;
  }

  private anyOrderOnTime(columnType: NineTypes, time: string): boolean {
    const row = this.rows[time][columnType];

    let anyOrder = false;

    this.iterateTableRowColumn(row, (_row: number, slot: number) => {
      const hasOrder = row[_row][slot].orderId != undefined;
      anyOrder = anyOrder || hasOrder;
    });

    return anyOrder;
  }

  private canSelect(columnType: NineTypes): boolean {
    let front = false;
    let back = false;
    let tertiary = false;

    switch (columnType) {
      case NineTypes.Front:
        back = this.anyInNineColumnSelected(NineTypes.Back);
        tertiary = this.anyInNineColumnSelected(NineTypes.Tertiary);
        return !(back || tertiary);
      case NineTypes.Back:
        front = this.anyInNineColumnSelected(NineTypes.Front);
        tertiary = this.anyInNineColumnSelected(NineTypes.Tertiary);
        return !(front || tertiary);
      case NineTypes.Tertiary:
        front = this.anyInNineColumnSelected(NineTypes.Front);
        back = this.anyInNineColumnSelected(NineTypes.Back);
        return !(front || back);
      default:
        return false;
    }
  }

  timeHasUnpaid(time: string): boolean {
    let hasUnpaid = false;
    Object.values(this.rows[time]).forEach(type => {
      Object.values(type).forEach(row => {
        Object.values(row).forEach((cell: any) => {
          if (cell?.userId && !cell.paid) {
            hasUnpaid = true
          }
        })
      })
    })
    return hasUnpaid;
  }

  getEventUser(userId: number) {
    return this.eventUsers$.pipe(
      take(1),
      map(users => users.find(u => u.id === userId)),
      map(user => {
        let fullName = '';

        if(user?.firstName) {
          fullName += user.firstName;
        }
        if(user?.firstName && user?.lastName) {
          fullName += ' ';
        }
        if(user?.lastName) {
          fullName += user?.lastName;
        }
        if(!fullName) {
          fullName = 'N/A'
        }

        return fullName
      })
    )
  }
}
