import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { UserDetails } from 'projects/shared/src/public-api';

@Component({
  selector: 'gcl-admin-account-view',
  templateUrl: './account-view.component.html',
  styleUrls: ['./account-view.component.scss']
})
export class AccountViewComponent implements OnInit {
  public user$!: Observable<UserDetails>;

  constructor(private authService: AuthService) {
    this.user$ = this.authService.user$;
  }

  ngOnInit(): void {
  }
}
